import {
  ChecklistItemStatusType,
  FormAnswerUpdateRequest,
  FormElementExtended,
  FormElementQuestionType,
  InapplicableBoolAnswer,
  KnownBoolAnswer,
  UnknownBoolAnswer,
} from "@kolibrisoftware/customerportal-api";
import {
  CheckboxInput,
  DateInput,
  FormElementChangeCallback,
  NumberInput,
  RadioGroup,
  TextInput,
  TextareaInput,
} from "@kolibrisoftware/customerportal-ui";
import { format, parseISO } from "date-fns";
import useKnownBoolOptions from "hooks/useKnownBoolOptions";
import useUnknownBoolOption from "hooks/useUnkownBoolOptions";
import { useTheme } from "injectors/theme";
import { isBoolean, isDate, isEmpty, isNumber, isString } from "lodash-es";
import useListOfItemsQuestionAnswer from "modules/list-of-items/hooks/useListOfItemsQuestionAnswer";
import {
  ChangeEvent,
  FC,
  useCallback,
  useEffect,
  useMemo,
  useState,
} from "react";
import { useDispatch, useSelector } from "store/helpers";
import { thunks as documentThunks } from "store/list-of-items";
import { useStyles } from "./styles";

type Props = {
  questionDetails: FormElementExtended;
  name: string;
};

const ForeignQuestionInput: FC<Props> = ({ questionDetails, name }) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const dispatch = useDispatch();
  const outsideQuestionAnswer = useListOfItemsQuestionAnswer(
    questionDetails.id
  );
  const { formEditableStatus } = useSelector(state => state.listOfItems);
  const unknownBoolOptions = useUnknownBoolOption();
  const knownBoolOptions = useKnownBoolOptions();

  const [questionAnswer, setQuestionAnswer] = useState(outsideQuestionAnswer);

  const onAnswerSubmit: FormElementChangeCallback = useCallback(
    event => {
      const val = event.target.value;
      const checked = event.currentTarget.checked;

      if (!questionAnswer || !questionAnswer.id) {
        throw new Error("Missing answer id");
      }

      const updatedAnswer: FormAnswerUpdateRequest = {
        ...questionAnswer,
        formAnswerId: questionAnswer.id,
      };

      switch (questionDetails.questionType) {
        case FormElementQuestionType.Text:
          if (isString(val)) {
            updatedAnswer.textAnswer = val;
          }
          break;
        case FormElementQuestionType.TextArea:
          if (isString(val)) {
            updatedAnswer.textAreaAnswer = val;
          }
          break;
        case FormElementQuestionType.Radio:
          updatedAnswer.radioAnswer = questionDetails.questionOptions?.find(
            item => item.translations?.[0].value === val
          )?.id;
          break;
        case FormElementQuestionType.InapplicableBool:
          updatedAnswer.inapplicableBoolOption = val as InapplicableBoolAnswer;
          break;
        case FormElementQuestionType.UnknownBool:
          updatedAnswer.unknownBoolOption = val as UnknownBoolAnswer;
          break;
        case FormElementQuestionType.KnownBool:
          updatedAnswer.knownBoolOption = val as KnownBoolAnswer;
          break;
        case FormElementQuestionType.Bool:
          if (isBoolean(checked)) {
            updatedAnswer.boolAnswer = checked;
          }
          break;
        case FormElementQuestionType.Date:
          if (!isDate(val) && isString(val) && val) {
            const answer = new Date(val);
            updatedAnswer.dateAnswer = answer;
          }
          break;
        case FormElementQuestionType.DateTime:
          if (!isDate(val) && isString(val) && val) {
            const answer = new Date(val);
            updatedAnswer.dateTimeAnswer = answer;
          }
          break;
        case FormElementQuestionType.Decimal:
          const parsedFloat = parseFloat(val);
          if (isNumber(parsedFloat)) {
            updatedAnswer.decimalAnswer = parsedFloat;
          }
          break;
        case FormElementQuestionType.Int:
          const parsedInt = parseInt(val);
          if (isNumber(parsedInt)) {
            updatedAnswer.intAnswer = parsedInt;
          }
          break;
        default:
          break;
      }

      const isRequiredAndEmpty =
        questionDetails.questionConstraint?.required && isEmpty(val);

      if (isRequiredAndEmpty) {
        return;
      }

      dispatch(
        documentThunks.setQuestionAnswer({
          answerId: questionAnswer.id || "",
          useCase: "foreign",
          updatedAnswerPayload: updatedAnswer,
        })
      );
    },
    [questionAnswer, questionDetails, dispatch]
  );

  const onAnswerChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      switch (questionDetails.questionType) {
        case FormElementQuestionType.Bool:
          setQuestionAnswer(prev => ({
            ...prev,
            boolAnswer: event.target.checked,
          }));
          break;
        case FormElementQuestionType.Date:
          setQuestionAnswer(prev => ({
            ...prev,
            dateAnswer: parseISO(event.target.value),
          }));
          break;
        case FormElementQuestionType.DateTime:
          setQuestionAnswer(prev => ({
            ...prev,
            dateTimeAnswer: parseISO(event.target.value),
          }));
          break;
        case FormElementQuestionType.Decimal:
          setQuestionAnswer(prev => ({
            ...prev,
            decimalAnswer: parseFloat(event.target.value),
          }));
          break;
        case FormElementQuestionType.Int:
          setQuestionAnswer(prev => ({
            ...prev,
            intAnswer: parseInt(event.target.value),
          }));
          break;
        case FormElementQuestionType.Radio:
          setQuestionAnswer(prev => ({
            ...prev,
            radioAnswer: event.target.value,
          }));
          break;
        case FormElementQuestionType.UnknownBool:
          setQuestionAnswer(prev => ({
            ...prev,
            unknownBoolOption: event.target.value as UnknownBoolAnswer,
          }));
          break;
        case FormElementQuestionType.KnownBool:
          setQuestionAnswer(prev => ({
            ...prev,
            knownBoolOption: event.target.value as KnownBoolAnswer,
          }));
          break;
        case FormElementQuestionType.InapplicableBool:
          setQuestionAnswer(prev => ({
            ...prev,
            inapplicableBoolOption: event.target
              .value as InapplicableBoolAnswer,
          }));
          break;
        case FormElementQuestionType.Text:
          setQuestionAnswer(prev => ({
            ...prev,
            textAnswer: event.target.value,
          }));
          break;
        case FormElementQuestionType.TextArea:
          setQuestionAnswer(prev => ({
            ...prev,
            textAreaAnswer: event.target.value,
          }));
          break;
        default:
          break;
      }
    },
    [questionDetails.questionType, setQuestionAnswer]
  );

  const inputElement = useMemo(() => {
    let dateVal = "";
    const isFormEditable = formEditableStatus === ChecklistItemStatusType.Open;
    switch (questionDetails.questionType) {
      case FormElementQuestionType.Text:
        return (
          <TextInput
            name={name}
            value={questionAnswer?.textAnswer || ""}
            onChange={onAnswerChange}
            onBlur={onAnswerSubmit}
            disabled={!isFormEditable}
          />
        );
      case FormElementQuestionType.Radio:
        const radioOptions =
          questionDetails.questionOptions
            ?.map(option => option.translations?.[0].value)
            .map(option => {
              return {
                value: option || "",
                label: option || "",
              };
            }) || [];
        const columnView = radioOptions?.length > 3;

        const answerOptionId = questionAnswer?.radioAnswer;
        const answerValue =
          questionDetails.questionOptions?.find(
            item => item.id === answerOptionId
          )?.translations?.[0].value || "";

        return (
          <RadioGroup
            showLabels={true}
            name={name}
            value={answerValue}
            values={radioOptions || []}
            onChange={(event: ChangeEvent<HTMLInputElement>) => {
              onAnswerChange(event);
              onAnswerSubmit(event);
            }}
            disabled={!isFormEditable}
          />
        );
      case FormElementQuestionType.UnknownBool:
        return (
          <RadioGroup
            showLabels={true}
            name={name}
            value={questionAnswer?.unknownBoolOption}
            values={unknownBoolOptions || []}
            onChange={onAnswerSubmit}
            disabled={!isFormEditable}
          />
        );
      case FormElementQuestionType.KnownBool:
        return (
          <RadioGroup
            showLabels={true}
            name={name}
            value={questionAnswer?.knownBoolOption}
            values={knownBoolOptions || []}
            onChange={onAnswerSubmit}
            disabled={!isFormEditable}
          />
        );
      case FormElementQuestionType.Bool:
        return (
          <CheckboxInput
            name={name}
            value={questionAnswer?.boolAnswer ?? false}
            onChange={onAnswerSubmit}
            disabled={!isFormEditable}
          />
        );
      case FormElementQuestionType.Date:
        if (questionAnswer?.dateAnswer) {
          dateVal = format(
            parseISO(new Date(questionAnswer.dateAnswer).toISOString()),
            "yyyy-MM-dd"
          );
        }

        return (
          <DateInput
            name={name}
            value={dateVal ?? ""}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              onAnswerChange(e);
              onAnswerSubmit(e);
            }}
            disabled={!isFormEditable}
          />
        );
      case FormElementQuestionType.DateTime:
        if (questionAnswer?.dateTimeAnswer) {
          dateVal = format(
            parseISO(new Date(questionAnswer.dateTimeAnswer).toISOString()),
            "yyyy-MM-dd"
          );
        }
        return (
          <DateInput
            type={"datetime-local"}
            name={name}
            value={dateVal ?? ""}
            onChange={(e: ChangeEvent<HTMLInputElement>) => {
              onAnswerChange(e);
              onAnswerSubmit(e);
            }}
            disabled={!isFormEditable}
          />
        );
      case FormElementQuestionType.Decimal:
        return (
          <NumberInput
            name={name}
            value={questionAnswer?.decimalAnswer ?? ""}
            onChange={onAnswerChange}
            onBlur={onAnswerSubmit}
            disabled={!isFormEditable}
          />
        );
      case FormElementQuestionType.Int:
        return (
          <NumberInput
            name={name}
            value={questionAnswer?.intAnswer ?? ""}
            onChange={onAnswerChange}
            onBlur={onAnswerSubmit}
            disabled={!isFormEditable}
          />
        );
      case FormElementQuestionType.MultiSelect:
        // TODO -> New component maybe?
        // console.log(questionDetails.questionOptions);
        // console.log(questionAnswer);
        return <p>Unhandled Multiselect</p>;

      case FormElementQuestionType.TextArea:
        return (
          <TextareaInput
            className={styles.textarea}
            name={name}
            value={questionAnswer?.textAreaAnswer || ""}
            onChange={onAnswerChange}
            onBlur={onAnswerSubmit}
            disabled={!isFormEditable}
          />
        );

      default:
        return (
          <div>
            {/* <TextInput
              name={name}
              value={questionAnswer?.textAnswer}
              onBlur={onAnswerSubmit}
              disabled={!isFormEditable}
            /> */}
          </div>
        );
    }
  }, [
    questionAnswer,
    questionDetails,
    name,
    formEditableStatus,
    unknownBoolOptions,
    knownBoolOptions,
    styles.textarea,
    onAnswerSubmit,
    onAnswerChange,
  ]);

  useEffect(() => {
    setQuestionAnswer(outsideQuestionAnswer);
  }, [outsideQuestionAnswer]);

  return <div className={styles.container}>{inputElement}</div>;
};

export { ForeignQuestionInput };
