import {
  ChecklistItemStatusType,
  UserForm,
  UserFormAnswer,
  FormCustomQuestionExtended,
  FormElementExtended,
} from "@kolibrisoftware/customerportal-api";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AnswerSavingStatus } from "enums/answer-saving-status";
import { isUndefined } from "lodash-es";
import { FormLoadingStatus } from "modules/list-of-items/enums/form-loading-status";
import { thunks as listOfItemsThunks } from "./index";

type State = {
  formStructure: UserForm;
  populatedElements: FormElementExtended[];
  customPopulatedElements: FormCustomQuestionExtended[];
  answers: UserFormAnswer[];
  formLoadingStatus: FormLoadingStatus;
  savingStatus: AnswerSavingStatus;
  formEditableStatus: ChecklistItemStatusType;
  isDeletingQuestion: boolean;
};

const initialState: State = {
  formStructure: {},
  populatedElements: [],
  customPopulatedElements: [],
  answers: [],
  formLoadingStatus: FormLoadingStatus.Loading,
  savingStatus: AnswerSavingStatus.Saved,
  formEditableStatus: ChecklistItemStatusType.Open,
  isDeletingQuestion: false,
};

export const slice = createSlice({
  name: "list-of-items",
  initialState: initialState,
  reducers: {
    clearState: state => {
      return initialState;
    },
    setFormLoadingStatus: (state, action: PayloadAction<FormLoadingStatus>) => {
      return { ...state, formLoadingStatus: action.payload };
    },
    setAnswerSavingStatus: (
      state,
      action: PayloadAction<AnswerSavingStatus>
    ) => {
      return { ...state, savingStatus: action.payload };
    },
    setFormStructure: (state, action: PayloadAction<UserForm>) => {
      return { ...state, formStructure: action.payload };
    },
    setElements: (state, action: PayloadAction<FormElementExtended[]>) => {
      return { ...state, populatedElements: action.payload };
    },
    setAnswers: (state, action: PayloadAction<UserFormAnswer[]>) => {
      return { ...state, answers: action.payload };
    },
    setQuestionAnswer: (state, action: PayloadAction<UserFormAnswer>) => {
      const updatedAnswers = [...state.answers];
      const currentAnswerIndex = updatedAnswers.findIndex(
        answer => answer.id === action.payload.id
      );
      if (currentAnswerIndex < 0) {
        updatedAnswers.push(action.payload);
      } else {
        updatedAnswers[currentAnswerIndex] = action.payload;
      }

      return { ...state, answers: [...updatedAnswers] };
    },

    setCustomQuestions: (
      state,
      action: PayloadAction<FormCustomQuestionExtended[]>
    ) => {
      return { ...state, customPopulatedElements: action.payload };
    },

    addCustomQuestion: (
      state,
      action: PayloadAction<{
        sectionId: string;
        questionData: FormCustomQuestionExtended;
        questionAnswer: UserFormAnswer;
      }>
    ) => {
      const { sectionId, questionData, questionAnswer } = action.payload;

      const formStructureCopy = JSON.parse(
        JSON.stringify(state.formStructure)
      ) as UserForm;

      const newStructureParts = formStructureCopy.parts?.map(part => {
        const sectionIndex = part.elements?.findIndex(
          section => section.id === sectionId
        );

        if (sectionIndex && sectionIndex > -1) {
          part.elements?.[sectionIndex].customQuestions?.push(questionData);
        }

        return part;
      });

      return {
        ...state,
        customPopulatedElements: [
          ...state.customPopulatedElements,
          questionData,
        ],
        answers: [...state.answers, questionAnswer],
        formStructure: { ...state.formStructure, parts: newStructureParts },
      };
    },
    deleteCustomQuestion: (state, action: PayloadAction<string>) => {
      const questionId = action.payload;
      const updatedCustomElements = [...state.customPopulatedElements];
      const questionIndex = updatedCustomElements.findIndex(
        item => item.id === questionId
      );
      if (questionIndex) {
        updatedCustomElements.splice(questionIndex, 1);
      }
      return {
        ...state,
        customPopulatedElements: [...updatedCustomElements],
      };
    },
    updateCustomQuestion: (
      state,
      action: PayloadAction<FormCustomQuestionExtended>
    ) => {
      const questionData = action.payload;
      const updatedCustomElements = [...state.customPopulatedElements];
      const questionIndex = updatedCustomElements.findIndex(
        item => item.id === questionData.id
      );
      updatedCustomElements[questionIndex] = questionData;
      return { ...state, customPopulatedElements: [...updatedCustomElements] };
    },
    pushModifiedAnswer: (state, action: PayloadAction<string>) => {
      const newModifiedAnswer = action.payload;

      const updatedModifiedAnswers = [
        ...(state.formStructure.latestModifiedAnswerIds || []),
      ];

      if (updatedModifiedAnswers.includes(newModifiedAnswer)) {
        return state;
      }

      updatedModifiedAnswers.push(newModifiedAnswer);

      return {
        ...state,
        formStructure: {
          ...state.formStructure,
          latestModifiedAnswerIds: updatedModifiedAnswers,
        },
      };
    },
    setFormEditableStatus: (
      state,
      action: PayloadAction<ChecklistItemStatusType>
    ) => {
      return { ...state, formEditableStatus: action.payload };
    },
    removeModifiedAnswer: (
      state,
      action: PayloadAction<string | null | undefined>
    ) => {
      const modifiedAnswerId = action.payload;

      if (!modifiedAnswerId) {
        return state;
      }

      const updatedModifiedAnswerIds =
        state.formStructure.latestModifiedAnswerIds?.filter(
          id => id !== modifiedAnswerId
        ) || [];

      return {
        ...state,
        formStructure: {
          ...state.formStructure,
          latestModifiedAnswerIds: [...updatedModifiedAnswerIds],
        },
      };
    },
  },

  extraReducers: builder => {
    builder.addCase(listOfItemsThunks.deleteCustomQuestion.pending, state => {
      state.isDeletingQuestion = true;
    });
    builder.addCase(listOfItemsThunks.deleteCustomQuestion.fulfilled, state => {
      state.isDeletingQuestion = false;
    });
    builder.addCase(listOfItemsThunks.deleteCustomQuestion.rejected, state => {
      state.isDeletingQuestion = false;
    });
  },
});
