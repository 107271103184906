import { FC, useMemo } from "react";
import {
  PhotoGalleryImage,
  PhotoGalleryWithSidebar,
} from "shared/components/photo-gallery-with-sidebar/photo-gallery-with-sidebar";
import { useSelector } from "store/helpers";
import { v4 as uuid } from "uuid";
type Props = {};

const ObjectGroundplan: FC<Props> = props => {
  const { maps: groundPlans } = useSelector(
    state => state.objectPreview.assignmentPreview
  );

  const galleryImages: PhotoGalleryImage[] | undefined = useMemo(() => {
    return groundPlans?.map(groundPlan => ({
      id: uuid(),
      big: groundPlan.url || "assets/empty-brochure-preview.svg",
    }));
  }, [groundPlans]);

  return <PhotoGalleryWithSidebar images={galleryImages || []} />;
};

export { ObjectGroundplan };
