import { actions as mainActions } from "store/main";
import { createErrorToast } from "helpers/create-new-toast";
import { createThunk } from "store/helpers";
import { actions as toastActions } from "store/toasts";
import { actions as dossierActions } from "store/dossier";
import { actions as assignmentRelationActions } from "store/assignment-relation";
import {
  assignmentApi,
  checklistItemApi,
  documentApi,
  relationApi,
} from "store/api";
import { PageLoadingStatus } from "enums/page-loading-status";
import { extendChecklistItems } from "helpers/extend-checklist-items";
import { AppStatus } from "enums/app-status";
import { Client, Culture } from "@kolibrisoftware/customerportal-api";

export type GetAssignmentDataPayload = {
  assignmentId: string;
};

const loadAssignment = createThunk(
  "dossier/loadAssignment",
  async ({ dispatch, getState }, args: GetAssignmentDataPayload) => {
    const state = getState();
    const realEstateAgencyId = state.main.companyData?.realEstateAgencyId;
    const { assignmentId } = args;

    try {
      if (!realEstateAgencyId || !assignmentId) {
        return;
      }

      dispatch(dossierActions.setLoadingStatus(PageLoadingStatus.Loading));

      const currentAssignment = await dispatch(
        assignmentApi.getAssignmentData({
          assignmentId,
          realEstateAgencyId: realEstateAgencyId || "",
        })
      ).unwrap();

      if (!currentAssignment) {
        throw new Error("404 - Assignment not found");
      }

      dispatch(dossierActions.setAssignmentDetails(currentAssignment));
      if (currentAssignment.displayName) {
        document.title = currentAssignment.displayName;
      }

      const assignmentRelations = await dispatch(
        assignmentApi.searchAssignmentRelations({
          realEstateAgencyId,
          assignmentId,
        })
      ).unwrap();
      if (assignmentRelations) {
        dispatch(dossierActions.setAssignmentRelations(assignmentRelations));
      }

      const culture = await dispatch(getCulture());

      const documents = await dispatch(
        documentApi.searchDocuments({
          realEstateAgencyId,
          assignmentId,
          searchAll: true,
          // requestBody: {
          //   documentStatuses: [
          //     DocumentStatus.Accepted,
          //     DocumentStatus.Uploaded,
          //   ],
          // },
        })
      ).unwrap();
      if (documents) {
        dispatch(dossierActions.setDocuments(documents));
      }

      const employees = await dispatch(
        assignmentApi.searchAssignmentEmployees({ realEstateAgencyId })
      ).unwrap();

      dispatch(dossierActions.setEmployeeList(employees || []));

      dispatch(loadChecklistItems({ culture: culture.payload as Culture }));
    } catch (error: any) {
      const errorMessage = `Could not retrieve the current assignment with assignment id : ${assignmentId}`;
      const toast = createErrorToast(errorMessage);
      dispatch(toastActions.addToast(toast));

      dispatch(mainActions.setAppStatus(AppStatus.Failed));
      throw error;
    }
  }
);

const loadChecklistItems = createThunk(
  "dossier/loadChecklistItems",
  async ({ dispatch, getState }, args: { culture: Culture }) => {
    const culture = args.culture;
    const state = getState();
    const realEstateAgencyId = state.main.companyData?.realEstateAgencyId || "";
    const assignmentId = state.dossier.assignmentDetails.id || "";
    const assignmentRelations = state.dossier.assignmentRelations;
    const documents = state.dossier.documents;

    try {
      const checklistItems = await dispatch(
        checklistItemApi.searchChecklistItems({
          realEstateAgencyId,
          assignmentId,
          requestBody: {
            skip: 0,
            take: 100,
            culture: culture,
          },
        })
      ).unwrap();

      if (checklistItems) {
        dispatch(dossierActions.setChecklistItems(checklistItems));
      }

      const extendedChecklistItems = extendChecklistItems(
        checklistItems || [],
        assignmentRelations || [],
        documents
      );
      if (extendedChecklistItems) {
        dispatch(
          dossierActions.setExtendedChecklistItems(extendedChecklistItems)
        );
      }
    } catch (error) {}
  }
);

const updateAssignmentRelations = createThunk(
  "dossier/updateAssignmentRelations",
  async ({ getState, dispatch }) => {
    const state = getState();
    const realEstateAgencyId = state.main.companyData?.realEstateAgencyId || "";
    const assignmentId = state.dossier.assignmentDetails.id || "";

    const assignmentRelations = await dispatch(
      assignmentApi.searchAssignmentRelations({
        realEstateAgencyId,
        assignmentId,
      })
    ).unwrap();
    if (assignmentRelations) {
      dispatch(dossierActions.setAssignmentRelations(assignmentRelations));
    }
  }
);

const updateChecklistItem = createThunk(
  "dossier/updateChecklistItem",
  async ({ getState, dispatch }, id: string) => {
    try {
      const state = getState();
      const realEstateAgencyId = state.main.companyData?.realEstateAgencyId;
      const dossierId = state.dossier.assignmentDetails.id;
      const culture = state.main.culture;

      const checklistItems = await dispatch(
        checklistItemApi.searchChecklistItems({
          realEstateAgencyId: realEstateAgencyId || "",
          assignmentId: dossierId || "",
          requestBody: {
            skip: 0,
            take: 100,
            ids: [id],
            culture: culture,
          },
        })
      ).unwrap();
      if (checklistItems?.[0]) {
        dispatch(dossierActions.updateChecklistItem(checklistItems[0]));
      }

      return checklistItems?.[0];
    } catch (error) {
      throw error;
    }
  }
);

type UpdateDocumentArgs = {
  documentId?: string;
};
const updateDocument = createThunk(
  "dossier/updateDocument",
  async ({ getState, dispatch }, args: UpdateDocumentArgs) => {
    try {
      const { documentId = "" } = args;
      const state = getState();
      const realEstateAgencyId =
        state.main?.companyData?.realEstateAgencyId || "";
      const assignmentId = state.dossier.assignmentDetails.id || "";

      const result = await dispatch(
        documentApi.searchDocuments({
          realEstateAgencyId,
          assignmentId,
          requestBody: {
            skip: 0,
            take: 100,
            ids: [documentId],
          },
        })
      ).unwrap();

      dispatch(dossierActions.updateDocument(result[0]));
    } catch (error) {
      throw error;
    }
  }
);

const updateRelationCulture = createThunk(
  "dossier/updateRelationCulture",
  async ({ getState, dispatch, userId }, args: { culture: Culture }) => {
    try {
      const state = getState();
      const realEstateAgencyId = state.main.companyData?.realEstateAgencyId;
      const assignmentRelations = state.dossier.assignmentRelations || [];
      const id = await userId();
      const relationId = assignmentRelations.find(
        relation => relation.subId === id
      )?.relationId;
      const { culture } = args;

      if (!realEstateAgencyId) {
        throw new Error("No real estate agency id");
      }
      if (!relationId) {
        throw new Error("No relation found for current user");
      }

      const result = await dispatch(
        relationApi.setDefaultLanguage({
          realEstateAgencyId,
          id: relationId,
          culture: culture,
        })
      ).unwrap();

      return result;
    } catch (error) {
      throw error;
    }
  }
);

const getCulture = createThunk(
  "dossier/getCulture",
  async ({ userId, getState, dispatch, settings, http }) => {
    try {
      const state = getState();
      const id = await userId();

      const companyId = state.main.companyData?.realEstateAgencyId || "";

      const assignmentRelations = state.dossier.assignmentRelations || [];

      const currentLoggedInRelation = assignmentRelations.find(
        relation => relation.subId === id
      );

      if (!currentLoggedInRelation || !currentLoggedInRelation.relationId) {
        throw new Error(`No relation with id ${id} found`);
      }
      const client = new Client(settings?.apiUrlCustomerPortal, http);

      const relation = await client.getRelation(
        companyId,
        currentLoggedInRelation?.relationId
      );

      const languages = relation.languages;

      if (!languages) {
        throw new Error("No languages found");
      }

      dispatch(assignmentRelationActions.setAvailableLanguages(languages));

      const defaultCulture =
        languages.find(culture => culture.isDefault)?.culture || Culture.Nl;

      dispatch(mainActions.setCulture(defaultCulture));

      return defaultCulture;
    } catch (error) {
      throw error;
    }
  }
);

const thunks = {
  loadAssignment,
  updateChecklistItem,
  loadChecklistItems,
  updateDocument,
  updateAssignmentRelations,
  getCulture,
  updateRelationCulture,
};

export default thunks;
