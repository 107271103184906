import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  carouselContent: {
    position: "relative",
    width: "100%",
    paddingTop: "56.25%",
    display: "flex",
    justifyContent: "center",
    overflow: "hidden",
  },
  carouselImage: {
    position: "absolute",
    top: 0,
    left: 0,
    bottom: 0,
    right: 0,
    width: "100%",
    maxHeight: "100%",
    objectFit: "cover",
  },
  missingImage: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "120%",
    position: "absolute",
    height: "100%",
    backgroundSize: "cover",
    backgroundPosition: "center",
    top: 0,
    color: theme.colors.offWhite.toString(),
  },
  missingImageText: {
    fontSize: 16,
    fontWeight: 600,
  },
  missinImageIcon: {
    fontSize: 60,
    margin: "20px 0",

    backgroundSize: "cover",
  },
  carouselControllers: {
    bottom: "5%",
    position: "absolute",
  },
}));

export { useStyles };
