import {
  AssignmentPreviewResponse,
  AssignmentRelationEntity,
  Culture,
  Document,
  Employee,
  SingleAssignment,
  SingleChecklistItem,
  SingleRelationLanguage,
} from "@kolibrisoftware/customerportal-api";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageLoadingStatus } from "enums/page-loading-status";
import {
  extendChecklistItems,
  ExtendedChecklistItem,
} from "helpers/extend-checklist-items";
import { checklistItemApi } from "store/api";
import { thunks as dossierThunks } from ".";

type State = {
  assignmentDetails: SingleAssignment;
  assignmentPreview: AssignmentPreviewResponse;
  assignmentRelations: AssignmentRelationEntity[];
  checklistItems: SingleChecklistItem[];
  documents: Document[];
  extendedChecklistItems: ExtendedChecklistItem[];
  loadingStatus: PageLoadingStatus;
  availableLanguages: SingleRelationLanguage[];
  employees: Employee[];
};

const initialState: State = {
  assignmentDetails: {},
  assignmentPreview: {},
  assignmentRelations: [],
  checklistItems: [],
  documents: [],
  extendedChecklistItems: [],
  loadingStatus: PageLoadingStatus.Pending,
  availableLanguages: [],
  employees: [],
};

export const slice = createSlice({
  name: "assignment",
  initialState: initialState,
  reducers: {
    clearState: () => {
      return initialState;
    },
    setLoadingStatus: (state, action: PayloadAction<PageLoadingStatus>) => {
      return { ...state, loadingStatus: action.payload };
    },
    setAssignmentDetails: (state, action: PayloadAction<SingleAssignment>) => {
      return { ...state, assignmentDetails: action.payload };
    },
    setEmployeeList: (state, action: PayloadAction<Employee[]>) => {
      return { ...state, employees: action.payload };
    },
    setAssignmentRelations: (
      state,
      action: PayloadAction<AssignmentRelationEntity[]>
    ) => {
      return { ...state, assignmentRelations: action.payload };
    },
    setChecklistItems: (
      state,
      action: PayloadAction<SingleChecklistItem[]>
    ) => {
      return { ...state, checklistItems: action.payload };
    },
    setExtendedChecklistItems: (
      state,
      action: PayloadAction<ExtendedChecklistItem[]>
    ) => {
      return { ...state, extendedChecklistItems: action.payload };
    },
    updateChecklistItem: (
      state,
      action: PayloadAction<SingleChecklistItem>
    ) => {
      const stateCopy: State = JSON.parse(JSON.stringify(state));
      const {
        assignmentRelations,
        documents,
        checklistItems: newChecklistItems,
      } = stateCopy;

      const itemIndex = newChecklistItems.findIndex(
        item => item.id === action.payload.id
      );
      if (itemIndex < 0) {
        newChecklistItems.unshift(action.payload);
      } else {
        newChecklistItems[itemIndex] = action.payload;
      }

      const extended = extendChecklistItems(
        newChecklistItems,
        assignmentRelations,
        documents
      );
      return {
        ...state,
        checklistItems: newChecklistItems,
        extendedChecklistItems: extended,
      };
    },
    removeChecklistItem: (state, action: PayloadAction<string>) => {
      const checklistId = action.payload;
      const stateCopy: State = JSON.parse(JSON.stringify(state));

      const { documents, assignmentRelations, checklistItems } = stateCopy;

      const newChecklistItems = checklistItems.filter(
        checklistItem => checklistItem.id !== checklistId
      );

      const extended = extendChecklistItems(
        newChecklistItems,
        assignmentRelations,
        documents
      );

      return {
        ...state,
        checklistItems: newChecklistItems,
        extendedChecklistItems: extended,
      };
    },
    setDocuments: (state, action: PayloadAction<Document[]>) => {
      return { ...state, documents: action.payload };
    },
    updateDocument: (state, action: PayloadAction<Document>) => {
      const stateCopy: State = JSON.parse(JSON.stringify(state));
      const {
        assignmentRelations,
        documents: newDocumentsList,
        checklistItems,
      } = stateCopy;

      const itemIndex = newDocumentsList.findIndex(
        item => item.id === action.payload.id
      );
      if (itemIndex < 0) {
        newDocumentsList.unshift(action.payload);
      } else {
        newDocumentsList[itemIndex] = action.payload;
      }

      const extended = extendChecklistItems(
        checklistItems,
        assignmentRelations,
        newDocumentsList
      );

      return {
        ...state,
        documents: newDocumentsList,
        extendedChecklistItems: extended,
      };
    },
    removeDocument: (state, action: PayloadAction<string>) => {
      const documentId = action.payload;
      const stateCopy: State = JSON.parse(JSON.stringify(state));

      const {
        documents: newDocumentsList,
        assignmentRelations,
        checklistItems,
      } = stateCopy;

      const filteredDocuments = newDocumentsList.filter(
        document => document.id !== documentId
      );

      const extended = extendChecklistItems(
        checklistItems,
        assignmentRelations,
        filteredDocuments
      );

      return {
        ...state,
        documents: filteredDocuments,
        extendedChecklistItems: extended,
      };
    },
  },
  extraReducers: builder => {
    builder.addCase(
      checklistItemApi.sendForReview.fulfilled,
      (state, action) => {
        const stateCopy: State = JSON.parse(JSON.stringify(state));
        const {
          assignmentRelations,
          documents,
          checklistItems: newChecklistItems,
        } = stateCopy;
        const newItem = action.payload.checklistItem;

        const itemIndex = newChecklistItems.findIndex(
          item => item.id === newItem?.id
        );
        if (itemIndex && newItem) {
          newChecklistItems[itemIndex] = newItem;
        }
        const extended = extendChecklistItems(
          newChecklistItems,
          assignmentRelations,
          documents
        );
        return {
          ...state,
          checklistItems: newChecklistItems,
          extendedChecklistItems: extended,
        };
      }
    );
    builder.addCase(
      dossierThunks.updateAssignmentRelations.fulfilled,
      state => {
        const stateCopy: State = JSON.parse(JSON.stringify(state));
        const { assignmentRelations, documents, checklistItems } = stateCopy;
        const extended = extendChecklistItems(
          checklistItems,
          assignmentRelations,
          documents
        );
        return { ...state, extendedChecklistItems: extended };
      }
    );
    builder.addCase(dossierThunks.loadAssignment.fulfilled, state => {
      return { ...state, loadingStatus: PageLoadingStatus.Success };
    });
    builder.addCase(dossierThunks.loadAssignment.rejected, state => {
      return { ...state, loadingStatus: PageLoadingStatus.Failed };
    });
  },
});
