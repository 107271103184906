import { InapplicableBoolAnswer } from "@kolibrisoftware/customerportal-api";
import { useTranslation } from "@kolibrisoftware/hooks-util";
import { useMemo } from "react";
import { RadioGroupItem } from "@kolibrisoftware/customerportal-ui";

const useInaplicableBoolOptions = () => {
  const t = useTranslation();
  const inaplicableBoolKeysList = useMemo(
    () => Object.keys(InapplicableBoolAnswer),
    []
  );
  const inaplicableBookOptions: RadioGroupItem<string>[] = useMemo(() => {
    return inaplicableBoolKeysList.map(item => {
      const labelValue = t(`inaplicable.option.${item}`);
      return { label: labelValue, value: item };
    });
  }, [t, inaplicableBoolKeysList]);

  return inaplicableBookOptions;
};

export default useInaplicableBoolOptions;
