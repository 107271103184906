import { ParsedRow, RenderType } from "helpers/template-builder/types";
import { useTheme } from "injectors/theme";
import { FC, useMemo } from "react";
import { Pill } from "@kolibrisoftware/customerportal-ui";
import { useStyles } from "./styles";
import EnergyLabel from "shared/components/energy-label";

type Props = {
  rowData: ParsedRow;
};

const ObjectDetailsRow: FC<Props> = ({ rowData }) => {
  const theme = useTheme();
  const styles = useStyles({ theme });

  const rowOption = useMemo(() => {
    switch (rowData.option.renderType) {
      case RenderType.EnergyLabel:
        return <EnergyLabel energyClass={rowData.option.value} />;
      case RenderType.PillsArray:
        if (rowData.option.isArray) {
          return rowData.option.value.map((item: string, index: number) => (
            <Pill
              radius="SM"
              key={index}
              style={{ backgroundColor: theme.colors.darkGray.toString() }}
            >
              {item}
            </Pill>
          ));
        }
        return (
          <Pill
            radius="SM"
            style={{ backgroundColor: theme.colors.darkGray.toString() }}
          >
            {rowData.option.value}
          </Pill>
        );
      default:
        return (
          <div className={styles.textOptionWrapper}>{rowData.option.value}</div>
        );
    }
  }, [rowData.option, styles, theme]);

  return (
    <div className={styles.detailsRowWrapper}>
      <div className={styles.labelWrapper}>{rowData.label}</div>
      <div className={styles.optionsWrapper}>{rowOption}</div>
    </div>
  );
};

export { ObjectDetailsRow };
