import noop from "lodash-es/noop";
import React, {
  createContext,
  FC,
  useCallback,
  useEffect,
  useRef,
  useState,
} from "react";
import {
  mapIntersectionObserverEntriesToRange,
  Range,
} from "./helpers/calculate-ranges";

type ContextProps = {
  create: (element: HTMLDivElement) => void;
  destroy: () => void;
  addListener: (element: HTMLDivElement | null, callback: Callback) => void;
  removeListener: (element: HTMLDivElement | null) => void;
  initialScroll: (elementId: string) => void;
  scrollToElement: (elementId: string) => void;
};
type Props = {
  threshold?: number;
  children?: React.ReactNode;
};
type Callback = (isInView: boolean) => void;
type CallbackRef = { ref: HTMLDivElement; callback: Callback };

export const Context = createContext<ContextProps>({
  create: noop,
  destroy: noop,
  addListener: noop,
  removeListener: noop,
  initialScroll: noop,
  scrollToElement: noop,
});

export const IntersectionObserverInjector: FC<Props> = ({
  children,
  threshold,
}) => {
  const [observer, setObserver] = useState<IntersectionObserver | null>(null);
  const [ranges, setRanges] = useState<Range[]>([]);
  const [callbacks, setCallbacks] = useState<CallbackRef[]>([]);

  const inRange = useRef<HTMLDivElement>();
  const userScrollingTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const initialScrollComplete = useRef(false);

  const disableScrollTimeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [scrollDisabled, setScrollDisabled] = useState(false);

  const create = useCallback(
    (root: HTMLDivElement) => {
      if (observer) {
        return;
      }

      const createdObserver = new IntersectionObserver(
        (
          entries: IntersectionObserverEntry[],
          observer: IntersectionObserver
        ) => {
          if (!ranges.length) {
            const newRanges = mapIntersectionObserverEntriesToRange(
              entries,
              observer
            );
            setRanges((prevState: Range[]) => {
              if (prevState.length >= newRanges.length) return prevState;
              else return newRanges;
            });
          }
        },
        {
          root,
          threshold: threshold || 0.1,
        }
      );
      setObserver(createdObserver);
    },
    [threshold, observer, ranges]
  );

  const scrollWithNoEvent = (scrollCallback: () => void) => {
    setScrollDisabled(true);
    scrollCallback();
  };

  useEffect(() => {
    if (scrollDisabled) {
      disableScrollTimeoutRef.current = setTimeout(() => {
        setScrollDisabled(false);
      }, 1500);
    }

    return () => {
      if (disableScrollTimeoutRef.current)
        clearTimeout(disableScrollTimeoutRef.current);
    };
  }, [scrollDisabled]);

  const scrollToElement = useCallback(
    (elementId: string, opt?: { behavior: ScrollBehavior | "instant" }) => {
      const scroll = () => {
        if (!!observer?.root && ranges) {
          const observerRootAsHTML = observer.root as HTMLDivElement;
          const elementRange = ranges.find(
            item => item.ref.dataset.id === elementId
          );

          const observerRect = observerRootAsHTML.getBoundingClientRect();
          const elementHeight = elementRange?.height ?? 1;
          let scrollTopDiff = 40;

          if (elementHeight < observerRect.height / 2) {
            scrollTopDiff = observerRect.height / 4;
          }
          if (elementRange?.start) {
            observerRootAsHTML.scrollTo({
              top: elementRange.start - scrollTopDiff,
              // @ts-ignore
              behavior: opt?.behavior || "smooth",
            });
          }
        }
      };
      scrollWithNoEvent(scroll);
    },
    [ranges, observer?.root]
  );

  const initialScroll = useCallback(
    (elementId: string) => {
      if (initialScrollComplete.current) return;
      if (!!observer?.root && ranges) {
        const elementRange = ranges.find(
          item => item.ref.dataset.id === elementId
        );

        if (elementRange?.start) {
          scrollToElement(elementId, { behavior: "instant" });
          const callback = callbacks.find(
            callback => callback.ref === elementRange.ref
          );
          if (callback) {
            setTimeout(() => {
              callback.callback(true);
            }, 500);
          }
          initialScrollComplete.current = true;
        }
      }
    },
    [callbacks, observer?.root, ranges, scrollToElement]
  );

  const destroy = useCallback(() => {
    if (observer) {
      observer.disconnect();
      setObserver(null);
    }
  }, [observer]);

  const addListener = useCallback(
    (ref: HTMLDivElement | null, callback: Callback) => {
      if (ref) {
        setCallbacks(callbacks => [...callbacks, { ref, callback }]);
        observer?.observe(ref);
      }
    },
    [observer]
  );

  const removeListener = useCallback(
    (element: HTMLDivElement | null) => {
      if (element) {
        observer?.unobserve(element);
        setCallbacks(callbacks =>
          callbacks.filter(callback => callback.ref === element)
        );
      }
    },
    [observer]
  );

  const handleScrollCallback = useCallback(() => {
    if (scrollDisabled) return;

    const observerAsHTML = observer?.root as HTMLDivElement;
    const topOffset = observerAsHTML.getBoundingClientRect().height / 2;
    const scrollPosition = observerAsHTML.scrollTop + topOffset;
    const activeRange = ranges.find(
      range => range.start < scrollPosition && range.stop > scrollPosition
    );
    const callback = callbacks.find(
      callback => callback.ref === activeRange?.ref
    );

    if (userScrollingTimeoutRef.current) {
      clearTimeout(userScrollingTimeoutRef.current);
    }
    userScrollingTimeoutRef.current = setTimeout(() => {
      callback?.callback(true);
    }, 150);

    const prevInRange = inRange.current;

    if (activeRange && activeRange.ref !== prevInRange) {
      inRange.current = activeRange.ref;

      const previousCallback = callbacks.find(
        callback => callback.ref === prevInRange
      );

      callback?.callback(true);
      previousCallback?.callback(false);
    }
  }, [ranges, callbacks, observer, scrollDisabled]);

  useEffect(() => {
    observer?.root?.addEventListener("scroll", handleScrollCallback);

    return () => {
      observer?.root?.removeEventListener("scroll", handleScrollCallback);
    };
  }, [observer, handleScrollCallback]);

  return (
    <Context.Provider
      value={{
        create,
        destroy,
        addListener,
        removeListener,
        initialScroll,
        scrollToElement,
      }}
    >
      {children}
    </Context.Provider>
  );
};
