import { Culture } from "@kolibrisoftware/customerportal-api";
import { CompanyData } from "@kolibrisoftware/customerportal-public-api";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AppStatus } from "enums/app-status";
import { actions as mainActions } from ".";

type State = {
  translations: Record<string, string>;
  appStatus: AppStatus;
  companyData: CompanyData | null;
  culture: Culture;
  userId: string;
  masterTable: Record<string, string>;
};

const initialState: State = {
  translations: {},
  appStatus: AppStatus.Idle,
  companyData: null,
  culture: Culture.Nl,
  userId: "",
  masterTable: {},
};

export const slice = createSlice({
  name: "main",
  initialState: initialState,
  reducers: {
    setTranslations: (state, action: PayloadAction<Record<string, string>>) => {
      return {
        ...state,
        translations: action.payload,
      };
    },
    setAppStatus: (state, action: PayloadAction<AppStatus>) => {
      return {
        ...state,
        appStatus: action.payload,
      };
    },
    setCompanyData: (state, action: PayloadAction<CompanyData | null>) => {
      return { ...state, companyData: action.payload };
    },
    setCulture: (state, action: PayloadAction<Culture>) => {
      return { ...state, culture: action.payload };
    },
    setUserId: (state, action: PayloadAction<string>) => {
      return { ...state, userId: action.payload };
    },
  },
});
