import {
  IconDefinition,
  fa360Degrees,
  faAlignLeft,
  faBookOpen,
  faExpand,
  faImages,
  faList,
} from "@fortawesome/pro-regular-svg-icons";
import { AssignmentPreviewResponse } from "@kolibrisoftware/customerportal-api";
import { v4 as uuid } from "uuid";

export enum ObjectTabActions {
  OpenModal = "OpenModal",
  ScrollTo = "ScrollTo",
}

export type ObjectTabType = {
  id: string;
  icon: IconDefinition;
  label: string;
  action: {
    type: ObjectTabActions;
    target?: string;
  };
  validator?: (state: AssignmentPreviewResponse) => boolean;
};

export enum ObjectTabTargets {
  Gallery = "gallery",
  Description = "object-description",
  Details = "object-details",
  Plan = "plan",
  View360 = "360View",
  Brochures = "brochures",
}

export const OBJECT_TABS: ObjectTabType[] = [
  {
    id: uuid(),
    icon: faImages,
    label: "objectPreview.objectTabs.photos",
    action: {
      type: ObjectTabActions.OpenModal,
      target: ObjectTabTargets.Gallery,
    },
    validator: state => !!state.photos && state.photos.length > 0,
  },
  {
    id: uuid(),
    icon: faAlignLeft,
    label: "objectPreview.objectTabs.description",
    action: {
      type: ObjectTabActions.ScrollTo,
      target: ObjectTabTargets.Description,
    },
  },
  {
    id: uuid(),
    icon: faList,
    label: "objectPreview.objectTabs.details",
    action: {
      type: ObjectTabActions.ScrollTo,
      target: ObjectTabTargets.Details,
    },
    validator: state => !!state.advertisementText,
  },
  {
    id: uuid(),
    icon: faExpand,
    label: "objectPreview.objectTabs.plan",
    action: {
      type: ObjectTabActions.OpenModal,
      target: ObjectTabTargets.Plan,
    },
    validator: state => !!state.maps && !!state.maps.length,
  },
  {
    id: uuid(),
    icon: fa360Degrees,
    label: "objectPreview.objectTabs.360View",
    action: {
      type: ObjectTabActions.OpenModal,
      target: ObjectTabTargets.View360,
    },
    validator: state => !!state.videoUrls && !!state.videoUrls.length,
  },
  {
    id: uuid(),
    icon: faBookOpen,
    label: "objectPreview.objectTabs.brochures",
    action: {
      type: ObjectTabActions.OpenModal,
      target: ObjectTabTargets.Brochures,
    },
    validator: state => !!state.brochures && !!state.brochures.length,
  },
];
