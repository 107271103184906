import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  primaryNavigationNavItemContainer: {
    position: "relative",
    color: theme.companyTheme?.mainColor?.toString(),
    transition: "all 0.2s ease",
    // States
    "&:hover": {
      color: theme.companyTheme?.mainColor?.lighten(0.2).toString(),
    },
  },
  primaryNavigationNavItemAction: {
    gap: 6,
    display: "flex",
    alignItems: "center",
  },
  contextMenuWrapper: {
    right: 30,
    top: 35,
    position: "absolute",
    opacity: "1!important",
  },
  languageButton: {
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    gap: "5px",
    border: "none",
    backgroundColor: "transparent",
  },
}));

export { useStyles };
