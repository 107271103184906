import { faClose, faSquareList } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useDefaultMediaQueries,
  useTranslation,
} from "@kolibrisoftware/hooks-util";
import classNames from "classnames";
import { useTheme } from "injectors/theme";
import { useDispatch, useSelector } from "store/helpers";
import { actions as questionnaireActions } from "store/questionnaire";
import { FC, useMemo } from "react";
import Sidebar from "../sidebar";
import { useStyles } from "./styles";

type Props = {};

const MobileSidebar: FC<Props> = props => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const t = useTranslation();
  const sidebarOpen = useSelector(state => state.questionnaire.sidebarOpen);
  const { isScreenXS, isScreenS } = useDefaultMediaQueries();
  const dispatch = useDispatch();

  const handleSidebarToggle = () => {
    dispatch(questionnaireActions.toggleSidebar());
  };

  const translations = useMemo(
    () => ({
      title: t("questionnaire.sidebar.title"),
    }),
    [t]
  );

  return (
    <div className={styles.mobileSidebarContainer}>
      {!sidebarOpen && !isScreenXS ? (
        <button className={styles.sidebarToggle} onClick={handleSidebarToggle}>
          {translations.title}
        </button>
      ) : (
        <FontAwesomeIcon
          className={styles.mobileSidebarToggle}
          icon={faSquareList}
          onClick={handleSidebarToggle}
        />
      )}
      {sidebarOpen && (
        <div
          className={styles.contextMenuBackground}
          onClick={handleSidebarToggle}
        ></div>
      )}
      <div
        className={classNames(styles.closedSidebar, {
          [styles.fullSidebarWrapper]: sidebarOpen,
        })}
      >
        {(isScreenXS || isScreenS) && (
          <FontAwesomeIcon
            icon={faClose}
            onClick={handleSidebarToggle}
            className={styles.mobileCloseButton}
          />
        )}
        <Sidebar />
      </div>
    </div>
  );
};

export { MobileSidebar };
