import { FormElement } from "@kolibrisoftware/customerportal-api";
import classNames from "classnames";
import { indexToEnumPrefix } from "helpers/form-elements";
import useElementTranslations from "hooks/useElementTranslations";
import { useTheme } from "injectors/theme";
import { isUndefined } from "lodash-es";
import FormElementTranslation from "modules/questionnaire/elements/form-element-translation";
import useElementData from "modules/questionnaire/hooks/useElementData";
import React, { FC, useMemo } from "react";
import { useStyles } from "./styles";

type Props = {
  textStructure: FormElement;
  hierarchyLevel: number;
  textIndex?: number;
  children?: React.ReactNode;
};

const FormText: FC<Props> = ({
  textStructure,
  hierarchyLevel,
  textIndex,
  children,
}) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const textElementData = useElementData(textStructure.id);
  const translations = useElementTranslations(textElementData);

  const textPrefix = useMemo(() => {
    if (textIndex) {
      return indexToEnumPrefix(textIndex, textStructure.enumerationType);
    }
  }, [textIndex, textStructure.enumerationType]);
  return (
    <>
      {(translations?.title || translations?.text) && (
        <div
          className={classNames(styles.formTextWrapper, styles.bottomBorder)}
        >
          <div className={styles.textDetailsWrapper}>
            <div className={styles.translationWrapper}>
              <FormElementTranslation
                text={translations.title || ""}
                prefix={textPrefix}
                hint={translations.text || ""}
              />
            </div>
          </div>

          {children && (
            <div
              className={classNames(styles.textChildrenWrapper, {
                [styles.childrenPadding]: hierarchyLevel === 2,
              })}
            >
              {children}
            </div>
          )}
        </div>
      )}
    </>
  );
};

export { FormText };
