import {
  faCloudArrowDown,
  faFileLines,
  faFileSlash,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "@kolibrisoftware/hooks-util";
import { useTheme } from "injectors/theme";
import { FC, useCallback, useMemo } from "react";
import ActivityItem from "shared/components/activity-item";
import {
  ContentSection,
  Button,
  ButtonThemes,
} from "@kolibrisoftware/customerportal-ui";
import HeaderSection from "shared/ui/header-section";
import ScreenWrapper from "shared/ui/screen-wrapper";
import { useSelector, useDispatch } from "store/helpers";
import { useStyles } from "./styles";
import { documentApi } from "store/api";
import { PageLoadingStatus } from "enums/page-loading-status";
import LoadingScreen from "shared/ui/loading-screen";
import { useToasts } from "hooks/useToast";
import { createErrorToast } from "helpers/create-new-toast";

type Props = {};

const DocumentsScreen: FC<Props> = props => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const styles = useStyles({ theme });
  const t = useTranslation();
  const loadingStatus = useSelector(state => state.dossier.loadingStatus);
  const toasts = useToasts();
  const translations = useMemo(
    () => ({
      headerTitle: t("documents.headerTitle"),
      headerDescription: t("documents.headerDescription"),
      noDocuments: t("documents.noDocuments"),
      downloadActionLabel: t("common.downloadAction"),
    }),
    [t]
  );
  const documents = useSelector(state =>
    state.dossier.documents.filter(item => item)
  );

  const handleDownload = useCallback(
    async (documentId: string) => {
      try {
        const downloadLink = await dispatch(
          documentApi.downloadDocument({ documentId: documentId })
        ).unwrap();

        if (downloadLink.tempAccessDownloadUrl) {
          window.open(downloadLink.tempAccessDownloadUrl);
        } else {
          const toast = createErrorToast("Niet beschikbaar");
          toasts.addToast(toast);
        }
      } catch (error) {
        throw error;
      }
    },
    [dispatch, toasts]
  );

  return (
    <ScreenWrapper>
      {(loadingStatus === PageLoadingStatus.Loading ||
        loadingStatus === PageLoadingStatus.Pending) && <LoadingScreen />}
      {loadingStatus === PageLoadingStatus.Success && (
        <>
          {!documents.length && (
            <ContentSection>
              <div className={styles.noDocumentsContainer}>
                <FontAwesomeIcon
                  icon={faFileSlash}
                  className={styles.noDocumentsIcon}
                />
                <h3 className={styles.noDocumentsText}>
                  {translations.noDocuments}
                </h3>
              </div>
            </ContentSection>
          )}
          {!!documents.length && (
            <>
              <HeaderSection
                title={translations.headerTitle}
                description={translations.headerDescription}
              />
              <ContentSection>
                {documents.map((item, index) => {
                  return (
                    <ActivityItem
                      key={`documents-${index}`}
                      status={item.status}
                      activityActions={
                        <Button
                          label={translations.downloadActionLabel}
                          onClick={() => handleDownload(item.id || "")}
                          buttonTheme={ButtonThemes.Primary}
                          size="s"
                        />
                      }
                      title={item.fileName || ""}
                      icon={faFileLines}
                    />
                  );
                })}
              </ContentSection>
            </>
          )}
        </>
      )}
    </ScreenWrapper>
  );
};

export { DocumentsScreen };
