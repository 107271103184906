import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  errorPageContainer: {
    height: "100vh",
    width: "100vw",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    flexDirection: "column",
    gap: 10,
    "& h2": {
      fontSize: 24,
      fontWeight: 400,
      marginBottom: 10,
      textAlign: "center",
    },
    "& h3": {
      fontSize: 14,
      fontWeight: 400,
      textAlign: "center",
    },
  },

  logoWrapper: {
    "& img": {
      height: 50,
    },
  },
  contentWrapper: {
    textAlign: "center",
  },
  actionWrapper: {
    backgroundColor: theme.colors?.primary?.toString(),
    width: "fit-content",
    margin: "10px auto",
    color: "white",
    borderRadius: 3,
    fontSize: 14,
    transition: "all 0.1s ease-in",
    "&:hover": {
      backgroundColor: theme.colors?.primary?.lighten(0.1).toString(),
    },
  },
  errorTip: {
    backgroundColor: theme.colors?.warning?.lighten(0.1).toString(),
    padding: "5px 20px",
    borderRadius: 3,
    color: theme.colors?.offWhite?.toString(),
    marginTop: 10,
    display: "flex",
    gap: 10,
    alignItems: "center",
    justifyContent: "center",
  },
}));

export { useStyles };
