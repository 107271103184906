import { useDefaultMediaQueries } from "@kolibrisoftware/hooks-util";
import { useTheme } from "injectors/theme";
import useStyles from "./styles";
import { Outlet } from "react-router-dom";
import SecondaryNavigation from "./secondary-navigation";
import classnames from "classnames";

export const SecondaryNavigationLayout = () => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const { isScreenXS, isScreenS } = useDefaultMediaQueries();

  return (
    <div
      className={classnames(styles.navigationLayout, {
        [styles.dossierLayoutTablet]: isScreenXS || isScreenS,
      })}
    >
      {!isScreenXS && !isScreenS && <SecondaryNavigation />}
      <div className={styles.navigationOutletContainer}>
        <Outlet />
      </div>
    </div>
  );
};
