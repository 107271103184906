import {
  FormAnswerEditor,
  FormElement,
  FormElementQuestionType,
} from "@kolibrisoftware/customerportal-api";
import { useTheme } from "injectors/theme";
import useElementData from "modules/list-of-items/hooks/useElementData";
import { FC, useMemo } from "react";
import { ModifiedLabel } from "shared/components/modified-label";
import { useSelector } from "store/helpers";
import { selectListOfItemsAnswers } from "store/list-of-items/selectors";
import ContractQuestion from "./contract";
import RegularQuestion from "./regular";
import { useStyles } from "./styles";

type Props = {
  questionStructure: FormElement;
};

const ListOfItemsQuestionWrapper: FC<Props> = ({ questionStructure }) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const questionData = useElementData(questionStructure.id);
  const modifiedAnswerIds = useSelector(
    state => state.listOfItems.formStructure.latestModifiedAnswerIds
  );
  const questionAnswers = useSelector(selectListOfItemsAnswers);
  const questionAnswer = questionAnswers.find(
    answer => answer.blueprintElementId === questionData?.id
  );

  const isModifiedByOwner = useMemo(() => {
    return (
      modifiedAnswerIds?.includes(questionAnswer?.id || "") &&
      questionAnswer?.editor === FormAnswerEditor.Owner
    );
  }, [modifiedAnswerIds, questionAnswer?.id, questionAnswer?.editor]);

  return (
    <div
      data-modified={isModifiedByOwner}
      className={styles.listOfItemsQuestionWrapper}
    >
      {isModifiedByOwner && <ModifiedLabel />}
      {questionData &&
        questionData.questionType ===
          FormElementQuestionType.ListOfItemRadioList && (
          <RegularQuestion
            questionData={questionData}
            key={questionStructure.id}
          />
        )}
      {questionData &&
        questionData.questionType ===
          FormElementQuestionType.ContractListOfGood && (
          <ContractQuestion
            questionData={questionData}
            key={questionStructure.id}
          />
        )}
    </div>
  );
};

export { ListOfItemsQuestionWrapper };
