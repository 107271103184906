import { reducer as toastsReducer } from "./toasts";
import { reducer as mainReducer } from "./main";
import { reducer as dossierReducer } from "./dossier";
import { reducer as assignmentRelationsReducer } from "./assignment-relation";
import { reducer as loadingOverlayReducer } from "./loading-overlay";
import { reducer as listOfItemsReducer } from "./list-of-items";
import { reducer as questionnaireReducer } from "./questionnaire";
import { reducer as objectPreviewReducer } from "./object-preview";
import { combineReducers, configureStore } from "@reduxjs/toolkit";
import { persistStore } from "redux-persist";

const reducer = combineReducers({
  questionnaire: questionnaireReducer,
  listOfItems: listOfItemsReducer,
  assignmentRelations: assignmentRelationsReducer,
  loadingOverlay: loadingOverlayReducer,
  dossier: dossierReducer,
  objectPreview: objectPreviewReducer,
  main: mainReducer,
  toasts: toastsReducer,
});

export const store = configureStore({
  reducer,
  devTools: { name: "root" },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware({ serializableCheck: false, immutableCheck: false }),
});

export type RootState = ReturnType<typeof store.getState>;
export const persistor = persistStore(store);
