import { adapter, slice } from "./slice";
import { RootState } from "store";
import { IconDefinition } from "@fortawesome/pro-regular-svg-icons";

export type Toast = {
  id: string;
  message: string;
  theme?: "dark" | "light" | "error" | "info" | "success";
  icon?: IconDefinition;
  canManuallyRemove?: boolean;
  removeAfter?: number;
  isError?: boolean;
  errorId?: string;
};

const actions = slice.actions;
const reducer = slice.reducer;
export const selectors = adapter.getSelectors<RootState>(state => state.toasts);

export { actions, reducer };
