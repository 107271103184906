import { useDefaultMediaQueries } from "@kolibrisoftware/hooks-util";
import defaultLogo from "assets/jouwmakelaar-online-logo.svg";
import { useTheme } from "injectors/theme";
import { FC, useMemo } from "react";
import { Outlet, useLocation, useNavigate } from "react-router-dom";
import { Snackbar } from "shared/components/snackbar/snackbar";
import { useSelector } from "store/helpers";
import { PrimaryNavigation } from "./primary-navigation/primary-navigation";
import useStyles from "./styles";

export const PrimaryNavigationLayout: FC = () => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const companyData = useSelector(state => state.main.companyData);
  const propertyDisplayName = useSelector(
    state => state.dossier.assignmentDetails.displayName
  );
  const navigate = useNavigate();
  const location = useLocation();
  const { isScreenXS, isScreenS, isScreenM } = useDefaultMediaQueries();

  const logo = useMemo(() => {
    if (!companyData) {
      return (
        <img
          src={defaultLogo}
          alt="Logo"
          className={styles.logo}
          onClick={() => navigate("/")}
        />
      );
    }

    if (companyData?.logo) {
      return (
        <img
          src={`data:image/jpeg;base64,${companyData.logo}`}
          alt="Logo"
          className={styles.logo}
          onClick={() => navigate("/")}
        />
      );
    }

    return (
      <div className={styles.logoWrapperRealtorName}>{companyData?.name}</div>
    );
  }, [styles, companyData, navigate]);

  const displayPropertyName = useMemo(
    () => !isScreenXS && !isScreenS && !isScreenM && location.pathname !== "/",
    [isScreenM, isScreenS, isScreenXS, location]
  );

  const displayMenuItems = useMemo(
    () => !isScreenXS && !isScreenS,
    [isScreenXS, isScreenS]
  );

  return (
    <div className={styles.primaryNavigationWrapper}>
      <div className={styles.primaryNavigationContainer}>
        <div className={styles.logoWrapper}>{logo}</div>
        {displayPropertyName && (
          <div className={styles.propertyDisplayName}>
            {!!propertyDisplayName && propertyDisplayName}
          </div>
        )}
        {displayMenuItems && <PrimaryNavigation />}
      </div>
      <div className={styles.body}>
        <Outlet />
      </div>
      <Snackbar />
    </div>
  );
};
