import {
  useDefaultMediaQueries,
  useTranslation,
} from "@kolibrisoftware/hooks-util";
import { useTheme } from "injectors/theme";
import { FC, FormEvent, useCallback, useMemo, useRef, useState } from "react";
import { useDispatch } from "store/helpers";
import { thunks as listOfItemsThunks } from "store/list-of-items";

import { faCirclePlus } from "@fortawesome/pro-regular-svg-icons";
import { Button, TextInput } from "@kolibrisoftware/customerportal-ui";
import { useStyles } from "./styles";
import { FormElementQuestionType } from "@kolibrisoftware/customerportal-api";
import { PageLoadingStatus as LoadingStatus } from "enums/page-loading-status";
import { useToasts } from "hooks/useToast";
import { createErrorToast } from "helpers/create-new-toast";
import { ListOfItemsUseCases } from "modules/list-of-items/enums/list-of-items-use-cases";

type Props = {
  sectionId: string;
  useCase: ListOfItemsUseCases;
};

const CreateQuestionWrapper: FC<Props> = ({ sectionId, useCase }) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const dispatch = useDispatch();
  const t = useTranslation();
  const toast = useToasts();
  const inputRef = useRef<HTMLInputElement | null>(null);
  const { isScreenS, isScreenXS } = useDefaultMediaQueries();
  const [loadingStatus, setLoadingStatus] = useState(LoadingStatus.Pending);
  const [showAddButton, setShowAddButton] = useState(false);

  const addNewQuestionHandler = useCallback(
    async (questionText: string) => {
      try {
        setLoadingStatus(LoadingStatus.Loading);
        if (useCase === ListOfItemsUseCases.Regular) {
          await dispatch(
            listOfItemsThunks.createCustomQuestion({
              sectionId,
              questionText,
              questionType: FormElementQuestionType.ListOfItemRadioList,
            })
          );
        }
        if (useCase === ListOfItemsUseCases.Contract) {
          await dispatch(
            listOfItemsThunks.createCustomQuestion({
              sectionId,
              questionText,
              questionType: FormElementQuestionType.ContractListOfGood,
            })
          );
        }

        setLoadingStatus(LoadingStatus.Pending);
        if (inputRef && inputRef.current) {
          inputRef.current.value = "";
          setShowAddButton(false);
        }
      } catch (error: any) {
        const toastBody = createErrorToast(
          error.message || "There was an issue saving your question."
        );
        toast.addToast(toastBody);
        setLoadingStatus(LoadingStatus.Failed);
      }
    },
    [sectionId, useCase, toast, dispatch]
  );

  const handleSubmit = useCallback(() => {
    if (!inputRef || !inputRef.current) {
      return;
    }
    const title = inputRef.current.value;
    if (title.length > 0) {
      addNewQuestionHandler(title);
    }
  }, [addNewQuestionHandler]);

  const handleInputKeyDown = useCallback(
    (e: React.KeyboardEvent<HTMLInputElement>) => {
      if (e.key === "Enter") {
        handleSubmit();
      }
    },
    [handleSubmit]
  );

  const handleInputChange = useCallback((e: FormEvent<HTMLInputElement>) => {
    if (e.currentTarget && e.currentTarget.value.length > 0) {
      setShowAddButton(true);
      return;
    }
    setShowAddButton(false);
  }, []);

  const translations = useMemo(
    () => ({
      label: t("listOfItems.newItemPlaceholder"),
    }),
    [t]
  );

  return (
    <div className={styles.addQuestionWrapper}>
      {(isScreenS || isScreenXS) && (
        <>
          <h2 className={styles.addQuestionLabel}>{translations.label}</h2>
        </>
      )}
      <div className={styles.addQuestionInputContainer}>
        <TextInput
          ref={inputRef}
          name={"new-question-label"}
          onBlur={handleSubmit}
          onKeyDown={handleInputKeyDown}
          onChange={handleInputChange}
          disabled={loadingStatus === LoadingStatus.Loading}
        />

        <div className={styles.addQuestionAction}>
          {showAddButton && (
            <Button
              icon={faCirclePlus}
              style={{ padding: 0, paddingRight: 1 }}
            />
          )}
        </div>
      </div>
      {!isScreenS && !isScreenXS && <div className={styles.fauxSpacer}></div>}
    </div>
  );
};

export { CreateQuestionWrapper };
