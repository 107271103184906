import { TouchEventHandler } from "react";

type SwipeOptions = {
  onSwipeLeft?: () => void;
  onSwipeRight?: () => void;
};

export function useSwipeListener(options: SwipeOptions): {
  onTouchStart: TouchEventHandler<HTMLElement>;
  onTouchEnd: TouchEventHandler<HTMLElement>;
} {
  let touchStartX = 0;

  const onTouchStart: TouchEventHandler<HTMLElement> = event => {
    touchStartX = event.touches[0].clientX;
  };

  const onTouchEnd: TouchEventHandler<HTMLElement> = event => {
    const touchEndX = event.changedTouches[0].clientX;
    const deltaX = touchEndX - touchStartX;

    if (deltaX > 0 && options.onSwipeRight) {
      options.onSwipeRight();
    } else if (deltaX < 0 && options.onSwipeLeft) {
      options.onSwipeLeft();
    }
  };

  return { onTouchStart, onTouchEnd };
}
