import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  photoGalleryWithSidebarWrapper: responsiveStyles({
    display: "flex",
    height: "100%",
    gap: 30,
    flex: 1,
  })
    .m(
      {
        gap: 20,
        flexDirection: "column",
      },
      { propagate: true }
    )
    .getStyle(),

  mobileImage: responsiveStyles({
    width: "100%",
    paddingBottom: 20,
    borderBottom: `1px solid ${theme.colors.lightGray?.alpha(0.8).toString()}`,
  })
    .m(
      {
        "&:last-child": {
          paddingBottom: 50,
        },
      },
      { propagate: true }
    )
    .getStyle(),
  thumbnailsContainer: {
    padding: {
      left: 0,
      right: 26,
      top: 10,
      bottom: 10,
    },
    gap: 10,
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    maxHeight: "100%",
    height: "100%",
    overflowY: "scroll",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
    borderRight: `1px solid ${theme.colors.lightGray.toString()}`,
  },
  thumbnailWrapper: {
    boxSizing: "border-box",
    padding: 3,
    borderRadius: 3,
    width: 200,
    paddingTop: "80%",
    position: "relative",
    border: "1px solid transparent",
    outline: "none",
    background: "none",
    cursor: "pointer",
    transition: "all 0.1s ease",

    opacity: 0.5,
    "&:hover": {
      opacity: 1,
    },
  },
  thumbnailActive: {
    border: `1px solid ${theme.colors.lightGray?.alpha(0.8).toString()}`,
    opacity: 1,
  },
  thumbnailImage: {
    width: "90%",
    height: "100%",
    position: "absolute",
    top: 0,
    left: "5%",
    objectFit: "contain",
  },
  galleryPreviewContainer: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    justifyContent: "center",
    height: "100%",
  },
  galleryPreviewImage: {
    height: "100%",
    width: "100%",
    objectFit: "contain",
  },
}));

export { useStyles };
