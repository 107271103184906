import { faFileSlash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "@kolibrisoftware/hooks-util";
import { PageLoadingStatus } from "enums/page-loading-status";
import { useTheme } from "injectors/theme";
import ChecklistItemsList from "modules/dossier/components/checklist-items-list/checklist-items-list";
import { FC, useMemo } from "react";
import { ContentSection } from "@kolibrisoftware/customerportal-ui";
import HeaderSection from "shared/ui/header-section";
import LoadingScreen from "shared/ui/loading-screen";
import ScreenWrapper from "shared/ui/screen-wrapper";
import { selectExtendedChecklistItems } from "store/dossier/selectors";
import { useSelector } from "store/helpers";
import { useStyles } from "./styles";

type Props = {};

const ChecklistScreen: FC<Props> = props => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const checklistItems = useSelector(selectExtendedChecklistItems);
  const t = useTranslation();
  const assignmentDetails = useSelector(
    state => state.dossier.assignmentDetails
  );
  const loadingStatus = useSelector(state => state.dossier.loadingStatus);
  const translations = useMemo(
    () => ({
      headerTitle: t("checklist.headerTitle"),
      headerDescriptionForSale: t("checklist.headerDescription.forSale"),
      headerDescriptionForRent: t("checklist.headerDescription.forRent"),
      noChecklistItems: t("checklist.noChecklistItems"),
    }),
    [t]
  );

  return (
    <>
      <ScreenWrapper>
        {(loadingStatus === PageLoadingStatus.Loading ||
          loadingStatus === PageLoadingStatus.Pending) && <LoadingScreen />}
        {loadingStatus === PageLoadingStatus.Success && (
          <>
            {!checklistItems.length && (
              <ContentSection>
                <div className={styles.noDocumentsContainer}>
                  <FontAwesomeIcon
                    icon={faFileSlash}
                    className={styles.noDocumentsIcon}
                  />
                  <h3 className={styles.noDocumentsText}>
                    {translations.noChecklistItems}
                  </h3>
                </div>
              </ContentSection>
            )}
            {!!checklistItems.length && (
              <>
                <HeaderSection
                  title={translations.headerTitle}
                  description={
                    assignmentDetails?.forRent
                      ? translations.headerDescriptionForRent
                      : translations.headerDescriptionForSale
                  }
                />
                <ContentSection>
                  <ChecklistItemsList checklistItems={checklistItems} />
                </ContentSection>
              </>
            )}
          </>
        )}
      </ScreenWrapper>
    </>
  );
};

export { ChecklistScreen };
