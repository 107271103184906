import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  sidebarSectionGroupContainer: {
    marginTop: 10,
    "&:last-child": {
      marginBottom: 30,
    },
  },
  sidebarSectionGroupTitle: {
    fontSize: 13,
    fontWeight: 600,
    color: theme.companyTheme?.mainColor?.toString(),
    marginBottom: 10,
  },
  sidebarSectionGroupElements: {
    display: "flex",
    flexDirection: "column",
    gap: 10,
  },
  sectionGroupDivider: {
    outline: "none",
    border: "none",
    height: 1,
    background: "#80808033",
  },
}));

export { useStyles };
