import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  detailsRowWrapper: responsiveStyles({
    display: "flex",
    borderTop: `1px solid ${theme.colors.lightGray.alpha(0.3).toString()}`,
    alignItems: "center",
    padding: "10px 0",
    fontSize: 15,
    margin: "5px 0",
    "&:last-child": {
      borderBottom: `1px solid ${theme.colors.lightGray.alpha(0.3).toString()}`,
    },
  })
    .s(
      {
        flexDirection: "column",
        alignItems: "flex-start",
      },
      { propagate: true }
    )
    .getStyle(),
  labelWrapper: {
    color: theme.colors.darkGray.toString(),
    flex: 1,
  },
  textOptionWrapper: {
    textShadow: "0px 1px 0px #E8ECF1",
  },
  optionsWrapper: {
    display: "flex",
    flexWrap: "wrap",
    gap: 5,
    flex: 1,
  },
}));

export { useStyles };
