import { KnownBoolAnswer } from "@kolibrisoftware/customerportal-api";
import { useTranslation } from "@kolibrisoftware/hooks-util";
import { useMemo } from "react";
import { RadioGroupItem } from "@kolibrisoftware/customerportal-ui";

const useKnownBoolOptions = () => {
  const t = useTranslation();
  const knownBoolOptionsList = useMemo(() => Object.keys(KnownBoolAnswer), []);

  const knownBoolOptions: RadioGroupItem<string>[] = useMemo(() => {
    return knownBoolOptionsList.map(item => {
      const labelValue = t(`knownBoolOption.${item}`);
      return { label: labelValue, value: item };
    });
  }, [t, knownBoolOptionsList]);

  return knownBoolOptions;
};

export default useKnownBoolOptions;
