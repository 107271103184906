import { SingleAssignmentRelation } from "@kolibrisoftware/customerportal-api";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";

const selectAssignmentRelations = (state: RootState) =>
  state.assignmentRelations.assignmentRelations;

const selectCompanyData = (state: RootState) => state.main.companyData;

const selectSortedAssignments = createSelector(
  selectAssignmentRelations,
  selectCompanyData,
  (assignmentRelations, companyData) => {
    if (!companyData) {
      return assignmentRelations;
    }
    const newAssignments: SingleAssignmentRelation[] = [];
    assignmentRelations.forEach(assignment => {
      if (assignment.realtorDisplayName === companyData.name) {
        newAssignments.unshift(assignment);
      } else {
        newAssignments.push(assignment);
      }
    });
    return newAssignments;
  }
);
export { selectSortedAssignments };
