import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import Color from "color";
import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  uploadFileModalContainer: {},
  descriptionWrapper: {
    fontSize: 15,
  },
  fileInputWrapper: {
    padding: 12,
    borderRadius: 6,
    background: "#EEF2FA",
    margin: "10px 0",
  },
  actionButtonsWrapper: responsiveStyles({
    display: "flex",
    justifyContent: "space-between",
    marginTop: 10,
  })
    .s(
      { flexDirection: "column-reverse", gap: 10, fontSize: 12 },
      { propagate: true }
    )
    .getStyle(),

  horizontalSpacer: responsiveStyles({
    width: "100%",
    height: 1,
    border: "none",
    background: theme.colors.lightGray.alpha(0.7).toString(),
    margin: "16px 0",
  })
    .s({ margin: "12px 0" }, { propagate: true })
    .getStyle(),
}));

export { useStyles };
