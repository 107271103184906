import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  postIt: {
    display: "flex",
    width: "fit-content",
    alignItems: "center",
    justifyContent: "center",
    gap: 6,
    padding: "6px 8px",
    position: "absolute",
    top: "50%",
    transform: "translate(50px, -50%)",
    left: "100%",
    color: theme.colors.warning.toString(),
    border: "1px solid currentColor",
    backgroundColor: theme.colors.warning.lighten(1.65).toString(),
    boxShadow: `0 2px 6px ${theme.colors.warning.fade(0.6).toString()}`,
    borderRadius: 5,
    "&:after": {
      content: "''",
      position: "absolute",
      border: "1px solid currentColor",
      height: "76%",
      left: "-10.5%",
      top: "12.5%",
      borderBottomLeftRadius: 4,
      aspectRatio: "1 / 1",
      transform: "rotate(45deg)",
      backgroundColor: theme.colors.warning.lighten(1.65).toString(),
      clipPath: "polygon(100% 100%, 0 0, 0 100%)",
    },
  },
  mobile: {
    transform: "none",
    position: "static",
    fontSize: 10,
    marginLeft: 5,
    padding: "3px 9px",
    boxShadow: "none",
    borderRadius: "5px 5px 0 0",
    border: "none",
    "&:after": {
      display: "none",
    },
  },
}));

export { useStyles };
