import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  "@keyframes rotation": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
  overlay: {
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    width: "100vw",
    height: "100vh",
    position: "fixed",
    top: 0,
    left: 0,
    zIndex: 1005,
  },
  loaderContainer: {
    position: "relative",
  },
  loaderLogo: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
  },

  loaderLogoDisplayName: {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    fontWeight: 600,
  },

  loaderLogoImage: {
    width: "165px",
    textAlign: "center",
  },
  loaderSpinner: {
    width: "212px",
    height: "212px",
    borderRadius: "50%",
    display: "inline-block",
    position: "relative",
    boxSizing: "border-box",
    animation: "$rotation 1.82s linear infinite",
    "&::after": {
      content: '""',
      boxSizing: "border-box",
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)",
      width: "195px",
      height: "195px",
      borderRadius: "50%",
      background: "white",
    },
  },
  loaderSpinnerEnding: {
    width: "10px",
    height: "10px",
    position: "absolute",
    top: "50%",
    right: "-5px",
    transform: "translate(-50%, -50%)",
    borderRadius: "20px",
  },
  infoText: responsiveStyles({})
    .s({ textAlign: "center" }, { propagate: true })
    .getStyle(),
  infoIcon: responsiveStyles({
    marginRight: "3px",
  }).getStyle(),
  infoContainer: responsiveStyles({
    fontSize: "13px",
    color: "#2E5AAC",
    display: "flex",
    alignItems: "center",
    background: "#EEF2FA",
    padding: "8px 12px",
    borderRadius: "4px",
    boxShadow: "0px 4px 10px 0px rgb(46 90 172 / 50%)",
  })
    .s(
      {
        flexDirection: "column",
      },
      { propagate: true }
    )
    .getStyle(),
}));

export { useStyles };
