import { ScreenWrapper } from "shared/ui/screen-wrapper/screen-wrapper";
import InitialDataLayer from "./initial-data-layer";
import ObjectPreview from "./screens";
import ObjectTabsModalContextProvider from "./components/modal-context";

export const ObjectPreviewModule = () => {
  return (
    <ScreenWrapper>
      <InitialDataLayer>
        <ObjectTabsModalContextProvider>
          <ObjectPreview />
        </ObjectTabsModalContextProvider>
      </InitialDataLayer>
    </ScreenWrapper>
  );
};
