import React, { FC, useRef } from "react";
import Portal from "../portal";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { useTheme } from "injectors/theme";
import { useStyles } from "./styles";

type Props = {
  visible: boolean;
  title?: string;
  closeOnBackdropClick?: boolean;
  noCloseButton?: boolean;
  blockCloseEvent?: boolean;
  children?: React.ReactNode;
  displayStyles?: React.CSSProperties;
  position?: "top" | "center";
  actions?: (
    onClose: () => void
  ) => React.ReactElement | React.ReactElement[] | React.ReactNode;
  onClose: () => void;
};

export const ModalComponent: FC<Props> = ({
  visible,
  children,
  title,
  closeOnBackdropClick,
  noCloseButton,
  blockCloseEvent,
  actions,
  onClose,
  displayStyles,
  position = "top",
}) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const modal = useRef<HTMLDivElement | null>(null);

  const onCloseCallback = () => {
    !blockCloseEvent && onClose();
  };

  const closeOnBackdropClickCallback = (
    event: React.MouseEvent<HTMLDivElement>
  ) => {
    if (
      !!modal.current?.contains(event.target as Node) ||
      !closeOnBackdropClick
    ) {
      return;
    }
    onCloseCallback();
  };

  return !visible ? null : (
    <Portal>
      <>
        <div className={styles.backdrop} />
        <div
          className={classnames(styles.modalWrapper, {
            [styles.clickable]: closeOnBackdropClick,
            [styles.positionCenter]: position === "center",
          })}
          onClick={closeOnBackdropClickCallback}
        >
          <div className={styles.spacer} />
          <div
            className={classnames(styles.modal)}
            ref={modal}
            style={displayStyles}
          >
            {(!!title || !noCloseButton) && (
              <div className={styles.modalHeader}>
                <div className={styles.headerTitle}>{title}</div>
                {!noCloseButton && (
                  <div className={styles.headerClose} onClick={onCloseCallback}>
                    <FontAwesomeIcon icon={faTimes} fontSize={20} />
                  </div>
                )}
              </div>
            )}
            <div className={classnames(styles.modalBody)}>{children}</div>
            {!!actions && (
              <div className={styles.modalFooter}>
                {actions(onCloseCallback)}
              </div>
            )}
          </div>
          <div className={styles.spacer} />
        </div>
      </>
    </Portal>
  );
};
