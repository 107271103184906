import { FC, useMemo } from "react";
import { useStyles } from "./styles";
import { useTheme } from "injectors/theme";
import useElementData from "modules/list-of-items/hooks/useElementData";
import useElementTranslations from "hooks/useElementTranslations";
import { useTranslation } from "@kolibrisoftware/hooks-util";
import {
  ChecklistItemStatusType,
  FormElement,
  FormElementType,
} from "@kolibrisoftware/customerportal-api";
import { ListOfItemsQuestionWrapper } from "../../list-of-items-question-wrapper/list-of-items-question-wrapper";
import AddQuestion from "../../list-of-items-question-wrapper/add-question";
import { useSelector } from "store/helpers";
import { CustomQuestionWrapper } from "../../list-of-items-question-wrapper/custom-question/custom-question-wrapper";
import FormSectionHeader from "../../form-section-header";
import { ListOfItemsUseCases } from "modules/list-of-items/enums/list-of-items-use-cases";

type Props = { sectionStructure: FormElement };

const ContractSectionComponent: FC<Props> = ({ sectionStructure }) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const sectionData = useElementData(sectionStructure.id);
  const sectionTranslations = useElementTranslations(sectionData);
  const { formEditableStatus } = useSelector(state => state.listOfItems);

  return (
    <div className={styles.contractSectionContainer}>
      <FormSectionHeader
        useCase={ListOfItemsUseCases.Contract}
        sectionTranslations={sectionTranslations}
      />
      {sectionStructure?.elements?.map(element => {
        if (element.type !== FormElementType.Question) {
          return null;
        }
        return (
          <ListOfItemsQuestionWrapper
            questionStructure={element}
            key={`${sectionStructure.id} -- ${element.id}`}
          />
        );
      })}
      {sectionStructure?.customQuestions?.map(question => (
        <CustomQuestionWrapper
          questionStructure={question}
          key={question.id}
          useCase={ListOfItemsUseCases.Contract}
        />
      ))}
      {formEditableStatus === ChecklistItemStatusType.Open &&
        sectionData?.childrenCustomQuestionAllowed &&
        sectionData?.id && (
          <AddQuestion
            sectionId={sectionData.id}
            useCase={ListOfItemsUseCases.Contract}
          />
        )}
    </div>
  );
};

export { ContractSectionComponent };
