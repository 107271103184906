import {
  ChecklistItemStatusType,
  DocumentStatus,
} from "@kolibrisoftware/customerportal-api";
import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";
import { selectMainSlice } from "store/main/selectors";

export const selectDocuments = (state: RootState) => state.dossier.documents;
export const selectChecklistItems = (state: RootState) =>
  state.dossier.checklistItems;
export const selectAssignmentRelations = (state: RootState) =>
  state.dossier.assignmentRelations;
export const selectExtendedChecklistItems = (state: RootState) =>
  state.dossier.extendedChecklistItems;

export const selectNonRefusedDocuments = createSelector(
  selectDocuments,
  list => {
    return list.filter(item => item.status !== DocumentStatus.Refused);
  }
);

export const selectRelationId = createSelector(
  selectMainSlice,
  selectAssignmentRelations,
  (main, relations) => {
    return relations.find(relation => relation.subId === main.userId)
      ?.relationId;
  }
);

export const selectOpenExtendedChecklistItems = createSelector(
  selectExtendedChecklistItems,
  itemsList => {
    return itemsList.filter(
      item => item.status === ChecklistItemStatusType.Open
    );
  }
);

export const dossierSelectors = {
  selectDocuments,
  selectChecklistItems,
  selectNonRefusedDocuments,
  selectOpenExtendedChecklistItems,
};
