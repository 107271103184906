import { ROUTER_PATHS } from "constants/routes";
import OpenChecklistItem from "helpers/open-checklist-item";
import PrimaryNavigationLayout from "layouts/primary-navigation-layout";
import SecondaryNavigationLayout from "layouts/secondary-navigation";
import { DossierModuleWrapper } from "modules/dossier/dossier";
import ChecklistScreen from "modules/dossier/screens/checklist";
import DocumentsScreen from "modules/dossier/screens/documents";
import OverviewScreen from "modules/dossier/screens/overview";
import DossiersModule from "modules/dossiers";
import ListOfItemsModule from "modules/list-of-items";
import ObjectPreviewModule from "modules/object-preview";
import QuestionnaireModule from "modules/questionnaire";
import CompanyDetailsCard from "shared/components/company-details-card";
import NotFound from "shared/components/error-page";
import { RedirectToNotFound } from "shared/components/error-page/error-page";
import ScreenWrapper from "shared/ui/screen-wrapper";

const handleNotFound = {
  path: "*",
  element: <RedirectToNotFound />,
};

const openChecklistItem = {
  path: ROUTER_PATHS.OPEN_CHECKLIST,
  element: <OpenChecklistItem />,
};

const AppRoutes = [
  handleNotFound,
  {
    element: <PrimaryNavigationLayout />,
    children: [
      handleNotFound,
      {
        path: "",
        element: <DossiersModule />,
      },
      {
        path: ROUTER_PATHS.DOSSIER_ROOT,
        element: <DossierModuleWrapper />,
        children: [
          handleNotFound,
          openChecklistItem,
          {
            path: "*",
            element: <SecondaryNavigationLayout />,
            children: [
              handleNotFound,
              { path: "", element: <OverviewScreen /> },
              {
                path: ROUTER_PATHS.CHECKLIST,
                element: <ChecklistScreen />,
              },
              {
                path: ROUTER_PATHS.DOCUMENTS,
                element: <DocumentsScreen />,
              },
              {
                path: ROUTER_PATHS.OBJECT_PREVIEW,
                element: <ObjectPreviewModule />,
              },
            ],
          },
          {
            path: ROUTER_PATHS.REALTOR,
            element: (
              <ScreenWrapper>
                <CompanyDetailsCard
                  extraStyles={{ position: "relative", marginBottom: 300 }}
                />
              </ScreenWrapper>
            ),
          },
          {
            path: ROUTER_PATHS.LIST_OF_ITEMS + "/:formId",
            element: <ListOfItemsModule />,
          },
          {
            path: ROUTER_PATHS.QUESTIONNAIRE + "/:formId",
            element: <QuestionnaireModule />,
          },
        ],
      },
    ],
  },
  { path: "not-found", element: <NotFound /> },
];

export default AppRoutes;
