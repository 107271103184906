import ModalContextProvider from "injectors/modal";
import SocketInjector from "injectors/socket-injector";
import StoreInjector from "injectors/store";
import ThemeInjector from "injectors/theme";
import TranslationsInjector from "injectors/translations";
import App from "./app";

const BootstrapComponent = () => {
  return (
    <StoreInjector>
      <SocketInjector>
        <TranslationsInjector>
          <ThemeInjector>
            <ModalContextProvider>
              <App />
            </ModalContextProvider>
          </ThemeInjector>
        </TranslationsInjector>
      </SocketInjector>
    </StoreInjector>
  );
};

export default BootstrapComponent;
