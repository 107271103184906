import {
  SingleAssignmentRelation,
  SingleRelationLanguage,
} from "@kolibrisoftware/customerportal-api";
import { thunks as assignmentRelationThunks } from "./index";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { PageLoadingStatus } from "enums/page-loading-status";

type State = {
  assignmentRelations: SingleAssignmentRelation[];
  assignmentsLoadingStatus: PageLoadingStatus;
};

const initialState: State = {
  assignmentRelations: [],
  assignmentsLoadingStatus: PageLoadingStatus.Pending,
};

export const slice = createSlice({
  name: "assignment-relations",
  initialState,
  reducers: {
    setItems: (state, action: PayloadAction<SingleAssignmentRelation[]>) => {
      return { ...state, assignmentRelations: action.payload };
    },
    setAvailableLanguages: (
      state,
      action: PayloadAction<SingleRelationLanguage[]>
    ) => {
      return { ...state, availableLanguages: action.payload };
    },
  },
  extraReducers: builder => {
    builder.addCase(
      assignmentRelationThunks.getAssignments.fulfilled,
      state => {
        state.assignmentsLoadingStatus = PageLoadingStatus.Success;
      }
    );
    builder.addCase(assignmentRelationThunks.getAssignments.rejected, state => {
      state.assignmentsLoadingStatus = PageLoadingStatus.Failed;
    });
  },
});
