import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  navigationLayout: {
    display: "grid",
    gridTemplateRows: "auto 1fr",
    overflow: "hidden",
  },
  dossierLayoutTablet: {
    marginTop: 0,
  },
  navigationOutletContainer: {
    overflowY: "hidden",
    "& > div": {
      overflowY: "scroll",
    },
  },
}));

export default useStyles;
