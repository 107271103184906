import { useTranslation } from "@kolibrisoftware/hooks-util";
import { ROUTER_PATHS } from "constants/routes";
import { PageLoadingStatus } from "enums/page-loading-status";
import { useTheme } from "injectors/theme";
import AssignmentCard from "modules/dossier/components/assignment-card";
import ChecklistItemsList from "modules/dossier/components/checklist-items-list/checklist-items-list";
import { FC, useMemo } from "react";
import {
  ContentSection,
  ContentSectionSize,
} from "@kolibrisoftware/customerportal-ui";
import LoadingScreen from "shared/ui/loading-screen";
import { selectOpenExtendedChecklistItems } from "store/dossier/selectors";
import { useSelector } from "store/helpers";
import { useStyles } from "./styles";
import { useNavigate } from "react-router-dom";

type Props = {};

const OverviewScreen: FC<Props> = props => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const checklistItems = useSelector(selectOpenExtendedChecklistItems);
  const navigate = useNavigate();

  const currentAssignment = useSelector(
    state => state.dossier.assignmentDetails
  );
  const loadingStatus = useSelector(state => state.dossier.loadingStatus);
  const companyData = useSelector(state => state.main.companyData);
  const t = useTranslation();

  const translations = useMemo(
    () => ({
      headerTitle: t("dossier.overview.welcomeHeader", {
        values: {
          userName: "",
        },
      }),
      headerDescription: t("dossier.overview.welcomeText", {
        values: {
          realtorName: companyData?.name,
        },
      }),
      checklistSubtitle: t("dossier.overview.checklistSubtitle"),
      checklistActionLabel: t("dossier.overview.checklistActionLabel"),
      emptyOpenChecklistItemsText: t(
        "dossier.overview.emptyOpenChecklistItemsText"
      ),
      completeChecklistActionLabel: t(
        "dossier.overview.completeChecklistActionLabel"
      ),
      checklistTitle: t("dossier.overview.checklistTitle"),
    }),
    [t, companyData]
  );

  return (
    <div className={styles.overviewRootScreen} data-cy="DashboardRoot">
      {(loadingStatus === PageLoadingStatus.Loading ||
        loadingStatus === PageLoadingStatus.Pending) && (
        <LoadingScreen useCase="overview" />
      )}
      {loadingStatus === PageLoadingStatus.Success && (
        <div className={styles.columnsWrapper}>
          <div className={styles.propertyDetailsWrapper}>
            {currentAssignment && (
              <AssignmentCard assignment={currentAssignment} />
            )}
          </div>
          <div className={styles.checklistSectionWrapper}>
            {!checklistItems && <LoadingScreen />}
            {!!checklistItems && (
              <ContentSection
                title={translations.checklistTitle}
                action={{
                  label:
                    checklistItems.length > 0
                      ? translations.checklistActionLabel
                      : translations.completeChecklistActionLabel,
                  callback: () => navigate(ROUTER_PATHS.CHECKLIST),
                }}
                size={ContentSectionSize.Full}
                style={{ marginBottom: 0, marginTop: 0 }}
              >
                <p className={styles.checklistSubtitle}>
                  {checklistItems.length > 0
                    ? translations.checklistSubtitle
                    : translations.emptyOpenChecklistItemsText}
                </p>

                <ChecklistItemsList checklistItems={checklistItems} />
              </ContentSection>
            )}
          </div>
        </div>
      )}
    </div>
  );
};

export { OverviewScreen };
