import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import Color from "color";
import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  companyDetailsCardContainer: responsiveStyles({
    display: "flex",
    background: "white",
    width: 700,
    boxShadow: "0px 1px 4px 1px #00000033",
    borderRadius: 3,
    color: Color.rgb(0, 0, 0).alpha(0.9).toString(),
  })
    .s(
      {
        width: "90vw",
        position: "absolute",
        top: "120px",
        left: "5%",
        flexDirection: "column",
      },
      { propagate: true }
    )
    .getStyle(),
  noLogoDetails: { width: 400 },
  companyName: {
    fontSize: 14,
    fontWeight: 600,
    marginBottom: 15,
  },
  companyDetailsCardRowContainer: {
    display: "flex",
    gap: 10,
    fontSize: 13,
    "&:not(:last-child)": {
      marginTop: 5,
      marginBottom: 5,
    },
  },
  labelWrapper: {
    flex: 5,
    display: "flex",
    alignItems: "center",
    gap: 6,
  },
  valueWrapper: {
    flex: 7,
  },
  companyDetailsWrapper: {
    flex: 1,
    padding: 16,
  },
  companyLogoWrapper: responsiveStyles({
    padding: 16,
    backgroundColor: "#f6f6f6",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    borderRadius: "3px 0 0 3px",
  })
    .s({ maxWidth: "100%" }, { propagate: true })
    .getStyle(),
  companyLogo: {
    maxWidth: "200px",
    maxHeight: "200px",
  },
  link: {
    textDecoration: "none",
    color: theme.companyTheme?.mainColor?.toString(),
    fontWeight: 500,
    opacity: 1,
    transition: "all 0.3s ease",
    "&:hover": {
      textDecoration: "underline",
    },
  },
}));

export { useStyles };
