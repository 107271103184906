import { AssignmentPreviewResponse } from "@kolibrisoftware/customerportal-api";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";
import { PageLoadingStatus } from "enums/page-loading-status";
import thunks from "./thunks";
type State = {
  assignmentPreview: AssignmentPreviewResponse;
  loadingStatus: PageLoadingStatus;
};

const initialState: State = {
  assignmentPreview: {},
  loadingStatus: PageLoadingStatus.Pending,
};

export const slice = createSlice({
  name: "object-preview",
  initialState: initialState,
  reducers: {
    setAssignmentPreview: (
      state,
      action: PayloadAction<AssignmentPreviewResponse>
    ) => {
      state.assignmentPreview = action.payload;
    },
  },
  extraReducers: builder => {
    builder.addCase(thunks.loadAssignmentPreview.fulfilled, state => {
      state.loadingStatus = PageLoadingStatus.Success;
    });
    builder.addCase(thunks.loadAssignmentPreview.rejected, state => {
      state.loadingStatus = PageLoadingStatus.Failed;
    });
    builder.addCase(thunks.loadAssignmentPreview.pending, state => {
      state.loadingStatus = PageLoadingStatus.Loading;
    });
  },
});
