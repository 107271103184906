import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  primaryNavigationWrapper: responsiveStyles({
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-end",
    fontSize: 15,
    gap: 20,
    flex: 1,
    minWidth: 620,
  })
    .add({ upperBound: 1550 }, { gap: 10, flex: "unset" }, { propagate: true })
    .m(
      {
        flex: "unset",
        gap: 15,
      },
      { propagate: true }
    )
    .getStyle(),
}));

export { useStyles };
