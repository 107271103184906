import {
  Address,
  AssignmentPreviewResponse,
  Floor,
  Garden,
  GardenType,
  ListingType,
} from "@kolibrisoftware/customerportal-api";
import {
  ParseComplexType,
  RawTemplate,
  RenderType,
  ValidatorType,
  ValuesParser,
} from "./template-builder/types";

const VveEnabledCategories = [ListingType.Apartment];

enum Categories {
  Overview = "objectPreview.details.overview",
  Building = "objectPreview.details.building",
  Layout = "objectPreview.details.layout",
  Energy = "objectPreview.details.energy",
  Cadastral = "objectPreview.details.cadastral",
  Outdoor = "objectPreview.details.outdoor",
  StorageSpace = "objectPreview.details.storageSpace",
  Parking = "objectPreview.details.parking",
  VveChecklist = "objectPreview.details.vveChecklist",
}

enum ApiPrefixes {
  HouseType = "houseTypes",
  RoofType = "roofTypeOptions",
  WarmWater = "warmWaterTypes",
  Insulation = "insulationTypes",
  Location = "locationPlaces",
  Garder = "gardenTypeOptions",
  BathroomFacilities = "bathroomFacilities",
  Orientations = "orientations",
}

const concatTranslationKey = (
  category: Categories | ApiPrefixes | string,
  key: string
) => (!key ? "" : category + "." + key);

const prefixStringArray = (
  prefix: string,
  list: string[] | null | undefined
) => {
  if (!list) return [];
  return list.map(item => concatTranslationKey(prefix, item));
};

/**
 * @row bathroomFacilities:
 *    - We get the bathroom facilities by:
 *        a. Iterating over all floors
 *        b. Adding all floors facilities to one array
 *        c. Filtering out the duplicates from the array
 */
const retrieveBathroomFacilities = (floorsData: Floor[] | null | undefined) => {
  if (!floorsData) return [];
  const bathroomFacilities: string[] = [];
  // a. Iterating over all floors
  floorsData.forEach(item => {
    if (item && !!item.bathroomFacilities?.length) {
      // b. Adding all floors facilities to one array
      bathroomFacilities.push(...item.bathroomFacilities);
    }
  });

  // c. Filtering out the duplicates from the array
  const filteredBathroomFacilities: string[] = [];
  bathroomFacilities.forEach(item => {
    const newItem = concatTranslationKey(ApiPrefixes.BathroomFacilities, item);
    if (!filteredBathroomFacilities.includes(newItem)) {
      filteredBathroomFacilities.push(newItem);
    }
  });
  return filteredBathroomFacilities;
};

const retrieveGardenTypes = (gardensData: Garden[] | null | undefined) => {
  if (!gardensData) return [];
  const gardenTypes: string[] = [];
  gardensData.forEach(garden => {
    if (garden.type) gardenTypes.push(garden.type);
  });

  const filteredGardenTypes: string[] = [];
  gardenTypes.forEach(type => {
    const newItem = concatTranslationKey(ApiPrefixes.Garder, type);
    if (!filteredGardenTypes.includes(newItem)) {
      filteredGardenTypes.push(newItem);
    }
  });
  return filteredGardenTypes;
};

const retrieveGardenLocation = (gardensData: Garden[] | null | undefined) => {
  if (!gardensData) return undefined;
  const firstGarden = gardensData[0];
  if (firstGarden) {
    return {
      orientation: firstGarden.orientation,
      hasBackEntrance: firstGarden.hasBackEntrance,
    };
  }
};

const buildAddress = (address: Address | null | undefined) => {
  if (!address) return "";
  let result: string[] = [];
  if (address.localityName) {
    result.push(address.localityName);
  }
  if (address.postalCode) {
    result.push(address.postalCode);
  }
  return result.join(" ");
};

const assigmentPreviewDetailsTemplate = (
  data: AssignmentPreviewResponse
): RawTemplate => {
  const price = data.saleOffer?.salePrice || data.rentOffer?.rentPrice;
  const isUnderConstruction = data.yearOfConstruction?.isUnderConstruction;
  const gardenLocation = retrieveGardenLocation(data.gardens);

  const constructionYear = data.yearOfConstruction?.constructionYear;
  const usableArea = data.usableArea?.area;
  const otherInsideSpaces = data.otherInsideSpacesArea?.area;
  const buildingRelatedOutdoorSpace =
    data.buildingRelatedOutdoorSpaceArea?.area;
  const externalStorageSpace = data.externalStorageArea?.area;
  const parcelSurface = data.parcelSurface?.area;
  const contentsVolume = data.contents?.volume;

  return [
    {
      label: concatTranslationKey(Categories.Overview, "title"),
      rows: [
        {
          label: concatTranslationKey(
            Categories.Overview,
            data.forSale ? "price.forSale" : "price.forRent"
          ),
          options: [
            {
              validatorType: ValidatorType.Simple,
              simple: {
                translationKey: "common.currency",
                translationValues: {
                  value: price,
                  forSale: data.forSale,
                },
              },
            },
            {
              validatorType: ValidatorType.Simple,
              simple: {
                translationKey: concatTranslationKey(
                  Categories.Overview,
                  `price.forRent.${data.rentOffer?.rentCondition}`
                ),
                translationValues: {
                  price: price,
                  forRent: data.forRent,
                },
              },
            },
          ],
        },
        /**
         * @row pricePerMeasurementUnit
         *              Residential = sale OR rent price / usableArea (in sqm)
         *              Agricultural = price / plotArea? (in acres)
         *
         */
        {
          label: concatTranslationKey(
            Categories.Overview,
            data.forSale ? "pricePerSqm.forSale" : "pricePerSqm.forRent"
          ),
          options: [
            {
              validatorType: ValidatorType.Simple,
              simple: {
                translationKey: "common.currency",
                translationValues: {
                  value:
                    data.usableArea?.area && price
                      ? price / data.usableArea.area
                      : undefined,
                },
              },
            },
          ],
        },
        /**
         * @row acceptance = previewData.acceptance
         */
        {
          label: concatTranslationKey(Categories.Overview, "acceptance"),
          options: [
            {
              validatorType: ValidatorType.Complex,
              complex: {
                isArray: false,
                parseValueType: ParseComplexType.MasterTable,
                value: `acceptances.${data.acceptance}`,
              },
            },
          ],
        },
        {
          label: concatTranslationKey(Categories.Overview, "serviceCost"),
          options: [
            {
              validatorType: ValidatorType.Simple,
              simple: {
                translationKey: concatTranslationKey(
                  Categories.Overview,
                  "serviceCost.value"
                ),
                translationValues: {
                  price: data.serviceCosts,
                },
              },
            },
          ],
        },
      ],
    },
    {
      label: concatTranslationKey(Categories.Building, "title"),
      rows: [
        {
          label: concatTranslationKey(Categories.Building, "houseType"),
          options: [
            {
              validatorType: ValidatorType.Simple,
              simple: {
                translationKey: data.houseType
                  ? concatTranslationKey(ApiPrefixes.HouseType, data.houseType)
                  : "",
                hasNoValues: true,
              },
            },
          ],
        },
        {
          label: concatTranslationKey(
            Categories.Building,
            "constructionStatus"
          ),
          options: [
            {
              validatorType: ValidatorType.Simple,
              simple: {
                translationKey: !isUnderConstruction
                  ? concatTranslationKey(
                      Categories.Building,
                      "constructionStatus.completed"
                    )
                  : "",
                hasNoValues: true,
              },
            },
            {
              validatorType: ValidatorType.Simple,
              simple: {
                translationKey: concatTranslationKey(
                  Categories.Building,
                  isUnderConstruction
                    ? "constructionStatus.underConstruction"
                    : "constructionStatus.completed"
                ),
                hasNoValues: true,
              },
            },
          ],
        },
        {
          label: concatTranslationKey(Categories.Building, "roofType"),
          options: [
            {
              validatorType: ValidatorType.Simple,
              simple: {
                translationKey: data.roof?.type
                  ? concatTranslationKey(ApiPrefixes.RoofType, data.roof.type)
                  : "",
                hasNoValues: true,
              },
            },
          ],
        },
        {
          label: concatTranslationKey(Categories.Building, "constructionYear"),
          options: [
            {
              validatorType: ValidatorType.Simple,
              simple: {
                translationKey: concatTranslationKey(
                  Categories.Building,
                  "constructionYear.value"
                ),
                translationValues: {
                  value: constructionYear,
                },
              },
            },
          ],
        },
        {
          label: concatTranslationKey(Categories.Building, "usableArea"),
          options: [
            {
              validatorType: ValidatorType.Simple,
              simple: {
                translationKey: "objectPreview.details.sqm.value",
                translationValues: { value: usableArea },
              },
            },
          ],
        },
        {
          label: concatTranslationKey(Categories.Building, "otherInsideSpaces"),
          options: [
            {
              validatorType: ValidatorType.Simple,
              simple: {
                translationKey: "objectPreview.details.sqm.value",
                translationValues: { value: otherInsideSpaces },
              },
            },
          ],
        },
        {
          label: concatTranslationKey(
            Categories.Building,
            "buildingRelatedOutdoorSpace"
          ),
          options: [
            {
              validatorType: ValidatorType.Simple,
              simple: {
                translationKey: "objectPreview.details.sqm.value",
                translationValues: { value: buildingRelatedOutdoorSpace },
              },
            },
          ],
        },
        {
          label: concatTranslationKey(Categories.Building, "externalStorage"),
          options: [
            {
              validatorType: ValidatorType.Simple,
              simple: {
                translationKey: "objectPreview.details.sqm.value",
                translationValues: { value: externalStorageSpace },
              },
            },
          ],
        },
        {
          label: concatTranslationKey(Categories.Building, "parcelSurface"),
          options: [
            {
              validatorType: ValidatorType.Simple,
              simple: {
                translationKey: "objectPreview.details.sqm.value",
                translationValues: { value: parcelSurface },
              },
            },
          ],
        },
        {
          label: concatTranslationKey(Categories.Building, "contentsVolume"),
          options: [
            {
              validatorType: ValidatorType.Simple,
              simple: {
                translationKey: "objectPreview.details.sqm.value",
                translationValues: { value: contentsVolume },
              },
            },
          ],
        },
      ],
    },
    {
      label: concatTranslationKey(Categories.Layout, "title"),
      rows: [
        {
          label: concatTranslationKey(Categories.Layout, "numberOfRooms"),
          options: [
            {
              validatorType: ValidatorType.Simple,
              simple: {
                translationKey: concatTranslationKey(
                  Categories.Layout,
                  "numberOfRooms.roomsAndBedrooms"
                ),
                translationValues: {
                  rooms: data.numberOfRooms,
                  bedrooms: data.numberOfBedrooms,
                },
              },
            },
            {
              validatorType: ValidatorType.Simple,
              simple: {
                translationKey: concatTranslationKey(
                  Categories.Layout,
                  "numberOfRooms.rooms"
                ),
                translationValues: {
                  rooms: data.numberOfRooms,
                },
              },
            },
          ],
        },
        {
          label: concatTranslationKey(Categories.Layout, "numberOfBathrooms"),
          options: [
            {
              validatorType: ValidatorType.Simple,
              simple: {
                translationKey: concatTranslationKey(
                  Categories.Layout,
                  "numberOfBathrooms.bathroomsAndToilets"
                ),
                translationValues: {
                  bathrooms: data.numberOfBathrooms,
                  toilets: data.numberOfToilets,
                },
              },
            },
            {
              validatorType: ValidatorType.Simple,
              simple: {
                translationKey: concatTranslationKey(
                  Categories.Layout,
                  "numberOfBathrooms.bathrooms"
                ),
                translationValues: {
                  bathrooms: data.numberOfBathrooms,
                },
              },
            },
          ],
        },
        {
          label: concatTranslationKey(Categories.Layout, "bathroomFacilities"),
          options: [
            {
              validatorType: ValidatorType.Complex,
              complex: {
                parseValueType: ParseComplexType.MasterTable,
                renderType: RenderType.PillsArray,
                value: retrieveBathroomFacilities(data.floors),
                isArray: true,
              },
            },
          ],
        },
        {
          label: concatTranslationKey(Categories.Layout, "numberOfFloors"),
          options: [
            {
              validatorType: ValidatorType.Simple,
              simple: {
                translationKey: concatTranslationKey(
                  Categories.Layout,
                  "numberOfFloors.value"
                ),
                translationValues: {
                  value: data.numberOfFloors,
                },
              },
            },
          ],
        },
        {
          label: concatTranslationKey(Categories.Layout, "locatedOn"),
          options: [
            {
              validatorType: ValidatorType.Complex,
              complex: {
                value: prefixStringArray(
                  ApiPrefixes.Location,
                  data.locationPlaces
                ),
                parseValueType: ParseComplexType.MasterTable,
                renderType: RenderType.PillsArray,
                isArray: true,
              },
            },
          ],
        },
      ],
    },
    {
      label: concatTranslationKey(Categories.Energy, "title"),
      rows: [
        {
          label: concatTranslationKey(Categories.Energy, "energyClass"),
          options: [
            {
              validatorType: ValidatorType.Complex,
              complex: {
                value: data.energyClass,
                parseValueType: ParseComplexType.None,
                renderType: RenderType.EnergyLabel,
              },
            },
          ],
        },
        {
          label: concatTranslationKey(Categories.Energy, "insulation"),
          options: [
            {
              validatorType: ValidatorType.Complex,
              complex: {
                parseValueType: ParseComplexType.MasterTable,
                renderType: RenderType.PillsArray,
                value: prefixStringArray(
                  ApiPrefixes.Insulation,
                  data.insulationTypes
                ),
                isArray: true,
              },
            },
          ],
        },
        {
          label: concatTranslationKey(Categories.Energy, "heating"),
          options: [
            {
              validatorType: ValidatorType.Complex,
              complex: {
                parseValueType: ParseComplexType.MasterTable,
                renderType: RenderType.PillsArray,
                value: prefixStringArray(
                  ApiPrefixes.WarmWater,
                  data.warmWaterTypes
                ),
                isArray: true,
              },
            },
          ],
        },
        {
          label: concatTranslationKey(Categories.Energy, "boiler"),
          options: [
            {
              validatorType: ValidatorType.Simple,
              simple: {
                translationKey: concatTranslationKey(
                  Categories.Energy,
                  "boilerType"
                ),
                translationValues: {
                  value: data.boilerType,
                },
              },
            },
          ],
        },
      ],
    },
    {
      label: concatTranslationKey(Categories.Cadastral, "title"),
      rows: [
        {
          label: concatTranslationKey(Categories.Cadastral, "location"),
          options: [
            {
              validatorType: ValidatorType.Complex,
              complex: {
                parseValueType: ParseComplexType.None,
                value: buildAddress(data.address),
              },
            },
          ],
        },
      ],
    },
    {
      label: concatTranslationKey(Categories.Outdoor, "title"),
      rows: [
        {
          label: concatTranslationKey(Categories.Outdoor, "gardens"),
          options: [
            {
              validatorType: ValidatorType.Complex,
              complex: {
                isArray: true,
                parseValueType: ParseComplexType.MasterTable,
                renderType: RenderType.PillsArray,
                value: retrieveGardenTypes(data.gardens),
              },
            },
          ],
        },
        {
          label: concatTranslationKey(Categories.Outdoor, "backGarden"),
          options: [
            {
              validatorType: ValidatorType.Simple,
              simple: {
                translationKey: concatTranslationKey(
                  Categories.Outdoor,
                  "backGarden.value"
                ),
                translationValues: {
                  area: data.gardens?.find(
                    item => item.type === GardenType.BackGarden
                  )?.size,
                },
              },
            },
          ],
        },
        {
          label: concatTranslationKey(Categories.Outdoor, "gardenLocation"),
          options: [
            {
              validatorType: ValidatorType.Simple,
              simple: {
                translationKey: concatTranslationKey(
                  Categories.Outdoor,
                  "gardenLocation.orientationAndBackEntrance"
                ),
                translationValues: {
                  orientation: concatTranslationKey(
                    ApiPrefixes.Orientations,
                    gardenLocation?.orientation || ""
                  ),
                  entrance: gardenLocation?.hasBackEntrance
                    ? "common.yes"
                    : "common.no",
                },
                valuesParser: ValuesParser.MasterTable,
              },
            },
            {
              validatorType: ValidatorType.Simple,
              simple: {
                translationKey: concatTranslationKey(
                  Categories.Outdoor,
                  "gardenLocation.orientation"
                ),
                translationValues: {
                  orientation: concatTranslationKey(
                    ApiPrefixes.Orientations,
                    gardenLocation?.orientation || ""
                  ),
                },
                valuesParser: ValuesParser.MasterTable,
              },
            },
          ],
        },
        {
          label: concatTranslationKey(Categories.Outdoor, "roofTerrace"),
          options: [
            {
              validatorType: ValidatorType.Simple,
              simple: {
                translationKey: concatTranslationKey(
                  Categories.Outdoor,
                  "roofTerrace"
                ),
                translationValues: {
                  value: data.hasRoofTerrace,
                },
              },
            },
          ],
        },
      ],
    },
    {
      label: concatTranslationKey(Categories.VveChecklist, "title"),
      disabled:
        !data.listingType || !VveEnabledCategories.includes(data.listingType),
      rows: [
        {
          label: concatTranslationKey(
            Categories.VveChecklist,
            "kvkRegistration"
          ),
          options: [
            {
              validatorType: ValidatorType.Simple,
              simple: {
                translationKey: data.isOwnersAssociationRegisteredKvK
                  ? "common.yes"
                  : "common.no",
                hasNoValues: true,
              },
            },
          ],
        },
        {
          label: concatTranslationKey(Categories.VveChecklist, "annualMeeting"),
          options: [
            {
              validatorType: ValidatorType.Simple,
              simple: {
                translationKey: data.hasOwnersAssociationYearlyMeeting
                  ? "common.yes"
                  : "common.no",
                hasNoValues: true,
              },
            },
          ],
        },
        {
          label: concatTranslationKey(
            Categories.VveChecklist,
            "regularContribution"
          ),
          options: [
            {
              validatorType: ValidatorType.Simple,
              simple: {
                translationKey: concatTranslationKey(
                  Categories.VveChecklist,
                  "regularContribution.value"
                ),
                translationValues: {
                  value: data.serviceCosts,
                },
              },
            },
          ],
        },
        {
          label: concatTranslationKey(Categories.VveChecklist, "reserveFund"),
          options: [
            {
              validatorType: ValidatorType.Simple,
              simple: {
                translationKey: data.hasOwnersAssociationReserveFund
                  ? "common.yes"
                  : "common.no",
                hasNoValues: true,
              },
            },
          ],
        },
        {
          label: concatTranslationKey(
            Categories.VveChecklist,
            "maintenancePlan"
          ),
          options: [
            {
              validatorType: ValidatorType.Simple,
              simple: {
                translationKey:
                  data.hasOwnersAssociationMultipleYearsMaintenancePlan
                    ? "common.yes"
                    : "common.no",
                hasNoValues: true,
              },
            },
          ],
        },
        {
          label: concatTranslationKey(
            Categories.VveChecklist,
            "buildingInsurance"
          ),
          options: [
            {
              validatorType: ValidatorType.Simple,
              simple: {
                translationKey: data.hasOwnersAssociationBuildingsInsurance
                  ? "common.yes"
                  : "common.no",
                hasNoValues: true,
              },
            },
          ],
        },
      ],
    },
  ];
};

export default assigmentPreviewDetailsTemplate;
