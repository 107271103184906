import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  modalTabButtonWrapper: responsiveStyles({
    outline: "none",
    background: "none",
    cursor: "pointer",
    border: `1px solid ${
      theme.companyTheme?.mainColor?.toString() ||
      theme.colors.primary.toString()
    }`,
    color:
      theme.companyTheme?.mainColor?.toString() ||
      theme.colors.primary.toString(),
    padding: "6px 13px",
    borderRadius: 3,
    maxWidth: "50%",
  })
    .m(
      {
        display: "flex",
        flex: 1,
        justifyContent: "center",
      },
      { propagate: true }
    )
    .s({ padding: "5px" }, { propagate: true })
    .getStyle(),
  modalTabButtonActive: {
    background:
      theme.companyTheme?.mainColor?.toString() ||
      theme.colors.primary.toString(),
    color:
      theme.companyTheme?.textColor?.toString() ||
      theme.colors.offWhite.toString(),
  },
  modalButtonContent: {
    display: "flex",
    alignItems: "center",
    gap: 10,
  },
  modalTabButtonLabel: {
    whiteSpace: "nowrap",
  },
  modalTabButtonIcon: {},
}));

export { useStyles };
