import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = (controls: { size?: string; theme?: string }) =>
  createUseStyles((theme: ThemeInterface) => ({
    toastContainer: {
      display: "flex",
      alignItems: "center",
      borderRadius: 2,
      margin: 8,
      boxShadow: "0 1px 2px rgba(0,0,0,0.15)",
      padding: "10px 0 10px 15px",
      width: () => {
        switch (controls.size) {
          case "medium":
            return 360;
          case "large":
            return 600;
          case "small":
          default:
            return 240;
        }
      },
      background: () => {
        switch (controls.theme) {
          case "info":
            return theme.colors.info.toString();
          case "success":
            return theme.colors.success.toString();
          case "error":
            return theme.colors.danger.toString();
          case "light":
            return "white";
          case "dark":
          default:
            return "rgba(0,0,0,0.8)";
        }
      },
      color: () => {
        switch (controls.theme) {
          case "light":
            return "#2e2e2e";
          case "dark":
          case "error":
          case "info":
          default:
            return "white";
        }
      },
    },
    icon: {
      flex: "none",
      display: "flex",
      alignItems: "center",
      paddingRight: 10,
      width: 30,
      justifyContent: "center",
    },
    message: {
      flex: 1,
      lineHeight: "18px",
      fontSize: 14,
    },
    close: {
      flex: "none",
      width: 40,
      height: 40,
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
      fontSize: 16,
      cursor: "pointer",
      color: () => {
        switch (controls.theme) {
          case "light":
            return "rgba(0,0,0,0.6)";
          case "info":
          case "dark":
          case "error":
          default:
            return "rgba(255,255,255, 0.6)";
        }
      },

      "&:hover": {
        color: () => {
          switch (controls.theme) {
            case "light":
            case "info":
              return "rgba(0,0,0,1)";
            case "dark":
            default:
              return "rgba(255,255,255,1)";
          }
        },
      },
    },
  }));

export { useStyles };
