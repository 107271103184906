export enum ROUTER_PATHS {
  EMPTY = "",
  ROOT = "/",
  PROFILE = "profile",
  PERSONAL_DETAILS = "personal-details",
  REALTOR = "realtor",
  LANGUAGE_SWITCH = "language",
  LOGOUT = "logout",
  ACTIVITIES = "activities",
  CHECKLIST = "checklist",
  DOCUMENTS = "documents",
  SETTINGS = "settings",
  LIST_OF_ITEMS = "list-of-items",
  QUESTIONNAIRE = "questionnaire",
  DOSSIER_ROOT = "dossier/:dossierId",
  OPEN_CHECKLIST = "open-checklist/:checklistItemId",
  OBJECT_PREVIEW = "preview",
}
