import { UnknownBoolAnswer } from "@kolibrisoftware/customerportal-api";
import { useMemo } from "react";
import { RadioGroupItem } from "@kolibrisoftware/customerportal-ui";
import { useTranslation } from "@kolibrisoftware/hooks-util";

const useUnknownBoolOption = () => {
  const t = useTranslation();
  const unknownBoolKeysList = useMemo(() => Object.keys(UnknownBoolAnswer), []);

  const unknownBoolOptions: RadioGroupItem<string>[] = useMemo(() => {
    return unknownBoolKeysList.map(item => {
      const labelValue = t(`unkownBoolOption.${item}`);
      return { label: labelValue, value: item };
    });
  }, [t, unknownBoolKeysList]);

  return unknownBoolOptions;
};
export default useUnknownBoolOption;
