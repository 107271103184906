import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  container: {
    position: "relative",
    overflowY: "auto",
    height: "100%",
  },
  contentWrapper: {
    height: "100%",
    overflowY: "scroll",
    display: "flex",
    justifyContent: "center",
  },
  title: {
    fontSize: 22,
    fontWeight: 400,
    marginBottom: 15,
  },
  listOfItemsDocumentWrapper: responsiveStyles({
    height: "100%",
    width: "100%",
  })
    .m({ width: "100%" }, { propagate: true })
    .getStyle(),
  hints: responsiveStyles({
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    left: 0,
    width: "100vw",
    gap: 8,
    bottom: 20,
  })
    .s({ bottom: 96 }, { propagate: true })
    .getStyle(),
}));

export { useStyles };
