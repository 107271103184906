import React, { useCallback, useEffect, FC } from "react";
import { httpWebUtil } from "store/helpers";

export const RenewComponent: FC = () => {
  const signinSilentCallback = useCallback(async () => {
    try {
      await httpWebUtil.signinSilentCallback();
    } catch (error) {
      throw error;
    }
  }, []);

  useEffect(() => {
    signinSilentCallback();
  }, [signinSilentCallback]);

  return <></>;
};
