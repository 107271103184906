import { MENU_ITEMS, MenuItem, MenuItemsUseCase } from "constants/menu-items";
import { ROUTER_PATHS } from "constants/routes";
import { useSelector } from "store/helpers";

/**
 * Returns a list of menu items filtered on
 *  - useCase
 *  - userDefinedFilters
 */
export default function useMenuItems(useCase: MenuItemsUseCase): MenuItem[] {
  const companyData = useSelector(state => state.main.companyData);
  const assignmentDetails = useSelector(
    state => state.dossier.assignmentDetails
  );

  return MENU_ITEMS.filter(item => item.useCase?.includes(useCase)).filter(
    item => {
      switch (item.path) {
        case ROUTER_PATHS.REALTOR:
          return !!companyData;
        case ROUTER_PATHS.OBJECT_PREVIEW:
          return assignmentDetails.showPreview;
        case ROUTER_PATHS.LANGUAGE_SWITCH:
          return !!Object.entries(assignmentDetails).length;
        default:
          return true;
      }
    }
  );
}
