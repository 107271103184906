import { FormElement } from "@kolibrisoftware/customerportal-api";
import { useTheme } from "injectors/theme";
import { ListOfItemsUseCases } from "modules/list-of-items/enums/list-of-items-use-cases";
import useElementData from "modules/list-of-items/hooks/useElementData";
import { FC } from "react";
import useElementTranslations from "hooks/useElementTranslations";
import { ForeignQuestion } from "../../foreign-question/foreign-question";
import { FormSectionHeader } from "../../form-section-header/form-section-header";
import { useStyles } from "./styles";

type Props = { sectionStructure: FormElement };

const ForeignSectionComponent: FC<Props> = ({ sectionStructure }) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const sectionData = useElementData(sectionStructure.id);
  const sectionTranslations = useElementTranslations(sectionData);

  return (
    <div className={styles.foreignSectionContainer}>
      <hr className={styles.divider} />
      <FormSectionHeader
        sectionTranslations={sectionTranslations}
        useCase={ListOfItemsUseCases.Foreign}
      />
      {sectionStructure?.elements?.map(currentQuestion => (
        <ForeignQuestion
          key={`${sectionStructure.id} -- ${currentQuestion.id}`}
          questionStructure={currentQuestion}
        />
      ))}
    </div>
  );
};

export { ForeignSectionComponent };
