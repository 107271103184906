import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  activityWrapper: {
    display: "flex",
    padding: 10,
    justifyContent: "space-between",
    alignItems: "center",
    gap: 10,
    border: "1px solid #E7E7ED",
    borderRadius: 10,
    marginTop: 10,
    "&:last-child": {
      marginBottom: 15,
    },
  },
  activityIconWrapper: {
    width: 40,
    textAlign: "center",
    position: "relative",
  },
  activityIcon: {
    color: theme.companyTheme?.supportColor?.toString(),
    fontSize: 22,
  },
  activityDetails: {
    flex: 1,
  },
  activityDetailsTitle: {
    fontSize: 15,
    fontWeight: "400",
  },
  activityDetailsSubtitle: {
    fontSize: 13,
    fontWeight: "400",
    color: "#8E8E93",
  },
  activityAction: {
    color: theme.companyTheme?.supportColor?.toString(),
    minHeight: 22,
    fontSize: 22,
    padding: "0 10px",
    display: "flex",
    flexDirection: "column",
    alignItems: "flex-end",
    justifyContent: "center",
  },

  clickableActivity: {
    transition: "all 0.1s ease-in-out",
    "&:hover": {
      boxShadow: "0 0 1px 1px #E8ECF1",
      cursor: "pointer",
    },
  },
  statusIconWrapper: {
    position: "absolute",
    right: 4,
    top: -8,
    background: "white",
    width: 18,
    height: 18,
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "50%",
    "&:hover": {
      "& $statusIconTooltip": {
        display: "unset",
      },
    },
  },
  actionIconLabel: {
    fontSize: 13,
  },
  actionWrapper: {
    textAlign: "center",
  },
  statusIconTooltip: {
    display: "none",
    position: "absolute",
    background: theme.colors.offBlack.toString(),
    color: theme.colors.offWhite.toString(),
    fontSize: 11,
    minWidth: 100,
    bottom: "125%",
    right: -5,
    borderRadius: 3,
    padding: 3,
    "&::after": {
      content: "''",
      position: "absolute",
      bottom: -8,
      right: 4,
      width: 0,
      height: 0,
      borderTop: `10px solid ${theme.colors.offBlack.toString()}`,
      borderLeft: "10px solid transparent",
      borderRight: "10px solid transparent",

      borderRadius: 1,
      clear: "both",
    },
  },
  highlightedItem: {
    boxShadow: `0px 0px 5px ${theme.companyTheme?.mainColor
      ?.lighten(0.2)
      .toString()}`,
  },
}));

export { useStyles };
