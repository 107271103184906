import { ThemeInterface } from "injectors/theme";
import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  questionTranslationsWrapper: responsiveStyles({
    display: "flex",
    gap: 20,
  })
    .s(
      {
        gap: 10,
      },
      { propagate: true }
    )
    .getStyle(),
  questionPrefix: {
    fontWeight: 500,
  },
  questionText: {
    alignSelf: "center",
  },
  questionHint: {
    fontSize: 12,
    fontStyle: "italic",
    color: theme.colors.offBlack.lighten(5).toString(),
  },
  bulletList: {
    fontSize: 24,
  },
}));

export { useStyles };
