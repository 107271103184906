import {
  Culture,
  Client as CustomerPortalClient,
  SearchChecklistItemsRequest,
} from "@kolibrisoftware/customerportal-api";
import { createThunk } from "store/helpers";

export type SearchChecklistItemsPayload = {
  assignmentId: string;
  realEstateAgencyId: string;
  requestBody?: SearchChecklistItemsRequest;
};

const searchChecklistItems = createThunk(
  "api/checklistItem/searchChecklistItems",
  async (
    { settings, http, handleError },
    args: SearchChecklistItemsPayload
  ) => {
    const {
      assignmentId,
      realEstateAgencyId,
      requestBody = { skip: 0, take: 100, culture: Culture.Nl },
    } = args;
    try {
      const client = new CustomerPortalClient(
        settings?.apiUrlCustomerPortal,
        http
      );
      if (!realEstateAgencyId || !assignmentId) {
        return;
      }
      const checklistItems = await client
        .searchChecklistItem(realEstateAgencyId, assignmentId, requestBody)
        .then(data => data.result);

      if (!checklistItems) {
        throw new Error("404 - Checklist details not found");
      }
      return checklistItems;
    } catch (error: any) {
      handleError(error);
      throw error;
    }
  }
);

export type SendForReviewPayload = {
  realEstateAgencyId: string;
  checklistItemId: string;
  assignmentId: string;
};
const sendForReview = createThunk(
  "api/checklistItem/sendForReview",
  async ({ settings, http, handleError }, args: SendForReviewPayload) => {
    const { realEstateAgencyId, checklistItemId, assignmentId } = args;
    try {
      const client = new CustomerPortalClient(
        settings?.apiUrlCustomerPortal,
        http
      );
      const result = await client.requestChecklistItemReview(
        realEstateAgencyId,
        checklistItemId,
        assignmentId
      );
      return result;
    } catch (error: any) {
      handleError(error);
      throw error;
    }
  }
);

export const thunks = { searchChecklistItems, sendForReview };
