import AsyncModal from "shared/components/async-modal";
import { createContext, FC, useCallback, useRef, useState } from "react";

export type ModalType = "modal" | "confirm" | "alert";
type Props = {
  children?: React.ReactNode;
};
type StateProps = {
  title: string | null;
  type: ModalType | null;
  body: React.ReactNode | null;
  visible: boolean;
};
type ContextProps = {
  state?: StateProps;
  proceed?: (value?: any) => void;
  cancel?: (reason?: any) => void;
  updateState?: (props: Partial<StateProps>) => void;
  setPromiseFn?: (
    proceed?: ((value?: any) => void) | undefined,
    cancel?: ((reason?: any) => void) | undefined
  ) => void;
};

export const ModalContext = createContext<ContextProps>({});

const ModalContextProvider: FC<Props> = ({ children }) => {
  const proceedFn = useRef<(value?: any) => void>();
  const cancelFn = useRef<(reason?: any) => void>();

  const [state, setState] = useState<StateProps>({
    title: null,
    type: null,
    body: null,
    visible: false,
  });
  const refCounter = useRef(0);
  refCounter.current++;

  const setPromiseFn = useCallback(
    (proceed?: (value?: any) => void, cancel?: (reason?: any) => void) => {
      proceedFn.current = proceed;
      cancelFn.current = cancel;
    },
    []
  );

  const updateState = useCallback((props: Partial<StateProps>) => {
    setState(state => ({ ...state, ...props }));
  }, []);

  const proceed = useCallback((args?: any) => {
    proceedFn.current?.(args);
  }, []);

  const cancel = useCallback((args?: any) => {
    cancelFn.current?.(args);
  }, []);

  return (
    <ModalContext.Provider
      value={{
        state,
        proceed,
        cancel,
        updateState,
        setPromiseFn,
      }}
    >
      {children}
      <AsyncModal visible={state.visible} />
    </ModalContext.Provider>
  );
};

export default ModalContextProvider;
