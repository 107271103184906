import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  "@keyframes rotation": {
    "0%": { transform: "rotate(0deg)" },
    "100%": { transform: "rotate(360deg)" },
  },
  overviewObjectsContainer: {
    height: "100%",
    overflowY: "auto",
    paddingTop: 20,
    paddingBottom: 60,
  },

  grid: responsiveStyles({
    position: "relative",
    display: "grid",
    gridTemplateColumns: "1fr 1fr",
    maxWidth: 900,
    margin: "0 auto",
    gap: 30,
    justifyContent: "center",
  })
    .xs({
      gridTemplateColumns: "unset",
      maxWidth: 380,
      gap: 20,
      justifyContent: "unset",
    })
    .s({ maxWidth: "90%", gap: 20 })
    .m({ maxWidth: 800 })
    .add(
      { lowerBound: 481, upperBound: 580 },
      {
        gridTemplateColumns: "unset",
        maxWidth: 380,
        gap: 20,
        justifyContent: "unset",
      }
    )
    .getStyle(),
}));

export default useStyles;
