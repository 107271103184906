import { useTranslation } from "@kolibrisoftware/hooks-util";
import { useTheme } from "injectors/theme";
import { useSelector } from "store/helpers";
import { FC, useMemo } from "react";
import SidebarSectionGroup from "../../components/sidebar-section-group";
import { useStyles } from "./styles";

type Props = {};

const Sidebar: FC<Props> = props => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const formStructure = useSelector(state => state.questionnaire.formStructure);
  const t = useTranslation();

  const translations = useMemo(
    () => ({
      title: t("questionnaire.sidebar.title"),
    }),
    [t]
  );

  const sidebarGroups = useMemo(
    () => formStructure.parts || [],
    [formStructure]
  );

  return (
    <>
      <div className={styles.sidebarContainer}>
        <h2 className={styles.sidebarTitle}>{translations.title}</h2>
        <div className={styles.sidebarPartsWrapper}>
          {sidebarGroups.map((part, index) => {
            return (
              <SidebarSectionGroup
                sectionStructure={part}
                key={part.id}
                index={index}
              />
            );
          })}
        </div>
      </div>
    </>
  );
};

export { Sidebar };
