import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  modalContextWrapper: {},
  modalBodyWrapper: responsiveStyles({
    padding: 16,
    display: "flex",
    flexDirection: "column",
    justifyContent: "space-between",
    height: "80vh",
    width: "70vw",
  })
    .add(
      { upperBound: 1200 },
      {
        padding: 0,
        flexDirection: "column-reverse",
        width: "100vw",
        height: "85vh",
        justifyContent: "flex-start",
      },
      { propagate: true }
    )
    .getStyle(),
  modalContentWrapper: responsiveStyles({
    height: "90%",
    display: "flex",
    overflowY: "scroll",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  })
    .add({ upperBound: 1200 }, { padding: 13 }, { propagate: true })
    .getStyle(),
  modalTabs: responsiveStyles({
    display: "flex",
    gap: 13,
  })
    .add(
      { upperBound: 1200 },
      {
        padding: 13,
        gap: 5,
        flexWrap: "wrap",
        boxShadow: "0px 1px 1px #c1c1c1",
      },
      { propagate: true }
    )
    .getStyle(),
}));

export { useStyles };
