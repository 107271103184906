import { FC } from "react";
import { OverviewScreen } from "./screens/overview/overview";

type Props = {};

const OverviewModule: FC<Props> = props => {
  return <OverviewScreen />;
};

export { OverviewModule };
