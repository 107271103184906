import { useMedia, useTranslation } from "@kolibrisoftware/hooks-util";
import { FC, useEffect, useMemo, useRef } from "react";
import { useParams } from "react-router-dom";
import ReviewChecklistItemButton from "shared/components/review-checklist-item-button/review-checklist-item-button";
import { BackActionLayout } from "layouts/back-action-layout/back-action-layout";
import { useDispatch, useSelector } from "store/helpers";
import { InitialDataLayer } from "./initial-data-layer/initial-data-layer";
import RootScreen from "./screens/root-screen";
import { actions as listOfItemsActions } from "store/list-of-items";
import {
  ChecklistItemStatusType,
  FormType,
} from "@kolibrisoftware/customerportal-api";
import MobileBackActionLayout from "layouts/back-action-layout/mobile-back-action-layout";
import { FormLoadingStatus } from "enums/form-loading-status";
import { ErrorPage } from "shared/components/error-page/error-page";
import LoadingScreen from "shared/ui/loading-screen";
import { PresenceProvider } from "injectors/presence";
import { Presence } from "shared/components/presence";

type Props = {};

const ListOfItemsModule: FC<Props> = props => {
  const { savingStatus, formEditableStatus, formLoadingStatus } = useSelector(
    state => state.listOfItems
  );

  const dispatch = useDispatch();
  const t = useTranslation();
  const { dossierId } = useParams();
  const { checklistItemId } = useSelector(
    state => state.listOfItems.formStructure
  );
  const attachedChecklistItem = useSelector(state =>
    state.dossier.checklistItems.find(item => item.id === checklistItemId)
  );

  const isScreenUnder1150 = useMedia("(max-width:1150px)");
  const renewHintRef = useRef(false);

  const translations = useMemo(
    () => ({
      pageTile: t("listOfItems.pageTitle"),
      backActionLabel: t("listOfItems.backActionLabel"),
    }),
    [t]
  );

  useEffect(() => {
    if (attachedChecklistItem) {
      dispatch(
        listOfItemsActions.setFormEditableStatus(attachedChecklistItem?.status)
      );
      renewHintRef.current = !renewHintRef.current;
    }
  }, [attachedChecklistItem, dispatch]);

  const showEditActions = useMemo(
    () => (formEditableStatus === ChecklistItemStatusType.Open ? true : false),
    [formEditableStatus]
  );
  return (
    <PresenceProvider formType={FormType.ListOfGoods}>
      <InitialDataLayer>
        {formLoadingStatus === FormLoadingStatus.Loading && <LoadingScreen />}
        {formLoadingStatus === FormLoadingStatus.Failed && (
          <ErrorPage useCase="FormError" />
        )}
        {formLoadingStatus === FormLoadingStatus.Success && (
          <>
            {isScreenUnder1150 ? (
              <MobileBackActionLayout
                centerTitle={translations.pageTile}
                backActionLabel={translations.backActionLabel}
                savingStatus={showEditActions ? savingStatus : undefined}
                presence={<Presence />}
                reviewButton={
                  showEditActions ? (
                    <ReviewChecklistItemButton
                      dossierId={dossierId || ""}
                      checklistItemId={checklistItemId || ""}
                      disabled={
                        formEditableStatus !== ChecklistItemStatusType.Open
                      }
                    />
                  ) : undefined
                }
              >
                <RootScreen renewHint={renewHintRef.current} />
              </MobileBackActionLayout>
            ) : (
              <BackActionLayout
                centerTitle={translations.pageTile}
                backActionLabel={translations.backActionLabel}
                savingStatus={showEditActions ? savingStatus : undefined}
                presence={<Presence />}
                reviewButton={
                  showEditActions ? (
                    <ReviewChecklistItemButton
                      dossierId={dossierId || ""}
                      checklistItemId={checklistItemId || ""}
                      disabled={
                        formEditableStatus !== ChecklistItemStatusType.Open
                      }
                    />
                  ) : undefined
                }
              >
                <RootScreen renewHint={renewHintRef.current} />
              </BackActionLayout>
            )}
          </>
        )}
      </InitialDataLayer>
    </PresenceProvider>
  );
};

export { ListOfItemsModule };
