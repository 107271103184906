import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  navItemsContainer: responsiveStyles({
    display: "flex",
    justifyContent: "center",
    gap: 5,
  })
    .m({ gap: 10 }, { propagate: true })
    .getStyle(),
}));

export { useStyles };
