import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  detailsCategoryWrapper: {
    marginTop: 30,
  },
  detailsCategoryTitle: {
    fontSize: 15,
    fontWeight: 600,
  },
  detailsCategoryRowsWrapper: {},
}));

export { useStyles };
