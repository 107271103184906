import { IconDefinition } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "@kolibrisoftware/hooks-util";
import { useTheme } from "injectors/theme";
import { FC } from "react";
import { useStyles } from "./styles";

type Props = {
  label: string;
  value?: string | null;
  linkTo?: string;
  icon?: IconDefinition;
};

const CompanyDetailsCardRow: FC<Props> = ({ label, icon, linkTo, value }) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const t = useTranslation();

  return !value ? null : (
    <div className={styles.companyDetailsCardRowContainer}>
      <div className={styles.labelWrapper}>
        {!!icon && <FontAwesomeIcon icon={icon} />}
        <span>{t(label)}:</span>
      </div>
      <div className={styles.valueWrapper}>
        {linkTo ? (
          <a
            href={linkTo}
            target="_blank"
            rel="noreferrer"
            className={styles.link}
          >
            {value}
          </a>
        ) : (
          <span>{value}</span>
        )}
      </div>
    </div>
  );
};

export default CompanyDetailsCardRow;
