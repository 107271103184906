import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  mobileNavItem: {
    position: "relative",
  },
  mobileNavItem__active: {
    color: "#012C57",
  },
  mobileNavItem__visible: {
    padding: "15px 0",
  },
  notification: {
    position: "absolute",
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    color: "#fefefe",
    fontSize: 11,
    fontWeight: 600,
    width: 18,
    height: 18,
    background: "#FF9500",
    borderRadius: "50%",
    right: 0,
  },
}));

export { useStyles };
