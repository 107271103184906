import { IconDefinition } from "@fortawesome/fontawesome-svg-core";
import {
  faArrowRightFromBracket,
  faFileLines,
  faFlag,
  faFolders,
  faLanguage,
  faListCheck,
  faPersonSign,
  faSignHanging,
  faUser,
} from "@fortawesome/pro-regular-svg-icons";
import { ROUTER_PATHS } from "./routes";

export enum MenuItemsUseCase {
  MOBILE = "Mobile",
  PRIMARY = "Primary",
  DOSSIER = "Dossier",
}

export type MenuItem = {
  eventType: MenuItemsEvents;
  path: ROUTER_PATHS | string;
  useCase?: MenuItemsUseCase[];
  label: string;
  icon?: IconDefinition;
};

export enum MenuItemsEvents {
  Navigate = "Navigate",
  OpenModal = "OpenModal",
  ExternalNavigation = "ExternalNavigation",
  LanguageSwitch = "LanguageSwitch",
}

export const MENU_ITEMS: MenuItem[] = [
  {
    label: "navItem.overview",
    icon: faUser,
    eventType: MenuItemsEvents.Navigate,
    path: ROUTER_PATHS.EMPTY,
    useCase: [MenuItemsUseCase.DOSSIER, MenuItemsUseCase.MOBILE],
  },
  {
    label: "navItem.checklist",
    icon: faListCheck,
    eventType: MenuItemsEvents.Navigate,
    path: ROUTER_PATHS.CHECKLIST,
    useCase: [MenuItemsUseCase.DOSSIER, MenuItemsUseCase.MOBILE],
  },
  {
    label: "navItem.documents",
    icon: faFileLines,
    eventType: MenuItemsEvents.Navigate,
    path: ROUTER_PATHS.DOCUMENTS,
    useCase: [MenuItemsUseCase.DOSSIER, MenuItemsUseCase.MOBILE],
  },
  {
    eventType: MenuItemsEvents.Navigate,
    icon: faSignHanging,
    label: "navItem.objectPreview",
    path: ROUTER_PATHS.OBJECT_PREVIEW,
    useCase: [MenuItemsUseCase.DOSSIER, MenuItemsUseCase.MOBILE],
  },
  {
    label: "navItem.dossier",
    icon: faFolders,
    eventType: MenuItemsEvents.Navigate,
    path: ROUTER_PATHS.ROOT,
    useCase: [MenuItemsUseCase.PRIMARY, MenuItemsUseCase.MOBILE],
  },
  {
    label: "navItem.profile",
    icon: faUser,
    eventType: MenuItemsEvents.ExternalNavigation,
    path: ROUTER_PATHS.PROFILE,
    useCase: [MenuItemsUseCase.PRIMARY, MenuItemsUseCase.MOBILE],
  },
  {
    label: "navItem.realtor",
    icon: faPersonSign,
    eventType: MenuItemsEvents.OpenModal,
    path: ROUTER_PATHS.REALTOR,
    useCase: [MenuItemsUseCase.PRIMARY, MenuItemsUseCase.MOBILE],
  },
  {
    label: "navItem.language",
    icon: faLanguage,
    eventType: MenuItemsEvents.LanguageSwitch,
    path: ROUTER_PATHS.LANGUAGE_SWITCH,
    useCase: [MenuItemsUseCase.PRIMARY],
  },
  {
    label: "navItem.logout",
    icon: faArrowRightFromBracket,
    eventType: MenuItemsEvents.ExternalNavigation,
    path: ROUTER_PATHS.LOGOUT,
    useCase: [MenuItemsUseCase.PRIMARY, MenuItemsUseCase.MOBILE],
  },
];
