import Color from "color";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles({
  // "@keyframes rotation": {
  //   "0%": { transform: "rotate(0deg) scale(1)" },
  //   "50%": { transform: "rotate(180deg) scale(2)" },
  //   "100%": { transform: "rotate(360deg) scale(1)" },
  // },
  container: {
    width: "100%",
    height: "100%",
    background: "#fefefe",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  container__full_screen: {
    height: "100vh",
  },

  loader: {
    width: "48px",
    height: "48px",
    borderRadius: "50%",
    display: "inline-block",
    position: "relative",
    border: "10px solid",
    borderColor:
      "rgb(225, 225, 225) rgb(200, 200, 200) rgb(175, 175, 175) rgb(150,150, 150)",
    boxSizing: "border-box",
    animation: "$rotation 1s linear infinite",
  },
  "@keyframes rotation": {
    "0%": {
      transform: "rotate(0deg)",
    },
    "100%": {
      transform: "rotate(360deg)",
    },
  },
});

export { useStyles };
