import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  objectView360Wrapper: {
    flex: 1,
    position: "relative",
    display: "flex",
    flexDirection: "column",
    color: "black",
  },
  objectView360Iframe: { flex: 1 },
  objectView360Controls: {
    fontWeight: "bold",
    position: "absolute",
    top: -3,
    left: "50%",
    transform: "translateX(-50%)",
    color: theme.colors.offBlack.toString(),
    textAlign: "center",
    backgroundColor: "white",
    borderBottomLeftRadius: 5,
    borderBottomRightRadius: 5,
  },
}));

export { useStyles };
