import { SingleChecklistItem } from "@kolibrisoftware/customerportal-api";
import { ExtendedChecklistItem } from "./extend-checklist-items";

const resolveName = (
  checklistItem: ExtendedChecklistItem | SingleChecklistItem
) => checklistItem.translations?.find(item => !!item.name)?.name || "...";

const resolveDescription = (
  checklistItem: ExtendedChecklistItem | SingleChecklistItem
) =>
  checklistItem.translations?.find(item => !!item.description)?.description ||
  "";

const checklistItemHelpers = {
  resolveName,
  resolveDescription,
};

export default checklistItemHelpers;
