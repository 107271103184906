import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  energyLabelWrapper: {
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    padding: "0 10px",
    height: 30,
    backgroundColor: "green",
    position: "relative",
    color: theme.colors.offWhite.toString(),
    "&::after": {
      content: "' '",
      width: 0,
      height: 0,
      borderTop: "15px solid transparent",
      borderBottom: "15px solid transparent",
      borderLeft: "15px solid",
      position: "absolute",
      left: "100%",
      top: 0,
    },
  },
  theme_A: {
    backgroundColor: "#1b8e43",
    "&::after": {
      borderLeftColor: "#1b8e43",
    },
  },
  theme_B: {
    backgroundColor: "#4aa847",
    "&::after": {
      borderLeftColor: "#4aa847",
    },
  },
  theme_C: {
    backgroundColor: "#9dba3a",
    "&::after": {
      borderLeftColor: "#9dba3a",
    },
  },
  theme_D: {
    color: "#8b8b8b",
    backgroundColor: "#faec0d",
    "&::after": {
      borderLeftColor: "#faec0d",
    },
  },
  theme_E: {
    backgroundColor: "#eb9d21",
    "&::after": {
      borderLeftColor: "#eb9d21",
    },
  },
  theme_F: {
    backgroundColor: "#ed6527",
    "&::after": {
      borderLeftColor: "#ed6527",
    },
  },
  theme_G: {
    backgroundColor: "#db372d",
    "&::after": {
      borderLeftColor: "#db372d",
    },
  },
}));

export { useStyles };
