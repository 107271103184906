import { useSelector } from "store/helpers";

const useListOfItemsQuestionAnswer = (questionId: string = "") => {
  return useSelector(state =>
    state.listOfItems.answers.find(
      answer => answer.blueprintElementId === questionId
    )
  );
};

export default useListOfItemsQuestionAnswer;
