import { useTheme } from "injectors/theme";
import { FC } from "react";
import { ObjectTabComponent } from "./object-tab/object-tab";
import { useStyles } from "./styles";

import useObjectPreviewTabs from "hooks/useObjectPreviewTabs";

type Props = {};

const ObjectTabs: FC<Props> = props => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const tabs = useObjectPreviewTabs("preview");
  return (
    <>
      {tabs.length > 0 && (
        <div className={styles.objectTabsContainer} data-cy="ObjectTabs">
          {tabs.map((item, index) => {
            return <ObjectTabComponent objectTab={item} key={index} />;
          })}
        </div>
      )}
    </>
  );
};

export { ObjectTabs };
