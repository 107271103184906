import {
  FormElement,
  FormElementQuestionType,
  FormElementType,
} from "@kolibrisoftware/customerportal-api";
import classNames from "classnames";
import { useTheme } from "injectors/theme";
import QuestionFormTranslation from "modules/questionnaire/elements/form-element-translation"; // TODO -> USED IN BOTH!
import useElementData from "modules/list-of-items/hooks/useElementData";
import { FC } from "react";
import useElementTranslations from "hooks/useElementTranslations";
import ForeignQuestionInput from "../foreign-question-input";
import { useStyles } from "./styles";

type Props = {
  questionStructure: FormElement;
};

const ForeignQuestion: FC<Props> = ({ questionStructure }) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const questionDetails = useElementData(questionStructure.id);
  const translations = useElementTranslations(questionDetails);

  return (
    <div className={classNames(styles.formQuestionWrapper)}>
      {questionStructure.type !== FormElementType.Section && (
        <div
          className={classNames(styles.questionDetailsWrapper, {
            [styles.questionDetailsColumn]:
              questionDetails?.questionType ===
              FormElementQuestionType.TextArea,
          })}
        >
          <div className={styles.translationWrapper}>
            <QuestionFormTranslation text={translations?.title || ""} />
          </div>
          <div
            className={classNames(styles.inputWrapper, {
              [styles.booleanInputWrapper]:
                questionDetails?.questionType === FormElementQuestionType.Bool,
            })}
          >
            {questionDetails &&
              questionStructure.type === FormElementType.Question && (
                <ForeignQuestionInput
                  name={translations?.title || ""}
                  questionDetails={questionDetails}
                />
              )}
          </div>
        </div>
      )}
    </div>
  );
};

export { ForeignQuestion };
