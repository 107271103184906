import { MenuItemsUseCase } from "constants/menu-items";
import { ROUTER_PATHS } from "constants/routes";
import useMenuItems from "hooks/useMenuItems";
import { useTheme } from "injectors/theme";
import SecondaryNavbar from "layouts/secondary-navbar-wrapper";
import { FC } from "react";
import { selectOpenExtendedChecklistItems } from "store/dossier/selectors";
import { useSelector } from "store/helpers";
import SecondaryNavItem from "../secondary-nav-item";
import { useStyles } from "./styles";

type Props = {};

const SecondaryNavigation: FC<Props> = props => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const openChecklistItems = useSelector(selectOpenExtendedChecklistItems);
  const menuItems = useMenuItems(MenuItemsUseCase.DOSSIER);

  return (
    <SecondaryNavbar>
      <div className={styles.navItemsContainer}>
        {menuItems.map((item, index) => {
          if (item.path === ROUTER_PATHS.CHECKLIST) {
            return (
              <SecondaryNavItem
                item={item}
                notification={openChecklistItems.length}
                key={`desktop-nav-item-${index}`}
                data-cy="ChecklistNavItem"
              />
            );
          }
          return (
            <SecondaryNavItem
              item={item}
              key={`desktop-nav-item-${index}`}
              data-cy="NavItem"
            />
          );
        })}
      </div>
    </SecondaryNavbar>
  );
};

export { SecondaryNavigation };
