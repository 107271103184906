import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles({
  reviewActionWrapper: {
    display: "flex",
    alignItems: "center",
  },
  pill: {},
  action: {
    width: "max-content",
  },
});
