import {
  FormElement,
  FormElementType,
} from "@kolibrisoftware/customerportal-api";
import { FC, useMemo } from "react";
import FormPartElement from "modules/questionnaire/components/form-part";
import FormSectionElement from "../../components/form-section";
import FormQuestionElement from "../../components/form-question";
import FormText from "modules/questionnaire/components/form-text";

type Props = {
  elementStructure: FormElement;
  hierarchyLevel: number;
  elementIndex?: number;
  totalSiblings?: number;
};

const FormElementWrapper: FC<Props> = ({
  elementStructure,
  hierarchyLevel,
  elementIndex,
  totalSiblings,
}) => {
  const elementChildrenElements = useMemo(() => {
    const elementChildren = elementStructure.elements || [];

    let counter = 0;
    const indexes = elementChildren.map(child => {
      if (child.useIndex) {
        const index = counter;
        counter++;
        return index;
      }

      return undefined;
    });

    const totalSiblings = indexes.filter(d => !!d).length;

    return elementChildren.map((child, index) => (
      <FormElementWrapper
        elementStructure={child}
        hierarchyLevel={hierarchyLevel + 1}
        elementIndex={indexes[index]}
        totalSiblings={totalSiblings}
        key={child.id}
      />
    ));
  }, [elementStructure, hierarchyLevel]);

  const currentElement = useMemo(() => {
    switch (elementStructure.type) {
      case FormElementType.Part:
        return (
          <FormPartElement
            partStructure={elementStructure}
            key={elementStructure.id}
          >
            {elementChildrenElements}
          </FormPartElement>
        );
      case FormElementType.Section:
        if (
          !elementStructure.elements ||
          elementStructure.elements.length === 0
        ) {
          return <></>;
        }
        return (
          <FormSectionElement
            sectionStructure={elementStructure}
            hierarchyLevel={hierarchyLevel}
            sectionIndex={elementIndex}
            totalSiblings={totalSiblings}
          >
            {elementChildrenElements}
          </FormSectionElement>
        );
      case FormElementType.Question:
        return (
          <FormQuestionElement
            questionStructure={elementStructure}
            hierarchyLevel={hierarchyLevel}
            questionIndex={elementIndex}
          >
            {elementChildrenElements}
          </FormQuestionElement>
        );
      case FormElementType.Text:
        return (
          <FormText
            textStructure={elementStructure}
            hierarchyLevel={hierarchyLevel}
            textIndex={elementIndex}
          >
            {elementChildrenElements}
          </FormText>
        );
      default:
        return <></>;
    }
  }, [
    elementStructure,
    hierarchyLevel,
    elementIndex,
    totalSiblings,
    elementChildrenElements,
  ]);

  return currentElement;
};

export { FormElementWrapper };
