import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  "@keyframes rotation": {
    "0%": { transform: "rotate(0deg)", color: "gray" },
    "50%": { transform: "rotate(180deg) ", color: "green" },
    "100%": { transform: "rotate(360deg)", color: "gray" },
  },
  layoutWrapper: {
    overflowY: "hidden",
  },
  navActionWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  navAction: {
    padding: 0,
    background: "none",
    border: "none",
    display: "flex",
    alignItems: "center",
    gap: 10,
    textDecoration: "none",
    color: theme.companyTheme?.mainColor?.toString() || "#000000aa",
    cursor: "pointer",
    transition: "all 0.1s ease",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    "&:hover": {
      color:
        theme.companyTheme?.mainColor?.lighten(0.2).toString() || "#000000",
    },
  },
  navTitle: responsiveStyles({
    flex: 4,
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    gap: 10,
  })
    .s({ flex: 4 }, { propagate: true })
    .getStyle(),
  fauxDix: {
    flex: 1,
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  },
  savingStatus: {
    display: "flex",
    gap: 5,
    fontSize: 13,
    fontWeight: 300,
    alignItems: "center",
  },
  loadingStatus: {
    animation: "$rotation 1s linear infinite",
  },
  savingStatusIcon: {
    fontSize: 16,
  },
  savingStatusLabel: {},
}));

export { useStyles };
