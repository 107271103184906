import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  emptyScreenWrapper: {
    display: "flex",
    height: "100%",
    width: "100%",
    alignItems: "center",
    justifyContent: "center",
    fontSize: 24,
    color: theme.colors.offBlack.toString(),
    flexDirection: "column",
    gap: 10,
  },
  icon: {
    fontSize: 46,
    color: theme.colors.lightGray.toString(),
  },
}));

export { useStyles };
