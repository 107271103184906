import Color from "color";
import { ThemeInterface } from "injectors/theme";
import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  mobileSidebarContainer: {
    zIndex: 1000,
  },
  sidebarToggle: {
    border: "none",
    padding: "5px 10px",
    backgroundColor: "none",
    background: theme?.companyTheme?.mainColor?.toString(),
    color: theme?.companyTheme?.mainColor?.isDark()
      ? Color.rgb(255, 255, 255).alpha(0.9).toString()
      : Color.rgb(0, 0, 0).alpha(0.9).toString(),
    fontWeight: 500,
    borderRadius: 4,
    fontSize: 12,
  },
  closedSidebar: {
    position: "absolute",
    top: 0,
    width: 0,
    overflow: "hidden",
    opacity: 0,
    transition: "all 0.2s ease",
  },
  fullSidebarWrapper: responsiveStyles({
    opacity: 1,
    overflow: "unset",
    overflowX: "hidden",
    backgroundColor: "white",
    padding: 20,
    boxShadow: "6px 1px 6px 0px #0000001a",
    width: "50vw",
    height: "100%",
    paddingBottom: 100,
    zIndex: 1000,
  })
    .s(
      { height: "calc(100% - 185px)", width: "100vw", paddingBottom: "unset" },
      { propagate: true }
    )
    .getStyle(),
  contextMenuBackground: {
    width: "100vw",
    height: "100vh",
    background: "#2727275c",
    zIndex: 900,
  },
  mobileCloseButton: {
    position: "absolute",
    right: 20,
    top: 25,
  },
  mobileSidebarToggle: {
    border: "none",
    padding: "5px 10px",
    backgroundColor: "none",
    background: theme?.companyTheme?.mainColor?.toString(),
    color: theme?.companyTheme?.mainColor?.isDark()
      ? Color.rgb(255, 255, 255).alpha(0.9).toString()
      : Color.rgb(0, 0, 0).alpha(0.9).toString(),
    fontWeight: 500,
    borderRadius: 4,
    marginLeft: 10,
    marginTop: 10,
    fontSize: 15,
  },
}));

export { useStyles };
