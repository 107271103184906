import {
  FormElement,
  FormElementExtended,
} from "@kolibrisoftware/customerportal-api";
import classNames from "classnames";
import { useInterSectionObserver } from "injectors/intersection-observer/hooks/use-intersection-observer";
import { useTheme } from "injectors/theme";
import useElementData from "modules/questionnaire/hooks/useElementData";
import { FC, useCallback } from "react";
import { useDispatch, useSelector } from "store/helpers";
import { actions as questionnaireActions } from "store/questionnaire";
import { useStyles } from "./styles";
import isUndefined from "lodash-es/isUndefined";

type Props = {
  index?: number;
  sectionStructure: FormElement;
};

const SidebarElement: FC<Props> = ({ sectionStructure, index }) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const dispatch = useDispatch();
  const sectionData = useElementData(sectionStructure.id);
  const { scrollToElement } = useInterSectionObserver();

  const isActive = useSelector(state => {
    if (state.questionnaire.activeSectionTab.id === sectionStructure.id) {
      return true;
    }
    return false;
  });

  const handleElementSelect = useCallback(async () => {
    if (!sectionData) {
      return;
    }
    dispatch(
      questionnaireActions.setActiveSectionTab(
        sectionData as FormElementExtended
      )
    );
    scrollToElement(sectionData.id || "");
  }, [dispatch, sectionData, scrollToElement]);

  return (
    <>
      {sectionStructure &&
        sectionStructure.elements &&
        sectionStructure.elements.length > 0 && (
          <button
            className={classNames(styles.sidebarElementContainer, {
              [styles.sidebarElementActive]: isActive,
            })}
            onClick={handleElementSelect}
            tabIndex={-1}
            id={sectionData?.id + "-sidebar"}
          >
            <div
              className={classNames(styles.sidebarElementDecorator, {
                [styles.alt]: isUndefined(index),
              })}
            >
              {index !== undefined && index + 1}
            </div>
            <div className={styles.sidebarElementTitle}>
              {sectionData?.translations?.[0]?.title}
            </div>
          </button>
        )}
    </>
  );
};

export { SidebarElement };
