import {
  IntlOptions,
  ParseComplexType,
  ParsedOption,
  ParsedRow,
  ValidatedTemplate,
  ValidatorType,
  ValuesParser,
} from "./types";

function validTemplateParser(
  template: ValidatedTemplate,
  t: (key: string, options?: IntlOptions) => string
) {
  const parsedTemplate: Record<string, ParsedRow[]> = {};
  Object.entries(template).forEach(([categoryLabel, validRows]) => {
    const translatedCategoryLabel = t(categoryLabel);
    const parsedRows: ParsedRow[] = [];

    validRows.forEach(row => {
      const { label, option } = row;
      const translatedRowLabel = t(label);
      let parsedOption: ParsedOption | undefined;

      switch (option.validatorType) {
        /**
         * Simple options are composed of 1 translation key, and 0 or more translation values
         * Some cases for this validator can include values that need to be parsed on their own
         * this is why, we introduced the valuesParser attribute, which can apply
         * a parser type to each individual value
         *
         * IMPROVEMENT: we can extend each value to hold their own parser, or no parser.
         */

        case ValidatorType.Simple:
          if (!option.simple) {
            console.error(`Missing simple option for ${categoryLabel}`, option);
            return;
          }
          if (option.simple?.hasNoValues) {
            parsedOption = {
              value: t(option.simple.translationKey),
            };
          } else {
            if (
              option?.simple?.translationValues &&
              option.simple.valuesParser === ValuesParser.MasterTable
            ) {
              Object.keys(option.simple.translationValues).forEach(key => {
                const initValue = option.simple?.translationValues?.[key];

                if (initValue) {
                  if (initValue === true) {
                    console.log(option);
                  }
                  option.simple!.translationValues![key] = t(initValue);
                }
              });
            }
            parsedOption = {
              value: t(option.simple.translationKey, {
                values: option.simple.translationValues,
              }),
            };
          }
          break;

        case ValidatorType.Complex:
          if (!option.complex) {
            console.error(`Missing simple option for ${categoryLabel}`, option);
            return;
          }
          const { parseValueType, ...rest } = option.complex;
          parsedOption = { ...rest };
          if (parseValueType === ParseComplexType.MasterTable) {
            let parsedValue: any = "";
            if (option.complex.isArray) {
              parsedValue = option.complex.value.map((value: string) =>
                t(value)
              );
            } else {
              parsedValue = t(option.complex.value);
            }
            parsedOption = {
              ...rest,
              value: parsedValue,
            };
          }
          break;

        default:
          parsedOption = undefined;
          break;
      }
      if (parsedOption) {
        parsedRows.push({
          label: translatedRowLabel,
          option: parsedOption,
        });
      }
    });
    if (parsedRows.length) {
      parsedTemplate[translatedCategoryLabel] = parsedRows;
    }
  });
  return parsedTemplate;
}

export default validTemplateParser;
