import {
  ChecklistItemStatusType,
  UserForm,
  UserFormAnswer,
  FormElementExtended,
} from "@kolibrisoftware/customerportal-api";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { AnswerSavingStatus } from "enums/answer-saving-status";
import { FormLoadingStatus } from "enums/form-loading-status";
import { validateQuestionnaireSections } from "helpers/questionnaire";

type State = {
  formStructure: UserForm;
  populatedElements: FormElementExtended[];
  answers: UserFormAnswer[];
  formEditableStatus: ChecklistItemStatusType;
  savingStatus: AnswerSavingStatus;
  formLoadingStatus: FormLoadingStatus;
  validatedSections: Record<string, boolean>;
  activeSectionTab: FormElementExtended;
  sidebarOpen: boolean;
  activeSectionId: string;
};

const initialState: State = {
  formStructure: {},
  populatedElements: [],
  answers: [],
  formEditableStatus: ChecklistItemStatusType.Open,
  savingStatus: AnswerSavingStatus.Saved,
  formLoadingStatus: FormLoadingStatus.Loading,
  validatedSections: {},
  activeSectionTab: {},
  sidebarOpen: false,
  activeSectionId: "",
};

export const slice = createSlice({
  name: "questionnaire",
  initialState: initialState,
  reducers: {
    clearState: state => {
      return initialState;
    },
    setFormStructure: (state, action: PayloadAction<UserForm>) => {
      const validatedSections = validateQuestionnaireSections(
        action.payload,
        state.populatedElements,
        state.answers
      );

      return { ...state, formStructure: action.payload, validatedSections };
    },
    setElements: (state, action: PayloadAction<FormElementExtended[]>) => {
      const validatedSections = validateQuestionnaireSections(
        state.formStructure,
        action.payload,
        state.answers
      );

      return { ...state, populatedElements: action.payload, validatedSections };
    },
    setAnswers: (state, action: PayloadAction<UserFormAnswer[]>) => {
      const validatedSections = validateQuestionnaireSections(
        state.formStructure,
        state.populatedElements,
        action.payload
      );

      return { ...state, answers: action.payload, validatedSections };
    },
    setQuestionAnswer: (state, action: PayloadAction<UserFormAnswer>) => {
      const newState: State = JSON.parse(JSON.stringify(state));

      const currentAnswerIndex = newState.answers.findIndex(
        answer => answer.id === action.payload.id
      );

      if (currentAnswerIndex < 0) {
        newState.answers.push(action.payload);
      } else {
        newState.answers[currentAnswerIndex] = action.payload;
      }

      const validatedSections = validateQuestionnaireSections(
        newState.formStructure,
        newState.populatedElements,
        newState.answers
      );

      return { ...newState, validatedSections };
    },
    pushModifiedAnswer: (state, action: PayloadAction<string>) => {
      const newModifiedAnswer = action.payload;

      const updatedModifiedAnswers = [
        ...(state.formStructure.latestModifiedAnswerIds || []),
      ];

      if (updatedModifiedAnswers.includes(newModifiedAnswer)) {
        return state;
      }

      updatedModifiedAnswers.push(newModifiedAnswer);

      return {
        ...state,
        formStructure: {
          ...state.formStructure,
          latestModifiedAnswerIds: updatedModifiedAnswers,
        },
      };
    },
    removeModifiedAnswer: (
      state,
      action: PayloadAction<string | null | undefined>
    ) => {
      const modifiedAnswerId = action.payload;

      if (!modifiedAnswerId) {
        return state;
      }

      const updatedModifiedAnswerIds =
        state.formStructure.latestModifiedAnswerIds?.filter(
          id => id !== modifiedAnswerId
        ) || [];

      return {
        ...state,
        formStructure: {
          ...state.formStructure,
          latestModifiedAnswerIds: [...updatedModifiedAnswerIds],
        },
      };
    },
    setSavingStatus: (state, action: PayloadAction<AnswerSavingStatus>) => {
      return { ...state, savingStatus: action.payload };
    },
    setFormLoadingStatus: (state, action: PayloadAction<FormLoadingStatus>) => {
      return { ...state, formLoadingStatus: action.payload };
    },
    setFormEditableStatus: (
      state,
      action: PayloadAction<ChecklistItemStatusType>
    ) => {
      return { ...state, formEditableStatus: action.payload };
    },
    setActiveSectionTab: (
      state,
      action: PayloadAction<FormElementExtended>
    ) => {
      const sectionData = action.payload;
      return {
        ...state,
        activeSectionTab: sectionData,
        sidebarOpen: false,
      };
    },

    toggleSidebar: state => {
      return { ...state, sidebarOpen: !state.sidebarOpen };
    },

    setActiveSection: (
      state,
      action: PayloadAction<{ formId: string; activeSectionId: string }>
    ) => {
      const { formId, activeSectionId } = action.payload;
      localStorage.setItem(`${formId}`, activeSectionId);
      return { ...state, activeSectionId: activeSectionId };
    },
  },
});
