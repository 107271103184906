import {
  ContractListOfGoodAnswer,
  Culture,
  Client as CustomerPortalClient,
  FormAnswerEditor,
  FormAnswerUpdateRequest,
  FormCustomQuestionExtended,
  FormElementExtended,
  FormElementQuestionType,
  ListOfItemRadioListAnswer,
  SearchFormAnswerResponse,
  SearchFormCustomQuestionResponse,
  SearchFormElementResult,
  UserFormAnswer,
} from "@kolibrisoftware/customerportal-api";
import { AnswerSavingStatus } from "enums/answer-saving-status";
import { createErrorToast, createSuccessToast } from "helpers/create-new-toast";
import { createGenericSearchAll } from "helpers/form-elements-search";
import { FormLoadingStatus } from "modules/list-of-items/enums/form-loading-status";
import { actions as toastActions } from "store/toasts";
import { createThunk } from "../helpers";
import { actions as listOfItemsActions } from "./index";

export type GetFormStructurePayload = {
  formId: string;
  ignoreLoadingStatus?: boolean;
  culture?: Culture;
};

const getFormData = createThunk(
  "list-of-items/getFormData",
  async (
    { settings, dispatch, http, getState },
    args: GetFormStructurePayload
  ) => {
    const { formId, ignoreLoadingStatus = false, culture } = args;
    try {
      if (!ignoreLoadingStatus) {
        dispatch(
          listOfItemsActions.setFormLoadingStatus(FormLoadingStatus.Loading)
        );
      }
      if (!settings?.apiUrlCustomerPortal) {
        throw new Error("Could not retrieve the url for customer portal api");
      }
      const state = getState();
      const realEstateAgencyId = state.main.companyData?.realEstateAgencyId;
      const take = 100,
        skip = 0;

      if (!realEstateAgencyId || !formId) {
        throw new Error("Missing form or agency id");
      }
      const client = new CustomerPortalClient(
        settings?.apiUrlCustomerPortal,
        http
      );
      const genericSearchAll = createGenericSearchAll(
        realEstateAgencyId,
        formId,
        take
      );
      if (state.listOfItems.formStructure.id !== formId) {
        dispatch(listOfItemsActions.clearState());
      }
      const checklistItem = state.dossier.checklistItems.find(
        item => item.formId === formId
      );
      if (checklistItem) {
        dispatch(
          listOfItemsActions.setFormEditableStatus(checklistItem.status)
        );
      }

      const supportedCultures = await client
        .getFormSupportedCultures(realEstateAgencyId, formId)
        .then(data => data.supportedCultures);

      let formCulture = culture || state.main.culture;

      if (
        !supportedCultures?.includes(formCulture) &&
        supportedCultures?.length
      ) {
        formCulture = supportedCultures[0];
      }
      const formStructureResult = await client.getForm(
        realEstateAgencyId,
        formId,
        formCulture
      );

      dispatch(listOfItemsActions.setFormStructure(formStructureResult));

      // ALL ELEMENTS
      const formElementsRespose = await client.searchFormElements(
        realEstateAgencyId,
        formId,
        {
          skip,
          take,
          culture: formCulture,
        }
      );

      if (!formElementsRespose) {
        throw new Error("No form elements found.");
      }

      const {
        totalResults: totalFormElementsResults,
        result: formElementResult,
      } = formElementsRespose;

      let formElementsResult = formElementResult || [];

      if (totalFormElementsResults && totalFormElementsResults > take) {
        const allFormElements = await genericSearchAll<
          SearchFormElementResult,
          FormElementExtended
        >(
          totalFormElementsResults,
          client.searchFormElements.bind(client),
          formCulture
        );
        formElementsResult = [...formElementsResult, ...allFormElements];
      }

      dispatch(listOfItemsActions.setElements(formElementsResult));

      // CUSTOM QUESTIONS
      const customFormElementsResponse = await client.searchFormCustomQuestions(
        realEstateAgencyId,
        formId,
        { skip, take }
      );

      const {
        totalResults: totalCustomQuestionsResult,
        result: customQuestionsResult,
      } = customFormElementsResponse;

      let allCustomQuestionsResult = customQuestionsResult || [];

      if (totalCustomQuestionsResult && totalCustomQuestionsResult > take) {
        const allCustomElements = await genericSearchAll<
          SearchFormCustomQuestionResponse,
          FormCustomQuestionExtended
        >(
          totalCustomQuestionsResult,
          client.searchFormCustomQuestions.bind(client),
          formCulture
        );

        allCustomQuestionsResult = [
          ...allCustomQuestionsResult,
          ...allCustomElements,
        ];
      }
      dispatch(
        listOfItemsActions.setCustomQuestions(allCustomQuestionsResult || [])
      );

      // ANSWERS
      const formAnswersResult = await client.searchAnswers(
        realEstateAgencyId,
        formId,
        {
          skip: 0,
          take: 100,
        }
      );
      const { result, totalResults } = formAnswersResult;
      let formElementsAnswers = result || [];

      if (totalResults && totalResults > take) {
        const allAnswers = await genericSearchAll<
          SearchFormAnswerResponse,
          UserFormAnswer
        >(totalResults, client.searchAnswers.bind(client));

        formElementsAnswers = [...formElementsAnswers, ...allAnswers];
      }

      dispatch(listOfItemsActions.setAnswers(formElementsAnswers));
      dispatch(
        listOfItemsActions.setFormLoadingStatus(FormLoadingStatus.Success)
      );
    } catch (error: any) {
      const errorMessage = error.message
        ? error.message
        : `Could not form with id ${formId}`;
      const toast = createErrorToast(errorMessage);
      dispatch(toastActions.addToast(toast));
      dispatch(
        listOfItemsActions.setFormLoadingStatus(FormLoadingStatus.Failed)
      );
      throw error;
    }
  }
);

export type SetQuestionAnswerPayload = {
  answerId: string;
  answerValue?: string;
  updatedAnswerPayload?: FormAnswerUpdateRequest;
  useCase: "contract" | "regular" | "foreign";
};
const setQuestionAnswer = createThunk(
  "list-of-items/setQuestionAnswer",
  async (
    { settings, dispatch, getState, http },
    args: SetQuestionAnswerPayload
  ) => {
    try {
      const timeout = setTimeout(
        () =>
          dispatch(
            listOfItemsActions.setAnswerSavingStatus(
              AnswerSavingStatus.InProgress
            )
          ),
        100
      );
      if (!settings?.apiUrlCustomerPortal) {
        throw new Error("Could not retrieve the url for customer portal api");
      }
      const state = getState();
      const realEstateAgencyId = state.main.companyData?.realEstateAgencyId;
      const { answerId, answerValue, useCase, updatedAnswerPayload } = args;

      if (!realEstateAgencyId) {
        throw new Error("Missing company id");
      }
      const client = new CustomerPortalClient(
        settings?.apiUrlCustomerPortal,
        http
      );
      if (!state.listOfItems.formStructure.id) {
        throw new Error("There is no form id present");
      }
      if (!answerValue && useCase !== "foreign") {
        throw new Error("There is no answer value present");
      }
      const answers = JSON.parse(
        JSON.stringify(state.listOfItems.answers)
      ) as UserFormAnswer[];

      let currentAnswer = answers.find(item => item.id === answerId);
      if (!currentAnswer) {
        throw new Error("We couldn't find an answer for this question");
      }
      if (useCase === "regular") {
        const typedAnswer = answerValue as ListOfItemRadioListAnswer;
        currentAnswer.listOfItemRadioListOption = typedAnswer;
        dispatch(listOfItemsActions.setQuestionAnswer(currentAnswer));

        await client.update(
          realEstateAgencyId,
          state.listOfItems.formStructure.id,
          {
            formAnswerId: answerId,
            listOfItemRadioListOption: typedAnswer,
          }
        );
      }
      if (useCase === "contract") {
        const typedAnswer = answerValue as ContractListOfGoodAnswer;
        currentAnswer.contractListOfGoodOption = typedAnswer;
        dispatch(listOfItemsActions.setQuestionAnswer(currentAnswer));

        await client.update(
          realEstateAgencyId,
          state.listOfItems.formStructure.id,
          {
            formAnswerId: answerId,
            contractListOfGoodOption: typedAnswer,
          }
        );
      }
      if (useCase === "foreign") {
        const updatedAnswer = {
          ...currentAnswer,
          ...updatedAnswerPayload,
        };
        dispatch(listOfItemsActions.setQuestionAnswer(updatedAnswer));

        await client.update(
          realEstateAgencyId,
          state.listOfItems.formStructure.id,
          {
            formAnswerId: answerId,
            ...updatedAnswerPayload,
          }
        );
      }
      if (timeout) {
        clearTimeout(timeout);
      }

      dispatch(listOfItemsActions.removeModifiedAnswer(answerId));
      dispatch(
        listOfItemsActions.setAnswerSavingStatus(AnswerSavingStatus.Saved)
      );
    } catch (error: any) {
      dispatch(
        listOfItemsActions.setAnswerSavingStatus(AnswerSavingStatus.Error)
      );
      const toast = createErrorToast(error);
      dispatch(toastActions.addToast(toast));
      throw error;
    }
  }
);

export type CreateCustomQuestionPayload = {
  sectionId: string;
  questionText: string;
  questionType: FormElementQuestionType;
};
const createCustomQuestion = createThunk(
  "list-of-items/createCustomQuestion",
  async (
    { getState, settings, http, dispatch },
    args: CreateCustomQuestionPayload
  ) => {
    try {
      if (!settings?.apiUrlCustomerPortal) {
        throw new Error("Could not retrieve the url for customer portal api");
      }
      const state = getState();
      const realEstateAgencyId = state.main.companyData?.realEstateAgencyId;
      if (!realEstateAgencyId) {
        throw new Error("Missing real estate agency id");
      }
      const client = new CustomerPortalClient(
        settings?.apiUrlCustomerPortal,
        http
      );
      const { sectionId, questionText, questionType } = args;
      const formId = state.listOfItems.formStructure.id;

      if (formId && state.listOfItems.formStructure.parts) {
        let nextQuestionIndex = 1;
        state.listOfItems.formStructure.parts?.forEach(section => {
          const parentSection = section.elements?.find(
            section => section.id === sectionId
          );
          if (parentSection) {
            parentSection.customQuestions?.forEach(customQuestion => {
              if (
                customQuestion.index &&
                customQuestion.index >= nextQuestionIndex
              ) {
                nextQuestionIndex = customQuestion.index + 1;
              }
            });
          }
        });

        const questionData = await client.createFormCustomQuestion(
          realEstateAgencyId,
          formId,
          {
            parentElementId: sectionId,
            questionType: questionType,
            index: nextQuestionIndex,
            text: questionText,
          }
        );

        const questionAnswer = await client
          .searchAnswers(realEstateAgencyId, formId, {
            skip: 0,
            take: 100,
            customQuestionIds: [questionData.id || ""],
          })
          .then(data => data.result?.[0] || {});

        dispatch(
          listOfItemsActions.addCustomQuestion({
            sectionId,
            questionData,
            questionAnswer,
          })
        );
        dispatch(getFormData({ formId: formId, ignoreLoadingStatus: true }));
      }
    } catch (error: any) {
      const errorMessage = error.message
        ? error.message
        : `Something went wrong`;
      const toast = createErrorToast(errorMessage);
      dispatch(toastActions.addToast(toast));
      throw error;
    }
  }
);

export type DeleteQuestionPayload = {
  formId: string;
  id: string;
};
const deleteCustomQuestion = createThunk(
  "list-of-items/deleteQuestion",
  async (
    { dispatch, settings, http, getState },
    args: DeleteQuestionPayload
  ) => {
    const { formId, id } = args;
    try {
      const state = getState();
      const realEstateAgencyId = state.main.companyData?.realEstateAgencyId;
      if (!realEstateAgencyId) {
        throw new Error("Missing real estate agency id");
      }
      if (!settings?.apiUrlCustomerPortal) {
        throw new Error("Could not retrieve the url for customer portal api");
      }

      const client = new CustomerPortalClient(
        settings?.apiUrlCustomerPortal,
        http
      );

      await client.deleteFormCustomQuestion(realEstateAgencyId, formId, id);
      dispatch(listOfItemsActions.deleteCustomQuestion(id));
      // Getting back the form
      await dispatch(
        getFormData({ formId: formId, ignoreLoadingStatus: true })
      );

      const successToast = createSuccessToast("Item succesvol verwijderd");
      dispatch(toastActions.addToast(successToast));
    } catch (error: any) {
      const errorMessage = error.message
        ? error.message
        : `Could not delete the question with question id ${id}`;
      const toast = createErrorToast(errorMessage);
      dispatch(toastActions.addToast(toast));
      throw error;
    }
  }
);
export type UpdateCustomQuestionPayload = {
  formId: string;
  questionId: string;
  newText: string;
  index?: number;
};
const updateCustomQuestion = createThunk(
  "list-of-items/updateQuestion",
  async (
    { dispatch, settings, http, getState },
    args: UpdateCustomQuestionPayload
  ) => {
    try {
      if (!settings?.apiUrlCustomerPortal) {
        throw new Error("Could not retrieve the url for customer portal api");
      }
      const state = getState();

      const realEstateAgencyId = state.main.companyData?.realEstateAgencyId;

      if (!realEstateAgencyId) {
        throw new Error("Missing real estate agency id");
      }
      const client = new CustomerPortalClient(
        settings?.apiUrlCustomerPortal,
        http
      );
      const { formId, questionId, newText, index } = args;

      const result = await client.updateFormCustomQuestion(
        realEstateAgencyId,
        formId,
        questionId,
        {
          text: newText,
          questionType: FormElementQuestionType.ListOfItemRadioList,
          index: index,
        }
      );

      dispatch(listOfItemsActions.updateCustomQuestion(result));
    } catch (error: any) {
      const errorMessage = error.message
        ? error.message
        : `Something went wrong`;
      const toast = createErrorToast(errorMessage);
      dispatch(toastActions.addToast(toast));
      throw error;
    }
  }
);

export type TextAreaUpdateQuestionPayload = {
  questionId: string;
  updatedAnswerPayload: FormAnswerUpdateRequest;
};
const setForeignQuestionAnswer = createThunk(
  "list-of-items/setForeignQuestionAnswer",
  async (
    { settings, dispatch, getState, http },
    args: TextAreaUpdateQuestionPayload
  ) => {
    const timeout = setTimeout(
      () =>
        dispatch(
          listOfItemsActions.setAnswerSavingStatus(
            AnswerSavingStatus.InProgress
          )
        ),
      200
    );
    try {
      if (!settings?.apiUrlCustomerPortal) {
        throw new Error("Could not retrieve the url for customer portal api");
      }
      const state = getState();
      const realEstateAgencyId = state.main.companyData?.realEstateAgencyId;
      if (!realEstateAgencyId) {
        throw new Error("Missing real estate agency id");
      }
      const { updatedAnswerPayload, questionId } = args;

      const client = new CustomerPortalClient(
        settings?.apiUrlCustomerPortal,
        http
      );
      if (!state.listOfItems.formStructure.id || !updatedAnswerPayload) {
        throw new Error("There is no form id present");
      }
      const updatedAnswer: UserFormAnswer = {
        ...updatedAnswerPayload,
        blueprintElementId: questionId,
        formId: state.listOfItems.formStructure.id,
        id: updatedAnswerPayload.formAnswerId,
      };
      dispatch(listOfItemsActions.setQuestionAnswer(updatedAnswer));

      await client.update(
        realEstateAgencyId,
        state.listOfItems.formStructure.id || "",
        updatedAnswerPayload
      );

      dispatch(
        listOfItemsActions.setAnswerSavingStatus(AnswerSavingStatus.Saved)
      );

      if (timeout) {
        clearTimeout(timeout);
      }
    } catch (error: any) {
      if (timeout) {
        clearTimeout(timeout);
      }
      dispatch(
        listOfItemsActions.setAnswerSavingStatus(AnswerSavingStatus.Error)
      );
      const toast = createErrorToast(error);
      dispatch(toastActions.addToast(toast));
      throw error;
    }
  }
);

const refetchAnswer = createThunk(
  "questionnaire/refetchAnswer",
  async (
    { settings, http, dispatch, getState },
    args: { formId: string; answerId: string }
  ) => {
    try {
      if (!settings?.apiUrlCustomerPortal) {
        throw new Error("Could not retrieve the url for customer portal api");
      }
      const state = getState();
      const realEstateAgencyId = state.main.companyData?.realEstateAgencyId;
      if (!realEstateAgencyId) {
        throw new Error("Missing Real Estate agency id");
      }
      const client = new CustomerPortalClient(
        settings?.apiUrlCustomerPortal,
        http
      );

      const answer = await client
        .searchAnswers(realEstateAgencyId, args.formId, {
          skip: 0,
          take: 1,
          ids: [args.answerId],
        })
        .then(data => data.result?.[0]);

      if (!answer) {
        throw new Error("No answer found");
      }

      dispatch(listOfItemsActions.setQuestionAnswer(answer));
      if (answer.id && answer.editor !== FormAnswerEditor.User) {
        dispatch(listOfItemsActions.pushModifiedAnswer(answer.id));
      }
    } catch (error: any) {
      const errorMessage = error.message
        ? error.message
        : `There was an issue while refetching the answer with answer id: ${args.answerId}`;
      const toast = createErrorToast(errorMessage);
      dispatch(toastActions.addToast(toast));
      throw error;
    }
  }
);

const thunks = {
  updateCustomQuestion,
  createCustomQuestion,
  deleteCustomQuestion,
  setQuestionAnswer,
  setForeignQuestionAnswer,
  getFormData,
  refetchAnswer,
};

export default thunks;
