import {
  UserForm,
  UserFormAnswer,
  FormElement,
  FormElementExtended,
  FormElementParentConditionAction,
  FormElementQuestionType,
  FormElementType,
  ListOfItemRadioListAnswer,
  UnknownBoolAnswer,
} from "@kolibrisoftware/customerportal-api";

const getSectionElementsIds = (element: FormElement): string[] => {
  let elementIds: string[] = [];

  if (element.id) {
    elementIds.push(element.id);

    if (!!element.elements?.length) {
      element.elements.forEach(element => {
        elementIds = [...elementIds, ...getSectionElementsIds(element)];
      });
    }
  }

  return elementIds;
};

const getElementsWithValidation = (
  ids: string[],
  populatedElements: FormElementExtended[]
) => {
  return ids
    .map(id => populatedElements.find(element => element.id === id))
    .filter(
      element =>
        !!element && (!!element.questionConstraint || !!element.parentCondition)
    ) as FormElementExtended[];
};

const isSectionValid = (
  elements: FormElementExtended[],
  answers: UserFormAnswer[]
) => {
  let condition = true;

  for (const element of elements) {
    const answer = answers.find(
      answer => answer.blueprintElementId === element.id
    );

    if (!answer) {
      condition = false;
      break;
    }

    let value:
      | boolean
      | number
      | string
      | string[]
      | Date
      | ListOfItemRadioListAnswer
      | UnknownBoolAnswer
      | null
      | undefined;

    switch (element.questionType) {
      case FormElementQuestionType.Bool: {
        value = answer.boolAnswer;
        break;
      }
      case FormElementQuestionType.Date: {
        value = answer.dateAnswer;
        break;
      }
      case FormElementQuestionType.DateTime: {
        value = answer.dateTimeAnswer;
        break;
      }
      case FormElementQuestionType.Decimal: {
        value = answer.decimalAnswer;
        break;
      }
      case FormElementQuestionType.Int: {
        value = answer.intAnswer;
        break;
      }
      case FormElementQuestionType.ListOfItemRadioList: {
        value = answer.listOfItemRadioListOption;
        break;
      }
      case FormElementQuestionType.MultiSelect: {
        value = answer.multiSelectAnswerOptionList;
        break;
      }
      case FormElementQuestionType.Radio: {
        value = answer.radioAnswer;
        break;
      }
      case FormElementQuestionType.Text: {
        value = answer.textAnswer;
        break;
      }
      case FormElementQuestionType.TextArea: {
        value = answer.textAreaAnswer;
        break;
      }
      case FormElementQuestionType.UnknownBool: {
        value = answer.unknownBoolOption;
        break;
      }
      default: {
        break;
      }
    }

    if (value === undefined) {
      condition = false;
      break;
    }

    if (
      element.parentCondition &&
      element.parentCondition.action ===
        FormElementParentConditionAction.Require
    ) {
      const match = element.parentCondition.expectedAnswer === value;
      if (!match) {
        condition = false;
        break;
      }
    }

    if (element.questionConstraint) {
      const { required, decimalMinValue, decimalMaxValue } =
        element.questionConstraint;
      let match = true;

      switch (true) {
        case required: {
          match = !!value;
          break;
        }
        case decimalMinValue !== undefined &&
          decimalMinValue !== null &&
          decimalMaxValue !== undefined &&
          decimalMaxValue !== null: {
          match =
            value !== null &&
            (value as number) >= (decimalMinValue as number) &&
            (value as number) <= (decimalMaxValue as number);
          break;
        }
        case decimalMinValue !== undefined && decimalMinValue !== null: {
          match =
            value !== null && (value as number) >= (decimalMinValue as number);
          break;
        }
        case decimalMaxValue !== undefined && decimalMaxValue !== null: {
          match =
            value !== null && (value as number) <= (decimalMaxValue as number);
          break;
        }
        default: {
          break;
        }
      }

      if (!match) {
        condition = false;
        break;
      }
    }
  }

  return condition;
};

export const validateQuestionnaireSections = (
  formStructure: UserForm,
  populatedElements: FormElementExtended[],
  answers: UserFormAnswer[]
): Record<string, boolean> => {
  let validatedSections: Record<string, boolean> = {};

  formStructure.parts?.forEach(part => {
    part.elements
      ?.filter(element => element.type === FormElementType.Section)
      .forEach(section => {
        if (section.id) {
          const elementIds = getSectionElementsIds(section);
          const elements = getElementsWithValidation(
            elementIds,
            populatedElements
          );
          validatedSections = {
            ...validatedSections,
            [section.id]: isSectionValid(elements, answers),
          };
        }
      });
  });

  return validatedSections;
};
