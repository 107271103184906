import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "@kolibrisoftware/hooks-util";
import { useTheme } from "injectors/theme";
import {
  ObjectTabActions,
  ObjectTabType,
} from "modules/object-preview/constants/object-tabs";
import { FC, useCallback, useContext, useMemo } from "react";
import { ObjectTabsModalContext } from "../../modal-context/modal-context";
import { useStyles } from "./styles";

type Props = {
  objectTab: ObjectTabType;
};

const ObjectTabComponent: FC<Props> = ({ objectTab }) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const t = useTranslation();
  const modalContext = useContext(ObjectTabsModalContext);

  const handleTabAction = useCallback(() => {
    switch (objectTab.action.type) {
      case ObjectTabActions.ScrollTo:
        document
          .getElementById(objectTab.action.target || "")
          ?.scrollIntoView({ behavior: "smooth" });
        break;
      case ObjectTabActions.OpenModal:
        modalContext.openModal?.(objectTab.id);
        break;
      default:
        break;
    }
  }, [modalContext, objectTab]);

  return (
    <button
      className={styles.objectTabWrapper}
      onClick={handleTabAction}
      data-cy="SingleObjectTab"
    >
      <div className={styles.objectTabContent}>
        <FontAwesomeIcon
          icon={objectTab.icon}
          className={styles.objectTabIcon}
        />
        <p className={styles.objectTabLabel}>{t(objectTab.label)}</p>
      </div>
    </button>
  );
};

export { ObjectTabComponent };
