import { createThunkCreator, HttpWebUtil } from "@kolibrisoftware/helpers-util";
import {
  createUseDispatch,
  createUseSelector,
} from "@kolibrisoftware/hooks-util";
import { RootState } from "store";

let appSettingsPath = process.env.PUBLIC_URL + "/app-settings.json";

if (process.env.NODE_ENV === "development") {
  appSettingsPath = process.env.PUBLIC_URL + "/local-app-settings.json";
}

export const httpWebUtil = new HttpWebUtil(appSettingsPath);

export const useSelector = createUseSelector<RootState>();
export const useDispatch = createUseDispatch<RootState>();
export const createThunk = createThunkCreator<RootState>(httpWebUtil);
export type RootDispatch = typeof useDispatch;
