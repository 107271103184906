import { useTranslation } from "@kolibrisoftware/hooks-util";
import { useTheme } from "injectors/theme";
import { FC, useMemo } from "react";
import { ContentSection } from "@kolibrisoftware/customerportal-ui";
import { HeaderSection } from "shared/ui/header-section/header-section";
import ObjectCarousel from "../components/object-carousel";
import ObjectTabs from "../components/object-tabs";
import { useStyles } from "./styles";
import ObjectDescriptionSection from "../components/object-description-section";
import ObjectDetailsSection from "../components/object-details-section";

type Props = {};

const ObjectPreview: FC<Props> = props => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const t = useTranslation();
  const translations = useMemo(
    () => ({
      headerTitle: t("objectPreview.headerTitle"),
      headerDescription: t("objectPreview.headerDescription"),
    }),
    [t]
  );
  return (
    <div className={styles.objectPreviewScreen}>
      <HeaderSection
        title={translations.headerTitle}
        description={translations.headerDescription}
      />

      <ContentSection style={{ padding: 0 }} data-cy="CarouselContentSection">
        <ObjectCarousel />
        <ObjectTabs />
      </ContentSection>

      <ObjectDescriptionSection />
      <ObjectDetailsSection />
    </div>
  );
};

export { ObjectPreview };
