import { useMedia, useTranslation } from "@kolibrisoftware/hooks-util";
import { IntersectionObserverInjector } from "injectors/intersection-observer/intersection-observer";
import { FC, useMemo, useRef, useEffect } from "react";
import { BackActionLayout } from "layouts/back-action-layout/back-action-layout";
import { useDispatch, useSelector } from "store/helpers";
import InitialDataLayer from "./initial-data-layer";
import RootScreen from "./screens/root-screen";
import ReviewChecklistItem from "../../shared/components/review-checklist-item-button/review-checklist-item-button";
import { useParams } from "react-router-dom";
import { actions as questionnaireActions } from "store/questionnaire";
import {
  ChecklistItemStatusType,
  FormType,
} from "@kolibrisoftware/customerportal-api";
import MobileBackActionLayout from "layouts/back-action-layout/mobile-back-action-layout";
import MobileSidebar from "./clusters/mobile-sidebar";
import { FormLoadingStatus } from "enums/form-loading-status";
import { ErrorPage } from "shared/components/error-page/error-page";
import { LoadingScreen } from "shared/ui/loading-screen/loading-screen";
import { PresenceProvider } from "injectors/presence";
import { Presence } from "shared/components/presence";

type Props = {};

const QuestionnaireModule: FC<Props> = props => {
  const { savingStatus, formEditableStatus, formLoadingStatus } = useSelector(
    state => state.questionnaire
  );
  const renewHintRef = useRef(false);
  const dispatch = useDispatch();
  const { dossierId } = useParams();
  const { checklistItemId } = useSelector(
    state => state.questionnaire.formStructure
  );
  const attachedChecklistItem = useSelector(state =>
    state.dossier.checklistItems.find(item => item.id === checklistItemId)
  );

  const isScreenUnder1150 = useMedia("(max-width:1150px)");

  const t = useTranslation();

  const translations = useMemo(
    () => ({
      pageTile: t("questionnaire.pageTitlePlaceholder"),
      backActionLabel: t("questionnaire.backActionLabel"),
    }),
    [t]
  );

  const showEditActions = useMemo(
    () => (formEditableStatus === ChecklistItemStatusType.Open ? true : false),
    [formEditableStatus]
  );

  useEffect(() => {
    if (attachedChecklistItem) {
      dispatch(
        questionnaireActions.setFormEditableStatus(
          attachedChecklistItem?.status
        )
      );
      renewHintRef.current = !renewHintRef.current;
    }
  }, [attachedChecklistItem, dispatch]);

  return (
    <IntersectionObserverInjector>
      <PresenceProvider formType={FormType.Questionnaire}>
        <InitialDataLayer>
          {formLoadingStatus === FormLoadingStatus.Loading && (
            <LoadingScreen useCase="document" showSide={true} />
          )}
          {formLoadingStatus === FormLoadingStatus.Failed && (
            <ErrorPage useCase="FormError" />
          )}
          {formLoadingStatus === FormLoadingStatus.Success && (
            <>
              {isScreenUnder1150 ? (
                <MobileBackActionLayout
                  centerTitle={translations.pageTile}
                  optionalSideBar={<MobileSidebar />}
                  backActionLabel={translations.backActionLabel}
                  savingStatus={showEditActions ? savingStatus : undefined}
                  presence={<Presence />}
                  reviewButton={
                    showEditActions ? (
                      <ReviewChecklistItem
                        dossierId={dossierId || ""}
                        checklistItemId={checklistItemId || ""}
                        disabled={
                          formEditableStatus !== ChecklistItemStatusType.Open
                        }
                      />
                    ) : undefined
                  }
                >
                  <RootScreen renewHint={renewHintRef.current} />
                </MobileBackActionLayout>
              ) : (
                <BackActionLayout
                  centerTitle={translations.pageTile}
                  backActionLabel={translations.backActionLabel}
                  savingStatus={showEditActions ? savingStatus : undefined}
                  presence={<Presence />}
                  reviewButton={
                    showEditActions ? (
                      <ReviewChecklistItem
                        dossierId={dossierId || ""}
                        checklistItemId={checklistItemId || ""}
                        disabled={
                          formEditableStatus !== ChecklistItemStatusType.Open
                        }
                      />
                    ) : undefined
                  }
                >
                  <RootScreen renewHint={renewHintRef.current} />
                </BackActionLayout>
              )}
            </>
          )}
        </InitialDataLayer>
      </PresenceProvider>
    </IntersectionObserverInjector>
  );
};

export { QuestionnaireModule };
