import {
  OBJECT_TABS,
  ObjectTabActions,
} from "modules/object-preview/constants/object-tabs";
import { useSelector } from "store/helpers";

const useObjectPreviewTabs = (useCase: "preview" | "modal") => {
  const objectPreview = useSelector(
    state => state.objectPreview.assignmentPreview
  );

  return OBJECT_TABS.filter(item => {
    if (useCase === "modal" && item.action.type === ObjectTabActions.ScrollTo)
      return false;
    if (item.validator) return item.validator(objectPreview);
    return true;
  });
};

export default useObjectPreviewTabs;
