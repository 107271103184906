import { useTheme } from "injectors/theme";
import { FC } from "react";
import { Button } from "@kolibrisoftware/customerportal-ui";
import { ModalComponent } from "../modal/modal";
import { useStyles } from "./styles";

type Props = {
  visible: boolean;
  title: string;
  content: string;
  confirmLabel: string;
  confirmCallback: () => void;
  denyCallback?: () => void;
  closeCallback: () => void;
};

const ConfirmPrompt: FC<Props> = ({
  visible,
  closeCallback,
  confirmCallback,
  confirmLabel,
  content,
  title,
}) => {
  const theme = useTheme();
  const styles = useStyles({ theme });

  return (
    <ModalComponent
      visible={visible}
      closeOnBackdropClick={true}
      onClose={closeCallback}
      title={title}
      displayStyles={{ width: 520, maxWidth: "96%" }}
    >
      <div className={styles.confirmPromptContainer}>
        <div
          dangerouslySetInnerHTML={{ __html: content }}
          className={styles.confirmPromptContainerWrapper}
        ></div>
        <Button size="full" onClick={() => confirmCallback()}>
          <div className={styles.confirmButtonWrapper}>
            <div> {confirmLabel} </div>
          </div>
        </Button>
      </div>
    </ModalComponent>
  );
};

export { ConfirmPrompt };
