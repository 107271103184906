import { REDIRECT_LOCALSTORAGE_KEY } from "constants/config";
import { FC, useCallback, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import storage from "redux-persist/lib/storage/session";
import { httpWebUtil } from "store/helpers";

export const CallbackComponent: FC = () => {
  const [error, setError] = useState(true);
  const navigate = useNavigate();

  const redirectCallback = useCallback(async () => {
    try {
      const user = await httpWebUtil.signinRedirectCallback();
      if (!user || user.expired) {
        // setError(true);
        return;
      }

      const redirect = await storage.getItem(REDIRECT_LOCALSTORAGE_KEY);

      if (!!redirect) {
        await storage.removeItem(REDIRECT_LOCALSTORAGE_KEY);
      }

      navigate(redirect || "/");
    } catch (error) {
      console.log(error);
      // setError(true);
      navigate("/");
      throw error;
    }
  }, [navigate]);

  useEffect(() => {
    redirectCallback();
  }, [redirectCallback]);

  return <></>;
};
