import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  listOfItemsQuestionWrapper: {
    position: "relative",
  },
}));

export { useStyles };
