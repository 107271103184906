import { unwrapResult } from "@reduxjs/toolkit";
import { assignmentApi } from "store/api";
import { createThunk } from "store/helpers";
import { actions as objectPreviewActions } from "./index";

const loadAssignmentPreview = createThunk(
  "dossier/loadAssignmentPreview",
  async ({ dispatch }) => {
    const result = await dispatch(assignmentApi.getAssignmentPreview()).then(
      unwrapResult
    );
    if (result) {
      dispatch(objectPreviewActions.setAssignmentPreview(result));
    }
  }
);

const thunks = {
  loadAssignmentPreview,
};

export default thunks;
