import { IntlShape } from "react-intl";
import noop from "lodash-es/noop";

const noopFn: () => any = noop;

let intl: IntlShape = {
  $t: noopFn,
  formatters: {
    getDateTimeFormat: noopFn,
    getNumberFormat: noopFn,
    getMessageFormat: noopFn,
    getRelativeTimeFormat: noopFn,
    getPluralRules: noopFn,
    getListFormat: noopFn,
    getDisplayNames: noopFn,
  },
  locale: "",
  formats: {
    date: {
      def: { day: "2-digit", month: "2-digit", year: "numeric" },
    },
  },
  messages: {},
  defaultLocale: "",
  defaultFormats: {},
  onError: noopFn,
  formatDate: noopFn,
  formatTime: noopFn,
  formatDateToParts: noopFn,
  formatTimeToParts: noopFn,
  formatRelativeTime: noopFn,
  formatNumber: noopFn,
  formatNumberToParts: noopFn,
  formatPlural: noopFn,
  formatMessage: noopFn,
  formatList: noopFn,
  formatDisplayName: noopFn,
  formatDateTimeRange: noopFn,
  formatListToParts: noopFn,
};

const setIntlShape = (intlShape: IntlShape) => (intl = intlShape);

export { intl, setIntlShape };
