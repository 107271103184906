import React, { FC, useMemo } from "react";
import { useStyles } from "./styles";
import { useTheme } from "injectors/theme";
import { useDefaultMediaQueries } from "@kolibrisoftware/hooks-util";

type Props = {
  showSide?: boolean;
  useCase?: "document" | "overview" | "root";
};

const LoadingScreen: FC<Props> = ({
  showSide = false,
  useCase = "document",
}) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const { isScreenS, isScreenXS } = useDefaultMediaQueries();

  const loadingView = useMemo(() => {
    switch (useCase) {
      case "document":
        return (
          <div className={styles.loadingSectionsContainer}>
            <div className={styles.loadingSection}></div>
            <div className={styles.loadingSection}></div>
            <div className={styles.loadingSection}></div>
          </div>
        );
      case "overview":
        return (
          <div className={styles.loadingSectionsContainer}>
            <div className={styles.columns}>
              <div className={styles.loadingSection}></div>
              <div className={styles.loadingSection}></div>
            </div>
          </div>
        );
      case "root":
        return (
          <div className={styles.loadingSectionsContainer}>
            <div className={styles.columns}>
              <div className={styles.loadingCard}></div>
              <div className={styles.loadingCard}></div>
            </div>
            <div className={styles.columns}>
              <div className={styles.loadingCard}></div>
              <div className={styles.loadingCard}></div>
            </div>
          </div>
        );
      default:
        return (
          <div className={styles.loadingSectionsContainer}>
            <div className={styles.loadingSection}></div>
          </div>
        );
    }
  }, [useCase, styles]);
  return (
    <div className={styles.loadingScreenLayout} data-cy="LoadingScreen">
      {showSide && !isScreenS && !isScreenXS && (
        <div className={styles.loadingSide}>
          <div className={styles.loadingSidebar}></div>
        </div>
      )}
      {loadingView}
      {showSide && <div className={styles.loadingSide}></div>}
    </div>
  );
};

export { LoadingScreen };
