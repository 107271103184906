function isElementInViewport(
  element: HTMLElement | null,
  topMargin: number = 0,
  bottomMargin: number = 0
) {
  if (!element) return;
  const rect = element.getBoundingClientRect();

  return (
    rect.top >= topMargin &&
    rect.left >= 0 &&
    rect.bottom <=
      (window.innerHeight || document.documentElement.clientHeight) -
        bottomMargin &&
    rect.right <= (window.innerWidth || document.documentElement.clientWidth)
  );
}

export default isElementInViewport;
