import {
  faChevronLeft,
  faChevronRight,
} from "@fortawesome/pro-regular-svg-icons";
import { useTranslation } from "@kolibrisoftware/hooks-util";
import { useTheme } from "injectors/theme";
import { FC } from "react";
import { Button, Pill, PillThemes } from "@kolibrisoftware/customerportal-ui";
import { useStyles } from "./styles";

type Props = {
  label: string;
  nextAction: () => void;
  prevAction: () => void;
  pillTheme: PillThemes.Primary | PillThemes.Blank;
};

const ObjectCarouselControllers: FC<Props> = ({
  label,
  prevAction,
  nextAction,
  pillTheme,
}) => {
  const theme = useTheme();
  const styles = useStyles({ theme });

  return (
    <div
      className={styles.carouselControllersWrapper}
      data-cy="CarouselControllers"
    >
      <Pill pillTheme={pillTheme} radius="SM" style={{ height: 32 }}>
        <Button
          icon={faChevronLeft}
          onClick={prevAction}
          data-cy="CarouselControllerPrevious"
        />
        <div className={styles.carouselControllerLabel}>{label}</div>
        <Button
          icon={faChevronRight}
          onClick={nextAction}
          data-cy="CarouselControllerNext"
        />
      </Pill>
    </div>
  );
};

export { ObjectCarouselControllers };
