import { FC, useMemo } from "react";
import { useTheme } from "injectors/theme";
import { useStyles } from "./styles";
import classNames from "classnames";

type Props = {
  text?: string;
  prefix?: string;
  hint?: string;
};

const FormElementTranslationComponent: FC<Props> = ({ text, prefix, hint }) => {
  const theme = useTheme();
  const styles = useStyles({ theme });

  const prefixElement = useMemo(() => {
    if (!prefix) return null;
    switch (prefix) {
      case "bullet":
        return (
          <span
            className={classNames(styles.questionPrefix, styles.bulletList)}
          >
            •
          </span>
        );
      default:
        return <span className={styles.questionPrefix}>{prefix}</span>;
    }
  }, [prefix, styles]);

  return (
    <div className={styles.questionTranslationsWrapper}>
      {prefixElement}
      <div className={styles.questionText}>
        {text && <div>{text}</div>}
        {hint && (
          <div
            className={styles.questionHint}
            dangerouslySetInnerHTML={{ __html: hint }}
          />
        )}
      </div>
    </div>
  );
};

export { FormElementTranslationComponent };
