import { FC, useEffect, useRef } from "react";
import { createPortal } from "react-dom";

type Props = {
  children: React.ReactNode;
};

export const PortalComponent: FC<Props> = ({ children }) => {
  const element = useRef(document.createElement("div"));

  useEffect(() => {
    const instance = element.current;

    document.body.appendChild(instance);
    return () => {
      document.body.removeChild(instance);
    };
  }, []);

  return createPortal(children, element.current);
};
