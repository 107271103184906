import { createSlice, PayloadAction } from "@reduxjs/toolkit";
type State = {
  loading: boolean;
  supportColor: string;
  logo?: string;
  realtorName: string;
};

const initialState: State = {
  loading: false,
  supportColor: "#fff",
  logo: "",
  realtorName: "",
};

export const slice = createSlice({
  name: "loading-overlay",
  initialState: initialState,
  reducers: {
    setLoadingData: (state, action: PayloadAction<State>) => {
      return { ...action.payload };
    },
    clearLoading: () => ({ ...initialState }),
  },
});
