import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  questionContainer: responsiveStyles({
    display: "flex",
    gap: 20,
    marginBottom: 10,
  })
    .s(
      {
        flexDirection: "column",
        gap: 0,
        border: "1px solid #0000001a",
        borderTop: "none",
        borderRadius: "5px",
      },
      { propagate: true }
    )
    .getStyle(),

  textInputContainer: {
    position: "relative",
    flex: 4,
  },
  radioInputContainer: responsiveStyles({
    flex: 2,
    height: 40,
    alignItems: "center",
    display: "flex",
  })
    .s({ display: "flex", gap: 10 }, { propagate: true })
    .getStyle(),
  textInputCustomQuestionAction: {
    position: "absolute",
    top: 0,
    right: 0,
    display: "flex",
    alignItems: "center",
    fontSize: 15,
    color:
      theme.companyTheme?.mainColor?.toString() ||
      theme.colors.primary.toString(),
    transition: "all 0.2s ease",
    "&:hover, &:focus-within": {
      color: theme.colors.success.toString(),
      opacity: 0.8,
    },
  },
  textInputDeleteAction: {
    padding: "6px 8px",
    color:
      theme.companyTheme?.mainColor?.toString() ||
      theme.colors.primary.toString(),
    "&:hover, &:focus-within": {
      color: theme.colors.danger.toString(),
    },
  },
  radioGroupWrapper: { flex: 4 },
  radioAnswerContainer: {
    flex: 2,
    display: "flex",
    alignItems: "center",
    fontSize: 13,
    fontWeight: 500,
    // Children
    "& p": {
      marginLeft: 8,
    },
  },
  spacer: {
    height: 1,
    background: "#0000001a",
    width: "100%",
    marginTop: 5,
    marginBottom: 5,
    outline: "none",
    border: "none",
  },
  listOfItemsRadioInput: responsiveStyles({})
    .m(
      {
        minHeight: 40,
        alignItems: "center",
      },
      { propagate: true }
    )
    .getStyle(),
  radioGroup: {
    display: "flex",
    flexDirection: "row !important",
  },
}));

export { useStyles };
