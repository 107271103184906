/**
 *
 * Extracts the youtube video id from the link and creates an ebeded version of it
 * @returns An embeded youtube link with the extracted id OR the original link
 *
 * Supports the following links
 * http://www.youtube.com/watch?v=0zM3nApSvMg&feature=feedrec_grec_index
 * http://www.youtube.com/user/IngridMichaelsonVEVO#p/a/u/1/QdK8U-VIH_o
 * http://www.youtube.com/v/0zM3nApSvMg?fs=1&amp;hl=en_US&amp;rel=0
 * http://www.youtube.com/watch?v=0zM3nApSvMg#t=0m10s
 * http://www.youtube.com/embed/0zM3nApSvMg?rel=0
 * http://www.youtube.com/watch?v=0zM3nApSvMg
 * http://youtu.be/0zM3nApSvMg
 */

export default function resolveYoutubeLink(url: string) {
  const regEx =
    /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
  const match = url.match(regEx);
  const result = match && match[7].length === 11 ? match[7] : false;
  if (result) {
    return `https://youtube.com/embed/${result}`;
  }
  return url;
}
