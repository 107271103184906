import Color from "color";
import { ModalType } from "injectors/modal";

import { createUseStyles } from "react-jss";

type Props = {
  type?: ModalType | null;
};

export default createUseStyles({
  container: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    backdropFilter: "blur(2px)",
    backgroundColor: "#00000050",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    zIndex: 1000,
  },
  modal: (props: Props) => ({
    width: props.type === "confirm" || props.type === "alert" ? 300 : 600,
    borderRadius: 5,
    overflow: "hidden",
    boxShadow: "0 0 24px #00000015",
  }),
  header: {
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    padding: 16,
    paddingBottom: 0,
    backgroundColor: "white",
    fontSize: 16,
  },
  title: {
    flex: 1,
  },
  close: {
    flex: "none",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    justifyContent: "center",
    width: 28,
    height: 28,
    cursor: "pointer",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "white",
    },
    "&:active": {
      backgroundColor: "white",
    },
  },
  body: {
    backgroundColor: "white",
    overflow: "hidden",
    padding: 16,
    paddingTop: 0,
  },
  confirmBody: {
    padding: 24,
    backgroundColor: "white",
  },
  confirmFooter: {
    padding: 7,
    display: "flex",
    flexDirection: "row",
    justifyContent: "flex-end",
    backgroundColor: "white",
    borderTop: `1px solid ${Color.rgb(40, 40, 40).hex()}`,
  },
  footerAction: {
    flex: "none",
    fontSize: 14,
    fontWeight: 600,
    cursor: "pointer",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
    height: 28,
    padding: "0 8px",
    borderRadius: 5,
    "&:hover": {
      backgroundColor: "black",
    },
    "&:active": {
      backgroundColor: "black",
    },
  },
  cancel: {
    color: Color.rgb(150, 150, 150).hex(),
  },
  proceed: {
    color: Color.rgb(0, 122, 204).hex(),
  },
});
