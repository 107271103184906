import { MenuItem, MenuItemsEvents } from "constants/menu-items";
import { useTheme } from "injectors/theme";
import React, { FC, useCallback, useEffect, useRef, useState } from "react";
import { Button } from "@kolibrisoftware/customerportal-ui";
import { useStyles } from "./styles";
import { useTranslation } from "@kolibrisoftware/hooks-util";

type Props = {
  item: MenuItem;
  onClick: (event: React.MouseEvent<any>) => void;
  contextMenuContent?: React.ReactNode;
};

const PrimaryNavigationNavItem: FC<Props> = ({
  item,
  contextMenuContent,
  onClick,
}) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const t = useTranslation();
  const [isContextOpen, setIsContextOpen] = useState(false);

  const onClickHandler = useCallback(
    (event: React.MouseEvent<any>) => {
      if (item.eventType === MenuItemsEvents.OpenModal) {
        setIsContextOpen(prev => !prev);
        return;
      } else {
        onClick(event);
      }
    },
    [item.eventType, onClick]
  );

  const hideContextMenu = useCallback(() => setIsContextOpen(false), []);

  useEffect(() => {
    if (isContextOpen) {
      document.addEventListener("click", hideContextMenu, true);
    } else {
      document.removeEventListener("click", hideContextMenu, true);
    }

    return () => {
      document.removeEventListener("click", hideContextMenu, true);
    };
  }, [isContextOpen, hideContextMenu]);

  return (
    <div className={styles.primaryNavigationNavItemContainer}>
      <>
        <Button
          label={t(item.label)}
          icon={item.icon}
          onClick={onClickHandler}
        />
        {isContextOpen && (
          <div
            className={styles.contextMenuWrapper}
            onClick={e => e.stopPropagation()}
          >
            {contextMenuContent}
          </div>
        )}
      </>
    </div>
  );
};

export { PrimaryNavigationNavItem };
