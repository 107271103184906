import { useContext } from "react";
import { PresenceContext } from "..";

export const usePresence = () => {
  const context = useContext(PresenceContext);

  if (!context) {
    throw new Error("usePresence must be used within a PresenceProvider");
  }

  return context;
};
