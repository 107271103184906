import { Client, Culture } from "@kolibrisoftware/customerportal-api";
import { createThunk } from "store/helpers";

type SetDefaultLanguagePayload = {
  realEstateAgencyId: string;
  id: string;
  culture: Culture;
};
const setDefaultLanguage = createThunk(
  "relation/setDefaultLanguage",
  async ({ settings, http }, args: SetDefaultLanguagePayload) => {
    const client = new Client(settings?.apiUrlCustomerPortal, http);
    try {
      const response = client.setRelationDefaultLanguage(
        args.realEstateAgencyId,
        args.id,
        {
          culture: args.culture,
        }
      );
      return response;
    } catch (error) {
      throw error;
    }
  }
);

export const thunks = { setDefaultLanguage };
