import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  "@keyframes removeElement": {
    from: { opacity: 1 },
    to: { opacity: 0 },
  },
  bottomHintContainer: responsiveStyles({
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    width: "min(600px, calc(100vw - 20px))",
    cursor: "pointer",
    transition: "all 0.1s ease",
    transform: "translateZ(0)",
    "&:hover": {
      transform: "scale(1.01)",
    },
    "&:active": {
      transform: "scale(0.99)",
    },
    zIndex: 4000,
  })
    .s({ bottom: 100 }, { propagate: true })
    .getStyle(),
  removeElement: {
    animation: "$removeElement 0.4s ease-in",
  },
}));

export { useStyles };
