import Color from "color";
import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  previewContainer: responsiveStyles({
    flex: 1,
    backgroundColor: Color.rgb(255, 255, 255).toString(),
    border: "1px solid #E7E7ED",
    borderRadius: 3,
    overflow: "hidden",
    minWidth: 320,
  })
    .s(
      {
        minWidth: 300,
        border: "none",
      },
      { propagate: true }
    )
    .getStyle(),
  thumbWrapper: {
    width: "100%",
    paddingTop: "75%",
    position: "relative",
  },
  thumbContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    height: "100%",
    width: "100%",
  },
  thumb: {
    width: "100%",
    height: "100%",
    backgroundPosition: "center center",
    backgroundSize: "cover",
    transition: "background-size 0.75s linear",
    backgroundRepeat: "no-repeat",
  },

  body: {
    padding: 16,
  },

  previewTitle: {
    color: "#000000",
    fontWeight: 400,
    fontSize: "18px",
    marginBottom: 6,
  },
  previewBody: {
    color: "#3A3A3C",
    fontSize: "15px",
    fontWeight: 400,
    marginBottom: 6,
  },
}));

export default useStyles;
