import { ThemeInterface } from "injectors/theme";
import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  sidebarContainer: responsiveStyles({})
    .s(
      {
        height: "100%",
        paddingBottom: 50,
      },
      { propagate: true }
    )
    .getStyle(),
  sidebarPartsWrapper: responsiveStyles({})
    .s({ height: "100%", overflow: "auto" })
    .getStyle(),
  sidebarElementsContainer: {},
  sidebarTitle: { fontSize: 15, fontWeight: 600, marginBottom: 15 },
  partTitle: {},
}));

export { useStyles };
