import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  loadingScreenLayout: {
    display: "flex",
    gap: 15,
  },
  loadingSectionsContainer: {
    display: "flex",
    justifyContent: "center",
    maxWidth: 900,
    margin: "auto",
    gap: 10,
    flexDirection: "column",
    marginTop: 15,
    alignItems: "center",
    overflow: "hidden",
    flex: 4,
  },
  loadingSection: {
    height: 350,
    width: "100%",
    backgroundColor: "#e2e2e2",
    animation: "$breathing 1s ease-in-out infinite alternate",
  },
  loadingCard: {
    height: 500,
    width: "100%",
    backgroundColor: "#e2e2e2",
    animation: "$breathing 1s ease-in-out infinite alternate",
  },
  loadingSide: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
    marginTop: 15,
  },
  loadingSidebar: {
    maxWidth: 250,
    width: "100%",
    height: "100%",
    backgroundColor: "#e2e2e2",
    animation: "$breathing 1s ease-in-out infinite alternate",
  },
  columns: {
    display: "flex",
    gap: 15,
    width: "100%",
  },
  "@keyframes breathing": {
    from: {
      opacity: 1,
    },
    to: {
      opacity: 0.5,
    },
  },
}));

export { useStyles };
