import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  thumbWrapper: {
    display: "flex",
    justifyContent: "center",
    alignItems: "center",
    flexDirection: "column",
    height: "100%",
    backgroundPosition: "center center",
    transition: "background-size 0.75s linear",
    color: theme.colors?.offWhite?.string(),
    backgroundSize: "auto",
    backgroundRepeat: "no-repeat",
  },
  thumbIcon: {
    height: "48px",
  },
  thumbText: {
    fontSize: "15px",
    fontWeight: "600",
  },
  responsiveThumb: responsiveStyles({})
    .s({
      width: "110%",
      position: "relative",
      left: "-5%",
      margin: "auto",
      alignSelf: "center",
      backgroundSize: "cover",
    })
    .getStyle(),
}));

export { useStyles };
