import React, { FC } from "react";
import { useStyles } from "./styles";
import { useTheme } from "injectors/theme";
import { ContentSection } from "@kolibrisoftware/customerportal-ui";

type Props = {
  title: string | undefined | null;
  description?: string | undefined | null;
  children?: React.ReactNode;
};

const HeaderSection: FC<Props> = ({ title, description, children }) => {
  const theme = useTheme();
  const styles = useStyles({ theme });

  return (
    <ContentSection>
      {title && <h2 className={styles.header__title}>{title}</h2>}
      {description && (
        <p className={styles.header__description}>{description}</p>
      )}
      {children}
    </ContentSection>
  );
};

export { HeaderSection };
