import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useModal } from "hooks/useModal";
import { FC, useCallback, useEffect } from "react";
import useStyles from "./styles";
import classnames from "classnames";

type Props = {
  visible: boolean;
};

export const AsyncModalComponent: FC<Props> = ({ visible }) => {
  const { type, title, body, cancel, proceed } = useModal();
  const styles = useStyles({ type });

  const onKeyUp = useCallback(
    (event: KeyboardEvent) => {
      if (!visible) {
        return;
      }

      switch (event.code) {
        case "Enter": {
          if (type === "confirm") {
            proceed?.(true);
          }
          if (type === "alert") {
            proceed?.();
          }
          break;
        }
        case "Escape": {
          if (type === "confirm") {
            cancel?.(false);
          }
          if (type === "modal") {
            cancel?.();
          }
          break;
        }
        default: {
          break;
        }
      }
    },
    [visible, type, proceed, cancel]
  );

  useEffect(() => {
    document.addEventListener("keyup", onKeyUp);

    return () => {
      document.removeEventListener("keyup", onKeyUp);
    };
  }, [onKeyUp]);

  return visible ? (
    <div className={styles.container}>
      <div className={styles.modal}>
        {type === "modal" && (
          <>
            <div className={styles.header}>
              {title && <div className={styles.title}>{title}</div>}
              <div onClick={() => cancel?.()} className={styles.close}>
                <FontAwesomeIcon icon={faTimes} />
              </div>
            </div>

            {body && <div className={styles.body}>{body}</div>}
          </>
        )}

        {type === "confirm" && (
          <>
            {title && <div className={styles.confirmBody}>{title}</div>}
            <div className={styles.confirmFooter}>
              <div
                onClick={() => cancel?.(false)}
                className={classnames(styles.footerAction, styles.cancel)}
              >
                Cancel
              </div>
              <div
                onClick={() => proceed?.(true)}
                className={classnames(styles.footerAction, styles.proceed)}
              >
                Confirm
              </div>
            </div>
          </>
        )}

        {type === "alert" && (
          <>
            {title && <div className={styles.confirmBody}>{title}</div>}
            <div className={styles.confirmFooter}>
              <div
                onClick={() => proceed?.()}
                className={classnames(styles.footerAction, styles.proceed)}
              >
                Okay
              </div>
            </div>
          </>
        )}
      </div>
    </div>
  ) : null;
};
