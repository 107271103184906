import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  confirmPromptContainer: {
    padding: 16,
  },
  confirmButtonWrapper: {
    padding: 10,
    background: theme.companyTheme?.mainColor?.toString(),
    color: "white",
  },
  confirmPromptContainerWrapper: {
    marginBottom: 40,
  },
}));

export { useStyles };
