import { faFileLines } from "@fortawesome/pro-regular-svg-icons";
import {
  BlueprintType,
  ChecklistItemResultType,
  ChecklistItemStatusType,
  SingleChecklistItem,
} from "@kolibrisoftware/customerportal-api";
import { useTranslation } from "@kolibrisoftware/hooks-util";
import { ROUTER_PATHS } from "constants/routes";
import checklistItemHelpers from "helpers/checklist-item";
import { createErrorToast, createSuccessToast } from "helpers/create-new-toast";
import { ExtendedChecklistItem } from "helpers/extend-checklist-items";
import { useModal } from "hooks/useModal";
import { useToasts } from "hooks/useToast";
import { first } from "lodash-es";
import { FC, useCallback, useMemo } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { ActivityItem } from "shared/components/activity-item/activity-item";
import { ButtonThemes } from "@kolibrisoftware/customerportal-ui";
import { documentApi } from "store/api";
import { DownloadArgs } from "store/api/document";
import { useDispatch } from "store/helpers";
import ActivityItemActions, {
  ActionInterface,
} from "shared/components/activity-item-actions";
import UploadDocumentModal from "../upload-document-modal";

type Props = {
  checklistItems: ExtendedChecklistItem[];
};

const ChecklistItemsList: FC<Props> = ({ checklistItems }) => {
  const navigate = useNavigate();
  const { openModal, proceed, cancel } = useModal<File>();
  const { addToast } = useToasts();
  const { dossierId } = useParams();
  const t = useTranslation();
  const dispatch = useDispatch();

  const handleNavigate = useCallback(
    (path: string) => {
      let root = `/dossier/${dossierId}`;
      navigate(`${root}/${path}`);
    },
    [navigate, dossierId]
  );

  const handleOpenModal = useCallback(
    async (checklistItem: SingleChecklistItem) => {
      const response = await openModal(
        checklistItemHelpers.resolveName(checklistItem),
        <UploadDocumentModal
          onCancel={cancel}
          onConfirm={proceed}
          checklistItem={checklistItem}
          data-cy="UploadChecklistItemDocumentModal"
        />
      );
      if (response) {
        const toastMessage = t("uploadDocument.toast.success", {
          values: {
            filename: response.name,
          },
        });
        const toast = createSuccessToast(toastMessage);
        addToast(toast);
      }
    },
    [openModal, proceed, cancel, t, addToast]
  );

  const handleDownload = useCallback(
    async (payload: DownloadArgs) => {
      try {
        const downloadLink = await dispatch(
          documentApi.downloadDocument(payload)
        ).unwrap();

        if (!downloadLink.tempAccessDownloadUrl) {
          throw new Error("No Download Link Available");
        }

        window.open(downloadLink.tempAccessDownloadUrl);
      } catch (error) {
        const errorMessage = t("common.notAvailable");
        const toast = createErrorToast(errorMessage);
        addToast(toast);
        throw error;
      }
    },
    [dispatch, addToast, t]
  );

  const mappedItems = useMemo(
    () =>
      checklistItems.map((checklistItem, index) => {
        const { status, checklistItemType } = checklistItem;
        const { resultType, acceptsInitialDocument, blueprintType } =
          checklistItemType;
        const checklistItemActions: ActionInterface[] = [];

        // GENERAL DOCUMENT RESOLVER
        if (resultType === ChecklistItemResultType.GeneralDocument) {
          // Open General Documents share the upload action
          const uploadActionCallback = () => handleOpenModal(checklistItem);
          const uploadAction: ActionInterface = {
            label: "common.uploadAction",
            callback: uploadActionCallback,
          };

          if (status === ChecklistItemStatusType.Open) {
            const initialDocument = first(checklistItem.initialDocuments);
            // INITIAL DOCUMENT CHECKLIST ITEM

            if (
              acceptsInitialDocument &&
              initialDocument &&
              initialDocument.id
            ) {
              // When the item acceptsInitial document
              // We create and append a download action for the initial document.
              let downloadInitialDocumentCallback = () =>
                handleDownload({
                  documentId: initialDocument.id || "",
                  documentType: "initial",
                });
              const downloadAction: ActionInterface = {
                label: "checklist.checklistItem.downloadAndUpload.stepOne",
                callback: downloadInitialDocumentCallback,
                theme: ButtonThemes.PrimaryOutline,
              };
              uploadAction.label =
                "checklist.checklistItem.downloadAndUpload.stepTwo";
              checklistItemActions.push(downloadAction);
            }
            // Open General Documents share the upload action
            checklistItemActions.push(uploadAction);
          } else {
            let downloadActionCallback = () =>
              handleDownload({
                documentId: checklistItem.attachedDocument?.id || "",
                documentType: "default",
              });
            const downloadAction: ActionInterface = {
              label: "common.downloadAction",
              callback: downloadActionCallback,
            };
            checklistItemActions.push(downloadAction);
          }
        }

        // FORM RESOLVER
        if (resultType === ChecklistItemResultType.Form) {
          const pathSuffix = `/${checklistItem.formId}`;
          let pathPrefix = "";

          if (blueprintType === BlueprintType.ListOfGoods) {
            pathPrefix = ROUTER_PATHS.LIST_OF_ITEMS;
          }
          if (blueprintType === BlueprintType.Questionnaire) {
            pathPrefix = ROUTER_PATHS.QUESTIONNAIRE;
          }

          const openFormActionCallback = () =>
            handleNavigate(pathPrefix + pathSuffix);
          const openFormAction: ActionInterface = {
            label:
              status === ChecklistItemStatusType.Open
                ? "common.fulfill"
                : "common.open",
            callback: openFormActionCallback,
          };
          checklistItemActions.push(openFormAction);
        }

        const title = checklistItemHelpers.resolveName(checklistItem),
          description = checklistItemHelpers.resolveDescription(checklistItem);

        return (
          <ActivityItem
            data-cy={"ChecklistItemComponent"}
            activityActions={
              <ActivityItemActions
                actions={checklistItemActions}
                data-cy={
                  checklistItemType.acceptsInitialDocument
                    ? "ChecklistItemUploadAndDownloadComponent"
                    : ""
                }
              />
            }
            id={checklistItem.id}
            icon={faFileLines}
            key={`${checklistItem.id}--${index}`}
            completedOn={checklistItem.dateTimeModified || undefined}
            title={title}
            description={description}
            status={status}
          />
        );
      }),
    [checklistItems, handleNavigate, handleOpenModal, handleDownload]
  );

  return <>{mappedItems}</>;
};

export default ChecklistItemsList;
