import { useTheme } from "injectors/theme";
import { FC } from "react";
import useStyles from "./styles";

type Props = {
  children: React.ReactNode;
};

export const SecondaryNavbarWrapper: FC<Props> = ({ children }) => {
  const theme = useTheme();
  const styles = useStyles({ theme });

  return (
    <div className={styles.secondaryNavbarWrapper}>
      <div className={styles.navContainer}>{children}</div>
    </div>
  );
};
