import { slice } from "./slice";
import mainThunks from "./thunks";
import { persistReducer, PersistConfig } from "redux-persist";
import storage from "redux-persist/lib/storage/session";

const persistConfig: PersistConfig<ReturnType<typeof slice.reducer>> = {
  storage,
  key: "hw/customer-portal-client/main",
  whitelist: ["translations"],
};

export const actions = slice.actions;
export const reducer = persistReducer(persistConfig, slice.reducer);
export const thunks = mainThunks;
