import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "@kolibrisoftware/hooks-util";
import Color from "color";
import { useTheme } from "injectors/theme";
import { useSelector } from "store/helpers";
import { FC, useMemo } from "react";
import { useStyles } from "./styles";

type Props = {};

const LoadingOverlay: FC<Props> = () => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const t = useTranslation();
  const loadingState = useSelector(state => state.loadingOverlay);
  const { supportColor, logo, realtorName } = loadingState;

  const translations = useMemo(
    () => ({
      infoText: t("loadingScreen.infoText", {
        values: {
          realtorName: ` <strong>${" "}${realtorName}</strong>`,
        },
      }),
    }),
    [t, realtorName]
  );

  const backgroundColor = useMemo(() => {
    if (supportColor) {
      return Color(supportColor).fade(0.8).toString();
    }
    return Color("#000").fade(0.2).toString();
  }, [supportColor]);

  const companyLogo = useMemo(() => {
    if (logo && logo.length > 0) {
      return `data:image/png;base64,${logo}`;
    }
    return "";
  }, [logo]);

  const loaderGradient = useMemo(() => {
    if (supportColor) {
      const gradientColor = new Color(supportColor).darken(0.3).string();
      return `conic-gradient(from 90deg at 50% 50%, rgba(129, 165, 202, 0) 0deg, ${gradientColor} 360deg)`;
    }
    return "conic-gradient(from 90deg at 50% 50%, rgba(129, 165, 202, 0) 0deg, #012C57 360deg)";
  }, [supportColor]);

  return (
    <div
      className={styles.overlay}
      style={{ backgroundColor: backgroundColor }}
      data-cy={`${realtorName}LoadingOverlay`}
    >
      <div className={styles.loaderContainer}>
        <span
          className={styles.loaderSpinner}
          style={{
            background: loaderGradient,
          }}
        >
          <span
            className={styles.loaderSpinnerEnding}
            style={{ background: Color(supportColor).darken(0.3).string() }}
          ></span>
        </span>

        {logo ? (
          <div className={styles.loaderLogo}>
            <img
              src={companyLogo}
              alt="company-logo"
              className={styles.loaderLogoImage}
            />
          </div>
        ) : (
          <div className={styles.loaderLogoDisplayName}>
            <div className={styles.loaderLogoImage}>{realtorName}</div>
          </div>
        )}
      </div>
      <div className={styles.infoContainer}>
        <FontAwesomeIcon icon={faCircleInfo} className={styles.infoIcon} />
        <div
          className={styles.infoText}
          dangerouslySetInnerHTML={{ __html: translations.infoText }}
        ></div>
      </div>
    </div>
  );
};

export { LoadingOverlay };
