import {
  AssignmentRelationEntity,
  ChecklistItemResultType,
  ChecklistItemStatusType,
  ChecklistItemSubmitType,
  Document,
  DocumentStatus,
  SingleChecklistItem,
} from "@kolibrisoftware/customerportal-api";

export interface ExtendedChecklistItem extends SingleChecklistItem {
  name?: string | null;
  targetRelation?: AssignmentRelationEntity;
  attachedDocument?: Document | null;
}

/**
 *
 * It will extend GeneralDocument checklist items by attaching the document if it's available
 *
 * In case the checklistItem has a submit type of separate and
 * we have multiple relations for the property, it
 * will create copies of the checklist item for each
 * relation and infer the review-status from the document status.
 *
 *
 * @param checklistItems initial checklist items
 * @param assignmentRelations assignment relations for a property
 * @param documents all the documents for a property

 */
export const extendChecklistItems = (
  checklistItems: SingleChecklistItem[],
  assignmentRelations: AssignmentRelationEntity[],
  documents: Document[]
) => {
  return checklistItems.reduce((reducerState, checklistItem) => {
    if (
      checklistItem.checklistItemType.resultType ===
      ChecklistItemResultType.GeneralDocument
    ) {
      if (
        checklistItem.submitType === ChecklistItemSubmitType.Separate &&
        assignmentRelations.length > 1
      ) {
        const extraChecklistItems: ExtendedChecklistItem[] =
          assignmentRelations.map(targetRelation => {
            const attachedDocument = documents.find(
              document =>
                document.ownerAssignmentRelationId === targetRelation.id &&
                document.checklistItemId === checklistItem.id
            );
            let status = checklistItem.status;
            let targetDisplayName = targetRelation.displayName;
            const translations = checklistItem.translations?.map(item => ({
              ...item,
              name: `${item.name} ${targetDisplayName}`,
            }));

            if (attachedDocument) {
              switch (attachedDocument.status) {
                case DocumentStatus.Refused: {
                  status = ChecklistItemStatusType.Open;
                  break;
                }
                case DocumentStatus.Accepted: {
                  status = ChecklistItemStatusType.Closed;
                  break;
                }
                case DocumentStatus.Uploaded: {
                  status = ChecklistItemStatusType.Review;
                  break;
                }
              }
            }

            return {
              ...checklistItem,
              status,
              targetRelation,
              attachedDocument,
              translations,
            };
          });
        return [...reducerState, ...extraChecklistItems];
      } else {
        const attachedDocument = documents.find(
          item => item.checklistItemId === checklistItem.id
        );
        if (attachedDocument) {
          return [...reducerState, { ...checklistItem, attachedDocument }];
        }
      }
    }
    return [...reducerState, checklistItem];
  }, [] as ExtendedChecklistItem[]);
};
