import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "@kolibrisoftware/hooks-util";
import classNames from "classnames";
import { useTheme } from "injectors/theme";
import { ObjectTabType } from "modules/object-preview/constants/object-tabs";
import { FC, useCallback, useContext, useMemo } from "react";
import { ObjectTabsModalContext } from "../modal-context/modal-context";
import { useStyles } from "./styles";

type Props = {
  tab: ObjectTabType;
};

const ModalTabButton: FC<Props> = ({ tab }) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const t = useTranslation();
  const modalContext = useContext(ObjectTabsModalContext);

  const isActive = useMemo(() => {
    return modalContext.activeTab?.id === tab.id;
  }, [modalContext.activeTab, tab]);

  const handleOnClick = useCallback(() => {
    modalContext.changeTab?.(tab.id);
  }, [modalContext, tab]);

  return (
    <button
      className={classNames(styles.modalTabButtonWrapper, {
        [styles.modalTabButtonActive]: isActive,
      })}
      onClick={handleOnClick}
      data-cy="ObjectPreviewModalTabButton"
    >
      <div className={styles.modalButtonContent}>
        <FontAwesomeIcon
          icon={tab.icon}
          className={styles.modalTabButtonIcon}
        />
        <div className={styles.modalTabButtonLabel}>{t(tab.label)}</div>
      </div>
    </button>
  );
};

export { ModalTabButton };
