import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  container: {},
  textarea: {
    flex: 1,
    resize: "none",
  },
}));

export { useStyles };
