import {
  Client as CustomerPortalClient,
  Document,
  FileParameter,
  SearchDocumentRequest,
  SearchDocumentResponse,
} from "@kolibrisoftware/customerportal-api";
import {
  createDocumentsSearchCallback,
  fetchAllElements,
} from "helpers/form-elements-search";
import { createThunk } from "store/helpers";

type SearchDocumentsPayload = {
  realEstateAgencyId: string;
  assignmentId: string;
  requestBody?: SearchDocumentRequest;
  searchAll?: boolean;
};

const searchDocuments = createThunk(
  "api/document/searchDocuments",
  async ({ http, settings, handleError }, args: SearchDocumentsPayload) => {
    try {
      const {
        assignmentId,
        realEstateAgencyId,
        requestBody = { skip: 0, take: 100, ...args.requestBody },
        searchAll,
      } = args;

      const client = new CustomerPortalClient(
        settings?.apiUrlCustomerPortal,
        http
      );

      const response = await client.searchDocument(
        realEstateAgencyId,
        assignmentId,
        requestBody
      );

      if (!response) {
        throw new Error("Something went wrong");
      }
      let result = response.result || [];
      const totalResults = response.totalResults || 0;

      if (requestBody.take && totalResults > requestBody.take && searchAll) {
        const searchCallback = createDocumentsSearchCallback(
          realEstateAgencyId,
          assignmentId,
          client.searchDocument.bind(client)
        );
        const allDocuments = await fetchAllElements<
          SearchDocumentResponse,
          Document
        >(totalResults, requestBody.take, searchCallback);

        result = [...result, ...allDocuments];
      }

      return result;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
);

type UploadDocumentPayload = {
  checklistItemId: string;
  file: File;
  uploadOnBehalfOfRelationId?: string | undefined;
};
const uploadDocument = createThunk(
  "api/document/uploadDocument",
  async (
    { http, settings, handleError, getState },
    args: UploadDocumentPayload
  ) => {
    try {
      const state = getState();
      const realEstateAgencyId =
        state.main.companyData?.realEstateAgencyId || "";
      const assignmentId = state.dossier.assignmentDetails.id || "";

      const {
        file,
        checklistItemId,
        uploadOnBehalfOfRelationId = undefined,
      } = args;

      const client = new CustomerPortalClient(
        settings?.apiUrlCustomerPortal,
        http
      );

      const requestBody = {
        data: file,
        fileName: file.name,
      } as FileParameter;

      const result = await client.uploadBlob(
        realEstateAgencyId,
        assignmentId,
        checklistItemId,
        requestBody,
        uploadOnBehalfOfRelationId || ""
      );
      return result;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
);

export type DownloadArgs = {
  documentId: string;
  documentType?: "default" | "initial";
};

const downloadDocument = createThunk(
  "api/document/downloadDocument",
  async ({ http, settings, getState, handleError }, args: DownloadArgs) => {
    try {
      const state = getState();
      const assignmentId = state.dossier.assignmentDetails.id || "";
      const companyId = state.main.companyData?.realEstateAgencyId || "";

      const { documentId, documentType = "default" } = args;

      const client = new CustomerPortalClient(
        settings?.apiUrlCustomerPortal,
        http
      );
      switch (documentType) {
        case "default": {
          return await client.generateDownloadUrl(
            companyId,
            assignmentId,
            documentId
          );
        }
        case "initial": {
          return await client.generateInitialDocumentDownloadUrl(
            companyId,
            assignmentId,
            documentId
          );
        }
        default: {
          throw new Error("Missing document type");
        }
      }
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
);

export const thunks = { searchDocuments, uploadDocument, downloadDocument };
