import { FC, useCallback, useEffect, useMemo } from "react";
import { useStyles } from "./styles";
import { useTheme } from "injectors/theme";
import { useTranslation } from "@kolibrisoftware/hooks-util";
import { Button } from "@kolibrisoftware/customerportal-ui";
import { useNavigate, useParams } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleInfo } from "@fortawesome/pro-regular-svg-icons";
import { useDispatch, useSelector } from "store/helpers";
import { actions as mainActions } from "store/main";
import { AppStatus } from "enums/app-status";
type Props = {
  useCase?: "Default" | "ErrorBoundary" | "LoginIssue" | "FormError";
};

export const RedirectToNotFound = () => {
  const navigate = useNavigate();
  useEffect(() => {
    navigate("/not-found", { replace: true });
  }, [navigate]);
  return <></>;
};

const ErrorPage: FC<Props> = ({ useCase = "Default" }) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const t = useTranslation();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const companyData = useSelector(state => state.main.companyData);
  const { dossierId } = useParams();
  const translations = useMemo(() => {
    switch (useCase) {
      case "ErrorBoundary":
        return {
          title: t("error.errorBoundary.title"),
          description: t("error.errorBoundary.description"),
        };
      case "LoginIssue":
        // TODO - translations issue
        return {
          title: "Er lijkt iets fout te zijn gegaan..",
          description:
            "Helaas is er iets niet goed gegaan. Probeer deze pagina te verversen of neem bij aanhoudende problemen contact op met je makelaar.",
          tip: "Leeg het cache-geheugen van je browser voordat je de pagina ververst",
        };
      case "FormError":
        return {
          title: t("error.notFound.form.title"),
          description: t("error.notFound.form.description"),
        };
      default:
        return {
          title: t("error.notFound.title"),
        };
    }
  }, [t, useCase]);

  const handleNavigate = useCallback(
    (action?: "Dossiers" | "Checklist") => {
      let path = "/";
      switch (action) {
        case "Checklist":
          if (dossierId) {
            path = `/dossier/${dossierId}/checklist`;
          }
          break;
        default:
          break;
      }

      dispatch(mainActions.setAppStatus(AppStatus.Success));
      navigate(path, { replace: true });
    },
    [dossierId, dispatch, navigate]
  );
  return (
    <div className={styles.errorPageContainer}>
      <div className={styles.logoWrapper}>
        {(!companyData || !companyData.logo) && (
          <img
            src={`${process.env.PUBLIC_URL}/jouwmakelaar-online-logo.svg`}
            alt={"jouwmakelaar.online"}
          />
        )}
        {companyData && companyData.logo && (
          <img
            src={`data:image/png;base64,${companyData.logo}`}
            alt={"Makelaar Logo"}
          />
        )}
      </div>
      <h2>{translations.title}</h2>
      {!!translations.description && <h3>{translations.description}</h3>}
      {useCase === "Default" && (
        <div className={styles.actionWrapper}>
          <Button
            label={t("error.notFound.action")}
            onClick={() => handleNavigate()}
            size="m"
          />
        </div>
      )}
      {useCase === "LoginIssue" && (
        <div className={styles.errorTip}>
          <FontAwesomeIcon icon={faCircleInfo} fontSize={20} />
          <p>{translations.tip}</p>
          <FontAwesomeIcon icon={faCircleInfo} fontSize={20} />
        </div>
      )}
      {useCase === "FormError" && (
        <div className={styles.actionWrapper}>
          <Button
            label={t("error.notFound.form.action")}
            onClick={() => handleNavigate("Checklist")}
            size="m"
          />
        </div>
      )}
    </div>
  );
};

export { ErrorPage };
