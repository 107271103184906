import { FormElement } from "@kolibrisoftware/customerportal-api";
import { useTheme } from "injectors/theme";
import useElementData from "modules/questionnaire/hooks/useElementData";
import { FC } from "react";
import {
  ContentSection,
  ContentSectionSize,
} from "@kolibrisoftware/customerportal-ui";
import useElementTranslations from "hooks/useElementTranslations";
import { useStyles } from "./styles";

type Props = {
  partStructure: FormElement;
  children?: React.ReactNode;
};

const FormPart: FC<Props> = ({ partStructure, children }) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const partDetails = useElementData(partStructure.id);
  const partTranslations = useElementTranslations(partDetails);

  return (
    <>
      <ContentSection size={ContentSectionSize.Full}>
        <h2 className={styles.formPartTitle}>{partTranslations?.title}</h2>
        {!!partTranslations?.text && (
          <div
            className={styles.formPartBody}
            dangerouslySetInnerHTML={{ __html: partTranslations.text }}
          />
        )}
      </ContentSection>
      {children}
    </>
  );
};

export { FormPart };
