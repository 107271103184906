import {
  FormElement,
  FormElementQuestionType,
} from "@kolibrisoftware/customerportal-api";
import { useTheme } from "injectors/theme";
import { FC, useEffect, useMemo } from "react";
import { useSelector } from "store/helpers";
import { ContractSectionComponent } from "./contract/contract";
import { ForeignSectionComponent } from "./foreign/foreign";
import { RegularSectionComponent } from "./regular/regular";
import { useStyles } from "./styles";

type Props = { sectionStructure: FormElement };

const FormSectionComponent: FC<Props> = ({ sectionStructure }) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const elements = useSelector(state => state.listOfItems.populatedElements);
  // TODO -> Check here if allows custom questions!
  const isForeignSection = useMemo(() => {
    return sectionStructure?.elements?.reduce((state, question) => {
      const currentItem = elements.find(item => item.id === question.id);
      if (
        currentItem &&
        currentItem.questionType !==
          FormElementQuestionType.ListOfItemRadioList &&
        currentItem.questionType !== FormElementQuestionType.ContractListOfGood
      ) {
        return true;
      }
      return state;
    }, false);
  }, [sectionStructure, elements]);

  const isContractSection = useMemo(() => {
    return sectionStructure?.elements?.reduce((state, question) => {
      const currentItem = elements.find(item => item.id === question.id);
      if (
        currentItem &&
        currentItem.questionType === FormElementQuestionType.ContractListOfGood
      ) {
        return true;
      }
      return state;
    }, false);
  }, [elements, sectionStructure.elements]);

  return (
    <div className={styles.formSectionContainer}>
      {!isForeignSection && !isContractSection && (
        <RegularSectionComponent sectionStructure={sectionStructure} />
      )}
      {isForeignSection && (
        <ForeignSectionComponent sectionStructure={sectionStructure} />
      )}
      {isContractSection && (
        <ContractSectionComponent sectionStructure={sectionStructure} />
      )}
    </div>
  );
};

export { FormSectionComponent };
