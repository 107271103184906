import React, {
  FC,
  createContext,
  useCallback,
  useState,
  useMemo,
} from "react";

import {
  useDefaultMediaQueries,
  useMedia,
  useTranslation,
} from "@kolibrisoftware/hooks-util";
import useObjectPreviewTabs from "hooks/useObjectPreviewTabs";
import { useTheme } from "injectors/theme";
import {
  ObjectTabTargets,
  ObjectTabType,
} from "modules/object-preview/constants/object-tabs";
import Modal from "shared/components/modal";
import { ModalTabButton } from "../modal-tab-button/modal-tab-button";
import { useStyles } from "./styles";
import { ObjectPhotoGallery } from "../object-photo-gallery/object-photo-gallery";
import { ObjectBrochures } from "../object-brochures/object-brochures";
import { ObjectGroundplan } from "../object-groundplan/object-groundplan";
import { ObjectView360 } from "../object-view360/object-view360";
import { createErrorToast } from "helpers/create-new-toast";
import { useDispatch } from "store/helpers";
import { actions as toastActions } from "store/toasts";

type Props = {
  children?: React.ReactNode;
};

type ContextProps = {
  activeTab?: ObjectTabType | null;
  visible?: boolean;
  changeTab?: (tabId: string) => void;
  closeModal?: () => void;
  openModal?: (tabId?: string) => void;
};

export const ObjectTabsModalContext = createContext<ContextProps>({});

const ObjectTabsModalContextProvider: FC<Props> = ({ children }) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const [isOpen, setIsOpen] = useState(false);
  const dispatch = useDispatch();
  const [activeTabItem, setActiveTabItem] = useState<ObjectTabType | null>(
    null
  );

  const isScreenUnder1200 = useMedia("(max-width: 1200px)");

  const tabs = useObjectPreviewTabs("modal");
  const t = useTranslation();

  const handleChangeTab = useCallback(
    (tabId: string) => {
      const selectedTab = tabs.find(item => item.id === tabId);
      if (!selectedTab) {
        const errorToast = createErrorToast(
          "objectPreview.objectTabs.notFound"
        );
        dispatch(toastActions.addToast(errorToast));
        return;
      }
      setActiveTabItem(selectedTab);
    },
    [tabs, dispatch]
  );

  const currentModalView = useMemo(() => {
    switch (activeTabItem?.action.target) {
      case ObjectTabTargets.Gallery:
        return <ObjectPhotoGallery />;
      case ObjectTabTargets.Brochures:
        return <ObjectBrochures />;
      case ObjectTabTargets.Plan:
        return <ObjectGroundplan />;
      case ObjectTabTargets.View360:
        return <ObjectView360 />;
      default:
        return null;
    }
  }, [activeTabItem]);

  const handleOpenModal = useCallback(
    (tabId?: string) => {
      if (tabId) {
        handleChangeTab(tabId);
      } else {
        setActiveTabItem(tabs?.[0]);
      }
      setIsOpen(true);
    },
    [handleChangeTab, tabs]
  );

  const handleCloseModal = useCallback(() => {
    setIsOpen(false);
  }, []);

  return (
    <ObjectTabsModalContext.Provider
      value={{
        activeTab: activeTabItem,
        openModal: handleOpenModal,
        closeModal: handleCloseModal,
        changeTab: handleChangeTab,
      }}
    >
      <Modal
        visible={isOpen}
        closeOnBackdropClick={true}
        onClose={handleCloseModal}
        position={isScreenUnder1200 ? "top" : "center"}
        title={t(activeTabItem?.label || "undefined")}
        displayStyles={{
          maxWidth: "unset",
        }}
      >
        <div className={styles.modalBodyWrapper} data-cy="ObjectPreviewModal">
          {/* CONTENT */}
          <div className={styles.modalContentWrapper}>{currentModalView}</div>

          {/* TABS */}
          {tabs.length > 1 && (
            <div className={styles.modalTabs} data-cy="ObjectPreviewModalTabs">
              {tabs.map((item, index) => (
                <ModalTabButton tab={item} key={index} />
              ))}
            </div>
          )}
        </div>
      </Modal>
      {children}
    </ObjectTabsModalContext.Provider>
  );
};

export { ObjectTabsModalContextProvider };
