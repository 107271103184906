import { useSelector } from "store/helpers";

const useQuestionAnswer = (questionId: string = "") => {
  return useSelector(state =>
    state.listOfItems.answers.find(
      answer =>
        answer.blueprintElementId === questionId ||
        answer.formCustomQuestionId === questionId
    )
  );
};

export default useQuestionAnswer;
