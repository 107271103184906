import { faFileSlash } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useDefaultMediaQueries,
  useTranslation,
} from "@kolibrisoftware/hooks-util";
import { PLACEHOLDER_OBJECT_IMAGE } from "assets/placeholder-image";
import { useTheme } from "injectors/theme";
import { FC, useCallback, useContext, useMemo, useState } from "react";
import { useSelector } from "store/helpers";
import { ObjectTabsModalContext } from "../modal-context/modal-context";
import ObjectCarouselControllers from "../object-carousel-controllers";
import { useStyles } from "./styles";
import { PillThemes } from "@kolibrisoftware/customerportal-ui";
import { useSwipeListener } from "hooks/useSwipeListener";

type Props = {};

const ObjectCarousel: FC<Props> = props => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const homePhotos = useSelector(
    state => state.objectPreview.assignmentPreview.photos
  );
  const { openModal } = useContext(ObjectTabsModalContext);
  const { isScreenLG } = useDefaultMediaQueries();
  const t = useTranslation();

  const handleNextPicture = useCallback(() => {
    if (homePhotos) {
      setSelectedImageIndex(prevState => (prevState + 1) % homePhotos?.length);
    }
  }, [homePhotos]);

  const handlePreviousPicture = useCallback(() => {
    if (homePhotos) {
      setSelectedImageIndex(
        prevState => (prevState - 1 + homePhotos.length) % homePhotos?.length
      );
    }
  }, [homePhotos]);

  const currentPhoto = useMemo(() => {
    if (!!homePhotos && !!homePhotos[selectedImageIndex]) {
      const photo = homePhotos[selectedImageIndex];
      let photoUrl = "";
      if (photo.urlBig) {
        photoUrl = photo.urlBig;
      }
      return (
        <img
          className={styles.carouselImage}
          src={photoUrl}
          alt="object-presentation"
          onClick={() => {
            if (!isScreenLG) {
              openModal?.();
            }
          }}
        />
      );
    } else {
      return (
        <div
          className={styles.missingImage}
          style={{ backgroundImage: PLACEHOLDER_OBJECT_IMAGE }}
          data-cy="PlaceholderImage"
        >
          <FontAwesomeIcon
            icon={faFileSlash}
            className={styles.missinImageIcon}
          />
          <div className={styles.missingImageText}>
            {t("objectPreview.carousel.noPhotoAvailable")}
          </div>
        </div>
      );
    }
  }, [homePhotos, selectedImageIndex, styles, t, isScreenLG, openModal]);

  const controlerLabel = useMemo(
    () =>
      t("objectPreview.carousel.controllerLabel", {
        values: {
          current: selectedImageIndex + 1,
          total: homePhotos?.length,
        },
      }),
    [t, selectedImageIndex, homePhotos]
  );

  const swipeListener = useSwipeListener({
    onSwipeLeft: handleNextPicture,
    onSwipeRight: handlePreviousPicture,
  });

  return (
    <div
      className={styles.carouselContent}
      data-cy="Carousel"
      onClick={e => e.stopPropagation()}
      {...swipeListener}
    >
      {currentPhoto}
      <div className={styles.carouselControllers}>
        {homePhotos && homePhotos.length > 1 && (
          <ObjectCarouselControllers
            label={controlerLabel}
            prevAction={handlePreviousPicture}
            nextAction={handleNextPicture}
            pillTheme={PillThemes.Primary}
          />
        )}
      </div>
    </div>
  );
};

export { ObjectCarousel };
