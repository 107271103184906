import React, { FC, useMemo } from "react";
import { useTheme } from "injectors/theme";
import { FormElementTranslation } from "@kolibrisoftware/customerportal-api";
import { useTranslation } from "@kolibrisoftware/hooks-util";
import { useStyles } from "./styles";
import { ListOfItemsUseCases } from "modules/list-of-items/enums/list-of-items-use-cases";

type Props = {
  sectionTranslations: FormElementTranslation | null | undefined;
  useCase: ListOfItemsUseCases;
};

const FormSectionHeader: FC<Props> = ({ sectionTranslations, useCase }) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const t = useTranslation();

  const labelsTranslations = useMemo(() => {
    switch (useCase) {
      case ListOfItemsUseCases.Contract:
        return t("listOfItems.contractQuestionOptions");
      case ListOfItemsUseCases.Regular:
        return t("listOfItems.regularQuestionOptions");
      default:
        return null;
    }
  }, [t, useCase]);

  return (
    <div className={styles.formSectionHeaderWrapper}>
      {sectionTranslations?.text && (
        <>
          <hr className={styles.divider} />
          {!!sectionTranslations.title && (
            <h2 className={styles.formSectionHeaderTitle}>
              {sectionTranslations?.title}
            </h2>
          )}
          <div className={styles.formSectionHeaderDescription}>
            {sectionTranslations.text}
          </div>
        </>
      )}
      <div className={styles.formSectionHeaderContainer}>
        <h2 className={styles.formSectionHeaderTitle}>
          {!sectionTranslations?.text && sectionTranslations?.title}
        </h2>
        <div className={styles.formSectionHeaderOptions}>
          {labelsTranslations?.split(" | ").map((item, index) => (
            <span className={styles.formSectionHeaderOption} key={index}>
              {item}
            </span>
          ))}
        </div>
      </div>
    </div>
  );
};

export { FormSectionHeader };
