import { FC, useMemo, useState, useRef, useEffect, useCallback } from "react";
import { useTheme } from "injectors/theme";
import { useTranslation } from "@kolibrisoftware/hooks-util";
import { useStyles } from "./styles";
import { ContentSection, Button } from "@kolibrisoftware/customerportal-ui";
import { useSelector } from "store/helpers";
import { faMinus, faPlus } from "@fortawesome/pro-regular-svg-icons";
import classNames from "classnames";
import { ObjectTabTargets } from "modules/object-preview/constants/object-tabs";

export const DESCRIPTION_MAX_HEIGHT = 150;
type Props = {};

const ObjectDescriptionSection: FC<Props> = props => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const t = useTranslation();
  const contentRef = useRef<HTMLDivElement | null>(null);
  const [isCollapsed, setIsCollapsed] = useState(true);
  const [showCollapsibleAction, setShowCollapsibleAction] = useState(false);
  const objectDescription = useSelector(
    state => state.objectPreview.assignmentPreview.advertisementText
  );

  const translations = useMemo(
    () => ({
      sectionTitle: t("objectPreview.description.title"),
    }),
    [t]
  );

  // It's an useEffect controlling state because
  // useMemo doesn't register changes to refs
  useEffect(() => {
    const contentHeight = contentRef.current?.getBoundingClientRect().height;
    if (contentHeight && contentHeight > DESCRIPTION_MAX_HEIGHT) {
      setShowCollapsibleAction(true);
      return;
    }
    setShowCollapsibleAction(false);
  }, [contentRef]);

  const toggleSection = useCallback(() => {
    setIsCollapsed(prevState => !prevState);
  }, []);

  return (
    <div
      className={styles.descriptionSectionWrapper}
      id={ObjectTabTargets.Description}
      data-cy="ObjectDescription"
    >
      {!!objectDescription && (
        <ContentSection
          title={translations.sectionTitle}
          style={{ marginBottom: "unset" }}
        >
          <div
            className={classNames(styles.descriptionContentWrapper, {
              [styles.collapsedDescription]: isCollapsed,
            })}
          >
            <div className={styles.descriptionContent} ref={contentRef}>
              {objectDescription?.map((description, index) => {
                return (
                  <div key={index} className={styles.descriptionRow}>
                    {description.text}
                  </div>
                );
              })}
            </div>
          </div>

          {showCollapsibleAction && (
            <div className={styles.collapsibleActionWrapper}>
              {isCollapsed && (
                <div className={styles.collapsibleActionGradient} />
              )}
              <Button
                data-cy="ObjectDescriptionCollapsible"
                onClick={toggleSection}
                icon={isCollapsed ? faPlus : faMinus}
                label={
                  isCollapsed
                    ? t("objectPreview.description.expandSection")
                    : t("objectPreview.description.collapseSection")
                }
              />
            </div>
          )}
        </ContentSection>
      )}
    </div>
  );
};

export { ObjectDescriptionSection };
