import { FormElementType, FormPart } from "@kolibrisoftware/customerportal-api";
import { useTheme } from "injectors/theme";
import useElementData from "modules/questionnaire/hooks/useElementData";

import { FC, useMemo } from "react";
import useElementTranslations from "hooks/useElementTranslations";
import SidebarElement from "../../elements/sidebar-element";
import { useStyles } from "./styles";

type Props = { sectionStructure: FormPart; index: number };

const SidebarSectionGroup: FC<Props> = ({ sectionStructure, index }) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const sectionDetails = useElementData(sectionStructure.id);
  const sectionTranslations = useElementTranslations(sectionDetails);
  const sectionElements = useMemo(() => {
    return (
      sectionStructure.elements?.filter(
        element => element.type === FormElementType.Section
      ) || []
    );
  }, [sectionStructure]);

  const showDivider = useMemo(
    () => !sectionTranslations?.title && index > 0,
    [index, sectionTranslations]
  );

  const indexes = useMemo(() => {
    let counter = 0;
    return sectionElements.map(child => {
      if (child.useIndex) {
        const index = counter;
        counter++;
        return index;
      }

      return undefined;
    });
  }, [sectionElements]);

  return (
    <div className={styles.sidebarSectionGroupContainer}>
      <h4 className={styles.sidebarSectionGroupTitle}>
        {!showDivider && sectionTranslations?.title}
        {showDivider && <hr className={styles.sectionGroupDivider} />}
      </h4>
      <div className={styles.sidebarSectionGroupElements}>
        {sectionElements.map((section, index) => (
          <SidebarElement
            sectionStructure={section}
            index={indexes[index]}
            key={section.id}
          />
        ))}
      </div>
    </div>
  );
};

export { SidebarSectionGroup };
