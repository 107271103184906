import React, { FC } from "react";
import { useTheme } from "injectors/theme";
import { useTranslation } from "@kolibrisoftware/hooks-util";
import { useStyles } from "./styles";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  IconDefinition,
  faFileSlash,
} from "@fortawesome/pro-regular-svg-icons";

export type NoFilesAvailableProps = {
  translationKey?: string;
  icon?: IconDefinition;
};

const NoFilesAvailable: FC<NoFilesAvailableProps> = ({
  translationKey = "common.noFilesAvailable",
  icon = faFileSlash,
}) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const t = useTranslation();

  return (
    <div data-cy="NoFilesAvailable" className={styles.noFilesAvailableWrapper}>
      <FontAwesomeIcon icon={icon} fontSize={80} />
      <div>{t(translationKey)}</div>
    </div>
  );
};

export { NoFilesAvailable };
