import {
  faArrowDown,
  faArrowUp,
  faInfoCircle,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useMedia, useTranslation } from "@kolibrisoftware/hooks-util";
import { useTheme } from "injectors/theme";
import { useCallback, useEffect, useRef } from "react";
import { useStyles } from "./styles";
import classNames from "classnames";

type Props = {
  modifiedAnswerIds: string[];
};

export const AnswerNav = ({ modifiedAnswerIds }: Props) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const counter = useRef(-1);
  const modifedAnswerElements = useRef<NodeListOf<HTMLElement> | null>(null);
  const t = useTranslation();

  // Using a lower breakpoint than most other places because it looks better
  const isScreenUnder600 = useMedia("(max-width: 600px)");

  useEffect(() => {
    modifedAnswerElements.current = document.querySelectorAll(
      "[data-modified='true']"
    );
  }, [modifiedAnswerIds]);

  const handleNavigate = useCallback(
    (direction: "up" | "down") => {
      const nextIndex =
        direction === "down" ? counter.current + 1 : counter.current - 1;

      // If next index is greater than the length of the modified answer ids, loop back to the start
      // If next index is less than 0, loop back to the end
      const indexToScrollTo =
        nextIndex >= modifiedAnswerIds.length
          ? 0
          : nextIndex < 0
          ? (modifedAnswerElements?.current || []).length - 1
          : nextIndex;

      counter.current = indexToScrollTo;

      const element = modifedAnswerElements?.current?.[indexToScrollTo];
      if (element) {
        const spans = element.querySelectorAll("span");
        spans.forEach(span => {
          if (span.classList.toString().includes("postIt")) {
            span.classList.add("blink");
            setTimeout(() => {
              span.classList.remove("blink");
            }, 500);
          }
        });

        element.scrollIntoView({ behavior: "smooth", block: "center" });
      }
    },
    [modifedAnswerElements, modifiedAnswerIds, counter]
  );

  return (
    <div className={styles.answerNav}>
      <span className={styles.body}>
        <FontAwesomeIcon icon={faInfoCircle} />
        {t("documentReview.answerNav", {
          values: {
            amount: modifiedAnswerIds.length,
          },
        })}
      </span>

      <div
        className={classNames(styles.buttonWrapper, {
          [styles.buttonWrapperMobile]: isScreenUnder600,
        })}
      >
        <button onClick={() => handleNavigate("up")} className={styles.button}>
          <FontAwesomeIcon icon={faArrowUp} />
        </button>

        <button
          onClick={() => handleNavigate("down")}
          className={styles.button}
        >
          <FontAwesomeIcon icon={faArrowDown} />
        </button>
      </div>
    </div>
  );
};
