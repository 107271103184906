import Color from "color";
import { ThemeInterface } from "injectors/theme";
import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  formSectionWrapper: {},
  positionLabel: {
    fontSize: 13,
    color: Color().hex("#999999").toString(),
    fontWeight: 500,
  },
  sectionTitle: {
    fontSize: 17,
    fontWeight: 500,
    marginTop: 10,
    "& + $sectionBody": {
      marginTop: 10,
    },
  },
  sectionBody: {
    whiteSpace: "pre-line",
    lineHeight: 1.5,
    color: Color.rgb(150, 150, 150).hex(),
  },
  sectionChildrenWrapper: {
    marginTop: 10,
  },

  scrollerAnchor: {
    position: "absolute",
    top: -30,
  },
}));

export { useStyles };
