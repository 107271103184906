import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles((theme: ThemeInterface) => ({
  answerNav: {
    display: "flex",
    alignItems: "stretch",
    backgroundColor: theme.colors.warning.lighten(1.65).toString(),
    borderRadius: 5,
    color: theme.colors.warning.toString(),
    border: "1px solid currentColor",
    boxShadow: `0 4px 8px ${theme.colors.offBlack.fade(0.8).toString()}`,
    width: "min(600px, calc(100vw - 20px))",
    zIndex: 4000,
    fontSize: 13,
  },
  body: {
    display: "flex",
    flex: 1,
    alignItems: "center",
    padding: "6px 12px",
    gap: 10,
  },
  buttonWrapper: {
    display: "flex",
    alignItems: "stretch",
  },
  buttonWrapperMobile: {
    flexDirection: "column",
    "& $button:not(:last-of-type)": {
      borderBottom: `1px solid ${theme.colors.warning.toString()}`,
    },
  },
  button: {
    background: "none",
    border: "none",
    flexGrow: 1,
    padding: "6px 12px",
    color: "inherit",
    borderLeft: `1px solid ${theme.colors.warning.toString()}`,
    cursor: "pointer",
    "&:disabled": {
      cursor: "not-allowed",
      color: theme.colors.warning.lighten(1).toString(),
    },
  },
}));
