import { ParsedRow } from "helpers/template-builder/types";
import { useTheme } from "injectors/theme";
import { FC } from "react";
import { useStyles } from "./styles";
import ObjectDetailsRow from "../object-details-row";

type Props = {
  label: string;
  rows: ParsedRow[];
};

const ObjectDetailsCategory: FC<Props> = ({ label, rows }) => {
  const theme = useTheme();
  const styles = useStyles({ theme });

  return (
    <div className={styles.detailsCategoryWrapper}>
      <h3 className={styles.detailsCategoryTitle}>{label}</h3>
      <div className={styles.detailsCategoryRowsWrapper}>
        {rows.map((rowData, index) => (
          <ObjectDetailsRow rowData={rowData} key={index} />
        ))}
      </div>
    </div>
  );
};

export { ObjectDetailsCategory };
