import { ThemeInterface } from "injectors/theme";
import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  documentTitle: {
    fontSize: 22,
    fontWeight: 400,
    marginBottom: 15,
  },
  documentBody: {
    lineHeight: 1.5,

    "& h3": {
      fontWeight: 500,
      fontSize: 16,

      "&:not(:last-child)": {
        margin: "0 0 0.5em",
      },
    },
  },
  questionnaireRootWrapper: {
    height: "100%",
    display: "flex",
    gap: 70,
    justifyContent: "center",
    width: "100%",
  },

  questionaireWrapper: responsiveStyles({
    height: "100%",
    flex: 1,
    display: "flex",
    justifyContent: "center",
    overflowY: "scroll",
  })
    .add({ upperBound: 1350, lowerBound: 1200 }, { justifyContent: "flex-end" })
    .getStyle(),
  questionaireContainer: responsiveStyles({
    maxWidth: 900,
  })
    .add({ upperBound: 1550 }, { marginLeft: 150, flex: "unset" })
    .add({ upperBound: 1200 }, { marginLeft: "unset" })
    .m({ maxWidth: "unset", width: "100%" }, { propagate: true })
    .getStyle(),
  sideBarWrapper: responsiveStyles({
    position: "fixed",
    top: 111,
    left: "10vw",
    height: "calc(100vh - 111px)",
    marginTop: 15,
    maxWidth: 250,
    overflowX: "hidden",
    overflowY: "auto",
    scrollBehavior: "smooth",
    msOverflowStyle: "none",
    scrollbarWidth: "none",
    "&::-webkit-scrollbar": {
      display: "none",
    },
  })
    .add({ upperBound: 1580 }, { left: "5vw" })
    .getStyle(),
  mobileSidebarWrapper: {
    position: "fixed",
    top: 111,
    left: 0,
    zIndex: 1000,
  },

  fauxSide: responsiveStyles({
    flex: 1,
    maxWidth: 250,
  })
    .add({ upperBound: 1450 }, { maxWidth: 150 })
    .add({ upperBound: 1300 }, { display: "none" })
    .getStyle(),
  mobileTopSpacer: {
    height: 20,
  },
  hints: responsiveStyles({
    position: "fixed",
    display: "flex",
    flexDirection: "column",
    alignItems: "center",
    bottom: 20,
    left: 0,
    width: "100vw",
    gap: 8,
  })
    .s({ bottom: 96 })
    .getStyle(),
}));

export { useStyles };
