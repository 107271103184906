import {
  AssignmentRelationStatus,
  Client as CustomerPortalClient,
} from "@kolibrisoftware/customerportal-api";
import { createThunk } from "store/helpers";

export type GetAssignmentDataPayload = {
  realEstateAgencyId: string;
  assignmentId: string;
};
const getAssignmentData = createThunk(
  "api/assignment/getAssignmentData",
  async ({ settings, http, handleError }, args: GetAssignmentDataPayload) => {
    const { assignmentId, realEstateAgencyId } = args;

    try {
      if (!assignmentId || !realEstateAgencyId) {
        throw new Error("Missing assignment or company id");
      }
      const client = new CustomerPortalClient(
        settings?.apiUrlCustomerPortal,
        http
      );

      const currentAssignment = await client
        .getAssignment(realEstateAgencyId, assignmentId)
        .then(data => data.assignment);

      if (!currentAssignment) {
        throw new Error("404 - Assignment not found");
      }
      return currentAssignment;
    } catch (error: any) {
      handleError(error);
    }
  }
);

type GetRelationsPayload = { realEstateAgencyId: string; assignmentId: string };
const searchAssignmentRelations = createThunk(
  "api/assignment/searchAssignmentRelations",
  async ({ settings, http, handleError }, args: GetRelationsPayload) => {
    try {
      const { realEstateAgencyId, assignmentId } = args;
      const client = new CustomerPortalClient(
        settings?.apiUrlCustomerPortal,
        http
      );
      const result = await client
        .searchAssignmentRelations(realEstateAgencyId, assignmentId, {
          skip: 0,
          take: 100,
          statuses: [
            AssignmentRelationStatus.Granted,
            AssignmentRelationStatus.Invited,
            AssignmentRelationStatus.PendingValidation,
          ],
        })
        .then(response => response.result);

      return result;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
);

type GetAssignmentEmployeesPayload = {
  realEstateAgencyId: string;
};
const searchAssignmentEmployees = createThunk(
  "api/assignment/searchAssignmentEmployees",
  async (
    { settings, http, handleError },
    args: GetAssignmentEmployeesPayload
  ) => {
    try {
      const { realEstateAgencyId } = args;
      const client = new CustomerPortalClient(
        settings?.apiUrlCustomerPortal,
        http
      );
      const result = await client
        .searchEmployee(realEstateAgencyId, { skip: 0, take: 100 })
        .then(response => response.result);

      return result;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
);

const getAssignmentPreview = createThunk(
  "api/assignment/getAssignmentPreview",
  async ({ settings, http, handleError, getState }) => {
    try {
      const state = getState();
      const realEstateAgencyId =
        state.main.companyData?.realEstateAgencyId || "";
      const assignmentId = state.dossier.assignmentDetails.id || "";

      const client = new CustomerPortalClient(
        settings?.apiUrlCustomerPortal,
        http
      );

      const result = await client.getAssignmentPreview(
        realEstateAgencyId,
        assignmentId
      );

      return result;
    } catch (error) {
      handleError(error);
      throw error;
    }
  }
);

export const thunks = {
  getAssignmentData,
  searchAssignmentRelations,
  getAssignmentPreview,
  searchAssignmentEmployees,
};
