import { FormElementType, FormPart } from "@kolibrisoftware/customerportal-api";
import SectionComponent from "modules/list-of-items/components/form-section";
import { FC, useMemo } from "react";
import { ContentSection } from "@kolibrisoftware/customerportal-ui";

type Props = {
  sectionGroupStructure: FormPart;
};

const FormSectionGroup: FC<Props> = ({ sectionGroupStructure }) => {
  const extractedSections = useMemo(
    () =>
      sectionGroupStructure.elements?.filter(
        element => element.type === FormElementType.Section
      ) || [],
    [sectionGroupStructure]
  );

  return (
    <ContentSection>
      {extractedSections.map(section => (
        <SectionComponent key={section.id} sectionStructure={section} />
      ))}
    </ContentSection>
  );
};

export { FormSectionGroup };
