import { useDefaultMediaQueries } from "@kolibrisoftware/hooks-util";
import classNames from "classnames";
import { useTheme } from "injectors/theme";
import { FC, useState } from "react";
import {
  NoFilesAvailable,
  NoFilesAvailableProps,
} from "shared/ui/no-files-available/no-files-available";
import { useStyles } from "./styles";

export type PhotoGalleryImage = {
  id: string;
  thumbnail?: string | null;
  big: string;
};
type Props = {
  images: PhotoGalleryImage[];
  noFilesAvailableProps?: NoFilesAvailableProps;
} & React.DetailedHTMLProps<
  React.HTMLAttributes<HTMLDivElement>,
  HTMLDivElement
>;

const PhotoGalleryWithSidebar: FC<Props> = ({
  images,
  noFilesAvailableProps,
  ...rest
}) => {
  const theme = useTheme();
  const styles = useStyles({ theme });

  const [selectedImage, setSelectedImage] = useState<
    PhotoGalleryImage | null | undefined
  >(images?.[0]);
  const { isScreenLG } = useDefaultMediaQueries();

  return (
    <>
      {(!images || !images.length) && (
        <NoFilesAvailable {...noFilesAvailableProps} />
      )}

      {images && images.length && (
        <div className={styles.photoGalleryWithSidebarWrapper} {...rest}>
          {!isScreenLG && (
            <>
              {images.map((image, index) => {
                const imgSrc = image.big || image.thumbnail;
                if (imgSrc) {
                  return (
                    <img
                      key={index}
                      src={imgSrc}
                      className={styles.mobileImage}
                      alt="gallery-item"
                    />
                  );
                }
                return null;
              })}
            </>
          )}
          {isScreenLG && (
            <>
              <div className={styles.thumbnailsContainer}>
                {images.map((image, index) => (
                  <button
                    key={index}
                    onClick={() => setSelectedImage(image)}
                    className={classNames(styles.thumbnailWrapper, {
                      [styles.thumbnailActive]: image.id === selectedImage?.id,
                    })}
                  >
                    <img
                      className={styles.thumbnailImage}
                      src={image.thumbnail || image.big || ""}
                      alt="groundplan-thumbnail"
                    />
                  </button>
                ))}
              </div>

              <div className={styles.galleryPreviewContainer}>
                <img
                  src={selectedImage?.big || ""}
                  alt="groundplan-preview"
                  className={styles.galleryPreviewImage}
                />
              </div>
            </>
          )}
        </div>
      )}
    </>
  );
};

export { PhotoGalleryWithSidebar };
