import { Culture } from "@kolibrisoftware/customerportal-api";

const createGenericSearchAll = (
  companyId: string,
  formId: string,
  take: number
) => {
  const genericSearchAll = async <Result = unknown, ElementType = unknown>(
    totalResults: number,
    clientCallback: (...args: any[]) => Promise<Result>,
    culture?: Culture
  ) =>
    await searchAll<Result, ElementType>(
      companyId,
      formId,
      take,
      totalResults,
      clientCallback,
      culture
    );
  return genericSearchAll;
};

/**
 *
 * @param clientMethod - !IMPORTANT! when providing a clientMethod from a class,
 * bind the client class to the method provided, otherwise it will break
 * @returns ElementType[]
 */
const searchAll = async <Result = unknown, ElementType = unknown>(
  companyId: string,
  formId: string,
  take: number,
  totalResults: number,
  clientMethod: (...args: any[]) => Promise<Result>,
  culture?: Culture
) => {
  const searchCallback = createFormSearchCallback<Result>(
    companyId,
    formId,
    clientMethod,
    culture
  );
  return await fetchAllElements<Result, ElementType>(
    totalResults,
    take,
    searchCallback
  );
};

/**
 * Creates a callback of clientMethod with companyId and formId provided
 *
 * @param companyId
 * @param formId
 * @param clientMethod
 */
const createFormSearchCallback = <Result = unknown>(
  companyId: string,
  formId: string,
  clientMethod: (...args: any[]) => Promise<Result>,
  culture?: Culture
): ((skip: number, take: number) => Promise<Result>) => {
  const promiseCallback = async (skip: number, take: number) => {
    try {
      return await clientMethod(companyId, formId, { skip, take, culture });
    } catch (error) {
      throw error;
    }
  };
  return promiseCallback;
};

const createDocumentsSearchCallback = <Result = unknown>(
  companyId: string,
  dossierId: string,
  clientMethod: (...args: any[]) => Promise<Result>
): ((skip: number, take: number) => Promise<Result>) => {
  const promiseCallback = async (
    skip: number,
    take: number,
    culture?: Culture
  ) => {
    try {
      return await clientMethod(companyId, dossierId, { skip, take, culture });
    } catch (error) {
      throw error;
    }
  };
  return promiseCallback;
};

/**
 * Calculates the number of times clientPromise needs to resolve
 * in order to retrieve totalResults.
 * It then builds and resolves a clientPromise array
 *
 * The default behavior is to skip the first [take] results and fetch the rest [total - take]
 * we can override this by providing a starting index (0 to fetch all)
 *
 * @param totalResults - the number of total results
 * @param take - how many results to request per promise
 * @param clientPromise - the promise that will be run
 * @param startingIndex - Default at 1
 * @returns ElementType[]
 */
const fetchAllElements = async <Result = unknown, ElementType = unknown>(
  totalResults: number,
  take: number,
  clientPromise: (skip: number, take: number) => Promise<Result>,
  startingIndex = 1
) => {
  const diff = totalResults - take;
  const count = Math.ceil(diff / take);
  const promises: Promise<ElementType[]>[] = [];

  for (let i = startingIndex; i <= count; i++) {
    promises.push(
      clientPromise(i * take, take).then(
        (response: any) => response.result || []
      )
    );
  }
  try {
    const results = await Promise.all(promises);
    const items = results.reduce((state, results) => {
      state = [...state, ...results];
      return state;
    }, [] as ElementType[]);
    return items;
  } catch (error: any) {
    throw new Error(error);
  }
};

export {
  searchAll,
  fetchAllElements,
  createGenericSearchAll,
  createFormSearchCallback,
  createDocumentsSearchCallback,
};
