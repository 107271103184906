import { FormatXMLElementFn } from "intl-messageformat";
import { PrimitiveType } from "react-intl";

export type Option = {
  validatorType: ValidatorType;
  simple?: {
    translationKey: string;
    translationValues?: Record<string, any | undefined>;
    hasNoValues?: boolean;
    valuesParser?: any;
  };
  complex?: {
    value: any | undefined;
    parseValueType: ParseComplexType;
    renderType?: RenderType;
    isArray?: boolean;
  };
};

export enum ValidatorType {
  Complex = "Complex",
  Simple = "Simple",
}

export enum ValuesParser {
  MasterTable = "Mastertable",
}
export enum ParseComplexType {
  None = "None",
  MasterTable = "MasterTable",
  TranslationWithMastertable = "TranslationWithMasterTable",
}

export enum RenderType {
  PillsArray = "PillsArray",
  EnergyLabel = "EnergyLabel",
}

export type Row = {
  label: string;
  options: Option[];
};

export type ValidRow = {
  label: string;
  option: Option;
};
export type Category = {
  label: string;
  disabled?: boolean;
  rows: Row[];
};

export type RawTemplate = Category[];

export type ValidatedTemplate = {
  [key: string]: ValidRow[];
};

export type ParsedOption = {
  value: any;
  renderType?: RenderType;
  isArray?: boolean;
};
export type ParsedRow = {
  label: string;
  option: ParsedOption;
};

export type IntlOptions = {
  defaultMessage?: string;
  description?: string;
  values?: Record<string, PrimitiveType | FormatXMLElementFn<string, string>>;
};
