import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  container: {},
  noDocumentsIcon: {
    fontSize: 60,
    margin: "20px 0",
    color: theme.colors.lightGray.toString(),
  },
  noDocumentsContainer: {
    textAlign: "center",
  },
  noDocumentsText: {
    color: theme.colors.offBlack.toString(),
    fontWeight: 400,
  },
}));

export { useStyles };
