import {
  faFileSlash,
  IconDefinition,
} from "@fortawesome/pro-regular-svg-icons";
import { faBadgeCheck, faCheckCircle } from "@fortawesome/pro-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  ChecklistItemStatusType,
  DocumentStatus,
} from "@kolibrisoftware/customerportal-api";
import { useTranslation } from "@kolibrisoftware/hooks-util";
import classNames from "classnames";
import { useTheme } from "injectors/theme";
import { intl } from "injectors/translations/helpers/intl-context";
import React, { FC, useCallback, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { useStyles } from "./styles";

type Props = {
  title: string;
  activityActions?: React.ReactNode;
  id?: string;
  icon?: IconDefinition | null;
  completedOn?: Date | undefined;
  description?: string;
  status?: ChecklistItemStatusType | DocumentStatus;
  "data-cy"?: string;
};

const ActivityItem: FC<Props> = ({
  title,
  activityActions,
  id,
  icon,
  completedOn,
  description,
  status,
  "data-cy": CypressAccesor,
}) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const t = useTranslation();
  const [searchParams, setSearchParams] = useSearchParams();
  const translations = useMemo(
    () => ({
      refused: t("documents.documentStatus.refused"),
      inReview: t("checklist.checklistItem.status.inReview"),
      accepted: t("checklist.checklistItem.status.accepted", {
        values: {
          date: intl.formatDate(completedOn),
        },
      }),
    }),
    [t, completedOn]
  );

  const isHighlighted = useMemo(() => {
    const checklistItemId = searchParams.get("checklistItemId");
    return checklistItemId === id;
  }, [searchParams, id]);

  const itemStatusIcon = useMemo(() => {
    let icon = null;
    let color = theme.colors?.success?.toString();
    let toolTipText = "";
    switch (status) {
      case ChecklistItemStatusType.Closed:
      case DocumentStatus.Accepted: {
        toolTipText = translations.accepted;
        icon = faCheckCircle;
        break;
      }
      case ChecklistItemStatusType.Review:
      case DocumentStatus.Uploaded: {
        toolTipText = translations.inReview;
        color = theme.colors?.warning?.lighten(0.2).toString();
        icon = faBadgeCheck;
        break;
      }
      case DocumentStatus.Refused: {
        color = theme.colors?.danger.lighten(0.2).toString();
        icon = faFileSlash;
        toolTipText = translations.refused;
        break;
      }
      default: {
        break;
      }
    }

    return (
      <>
        {icon && (
          <div className={styles.statusIconWrapper}>
            <span
              className={styles.statusIconTooltip}
              dangerouslySetInnerHTML={{ __html: toolTipText }}
            ></span>
            <FontAwesomeIcon
              icon={icon}
              style={{ color: color }}
              fontSize={15}
            />
          </div>
        )}
      </>
    );
  }, [status, theme.colors, styles, translations]);

  return (
    <div
      className={classNames(styles.activityWrapper, {
        [styles.highlightedItem]: isHighlighted,
      })}
      onClick={() => {
        isHighlighted && setSearchParams({});
      }}
      data-cy={CypressAccesor}
    >
      {icon && (
        <div className={styles.activityIconWrapper}>
          {itemStatusIcon}
          <FontAwesomeIcon icon={icon} className={styles.activityIcon} />
        </div>
      )}
      <div className={styles.activityDetails}>
        <h3 className={styles.activityDetailsTitle}>{title}</h3>
        {description && (
          <p className={styles.activityDetailsSubtitle}>{description}</p>
        )}
      </div>
      <div className={styles.activityAction}>{activityActions}</div>
    </div>
  );
};

export { ActivityItem };
