/*
  !!!! DISCLAIMER !!!!

  This slice actually stores the DOSSIER ITEMS LIST!
  It will be refactored in the future.
  Because in the initial API development, the original controller was named "AssignmentRelations",
  the types, enums and client methods have the same name. 
  Since this was uncovered when the live version was up, the name change would introduce breaking changes.
  As of today (06-02-2023) it is a known issue and will be solved in the future.


*/
import {
  AssignmentRelationSearchRequest,
  AssignmentRelationStatus,
  AssignmentRelationType,
  Client,
} from "@kolibrisoftware/customerportal-api";
import { createThunk } from "../helpers";
import { actions as toastActions } from "store/toasts";
import { actions as objectListActions } from "./index";
import { actions as loadingOverlayActions } from "store/loading-overlay";
import { createErrorToast } from "helpers/create-new-toast";

const getAssignments = createThunk(
  "assignment-relations/getAssignments",
  async ({ dispatch, settings, http, userId, getState }) => {
    const requestBody: AssignmentRelationSearchRequest = {
      skip: 0,
      take: 100,
      assignmentRelationTypes: [AssignmentRelationType.Vendor],
    };

    try {
      const state = getState();
      const subjectId = await userId();
      const client = new Client(settings?.apiUrlCustomerPortal, http);
      const realtorDisplayName = state.main.companyData?.name;
      requestBody.subjectIds = [subjectId];

      const result = await client
        .getAssignmentRelationOverview(requestBody)
        .then(data => {
          return data.result;
        });

      if (
        result?.length === 1 &&
        result[0].objectUrl &&
        result[0].realtorDisplayName !== realtorDisplayName &&
        result[0].status === AssignmentRelationStatus.Granted
      ) {
        const item = result[0];
        dispatch(
          loadingOverlayActions.setLoadingData({
            loading: true,
            realtorName: item.realtorDisplayName || "",
            logo: item.realtorLogo || "",
            supportColor: item.supportColor || "#fff",
          })
        );

        setTimeout(() => {
          if (process.env.NODE_ENV === "development") {
            const devUrl = item.objectUrl?.split(".nl/");
            window.location.href += devUrl![1];
            dispatch(loadingOverlayActions.clearLoading());
          } else {
            window.location.href = item.objectUrl || "";
          }
        }, 2500);
      }

      dispatch(objectListActions.setItems(result || []));
    } catch (error: any) {
      const errorMessage = error.message
        ? error.message
        : `Could not retrieve assignments for current user.`;
      const toast = createErrorToast(errorMessage);

      dispatch(toastActions.addToast(toast));
      throw error;
    }
  }
);

const thunks = { getAssignments };

export default thunks;
