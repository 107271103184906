import { useSelector } from "store/helpers";

const useQuestionAnswer = (questionId: string = "") => {
  return useSelector(state =>
    state.questionnaire.answers.find(
      answer => answer.blueprintElementId === questionId
    )
  );
};

export default useQuestionAnswer;
