import { useTranslation } from "@kolibrisoftware/hooks-util";
import usePreviewDetails from "hooks/usePreviewDetails";
import { useTheme } from "injectors/theme";
import { ObjectTabTargets } from "modules/object-preview/constants/object-tabs";
import { FC } from "react";
import { ContentSection } from "@kolibrisoftware/customerportal-ui";
import ObjectDetailsCategory from "./object-details-category";
import { useStyles } from "./styles";

type Props = {};

const ObjectDetailsSection: FC<Props> = props => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const t = useTranslation();
  const mappedPreviewDetails = usePreviewDetails();

  return (
    <>
      {!!mappedPreviewDetails && (
        <div
          className={styles.detailsSectionWrapper}
          id={ObjectTabTargets.Details}
          data-cy="ObjectPreviewDetailSection"
        >
          <ContentSection title={t("objectPreview.details.title")}>
            {Object.entries(mappedPreviewDetails).map(
              ([label, rows], index) => (
                <ObjectDetailsCategory
                  label={label}
                  rows={rows}
                  key={`detail-category-${index}`}
                />
              )
            )}
          </ContentSection>
        </div>
      )}
    </>
  );
};

export { ObjectDetailsSection };
