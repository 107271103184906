import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";
import { DESCRIPTION_MAX_HEIGHT } from "./object-description-section";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  descriptionSectionWrapper: {},
  descriptionContentWrapper: {
    overflow: "hidden",
  },
  collapsedDescription: {
    maxHeight: DESCRIPTION_MAX_HEIGHT,
  },
  collapsibleActionWrapper: {
    height: 40,
    display: "flex",
    alignItems: "flex-end",
    position: "relative",
    color:
      theme.companyTheme?.mainColor?.toString() ||
      theme.colors.primary.toString(),
  },
  collapsibleActionGradient: {
    height: 110,
    width: "100%",
    position: "absolute",
    bottom: 20,
    backgroundImage:
      "linear-gradient(rgb(255 255 255 / 0%) 0%, rgb(255 255 255) 80%)",
  },
  descriptionContent: {},
  descriptionRow: {
    whiteSpace: "pre-wrap",
  },
}));

export { useStyles };
