import { AppStatus } from "enums/app-status";
import { FC, useCallback, useEffect, useMemo, useRef } from "react";
import {
  createBrowserRouter,
  Outlet,
  RouterProvider,
  useLocation,
} from "react-router-dom";
import { httpWebUtil, useDispatch, useSelector } from "store/helpers";
import mainThunks from "store/main/thunks";
import AppRoutes from "./routing";
import CallbackRoute from "modules/auth/components/callback";
import RenewRoute from "modules/auth/components/renew";
import { REDIRECT_LOCALSTORAGE_KEY } from "constants/config";
import storage from "redux-persist/lib/storage/session";
import DefaultLoadingScreen from "shared/ui/default-loading-screen";
import ErrorPage from "shared/components/error-page";
import { ErrorBoundaryComponent } from "helpers/error-boundary";
import { LoadingScreen } from "shared/ui/loading-screen/loading-screen";

// I don't like this, but I can't find how to just make the package use style.css itself
import "@kolibrisoftware/customerportal-ui/styles";

const NonAuthRoute: FC = () => {
  const appStatus = useSelector(state => state.main.appStatus);
  const dispatch = useDispatch();
  const location = useLocation();
  const initialized = useRef(false);

  const initial = useCallback(async () => {
    try {
      if (await httpWebUtil.shouldRedirect()) {
        await storage.setItem(
          REDIRECT_LOCALSTORAGE_KEY,
          location.pathname + window.location.search
        );

        await httpWebUtil.signinRedirect();
        return;
      }

      await dispatch(mainThunks.initial());
      initialized.current = true;
    } catch (error) {
      initialized.current = false;
      throw error;
    }
  }, [location, dispatch]);

  useEffect(() => {
    if (initialized.current) {
      return;
    }
    initial();
  }, [initial]);

  useEffect(() => {
    return () => {
      initialized.current = false;
    };
  }, []);

  const view = useMemo(() => {
    switch (appStatus) {
      case AppStatus.Idle:
      case AppStatus.Loading:
        return <DefaultLoadingScreen size={"full-screen"} />;
      case AppStatus.NoCompanyData:
      case AppStatus.Failed: {
        return <ErrorPage />;
      }
      default: {
        return <Outlet />;
      }
    }
  }, [appStatus]);
  return view;
};

const router = createBrowserRouter([
  {
    path: "/callback",
    element: <CallbackRoute />,
  },
  {
    path: "/renew",
    element: <RenewRoute />,
  },
  {
    path: "*",
    element: <NonAuthRoute />,
    errorElement: <ErrorPage />,
    children: AppRoutes,
  },
]);

const App: FC = () => {
  return (
    <ErrorBoundaryComponent>
      <RouterProvider router={router} fallbackElement={<LoadingScreen />} />
    </ErrorBoundaryComponent>
  );
};

export default App;
