import { useTheme } from "injectors/theme";
import { FC } from "react";
import { useSelector } from "store/helpers";
import { useStyles } from "./styles";
import { ObjectBrochureItem } from "./object-brochure-item/object-brochure-item";
import { NoFilesAvailable } from "shared/ui/no-files-available/no-files-available";

type Props = {};

const ObjectBrochures: FC<Props> = props => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const { brochures } = useSelector(
    state => state.objectPreview.assignmentPreview
  );

  return (
    <>
      {!brochures && (
        <NoFilesAvailable translationKey="objectPreview.brochures.notAvailable" />
      )}
      {brochures && (
        <div
          className={styles.objectBrochuresWrapper}
          data-cy="ObjectPreviewBrochures"
        >
          {brochures?.map((brochure, index) => (
            <ObjectBrochureItem key={index} brochure={brochure} />
          ))}
        </div>
      )}
    </>
  );
};

export { ObjectBrochures };
