import { faCamera } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTheme } from "injectors/theme";
import { PLACEHOLDER_OBJECT_IMAGE } from "assets/placeholder-image";
import { FC, useMemo, useRef } from "react";
import { useStyles } from "./styles";
import { useTranslation } from "@kolibrisoftware/hooks-util";
import classnames from "classnames";

type Props = { isResponsive?: boolean };

const PlaceholderThumbnail: FC<Props> = ({ isResponsive }) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const t = useTranslation();
  const ref = useRef<HTMLDivElement | null>(null);

  return (
    <div
      ref={ref}
      className={classnames(styles.thumbWrapper, {
        [styles.responsiveThumb]: isResponsive,
      })}
      style={{
        backgroundImage: PLACEHOLDER_OBJECT_IMAGE,
      }}
    >
      <FontAwesomeIcon icon={faCamera} className={styles.thumbIcon} />
      <p className={styles.thumbText}>
        {t("previewThumbnail.noPhotoAvailable")}
      </p>
    </div>
  );
};

export { PlaceholderThumbnail };
