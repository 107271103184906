import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

export const useStyles = createUseStyles((theme: ThemeInterface) => {
  const backgroundColor = theme.companyTheme?.mainColor?.toString();
  const color = theme.companyTheme?.mainColor?.isDark() ? "white" : "black";

  return {
    presence: {
      display: "inline-block",
      position: "relative",
      height: 36,
      width: 36,
      marginRight: 10,
      "&:hover $dropDown": {
        display: "block",
      },
    },
    toggle: {
      cursor: "pointer",
      backgroundColor: "transparent",
      border: "none",
    },
    member: {
      display: "flex",
      flexShrink: 0,
      alignItems: "center",
      justifyContent: "center",
      aspectRatio: "1/1",
      borderRadius: "50%",
      height: 36,
      width: 36,
      overflow: "hidden",
      backgroundColor,
      color,
      outline: "2px solid white",
    },
    headsUp: {
      position: "absolute",
      top: 0,
      right: 0,
      transition: "right 0.1s ease-in-out",
    },
    dropDown: {
      display: "none",
      position: "absolute",
      zIndex: 200,
      top: "calc(100% + 10px)",
      width: 300,
      right: 0,
      backgroundColor: "white",
      borderRadius: 5,
      boxShadow: "0 0 5px rgba(0, 0, 0, 0.1)",
      "&:before": {
        content: '""',
        position: "absolute",
        transform: "rotate(45deg)",
        backgroundColor,
        top: -5,
        right: "12.5px",
        width: 15,
        height: 15,
      },
    },
    dropDownHeader: {
      borderRadius: "5px 5px 0 0",
      backgroundColor,
      color,
      textTransform: "uppercase",
      textAlign: "start",
      padding: "8px 12px",
    },
    dropDownList: {
      listStyle: "none",
      "& > li": {
        display: "flex",
        alignItems: "center",
        gap: 12,
        padding: "8px 12px",
      },
    },
    relationName: {
      overflow: "hidden",
      textOverflow: "ellipsis",
      whiteSpace: "nowrap",
    },
  };
});
