import {
  ChecklistItemStatusType,
  FormAnswerEditor,
  FormElement,
  FormElementType,
} from "@kolibrisoftware/customerportal-api";
import {
  ContentSection,
  ContentSectionSize,
  Hint,
} from "@kolibrisoftware/customerportal-ui";
import { useDefaultMediaQueries, useMedia } from "@kolibrisoftware/hooks-util";
import { FormLoadingStatus } from "enums/form-loading-status";
import { extractFormTranslationsFromStructure } from "helpers/form-elements";
import { useInterSectionObserver } from "injectors/intersection-observer/hooks/use-intersection-observer";
import { useTheme } from "injectors/theme";
import FormElementWrapper from "modules/questionnaire/clusters/form-element-wrapper";
import MobileSidebar from "modules/questionnaire/clusters/mobile-sidebar";
import Sidebar from "modules/questionnaire/clusters/sidebar";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { AnswerNav } from "shared/components/answer-nav";
import DocumentBottomHint from "shared/components/document-bottom-hint";
import LoadingScreen from "shared/ui/loading-screen";
import { useDispatch, useSelector } from "store/helpers";
import { actions as questionnaireActions } from "store/questionnaire";
import {
  selectQuestionnaireAnswers,
  selectQuestionnaireFormId,
} from "store/questionnaire/selectors";
import { useStyles } from "./styles";

type Props = {
  renewHint?: boolean;
};

const RootScreen: FC<Props> = ({ renewHint }) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const {
    formStructure,
    populatedElements: formElements,
    formLoadingStatus: loadingStatus,
    formEditableStatus,
    activeSectionId,
  } = useSelector(state => state.questionnaire);
  const formId = useSelector(selectQuestionnaireFormId);
  const questionAnswers = useSelector(selectQuestionnaireAnswers);
  const { isScreenXS, isScreenS, isScreenM } = useDefaultMediaQueries();
  const isScreenUnder1200 = useMedia("(max-width:1200px)");
  const { create, destroy, initialScroll } = useInterSectionObserver();
  const dispatch = useDispatch();
  const scrollerRootRef = useRef<HTMLDivElement | null>(null);
  const [isHintVisible, setIsHintVisible] = useState<boolean>(
    !isScreenXS && !isScreenS
  );

  const modifiedAnswerIds = useMemo(() => {
    return (formStructure?.latestModifiedAnswerIds || []).reduce(
      (state, latestModifiedAnswerId) => {
        const answer = questionAnswers.find(
          questionAnswer => questionAnswer.id === latestModifiedAnswerId
        );

        if (answer && answer.editor === FormAnswerEditor.Owner) {
          state.push(latestModifiedAnswerId);
        }

        return state;
      },
      [] as string[]
    );
  }, [formStructure?.latestModifiedAnswerIds, questionAnswers]);

  const sidebarInitialization = useCallback(() => {
    if (formEditableStatus !== ChecklistItemStatusType.Open) {
      return;
    }
    let storedSectionId = localStorage.getItem(`${formId}`)?.toString();

    if (storedSectionId && loadingStatus === FormLoadingStatus.Success) {
      initialScroll(storedSectionId);
      const currentSelectedSection = formElements.find(
        element => element.id === storedSectionId
      );

      if (currentSelectedSection && activeSectionId !== storedSectionId) {
        dispatch(
          questionnaireActions.setActiveSectionTab(currentSelectedSection)
        );
        dispatch(
          questionnaireActions.setActiveSection({
            formId: formId || " ",
            activeSectionId: storedSectionId,
          })
        );
      }
    }
  }, [
    formElements,
    loadingStatus,
    dispatch,
    activeSectionId,
    initialScroll,
    formEditableStatus,
    formId,
  ]);

  useEffect(() => {
    sidebarInitialization();
  }, [sidebarInitialization]);

  const formTranslations = useMemo(
    () => extractFormTranslationsFromStructure(formStructure),
    [formStructure]
  );

  const formPartsAsElements = useMemo(
    () =>
      formStructure?.parts?.map(part => {
        return {
          id: part.id,
          index: part.index,
          type: FormElementType.Part,
          elements: part.elements,
        } as FormElement;
      }) || [],
    [formStructure]
  );

  useEffect(() => {
    if (renewHint) {
      setIsHintVisible(renewHint);
    }
  }, [renewHint]);

  useEffect(() => {
    if (scrollerRootRef.current) {
      create(scrollerRootRef.current);
    }
    return () => {
      destroy();
    };
  }, [scrollerRootRef, create, destroy]);

  const showDocumentHeader = useMemo(() => {
    return (
      !!formTranslations?.description ||
      !!formTranslations?.tip ||
      !!formTranslations?.title
    );
  }, [formTranslations]);

  const showAnswerNav = useMemo(
    () => modifiedAnswerIds.length > 0,
    [modifiedAnswerIds]
  );

  return (
    <>
      {loadingStatus === FormLoadingStatus.Loading && (
        <LoadingScreen showSide={true} />
      )}
      <div className={styles.questionnaireRootWrapper}>
        {loadingStatus === FormLoadingStatus.Success && (
          <>
            {!isScreenUnder1200 && (
              <div className={styles.sideBarWrapper}>
                <Sidebar />
              </div>
            )}
            {isScreenUnder1200 && !isScreenM && isScreenS && isScreenXS && (
              <div className={styles.mobileSidebarWrapper}>
                <MobileSidebar />
              </div>
            )}
          </>
        )}

        <div className={styles.questionaireWrapper} ref={scrollerRootRef}>
          <div className={styles.questionaireContainer}>
            {(isScreenXS || isScreenS) && (
              <div className={styles.mobileTopSpacer}></div>
            )}
            {showDocumentHeader && (
              <ContentSection size={ContentSectionSize.Full}>
                <h2 className={styles.documentTitle}>
                  {formTranslations?.title}
                </h2>
                {formTranslations?.description && (
                  <div
                    className={styles.documentBody}
                    dangerouslySetInnerHTML={{
                      __html: formTranslations.description,
                    }}
                  />
                )}
                {formTranslations?.tip && (
                  <Hint content={formTranslations?.tip || ""} />
                )}
              </ContentSection>
            )}

            {formPartsAsElements.map(elementStructure => (
              <FormElementWrapper
                elementStructure={elementStructure}
                hierarchyLevel={0}
                key={elementStructure.id}
              />
            ))}
            <div style={{ height: 150 }}></div>
          </div>
        </div>

        <div className={styles.hints}>
          {isHintVisible && (
            <DocumentBottomHint
              status={formEditableStatus}
              removeCallback={() => setIsHintVisible(false)}
            />
          )}

          {showAnswerNav && <AnswerNav modifiedAnswerIds={modifiedAnswerIds} />}
        </div>
      </div>
    </>
  );
};

export { RootScreen };
