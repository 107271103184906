import { ContractListOfGoodAnswer } from "@kolibrisoftware/customerportal-api";
import { useTranslation } from "@kolibrisoftware/hooks-util";
import { useMemo } from "react";
import { RadioGroupItem } from "@kolibrisoftware/customerportal-ui";

const useQuestionContractOptions = () => {
  const t = useTranslation();

  const optionsList: RadioGroupItem<ContractListOfGoodAnswer>[] =
    useMemo(() => {
      return Object.values(ContractListOfGoodAnswer).map(item => {
        const labelValue = t(`listOfItems.contractOption.${item}`);
        return { label: labelValue, value: item };
      });
    }, [t]);

  return optionsList;
};

export default useQuestionContractOptions;
