import {
  AssignmentRelationStatus,
  AssignmentRelationType,
  SingleAssignmentRelation,
} from "@kolibrisoftware/customerportal-api";
import { useTranslation } from "@kolibrisoftware/hooks-util";
import classnames from "classnames";
import { useTheme } from "injectors/theme";
import { FC, useCallback, useMemo } from "react";
import { useNavigate } from "react-router-dom";
import { Pill, PillThemes } from "@kolibrisoftware/customerportal-ui";
import PlaceholderPhoto from "shared/ui/placeholder-thumbnail";
import { useDispatch, useSelector } from "store/helpers";
import { actions as loadingOverlayActions } from "store/loading-overlay";
import useStyles from "./styles";

type Props = {
  assignment: SingleAssignmentRelation;
};

export const AssignmentCard: FC<Props> = ({ assignment }) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const companyData = useSelector(state => state.main.companyData);
  const t = useTranslation();

  const translations = useMemo(
    () => ({
      actionLabel: t("assignmentCard.action"),
      price: assignment.forSale
        ? t("assignmentCard.price.forSale", {
            values: {
              price: assignment.price || 0,
            },
          })
        : t(`assignmentCard.price.forRent.${assignment.rentCondition}`, {
            values: { price: assignment.price || 0 },
          }),
    }),
    [t, assignment]
  );

  const assignmentPillDetails = useMemo(() => {
    let type: PillThemes;
    let label = "";

    switch (assignment.status) {
      case AssignmentRelationStatus.Granted:
        type = PillThemes.Success;
        break;
      case AssignmentRelationStatus.Denied:
        label = "assignmentCard.relationStatus.denied";
        type = PillThemes.Danger;
        break;
      case AssignmentRelationStatus.NotInvited:
        label = "assignmentCard.relationStatus.notInvited";
        type = PillThemes.Danger;
        break;
      case AssignmentRelationStatus.InviteExpired:
        label = "assignmentCard.relationStatus.inviteExpired";
        type = PillThemes.Danger;
        break;
      case AssignmentRelationStatus.Invited:
        label = "assignmentCard.relationStatus.invited";
        type = PillThemes.Info;
        break;
      case AssignmentRelationStatus.PendingValidation:
        label = "assignmentCard.relationStatus.pendingValidation";
        type = PillThemes.Info;
        break;
      default:
        type = PillThemes.Success;
        break;
    }

    if (type === PillThemes.Success) {
      switch (assignment.assignmentRelationType) {
        case AssignmentRelationType.Vendor: {
          label = assignment.forSale
            ? "assignmentCard.relationType.vendor"
            : "assignmentCard.relationType.landLord";
          break;
        }
        case AssignmentRelationType.Potential: {
          label = "assignmentCard.relationType.potential";
          break;
        }
        case AssignmentRelationType.Applicant: {
          label = "assignmentCard.relationType.applicant";
          break;
        }
        default: {
          label = "Undefined";
          break;
        }
      }
    }

    return { type, label };
  }, [assignment]);

  const handleRedirect = useCallback(() => {
    if (assignmentPillDetails.type !== PillThemes.Success) {
      return;
    }

    if (companyData?.name === assignment.realtorDisplayName) {
      const objectUrlList = assignment.objectUrl?.split("/");
      const splitIndex = objectUrlList?.findIndex(item => item === "dossier");
      const navigatePath = objectUrlList?.slice(splitIndex).join("/") || "";
      navigate(navigatePath);
      return;
    }

    dispatch(
      loadingOverlayActions.setLoadingData({
        loading: true,
        realtorName: assignment.realtorDisplayName || "",
        logo: assignment.realtorLogo || "",
        supportColor: assignment.supportColor || "#fff",
      })
    );

    setTimeout(() => {
      if (process.env.NODE_ENV === "development") {
        const objectUrlList = assignment.objectUrl?.split("/");
        const splitIndex = objectUrlList?.findIndex(item => item === "dossier");
        const navigatePath = objectUrlList?.slice(splitIndex).join("/") || "";

        navigate(navigatePath);
        dispatch(loadingOverlayActions.clearLoading());
      } else {
        window.location.href = assignment.objectUrl || "";
      }
    }, 2500);
  }, [assignment, companyData, dispatch, navigate, assignmentPillDetails]);

  return (
    <div
      className={styles.previewContainer}
      onClick={handleRedirect}
      data-cy={assignment.assignmentId}
    >
      <div
        className={classnames(styles.thumbWrapper, {
          [styles.unavailable]:
            assignment.status !== AssignmentRelationStatus.Granted,
        })}
      >
        <div className={styles.thumbContainer}>
          {assignment.assignmentPreviewThumbnailUrl && (
            <div
              className={classnames(styles.thumb, {
                [styles.unavailable]:
                  assignment.status !== AssignmentRelationStatus.Granted,
              })}
              style={{
                backgroundImage: `url(${assignment.assignmentPreviewThumbnailUrl.replace(
                  "$s",
                  "$m"
                )})`,
                backgroundSize: "cover",
              }}
            />
          )}

          {!assignment.assignmentPreviewThumbnailUrl && <PlaceholderPhoto />}
        </div>

        {assignment.realtorLogo ? (
          <div className={styles.realtorLogoWrapper}>
            <img
              className={styles.realtorLogo}
              src={"data:image/jpeg;base64," + assignment.realtorLogo}
              alt="logo"
            />
          </div>
        ) : (
          <div className={styles.realtorLogoWrapperDisplayName}>
            <div>{assignment.realtorDisplayName}</div>
          </div>
        )}
      </div>

      <div className={styles.body}>
        <Pill
          label={t(assignmentPillDetails.label)}
          pillTheme={assignmentPillDetails.type}
        />
        <div className={styles.previewTitle}>
          {assignment.assignmentDisplayName}
        </div>
        <div className={styles.previewBody}>
          {assignment.assignmentAddressSpecs}
        </div>
        <div className={styles.previewBody}>{translations.price}</div>
        {assignment.status === AssignmentRelationStatus.Granted && (
          <button className={styles.button}>{translations.actionLabel}</button>
        )}
      </div>
    </div>
  );
};
