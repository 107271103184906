import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  "@keyframes rotation": {
    "0%": { transform: "rotate(0deg)", color: "gray" },
    "50%": { transform: "rotate(180deg) ", color: "green" },
    "100%": { transform: "rotate(360deg)", color: "gray" },
  },
  layoutWrapper: {
    overflowY: "hidden",
  },
  navActionWrapper: {
    flex: 1,
    display: "flex",
    justifyContent: "space-between",
  },
  navAction: {
    padding: 0,
    background: "none",
    border: "none",
    display: "flex",
    alignItems: "center",
    flexDirection: "row",
    gap: 10,
    textDecoration: "none",
    color: theme.companyTheme?.mainColor?.toString() || "#000000aa",
    cursor: "pointer",
    transition: "all 0.1s ease",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    "&:hover": {
      color:
        theme.companyTheme?.mainColor?.lighten(0.1).toString() || "#000000aa",
    },
  },
  navTitle: responsiveStyles({
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
    display: "flex",
    alignItems: "center",
    justifyContent: "space-between",
    gap: 10,
  })
    .s({})
    .getStyle(),
  fauxDix: {
    flex: 1,
    display: "flex",
    justifyContent: "flex-end",
  },
  savingStatus: {
    display: "flex",
    gap: 5,
    fontSize: 13,
    fontWeight: 300,
  },

  loadingStatus: {
    animation: "$rotation 1s linear infinite",
  },
  savingStatusIcon: {
    fontSize: 16,
  },
  savingStatusLabel: {},
  mobileBackActionLayoutWrapper: {
    flex: 1,
    display: "flex",
    flexDirection: "column",
    overflow: "hidden",
    gap: 4,
  },
  titleLabel: {
    textAlign: "end",
    overflow: "hidden",
    textOverflow: "ellipsis",
    flex: 1,
  },
  centerTitleWrapper: {
    overflow: "hidden",
    flex: 1,
    gap: 10,
    display: "flex",
    justifyContent: "center",
  },
}));

export { useStyles };
