import { useTranslation } from "@kolibrisoftware/hooks-util";
import assigmentPreviewDetailsTemplate from "helpers/assignment-preview-details-template";
import TemplateBuilder from "helpers/template-builder";
import { useMemo } from "react";
import { useSelector } from "store/helpers";
import { selectObjectPreviewData } from "store/object-preview/selectors";
import invariant from "invariant";

const usePreviewDetails = () => {
  const previewData = useSelector(selectObjectPreviewData);
  const t = useTranslation();

  const result = useMemo(() => {
    invariant(previewData, "There is no preview data");
    const template = assigmentPreviewDetailsTemplate(previewData);
    const validatedTemplate = TemplateBuilder.validator(template);
    const parsedTemplate = TemplateBuilder.parser(validatedTemplate, t);
    return parsedTemplate;
  }, [previewData, t]);

  return result;
};

export default usePreviewDetails;
