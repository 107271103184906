import { Toast } from "store/toasts";
import { v4 as uuid } from "uuid";
import { faCheck, faInfo, faWarning } from "@fortawesome/pro-regular-svg-icons";

const createErrorToast = (message: string, removeAfter: number = 5 * 1000) => {
  return {
    id: uuid(),
    message: message,
    canManuallyRemove: true,
    removeAfter,
    icon: faWarning,
    theme: "error",
  } as Toast;
};

const createInfoToast = (message: string, removeAfter: number = 5 * 1000) => {
  return {
    id: uuid(),
    message: message,
    canManuallyRemove: true,
    removeAfter: removeAfter,
    icon: faInfo,
    theme: "info",
  } as Toast;
};

const createSuccessToast = (
  message: string,
  removeAfter: number = 5 * 1000
) => {
  return {
    id: uuid(),
    message: message,
    canManuallyRemove: true,
    removeAfter: removeAfter,
    icon: faCheck,
    theme: "success",
  } as Toast;
};

export { createErrorToast, createInfoToast, createSuccessToast };
