import { FC, useMemo } from "react";
import {
  PhotoGalleryImage,
  PhotoGalleryWithSidebar,
} from "shared/components/photo-gallery-with-sidebar/photo-gallery-with-sidebar";
import { useSelector } from "store/helpers";
import { v4 as uuid } from "uuid";

type Props = {};

const ObjectPhotoGallery: FC<Props> = props => {
  const photos = useSelector(
    state => state.objectPreview.assignmentPreview.photos
  );
  const galleryImages: PhotoGalleryImage[] | undefined = useMemo(() => {
    return photos?.map(image => ({
      id: uuid(),
      thumbnail: image.urlPreview,
      big: image.urlBig || "",
    }));
  }, [photos]);

  return (
    <PhotoGalleryWithSidebar
      images={galleryImages || []}
      data-cy="ObjectPhotoGallery"
    />
  );
};

export { ObjectPhotoGallery };
