import { ListOfItemRadioListAnswer } from "@kolibrisoftware/customerportal-api";
import { useTranslation } from "@kolibrisoftware/hooks-util";
import { useMemo } from "react";
import { RadioGroupItem } from "@kolibrisoftware/customerportal-ui";

const useListOfItemsOptions = () => {
  const t = useTranslation();
  const listOfItemsOptionKeys = useMemo(
    () =>
      Object.values(ListOfItemRadioListAnswer).filter(
        value => value !== ListOfItemRadioListAnswer.Undefined
      ),
    []
  );
  const listOfItemsOptions: RadioGroupItem<ListOfItemRadioListAnswer>[] =
    useMemo(() => {
      return listOfItemsOptionKeys.map(item => {
        const labelValue = t(`listOfItemsRegularOption.${item}`);
        return { label: labelValue, value: item };
      });
    }, [t, listOfItemsOptionKeys]);

  return listOfItemsOptions;
};

export default useListOfItemsOptions;
