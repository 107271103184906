import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import Color from "color";
import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  sidebarElementContainer: {
    display: "flex",
    alignItems: "center",
    justifyContent: "flex-start",
    gap: 15,
    textDecoration: "none",
    color: theme.companyTheme?.mainColor?.alpha(0.7).toString(),
    fontSize: 13,
    lineHeight: "24px",
    transition: "all 0.1s ease",
    background: "none",
    border: "none",
    cursor: "pointer",
    position: "relative",
    padding: 0,
    scrollMargin: "100px",
    scrollMarginBottom: "100px",
    "&:hover": {
      color: theme.companyTheme?.mainColor?.alpha(0.9).toString(),
      fontWeight: 600,
    },
  },

  sidebarElementActive: {
    color: theme.companyTheme?.mainColor?.toString(),
    fontWeight: 600,
  },
  sidebarElementTitle: responsiveStyles({
    whiteSpace: "nowrap",
    "&::before": {
      content: "''",
      position: "absolute",
      top: 0,
      right: 0,
      width: 72,
      height: "100%",
      background: `linear-gradient(-90deg, ${Color.rgb(
        245,
        245,
        245
      ).toString()} 0%, ${Color.rgb(245, 245, 245).alpha(0).toString()} 100%)`,
    },
  })
    .m(
      {
        "&::before": {
          background: `linear-gradient(-90deg, ${Color.rgb(
            255,
            255,
            255
          ).toString()} 0%, ${Color.rgb(255, 255, 255)
            .alpha(0)
            .toString()} 100%)`,
        },
      },
      { propagate: true }
    )
    .getStyle(),
  sidebarElementDecorator: {
    backgroundColor: "white",
    fontWeight: "bold",
    fontSize: 11,
    minWidth: 20,
    height: 20,
    borderRadius: "50%",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  },
  alt: {
    backgroundColor: "transparent",
    "&:before": {
      content: `""`,
      backgroundColor: theme.companyTheme?.mainColor?.toString(),
      width: 8,
      height: 8,
      borderRadius: "50%",
    },
  },
  validated: {
    backgroundColor: theme.companyTheme?.mainColor?.toString(),
    color: "white",
  },
}));

export { useStyles };
