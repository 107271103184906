import { errorLogger } from "./error-log";
import React, { Component, ErrorInfo } from "react";
import ErrorPage from "shared/components/error-page";

type Props = { children: React.ReactNode };
type State = {
  hasError: boolean;
};

export class ErrorBoundaryComponent extends Component<Props, State> {
  constructor(props: Props) {
    super(props);

    this.state = {
      hasError: false,
    };
  }

  static getDerivedStateFromError() {
    return { hasError: true };
  }

  componentDidCatch(error: any, errorInfo: ErrorInfo) {
    errorLogger(error, errorInfo);
  }

  render() {
    if (this.state.hasError) {
      return <ErrorPage useCase="ErrorBoundary" />;
    }

    return <>{this.props.children}</>;
  }
}
