import {
  Culture,
  FormElementExtended,
  FormElementTranslationVisibility,
} from "@kolibrisoftware/customerportal-api";
import { useMemo } from "react";

const useElementTranslations = (element: FormElementExtended | undefined) => {
  const result = useMemo(() => {
    if (!element) {
      return null;
    }
    return element.translations?.find(
      item =>
        item.visibility === FormElementTranslationVisibility.Form ||
        FormElementTranslationVisibility.All
    );
  }, [element]);
  return result;
};

export default useElementTranslations;
