import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  container: {},
  booleanRadioLayout: {
    display: "flex !important",
    gap: 10,
    flexWrap: "wrap",
    justifyContent: "flex-end !important",
    "& label": {
      flex: "unset !important",
      maxWidth: "100%",
      "& span": {
        maxWidth: "90%",
        wordBreak: "break-word",
        wordWrap: "break-word",
        whiteSpace: "unset",
      },
    },
  },
  booleanColumnView: {
    flexDirection: "column !important",
    alignItems: "flex-start!important",
  },
  textarea: {
    flex: 1,
    resize: "none",
  },
}));

export { useStyles };
