import { FC, useEffect } from "react";
import HoistIntlContext from "injectors/translations/helpers";
import { CustomFormats, IntlProvider } from "react-intl";
import { useSelector, useDispatch } from "store/helpers";
import { thunks as mainThunks } from "store/main";

export const TranslationsInjector: FC<{ children: React.ReactNode }> = ({
  children,
}) => {
  const messages = useSelector(state => state.main.translations);
  const culture = useSelector(state => state.main.culture);
  const dispatch = useDispatch();
  const formats: CustomFormats = {
    date: {
      def: { day: "2-digit", month: "2-digit", year: "numeric" },
    },
    number: {
      euro: { style: "currency", currency: "EUR" },
      euro_short: {
        style: "currency",
        currency: "EUR",
        minimumFractionDigits: 0,
        maximumFractionDigits: 2,
      },
    },
  };

  useEffect(() => {
    dispatch(mainThunks.loadTranslation(culture));
  }, [dispatch, culture]);

  return (
    <IntlProvider locale={culture} messages={messages} formats={formats}>
      <HoistIntlContext />
      {children}
    </IntlProvider>
  );
};
