import classnames from "classnames";
import { MenuItem } from "constants/menu-items";
import { ROUTER_PATHS } from "constants/routes";
import { useTheme } from "injectors/theme";
import { FC } from "react";
import { matchPath, useLocation, useNavigate } from "react-router-dom";
import { Button } from "@kolibrisoftware/customerportal-ui";
import { useStyles } from "./styles";
import { useTranslation } from "@kolibrisoftware/hooks-util";

type Props = {
  item: MenuItem;
  notification?: number;
  "data-cy"?: string;
};

const SHOW_ICON: string[] = [ROUTER_PATHS.EMPTY];

const SecondaryNavItem: FC<Props> = ({
  item,
  notification,
  "data-cy": CypressAccesor,
}) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const t = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const isActive = matchPath(
    { path: `${ROUTER_PATHS.DOSSIER_ROOT}/${item.path}` },
    location.pathname
  );

  return (
    <div
      className={classnames(styles.desktopNavItem, {
        [styles.desktopNavItem__active]: isActive,
      })}
      data-cy={CypressAccesor}
    >
      {!!notification && (
        <div
          className={classnames(styles.notification, {
            [styles.notification__active]: isActive,
          })}
        >
          <span className={styles.notificationLabel}>{notification}</span>
        </div>
      )}
      <Button
        label={t(item.label)}
        icon={SHOW_ICON.includes(item.path) && isActive ? item.icon : undefined}
        onClick={() => navigate(item.path)}
        style={{ lineHeight: "100%" }}
      />
    </div>
  );
};

export { SecondaryNavItem };
