import { useCallback } from "react";
import { useDispatch } from "store/helpers";
import { actions as toastsActions, Toast } from "store/toasts";

export const useToasts = () => {
  const dispatch = useDispatch();

  const addToastCallback = useCallback(
    (toast: Toast) => {
      dispatch(toastsActions.addToast(toast));
    },
    [dispatch]
  );

  const removeToastCallback = useCallback(
    (toastId: string) => {
      dispatch(toastsActions.removeToast(toastId));
    },
    [dispatch]
  );

  return { addToast: addToastCallback, removeToast: removeToastCallback };
};
