import { PageLoadingStatus } from "enums/page-loading-status";
import { useTheme } from "injectors/theme";
import EmptyScreen from "modules/dossiers/components/empty-screen";
import LoadingOverlay from "modules/dossiers/components/loading-overlay";
import { FC, useCallback, useEffect, useRef } from "react";
import LoadingScreen from "shared/ui/loading-screen";
import { thunks as assignmentsThunks } from "store/assignment-relation";
import { selectSortedAssignments } from "store/assignment-relation/selectors";
import { useDispatch, useSelector } from "store/helpers";
import AssignmentCard from "../../components/assignment-card";
import useStyles from "./styles";

export const OverviewScreen: FC = () => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const dispatch = useDispatch();
  const assignmentsData = useSelector(selectSortedAssignments);
  const isLoading = useSelector(state => state.loadingOverlay.loading);
  const assignmentsLoadingStatus = useSelector(
    state => state.assignmentRelations.assignmentsLoadingStatus
  );
  const companyData = useSelector(state => state.main.companyData);
  const initialized = useRef(false);

  const initial = useCallback(async () => {
    // TODO -> Move this initial to a initial-data-layer
    if (!!initialized.current) return;
    initialized.current = true;
    try {
      await dispatch(assignmentsThunks.getAssignments());
      if (companyData?.name) {
        document.title = companyData.name;
      }
    } catch (error) {
      throw error;
    }
  }, [dispatch, companyData]);

  useEffect(() => {
    initial();
  }, [initial]);

  useEffect(() => {
    return () => {
      initialized.current = false;
    };
  }, []);

  return (
    <div
      className={styles.overviewObjectsContainer}
      data-cy="OverviewObjectsContainer"
    >
      {isLoading && <LoadingOverlay />}
      {(assignmentsLoadingStatus === PageLoadingStatus.Loading ||
        assignmentsLoadingStatus === PageLoadingStatus.Pending) && (
        <LoadingScreen useCase={"root"} />
      )}
      {assignmentsLoadingStatus === PageLoadingStatus.Success &&
        !assignmentsData.length && <EmptyScreen />}
      <div className={styles.grid}>
        {assignmentsData.map(assignment => (
          <AssignmentCard
            assignment={assignment}
            key={assignment.assignmentId}
          />
        ))}
      </div>
    </div>
  );
};
