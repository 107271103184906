import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";
import { selectChecklistItems } from "store/dossier/selectors";

export const selectListOfItemsSlice = (state: RootState) => state.listOfItems;

export const selectListOfItemsForm = createSelector(
  selectListOfItemsSlice,
  listOfItems => listOfItems.formStructure
);

export const selectListOfItemsLatestModifiedAnswerIds = createSelector(
  selectListOfItemsForm,
  listOfItems => listOfItems.latestModifiedAnswerIds || []
);

export const selectListOfItemsAnswers = createSelector(
  selectListOfItemsSlice,
  listOfItems => listOfItems.answers
);

export const selectListOfItemsChecklistItem = createSelector(
  selectChecklistItems,
  selectListOfItemsForm,
  (checklistItems, listOfItems) =>
    checklistItems.find(
      checklistItem => checklistItem.formId === listOfItems.id
    )
);

export const selectListOfItemsHasModifiedAnswers = createSelector(
  selectListOfItemsForm,
  listOfItems => !!listOfItems.latestModifiedAnswerIds?.length
);
