import { useSelector } from "store/helpers";

const useElementData = (elementId: string = "") => {
  return useSelector(state =>
    state.listOfItems.populatedElements.find(
      element => element.id === elementId
    )
  );
};

export default useElementData;
