import { ModalContext, ModalType } from "injectors/modal";
import React, { useContext } from "react";

export const useModal = <G extends unknown>() => {
  const { state, cancel, proceed, updateState, setPromiseFn } =
    useContext(ModalContext);

  const open =
    <T extends unknown>(type: ModalType) =>
    (title: string | null = null, body?: React.ReactNode): Promise<T> => {
      const resetState = () => {
        updateState?.({
          visible: false,
          title: null,
          body: null,
          type: null,
        });
        setPromiseFn?.();
      };

      const promise = new Promise<T>((resolve, reject) => {
        updateState?.({
          title,
          body,
          type,
          visible: true,
        });
        setPromiseFn?.(resolve, reject);
      });

      return promise
        .then(response => response)
        .catch(response => response)
        .finally(resetState);
    };

  return {
    ...state,
    cancel,
    proceed,
    confirm: open<boolean>("confirm"),
    alert: open<void>("alert"),
    openModal: open<G | undefined>("modal"),
  };
};
