import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  objectBrochuresWrapper: responsiveStyles({
    display: "grid",
    gridTemplateColumns: "repeat(auto-fit,220px)",
    gridTemplateRows: "repeat(auto-fit, 300px)",
    gap: 10,
    alignItems: "start",
    flex: 1,
  })
    .m(
      {
        gap: 5,
        paddingBottom: 30,
        overflowY: "scroll",
      },
      { propagate: true }
    )
    .xs(
      {
        justifyContent: "center",
        gridTemplateColumns: "repeat(auto-fit,300px)",
        gridTemplateRows: "unset",
      },
      { propagate: true }
    )
    .getStyle(),
}));

export { useStyles };
