import {
  FormAnswerEditor,
  FormElement,
  FormElementQuestionType,
  FormElementType,
} from "@kolibrisoftware/customerportal-api";
import classNames from "classnames";
import { indexToEnumPrefix } from "helpers/form-elements";
import useElementTranslations from "hooks/useElementTranslations";
import { useTheme } from "injectors/theme";
import { isUndefined } from "lodash-es";
import QuestionFormTranslation from "modules/questionnaire/elements/form-element-translation";
import useElementData from "modules/questionnaire/hooks/useElementData";
import { FC, useMemo } from "react";
import { ModifiedLabel } from "shared/components/modified-label";
import { useSelector } from "store/helpers";
import {
  selectQuestionnaireAnswers,
  selectQuestionnaireLatestModifiedAnswerIds,
} from "store/questionnaire/selectors";
import QuestionInput from "../../components/question-input";
import { useStyles } from "./styles";

type Props = {
  questionStructure: FormElement;
  hierarchyLevel: number;
  questionIndex?: number;
  children?: React.ReactNode;
};

const FormQuestion: FC<Props> = ({
  questionStructure,
  hierarchyLevel,
  questionIndex,
  children,
}) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const questionDetails = useElementData(questionStructure.id);
  const translations = useElementTranslations(questionDetails);

  const questionAnswers = useSelector(selectQuestionnaireAnswers);
  const modifiedAnswerIds = useSelector(
    selectQuestionnaireLatestModifiedAnswerIds
  );
  const questionAnswer = questionAnswers.find(
    answer => answer.blueprintElementId === questionDetails?.id
  );

  const isModifiedByOwner = useMemo(() => {
    return (
      modifiedAnswerIds?.includes(questionAnswer?.id || "") &&
      questionAnswer?.editor === FormAnswerEditor.Owner
    );
  }, [modifiedAnswerIds, questionAnswer?.id, questionAnswer?.editor]);

  const isRadioLayout = useMemo(() => {
    switch (questionDetails?.questionType) {
      case FormElementQuestionType.Bool:
      case FormElementQuestionType.InapplicableBool:
      case FormElementQuestionType.KnownBool:
      case FormElementQuestionType.Radio:
      case FormElementQuestionType.UnknownBool: {
        return true;
      }
      default: {
        return false;
      }
    }
  }, [questionDetails]);

  const questionDirectionColumn = useMemo(() => {
    if (
      questionDetails?.questionType === FormElementQuestionType.TextArea ||
      questionDetails?.questionType === FormElementQuestionType.MultiSelect
    ) {
      return true;
    }
  }, [questionDetails]);

  const questionPrefix = useMemo(() => {
    if (questionIndex) {
      return indexToEnumPrefix(
        questionIndex,
        questionStructure.enumerationType
      );
    }
  }, [questionIndex, questionStructure.enumerationType]);

  return (
    <div className={classNames(styles.bottomBorder)}>
      {questionStructure.type !== FormElementType.Section && (
        <>
          <div
            data-modified={isModifiedByOwner}
            className={classNames(styles.questionDetailsWrapper, {
              [styles.booleanWrapper]:
                questionDetails?.questionType === FormElementQuestionType.Bool,
              [styles.questionDetailsColumn]: questionDirectionColumn,
            })}
          >
            <div
              className={classNames(styles.translationWrapper, {
                [styles.boolTranslationWrapper]: isRadioLayout,
              })}
            >
              {isModifiedByOwner && <ModifiedLabel />}

              <QuestionFormTranslation
                prefix={questionPrefix}
                text={translations?.title || ""}
                hint={translations?.text || ""}
              />
            </div>
            <div
              className={classNames(styles.inputWrapper, {
                [styles.boolInputWrapper]: isRadioLayout,
                [styles.childrenPadding]:
                  questionDetails?.questionType ===
                    FormElementQuestionType.TextArea && hierarchyLevel === 2,
              })}
            >
              {questionDetails &&
                questionStructure.type === FormElementType.Question && (
                  <QuestionInput
                    questionDetails={questionDetails}
                    isRadioLayout={isRadioLayout}
                  />
                )}
            </div>
          </div>
        </>
      )}
      {children && (
        <div
          className={classNames({
            [styles.childrenPadding]: hierarchyLevel === 2,
          })}
        >
          {children}
        </div>
      )}
    </div>
  );
};

export { FormQuestion };
