import { useCallback } from "react";
import { faDownload } from "@fortawesome/pro-regular-svg-icons";
import { useTheme } from "injectors/theme";
import { FC } from "react";
import { Button, ButtonThemes } from "@kolibrisoftware/customerportal-ui";
import { useStyles } from "./styles";
import { Brochure } from "@kolibrisoftware/customerportal-api";
import { useTranslation } from "@kolibrisoftware/hooks-util";

type Props = {
  brochure: Brochure;
};

const ObjectBrochureItem: FC<Props> = ({ brochure }) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const t = useTranslation();

  const handleBrochureOpen = useCallback(() => {
    if (brochure.url) {
      window.open(brochure.url, "_blank");
    }
  }, [brochure]);
  return (
    <div className={styles.objectBrochureItemWrapper}>
      <img
        className={styles.objectBrochureItemPreview}
        src={brochure.urlPreview || "/assets/empty-brochure-preview.svg"}
        alt="brochure-preview"
      />
      <div className={styles.objectBrochureItemDetails}>
        <p className={styles.objectBrochureItemTitle}>
          {brochure.fileName?.toUpperCase()}
        </p>
        <Button
          buttonTheme={ButtonThemes.Primary}
          size="full"
          icon={faDownload}
          label={t("common.downloadAction")}
          onClick={handleBrochureOpen}
        />
      </div>
    </div>
  );
};

export { ObjectBrochureItem };
