import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  secondaryNavbarWrapper: responsiveStyles({
    position: "relative",
    // zIndex: 200,
    gap: "20px",
    backgroundColor: "white",
    padding: "10px 36px",
    borderBottom: "1px solid #e7e7ed",
    display: "flex",
    flexDirection: "row",
    alignItems: "center",
  })
    .s({ padding: "10px 22px" })
    .getStyle(),
  navContainer: {
    flex: 1,
    display: "flex",
    justifyContent: "center",
  },
  navAction: {
    flex: 1,
    display: "flex",
    alignItems: "center",
    gap: "10px",
    textDecoration: "none",
    color: theme.companyTheme?.mainColor?.toString() || "#000000aa",
    cursor: "pointer",
    transition: "all 0.1s ease",
    "&:hover": {
      color:
        theme.companyTheme?.mainColor?.lighten(0.7).toString() || "#000000",
    },
  },
  navTitle: { flex: 1 },
  fauxDix: { flex: 1 },
}));

export default useStyles;
