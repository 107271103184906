import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  formPartTitle: {
    fontSize: 22,
    fontWeight: 400,
    marginBottom: 15,
  },
  formPartBody: {
    lineHeight: 1.5,

    "& h3": {
      fontWeight: 500,
      fontSize: 16,

      "&:not(:last-child)": {
        margin: "0 0 0.5em",
      },
    },
  },
}));

export { useStyles };
