import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  formTextWrapper: {},
  textDetailsWrapper: responsiveStyles({
    display: "flex",
    gap: 10,
    padding: "10px 0",
    alignItems: "center",
  })
    .s(
      {
        flexDirection: "column",
        alignItems: "unset",
        gap: 5,
      },
      { propagate: true }
    )
    .getStyle(),
  bottomBorder: {
    "&:not(:last-child)": {
      borderBottom: "1px solid #efefef",
    },
  },
  childrenPadding: responsiveStyles({
    paddingLeft: 33,
  })
    .s(
      {
        paddingLeft: 15,
      },
      { propagate: true }
    )
    .xs({ paddingLeft: 0 })
    .getStyle(),
  translationWrapper: {
    flex: 3,
  },
  textChildrenWrapper: {
    //paddingLeft: 24,
  },
}));

export { useStyles };
