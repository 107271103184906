import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  overviewRootScreen: {
    position: "relative",
    overflowY: "auto",
    height: "100%",
  },
  columnsWrapper: responsiveStyles({
    width: 900,
    margin: "auto",
    display: "flex",
    gap: 10,
    marginTop: 15,
  })
    .m({ width: 800 })
    .s(
      {
        flexDirection: "column",
        width: "100%",
      },
      { propagate: true }
    )
    .getStyle(),
  propertyDetailsWrapper: responsiveStyles({
    flex: 2,
  })
    .s({ width: "96%", margin: "auto" })
    .xs({ width: "100%" })
    .getStyle(),
  checklistSectionWrapper: {
    flex: 3,
    marginBottom: 120,
  },
  checklistSubtitle: {
    fontSize: "15px",
    marginTop: "15px",
  },
}));

export { useStyles };
