import {
  FormAnswerEditor,
  FormElementQuestionType,
  UserFormCustomQuestion,
} from "@kolibrisoftware/customerportal-api";
import { ListOfItemsUseCases } from "modules/list-of-items/enums/list-of-items-use-cases";
import { FC, useMemo } from "react";
import { useDispatch, useSelector } from "store/helpers";
import { thunks as listOfItemsThunks } from "store/list-of-items";

import { ContractQuestion } from "../contract/contract";
import { RegularQuestion } from "../regular/regular";
import { ModifiedLabel } from "shared/components/modified-label";
import { useStyles } from "./styles";
import { selectListOfItemsAnswers } from "store/list-of-items/selectors";

type Props = {
  questionStructure: UserFormCustomQuestion;
  useCase: ListOfItemsUseCases;
};

const CustomQuestionWrapper: FC<Props> = ({ questionStructure, useCase }) => {
  const dispatch = useDispatch();
  const styles = useStyles();
  const questionData = useSelector(state =>
    state.listOfItems.customPopulatedElements.find(
      question => question.id === questionStructure.id
    )
  );
  const modifiedAnswerIds = useSelector(
    state => state.listOfItems.formStructure.latestModifiedAnswerIds
  );
  const questionAnswers = useSelector(selectListOfItemsAnswers);
  const questionAnswer = questionAnswers.find(
    answer => answer.formCustomQuestionId === questionData?.id
  );

  const handleTextChange = (text: string) => {
    if (text.length === 0) {
      dispatch(
        listOfItemsThunks.deleteCustomQuestion({
          formId: questionData?.formId || "",
          id: questionData?.id || "",
        })
      );
      return;
    }
    if (text !== questionData?.text) {
      dispatch(
        listOfItemsThunks.updateCustomQuestion({
          formId: questionData?.formId || "",
          questionId: questionData?.id || "",
          index: questionData?.index,
          newText: text,
        })
      );
    }
  };

  const isModifiedByOwner = useMemo(() => {
    return (
      modifiedAnswerIds?.includes(questionAnswer?.id || "") &&
      questionAnswer?.editor === FormAnswerEditor.Owner
    );
  }, [modifiedAnswerIds, questionAnswer?.id, questionAnswer?.editor]);

  return (
    <>
      <div
        data-modified={isModifiedByOwner}
        className={styles.listOfItemsQuestionWrapper}
      >
        {isModifiedByOwner && <ModifiedLabel />}
        {questionData &&
          questionData.questionType ===
            FormElementQuestionType.ListOfItemRadioList && (
            <RegularQuestion
              questionData={questionData}
              key={questionStructure.id}
              onTitleChange={handleTextChange}
            />
          )}
        {questionData &&
          questionData.questionType ===
            FormElementQuestionType.ContractListOfGood && (
            <ContractQuestion
              questionData={questionData}
              key={questionStructure.id}
              onTitleChange={handleTextChange}
            />
          )}
      </div>
    </>
  );
};

export { CustomQuestionWrapper };
