import { faEllipsisStrokeVertical } from "@fortawesome/pro-regular-svg-icons";
import { useDefaultMediaQueries } from "@kolibrisoftware/hooks-util";
import classnames from "classnames";
import {
  MenuItem,
  MenuItemsEvents,
  MenuItemsUseCase,
} from "constants/menu-items";
import { ROUTER_PATHS } from "constants/routes";
import { ButtonVariants } from "enums/button-variants";
import useMenuItems from "hooks/useMenuItems";
import { useTheme } from "injectors/theme";
import { FC, MouseEvent, useCallback, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { selectOpenExtendedChecklistItems } from "store/dossier/selectors";
import { useDispatch, useSelector } from "store/helpers";
import { thunks as mainThunks, actions as mainActions } from "store/main";
import MobileNavItem from "../mobile-nav-item";
import { useStyles } from "./styles";
import { LanguageSelect } from "@kolibrisoftware/customerportal-ui";
import { Culture } from "@kolibrisoftware/customerportal-api";

type Props = {};

const MobileNavigation: FC<Props> = props => {
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const theme = useTheme();
  const styles = useStyles({ theme });
  const { isScreenXS, isScreenS } = useDefaultMediaQueries();
  const navigate = useNavigate();
  const openChecklistItems = useSelector(selectOpenExtendedChecklistItems);
  const menuItems = useMenuItems(MenuItemsUseCase.MOBILE);
  const dispatch = useDispatch();

  const currentCulture = useSelector(state => state.main.culture);

  const setCulture = useCallback(
    (culture: Culture) => dispatch(mainActions.setCulture(culture)),
    [dispatch]
  );

  const navItems = useMemo(() => {
    if (isScreenS) {
      return {
        visible: [...menuItems].slice(0, 6),
        hidden: [...menuItems].slice(6),
      };
    }
    if (isScreenXS) {
      return {
        visible: [...menuItems].slice(0, 4),
        hidden: [...menuItems].slice(4),
      };
    }
  }, [isScreenXS, isScreenS, menuItems]);

  const handleExternalNavigation = useCallback(
    (item: MenuItem) => {
      switch (item.path) {
        case ROUTER_PATHS.LOGOUT:
          dispatch(mainThunks.logout());
          break;
        case ROUTER_PATHS.PROFILE:
          dispatch(mainThunks.navigateToProfile());
          break;
        default:
          window.open(item.path);
      }
    },
    [dispatch]
  );

  /**
   * Returns a MobileNavItem
   * with resolved onClickHandler
   */
  const navItemsMapper = useCallback(
    (item: MenuItem, index: number, buttonVariant: ButtonVariants) => {
      let notification = undefined;
      let onClickHandler = (e: MouseEvent<HTMLDivElement>) => {
        e?.stopPropagation();
        setIsMenuOpen(false);
        navigate(item.path);
      };

      if (item.eventType === MenuItemsEvents.ExternalNavigation) {
        onClickHandler = () => handleExternalNavigation(item);
      }

      if (item.path === ROUTER_PATHS.CHECKLIST) {
        notification = openChecklistItems.length;
      }

      return (
        <MobileNavItem
          item={item}
          key={`mobile-nav-item-${index}`}
          variant={buttonVariant}
          onClick={onClickHandler}
          notification={notification}
        />
      );
    },
    [handleExternalNavigation, navigate, openChecklistItems.length]
  );

  return (
    <div>
      <div className={styles.mobileNavigationContainer}>
        {/* VISIBLE CHECKLIST ITEMS */}
        {navItems?.visible.map((item, index) =>
          navItemsMapper(item, index, ButtonVariants.COLUMN)
        )}

        {/* HIDDEN MENU ITEMS */}
        {!!navItems?.hidden.length && (
          <MobileNavItem
            item={{
              eventType: MenuItemsEvents.OpenModal,
              label: "navItem.more",
              icon: faEllipsisStrokeVertical,
              path: ROUTER_PATHS.EMPTY,
            }}
            variant={ButtonVariants.COLUMN}
            key={"mobile-nav-item-more"}
            onClick={() => setIsMenuOpen(true)}
          />
        )}
      </div>

      <div
        className={classnames(styles.mobileMenuExtras, {
          [styles.mobileMenuExtras__open]: isMenuOpen,
        })}
        onClick={() => setIsMenuOpen(false)}
      >
        <div
          className={styles.mobileMenuExtras__items}
          onClick={e => {
            e.stopPropagation();
          }}
        >
          <div className={styles.mobileMenuExtras__header}>
            <LanguageSelect
              currentCulture={currentCulture}
              setCulture={setCulture}
              dropdownAnchor="top"
            />
          </div>
          {navItems?.hidden.map((item, index) =>
            navItemsMapper(item, index, ButtonVariants.DEFAULT)
          )}
        </div>
      </div>
    </div>
  );
};

export { MobileNavigation };
