import {
  faCheck,
  faChevronLeft,
  faCircleExclamation,
  faPenToSquare,
  faSpinnerThird,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useDefaultMediaQueries,
  useTranslation,
} from "@kolibrisoftware/hooks-util";
import classNames from "classnames";
import { AnswerSavingStatus } from "enums/answer-saving-status";
import { useTheme } from "injectors/theme";
import { FC, useMemo } from "react";
import { Outlet, useNavigate } from "react-router-dom";
import SecondaryNavbar from "layouts/secondary-navbar-wrapper";
import { useStyles } from "./styles";

type Props = {
  centerTitle: string;
  backActionLabel: string;
  savingStatus?: AnswerSavingStatus;
  reviewButton?: React.ReactNode;
  children?: React.ReactNode;
  optionalSideBar?: React.ReactNode;
  presence?: React.ReactNode;
};

const MobileBackActionLayout: FC<Props> = ({
  centerTitle,
  backActionLabel,
  savingStatus,
  reviewButton,
  children,
  optionalSideBar,
}) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const t = useTranslation();
  const { isScreenXS, isScreenS } = useDefaultMediaQueries();
  const navigate = useNavigate();

  const translations = useMemo(
    () => ({
      validatorSaved: t("validatorLabel.saved"),
      validatorInProgress: t("validatorLabel.inProgress"),
      validatorEditing: t("validatorLabel.editing"),
      validatorError: t("validatorLabel.error"),
      validatorSavedMobile: t("validatorLable.savedMobile"),
    }),
    [t]
  );

  const savingStatusIcon = useMemo(() => {
    switch (savingStatus) {
      case AnswerSavingStatus.Editing:
        return {
          icon: faPenToSquare,
          label: translations.validatorEditing,
          color: "#ff7600",
        };
      case AnswerSavingStatus.Saved:
        return {
          icon: faCheck,
          label: translations.validatorSavedMobile,
          color: "green",
        };
      case AnswerSavingStatus.Error:
        return {
          icon: faCircleExclamation,
          label: translations.validatorError,
          color: "red",
        };
      default:
        return {
          icon: faSpinnerThird,
          label: translations.validatorInProgress,
        };
    }
  }, [savingStatus, translations]);

  return (
    <>
      <SecondaryNavbar>
        <div className={styles.mobileBackActionLayoutWrapper}>
          <div className={styles.navActionWrapper}>
            <div>
              <button
                className={styles.navAction}
                tabIndex={-1}
                onClick={() => navigate(-1)}
              >
                <FontAwesomeIcon icon={faChevronLeft} />
                {!isScreenXS && !isScreenS && <p>{backActionLabel}</p>}
              </button>
            </div>
            <div className={styles.fauxDix}>
              {!!reviewButton && reviewButton}
            </div>
          </div>
          <div className={styles.navTitle}>
            {optionalSideBar}

            <div className={styles.centerTitleWrapper}>
              <span className={styles.titleLabel}>{centerTitle}</span>
              {savingStatus && (
                <div className={styles.savingStatus}>
                  •
                  <span
                    className={classNames(styles.savingStatusIcon, {
                      [styles.loadingStatus]:
                        savingStatus === AnswerSavingStatus.InProgress,
                    })}
                  >
                    <FontAwesomeIcon
                      style={{
                        color: savingStatusIcon.color,
                        position: "relative",
                        bottom: 2,
                      }}
                      icon={savingStatusIcon.icon}
                    />
                  </span>
                  <span className={styles.savingStatusLabel}>
                    {savingStatusIcon?.label}
                  </span>
                </div>
              )}
            </div>
          </div>
        </div>
      </SecondaryNavbar>
      <div className={styles.layoutWrapper}>
        {children ? children : <Outlet />}
      </div>
    </>
  );
};

export { MobileBackActionLayout };
