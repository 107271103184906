import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  header__title: {
    fontSize: "22px",
    fontWeight: "400",
    marginBottom: "15px",
  },
  header__description: {
    fontSize: "15px",
    lineHeight: "22px",
  },
}));

export { useStyles };
