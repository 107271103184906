import React, { FC } from "react";
import { useStyles } from "./styles";

type Props = {
  children: React.ReactNode;
};

const ScreenWrapper: FC<Props> = ({ children }) => {
  const styles = useStyles();

  return <div className={styles.screenWrapper}>{children}</div>;
};

export { ScreenWrapper };
