import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  formSectionHeaderWrapper: {},
  formSectionHeaderContainer: responsiveStyles({
    display: "flex",
    gap: 20,
    marginBottom: 10,
  })
    .s({ gap: 10 }, { propagate: true })
    .getStyle(),

  formSectionHeaderTitle: responsiveStyles({
    flex: 4,
    fontSize: 15,
    fontWeight: 600,
  })
    .s({ flex: 2 }, { propagate: true })
    .getStyle(),

  formSectionHeaderOptions: responsiveStyles({
    display: "flex",
    flex: 2,
    justifyContent: "space-around",
  })
    .s({ flex: 4 }, { propagate: true })
    .m({ justifyContent: "space-between" })
    .getStyle(),

  formSectionHeaderOption: responsiveStyles({
    textAlign: "center",
    fontSize: 11,
    fontWeight: 600,
    lineHeight: "13px",
    padding: "0 8px",
    flex: 1,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
  })
    .xs({ padding: "0 5px", fontSize: 9 })
    .getStyle(),
  formSectionHeaderDescription: {},
  divider: {
    outline: "none",
    border: "none",
    height: 1,
    background: "#80808033",
    marginBottom: 20,
  },
}));

export { useStyles };
