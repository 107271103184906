import { FC } from "react";
import { useStyles } from "./styles";
import classnames from "classnames";

type Props = {
  size?: "full-screen";
};

const DefaultLoadingScreen: FC<Props> = ({ size }) => {
  const styles = useStyles();

  return (
    <div
      className={classnames(styles.container, {
        [styles.container__full_screen]: size === "full-screen",
      })}
    >
      <span className={styles.loader}></span>
    </div>
  );
};

export { DefaultLoadingScreen };
