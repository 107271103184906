import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  formSectionContainer: {
    "&:not(:last-child)": {
      marginBottom: 25,
    },
  },
}));

export { useStyles };
