import { faUser } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { RelationType } from "@kolibrisoftware/customerportal-api";
import { useTranslation } from "@kolibrisoftware/hooks-util";
import classNames from "classnames";
import { Member, usePresence } from "injectors/presence";
import { useTheme } from "injectors/theme";
import { useCallback, useId, useMemo, useRef } from "react";
import { useStyles } from "./styles";
import { displayNameToInitials } from "helpers/displayName-to-initials";

export const Presence = () => {
  const dropDownHeaderId = useId();
  const theme = useTheme();
  const styles = useStyles({ theme });
  const { currentMembers } = usePresence();
  const t = useTranslation();
  const presenceRef = useRef<HTMLDivElement>(null);

  const translations = useMemo(
    () => ({
      dropDownHeader: t("documentReview.presence.dropDownHeader", {
        values: { amount: currentMembers.length },
      }),
      realtor: t("common.realtor"),
      contact: t("common.contact"),
    }),
    [currentMembers.length, t]
  );

  const getInitials = useCallback((displayName?: string) => {
    if (!displayName) {
      return <FontAwesomeIcon icon={faUser} />;
    }
    return displayNameToInitials(displayName);
  }, []);

  const getDisplayName = useCallback(
    (member: Member) => {
      switch (true) {
        case !!member.displayName: {
          return member.type === RelationType.Employee
            ? `${translations.realtor}: ${member.displayName}`
            : member.displayName;
        }
        default: {
          return member.type === RelationType.Employee
            ? translations.realtor
            : translations.contact;
        }
      }
    },
    [translations]
  );

  const hasMembers = useMemo(() => currentMembers.length > 0, [currentMembers]);
  const hasMoreThanOneMember = useMemo(
    () => currentMembers.length > 1,
    [currentMembers]
  );

  if (!hasMembers) {
    return <></>;
  }

  return (
    <div ref={presenceRef} className={styles.presence}>
      <div className={styles.toggle}>
        {hasMoreThanOneMember && (
          <div
            style={{ right: "20px" }}
            className={classNames(styles.member, styles.headsUp)}
          >
            {getInitials(currentMembers[1]?.displayName || "")}
          </div>
        )}
        {hasMembers && (
          <div className={classNames(styles.member, styles.headsUp)}>
            {getInitials(currentMembers[0]?.displayName || "")}
          </div>
        )}
      </div>
      <div className={styles.dropDown}>
        <div id={dropDownHeaderId} className={styles.dropDownHeader}>
          {translations.dropDownHeader}
        </div>
        <ul className={styles.dropDownList} aria-describedby={dropDownHeaderId}>
          {currentMembers.map((relation, index) => {
            return (
              <li key={index}>
                <div className={styles.member}>
                  {getInitials(relation?.displayName || "")}
                </div>
                <span className={styles.relationName}>
                  {getDisplayName(relation)}
                </span>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};
