import { FC } from "react";
import { Provider } from "react-redux";
import { PersistGate } from "redux-persist/integration/react";
import { persistor, store } from "store";
import DefaultLoadingScreen from "shared/ui/default-loading-screen";

type Props = { children: React.ReactNode };
const StoreInjector: FC<Props> = ({ children }) => {
  return (
    <Provider store={store}>
      <PersistGate loading={<DefaultLoadingScreen />} persistor={persistor}>
        {children}
      </PersistGate>
    </Provider>
  );
};

export default StoreInjector;
