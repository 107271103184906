import { FC } from "react";
import { Button, ButtonThemes } from "@kolibrisoftware/customerportal-ui";
import { useTranslation } from "@kolibrisoftware/hooks-util";

export type ActionInterface = {
  callback: (...args: any) => void;
  label?: string;
  theme?: ButtonThemes;
};

type Props = {
  actions: ActionInterface[];
  "data-cy"?: string;
};

const ActivityItemActions: FC<Props> = ({
  actions,
  "data-cy": CypressAccesor,
}) => {
  const t = useTranslation();
  return (
    <>
      {actions.map((action, index) => {
        return (
          <Button
            data-cy={CypressAccesor || "ActivityItemAction"}
            key={`activity-item-action-${index}`}
            onClick={action.callback}
            buttonTheme={action.theme || ButtonThemes.Primary}
            label={action.label ? t(action.label) : ""}
            size="s"
            style={{ marginBottom: actions.length > 1 ? 5 : 0 }}
          />
        );
      })}
    </>
  );
};

export { ActivityItemActions };
