import { Culture } from "@kolibrisoftware/customerportal-api";
import { FC, useCallback, useEffect, useLayoutEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import { thunks as dossierThunks } from "store/dossier";
import { useDispatch, useSelector } from "store/helpers";
import { thunks as listOfItemsThunks } from "store/list-of-items";

type Props = {
  children: React.ReactNode;
};

const InitialDataLayer: FC<Props> = ({ children }) => {
  const { dossierId, formId } = useParams();
  const checklistItems = useSelector(state => state.dossier.checklistItems);
  const loadedAssignment = useSelector(
    state => state.objectPreview.assignmentPreview
  );
  const culture = useSelector(state => state.main.culture);
  const dispatch = useDispatch();
  const initialized = useRef(false);

  const getInitialFormData = useCallback(async () => {
    await dispatch(
      listOfItemsThunks.getFormData({
        formId: formId || "",
        culture: culture,
      })
    );
  }, [dispatch, formId, culture]);

  const initial = useCallback(async () => {
    if (!!initialized.current) return;
    if (dossierId === loadedAssignment.assignmentId) return;

    if (!checklistItems || checklistItems.length === 0) {
      await dispatch(
        dossierThunks.loadAssignment({ assignmentId: dossierId || "" })
      );
    }

    await getInitialFormData();
  }, [
    dispatch,
    checklistItems,
    dossierId,
    loadedAssignment,
    getInitialFormData,
  ]);

  useLayoutEffect(() => {
    if (!initialized.current) {
      initial();
      initialized.current = true;
    }
  }, [initial]);

  useEffect(() => {
    getInitialFormData();
  }, [getInitialFormData]);

  return <>{children}</>;
};

export { InitialDataLayer };
