import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  objectTabWrapper: responsiveStyles({
    background: "none",
    outline: "none",
    border: `1px solid ${theme.colors.lightGray.alpha(0.3).toString()}`,
    borderRadius: 3,
    padding: 13,
    flex: 1,
    cursor: "pointer",
    transition: "all 0.2s ease",
    "&:hover": {
      background: theme.colors.lightGray.alpha(0.2).toString(),
    },
  })
    .s(
      {
        padding: 8,
      },
      { propagate: true }
    )
    .getStyle(),
  objectTabContent: responsiveStyles({
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
  })
    .s(
      {
        flexDirection: "row",
        alignItems: "center",
        gap: 5,
      },
      { propagate: true }
    )
    .getStyle(),
  objectTabLabel: {
    fontWeight: 500,
    whiteSpace: "nowrap",
    fontSize: 13,
  },
  objectTabIcon: responsiveStyles({
    color:
      theme.companyTheme?.supportColor?.toString() ||
      theme.colors.secondary.toString(),
    fontSize: 24,
    marginBottom: 13,
  })
    .s(
      {
        margin: 0,
        fontSize: 16,
      },
      { propagate: true }
    )
    .getStyle(),
}));

export { useStyles };
