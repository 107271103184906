import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  backdrop: {
    position: "fixed",
    top: 0,
    left: 0,
    width: "100%",
    height: "100%",
    zIndex: 1000,
    backgroundColor: "#00000050",
  },
  modalWrapper: responsiveStyles({
    position: "fixed",
    top: 20,
    left: 0,
    width: "100%",
    height: "100%",
    display: "flex",
    flexDirection: "column",
    overflowY: "auto",
    zIndex: 1005,
  })
    .m(
      {
        overflowY: "unset",
      },
      { propagate: true }
    )
    .getStyle(),
  modal: {
    backgroundColor: "white",
    margin: "0 auto",
    maxWidth: 708,
    flex: "none",
    cursor: "default",
    boxShadow: "0 0 24px #00000015",
    borderRadius: "5px",
  },
  modalBody: {},
  modalFooter: {},
  clickable: {},
  spacer: {},
  modalHeader: {
    display: "flex",
    alignItems: "center",
    height: "36px",
    marginTop: 12,
  },
  headerTitle: {
    flex: 1,
    whiteSpace: "nowrap",
    overflow: "hidden",
    textOverflow: "ellipsis",
    padding: "0 4px 0 16px",
    fontSize: 20,
    fontWeight: 600,
  },
  headerClose: {
    flex: "none",
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    height: "36px",
    width: "36px",
    cursor: "pointer",
  },
  positionCenter: {
    justifyContent: "center",
    alignItems: "center",
  },
}));

export { useStyles };
