import {
  BlueprintType,
  ChecklistItemResultType,
} from "@kolibrisoftware/customerportal-api";
import { ROUTER_PATHS } from "constants/routes";
import { useCallback, useEffect } from "react";
import { useNavigate, useParams } from "react-router-dom";
import { checklistItemApi } from "store/api";
import { useDispatch, useSelector } from "store/helpers";

const OpenChecklistItem = () => {
  const { dossierId, checklistItemId } = useParams();
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const realEstateAgencyId = useSelector(
    state => state.main.companyData?.realEstateAgencyId
  );
  const initial = useCallback(async () => {
    try {
      if (checklistItemId && dossierId) {
        const result = await dispatch(
          checklistItemApi.searchChecklistItems({
            assignmentId: dossierId,
            realEstateAgencyId: realEstateAgencyId || "",
            requestBody: { ids: [checklistItemId] },
          })
        ).unwrap();
        const checklistItem = result?.[0];

        if (!checklistItem) {
          throw new Error(
            `We couldn't find checklist item with id ${checklistItemId}`
          );
        }
        const PATH_PREFIX = `/dossier/${dossierId}`;
        const { resultType, blueprintType } = checklistItem.checklistItemType;
        if (resultType === ChecklistItemResultType.GeneralDocument) {
          navigate(
            `${PATH_PREFIX}/checklist?checklistItemId=${checklistItem.id}`
          );
        }
        if (resultType === ChecklistItemResultType.Form) {
          switch (blueprintType) {
            case BlueprintType.ListOfGoods:
              const listPath = `${PATH_PREFIX}/${ROUTER_PATHS.LIST_OF_ITEMS}/${checklistItem.formId}`;
              navigate(listPath);
              break;
            case BlueprintType.Questionnaire:
              const p = `${PATH_PREFIX}/${ROUTER_PATHS.QUESTIONNAIRE}/${checklistItem.formId}`;
              navigate(p);
              break;
            default:
              navigate("not-found", { replace: true });
              break;
          }
        }
      }
    } catch (error) {
      throw error;
    }
  }, [checklistItemId, dispatch, dossierId, navigate, realEstateAgencyId]);
  useEffect(() => {
    initial();
  }, [initial]);

  return <></>;
};

export default OpenChecklistItem;
