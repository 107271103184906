import { faTriangleExclamation } from "@fortawesome/pro-regular-svg-icons";
import { ChecklistItemStatusType } from "@kolibrisoftware/customerportal-api";
import {
  Hint,
  HintProps,
  HintThemes,
} from "@kolibrisoftware/customerportal-ui";
import { useTranslation } from "@kolibrisoftware/hooks-util";
import classNames from "classnames";
import { useTheme } from "injectors/theme";
import { FC, useCallback, useEffect, useMemo, useRef, useState } from "react";
import { useStyles } from "./styles";

type Props = {
  status: ChecklistItemStatusType;
  removeCallback: () => void;
};

const DocumentBottomHint: FC<Props> = ({ status, removeCallback }) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const t = useTranslation();
  const timeoutRef = useRef<NodeJS.Timeout | null>(null);
  const [isBeingRemoved, setIsBeingRemoved] = useState(false);

  const hintProps: HintProps = useMemo(() => {
    switch (status) {
      case ChecklistItemStatusType.Open: {
        return {
          hintTheme: HintThemes.Info,
          content: t("questionnaire.bottomHintInfo"),
        };
      }
      case ChecklistItemStatusType.Review: {
        return {
          hintTheme: HintThemes.Warning,
          content: t("questionnaire.reviewHintInfo"),
          hintIcon: faTriangleExclamation,
        };
      }
      case ChecklistItemStatusType.Closed: {
        return {
          hintTheme: HintThemes.Success,
          content: t("questionnaire.closedHintInfo"),
        };
      }
      default: {
        return {
          hintTheme: HintThemes.Info,
          translationKey: t("questionnaire.bottomHintInfo"),
        };
      }
    }
  }, [status, t]);

  const handleRemove = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    setIsBeingRemoved(true);
    timeoutRef.current = setTimeout(removeCallback, 300);
  }, [removeCallback]);

  const tabListener = useCallback(
    (e: KeyboardEvent) => {
      if (e.key === "Tab") {
        handleRemove();
      }
    },
    [handleRemove]
  );

  useEffect(() => {
    document.addEventListener("keydown", tabListener);
    return () => {
      document.removeEventListener("keydown", tabListener);
    };
  });
  return (
    <div
      className={classNames(styles.bottomHintContainer, {
        [styles.removeElement]: isBeingRemoved,
      })}
      onClick={handleRemove}
    >
      <Hint {...hintProps} showBorder={true} showShadow={true} />
    </div>
  );
};

export { DocumentBottomHint };
