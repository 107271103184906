import Color from "color";
import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  previewContainer: responsiveStyles({
    flex: 1,
    backgroundColor: Color.rgb(255, 255, 255).toString(),
    borderRadius: 3,
    overflow: "hidden",
    boxShadow: `0 0 8px ${Color.rgb(0, 0, 0).alpha(0.1).toString()}`,
    cursor: "pointer",
    border: "1px solid #80808057",
    transition: "box-shadow 0.5s ease-in-out",
    position: "relative",
    minWidth: 320,
    "&:hover": {
      boxShadow: `0 8px 16px ${Color.rgb(0, 0, 0).alpha(0.2).toString()}`,
      "& $thumb": {
        backgroundSize: "260px auto",
      },
    },
  })
    .s({
      minWidth: 300,
    })
    .getStyle(),
  thumbWrapper: {
    width: "100%",
    paddingTop: "75%",
    position: "relative",
  },
  thumbContainer: {
    position: "absolute",
    top: 0,
    right: 0,
    bottom: 0,
    left: 0,
    height: "100%",
    width: "100%",
  },
  thumb: {
    width: "100%",
    height: "100%",
    backgroundPosition: "center center",
    transition: "background-size 0.75s linear",
    backgroundRepeat: "no-repeat",
  },
  unavailable: {
    filter: "grayscale(1)",
  },
  body: {
    padding: 16,
    paddingTop: 24,
  },

  button: {
    marginTop: 10,
    backgroundColor: theme.companyTheme?.mainColor?.toString() || "#000000",
    color: theme.companyTheme?.textColor?.toString() || "#fff",
    padding: 10,
    borderRadius: 3,
    border: "none",
    fontSize: 15,
    fontWeight: 400,
    cursor: "pointer",
    transition: "all 0.3s ease",
    "&:hover": {
      opacity: "0.8",
    },
  },
  previewTitle: {
    color: "#000000",
    fontWeight: 400,
    fontSize: 22,
    marginBottom: 6,
    marginTop: 12,
  },
  previewBody: {
    color: "#3A3A3C",
    fontSize: 15,
    fontWeight: 400,
    marginBottom: 6,
  },
  realtorLogoWrapper: responsiveStyles({
    padding: 8,
    minHeight: 90,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    position: "absolute",
    right: 16,
    bottom: -16,
    background: "white",
    border: "1px solid #E7E7ED",
    borderRadius: 3,
  })
    .s({
      height: 60,
    })
    .getStyle(),

  realtorLogoWrapperDisplayName: responsiveStyles({
    padding: 8,
    minHeight: 90,
    display: "flex",
    alignItems: "center",
    justifyContent: "center",
    fontWeight: 600,
    position: "absolute",
    right: 16,
    bottom: -16,
    background: "white",
    border: "1px solid #E7E7ED",
    borderRadius: 3,
  })
    .s({
      height: 60,
    })
    .getStyle(),

  realtorLogo: responsiveStyles({
    maxHeight: 85,
    maxWidth: 160,
  })
    .s(
      {
        maxHeight: 40,
        maxWidth: 100,
      },
      { propagate: true }
    )
    .xs({ maxHeight: 90, maxWidth: 160 })
    .getStyle(),
}));

export default useStyles;
