import classnames from "classnames";
import { MenuItem } from "constants/menu-items";
import { ButtonVariants } from "enums/button-variants";
import { useTheme } from "injectors/theme";
import React, { FC, useMemo } from "react";
import { matchPath, useLocation } from "react-router-dom";
import { Button } from "@kolibrisoftware/customerportal-ui";
import { useStyles } from "./styles";
import { useTranslation } from "@kolibrisoftware/hooks-util";

type Props = {
  item: MenuItem;
  variant: ButtonVariants;
  onClick: (event: React.MouseEvent<any>) => void;
  notification?: number;
};

const MobileNavItem: FC<Props> = ({ item, variant, onClick, notification }) => {
  const theme = useTheme();
  const t = useTranslation();
  const styles = useStyles({ theme });
  const location = useLocation();
  const match = matchPath(
    { path: `dossier/:id/${item.path}` },
    location.pathname
  );

  const isActive = useMemo(() => {
    return match ? true : false;
  }, [match]);

  return (
    <div
      className={classnames(styles.mobileNavItem, {
        [styles.mobileNavItem__active]: isActive,
        [styles.mobileNavItem__visible]: variant === ButtonVariants.COLUMN,
      })}
    >
      {!!notification && (
        <div className={styles.notification}>
          <div>{notification}</div>
        </div>
      )}
      <Button
        label={t(item.label)}
        icon={item.icon}
        onClick={onClick}
        variant={variant}
      />
    </div>
  );
};

export { MobileNavItem };
