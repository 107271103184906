import {
  FormElement,
  FormElementExtended,
} from "@kolibrisoftware/customerportal-api";
import { useTranslation } from "@kolibrisoftware/hooks-util";
import isElementInViewport from "injectors/intersection-observer/helpers/is-in-viewport";
import { useInterSectionObserver } from "injectors/intersection-observer/hooks/use-intersection-observer";
import { useTheme } from "injectors/theme";
import { isUndefined } from "lodash-es";
import useElementData from "modules/questionnaire/hooks/useElementData";
import { useDispatch, useSelector } from "store/helpers";
import { actions as questionnaireActions } from "store/questionnaire";
import React, { FC, useCallback, useEffect, useMemo, useRef } from "react";
import {
  ContentSection,
  ContentSectionSize,
} from "@kolibrisoftware/customerportal-ui";
import useElementTranslations from "hooks/useElementTranslations";
import { useStyles } from "./styles";

type Props = {
  sectionStructure: FormElement;
  hierarchyLevel: number;
  children?: React.ReactNode;
  sectionIndex?: number;
  totalSiblings?: number;
};

const FormSection: FC<Props> = ({
  hierarchyLevel,
  sectionStructure,
  children,
  sectionIndex,
  totalSiblings,
}) => {
  const theme = useTheme();
  const dispatch = useDispatch();
  const styles = useStyles({ theme });
  const t = useTranslation();
  const sectionData = useElementData(sectionStructure.id);
  const sectionTranslations = useElementTranslations(sectionData);
  const viewRef = useRef<HTMLDivElement | null>(null);
  const { addListener, removeListener } = useInterSectionObserver();
  const { activeSectionId, activeSectionTab } = useSelector(
    state => state.questionnaire
  );
  const formId = useSelector(state => state.questionnaire.formStructure.id);

  const translations = useMemo(
    () => ({
      positionLabel: !sectionIndex
        ? null
        : t("questionnaire.section.positionLabel", {
            values: {
              index: sectionIndex + 1,
              totalSections: totalSiblings,
            },
          }),
    }),
    [t, sectionIndex, totalSiblings]
  );

  const sidebarScrollCallback = useCallback(() => {
    if (activeSectionTab.id !== sectionData?.id) {
      dispatch(
        questionnaireActions.setActiveSectionTab(
          sectionData as FormElementExtended
        )
      );
    }
    const sidebarElement = document.getElementById(
      `${sectionData?.id}-sidebar`
    );
    if (!isElementInViewport(sidebarElement, 200, 200)) {
      sidebarElement?.scrollIntoView();
    }
  }, [activeSectionTab.id, dispatch, sectionData]);

  const setActiveSectionHandler = useCallback(() => {
    if (!sectionData) {
      return;
    }
    if (activeSectionId !== sectionData.id) {
      dispatch(
        questionnaireActions.setActiveSection({
          formId: formId || "",
          activeSectionId: sectionData?.id || "",
        })
      );
    }
  }, [sectionData, dispatch, activeSectionId, formId]);

  useEffect(() => {
    const ref = viewRef.current;
    addListener(ref, sidebarScrollCallback);

    return () => {
      removeListener(ref);
    };
  }, [addListener, removeListener, sidebarScrollCallback]);

  return (
    <ContentSection
      size={ContentSectionSize.Full}
      ref={viewRef}
      data-id={sectionStructure.id}
      onClick={setActiveSectionHandler}
      onKeyDown={setActiveSectionHandler}
      onKeyUp={setActiveSectionHandler}
      onSelect={setActiveSectionHandler}
      isActive={activeSectionId === sectionData?.id}
    >
      <div className={styles.formSectionWrapper}>
        {hierarchyLevel === 1 && translations.positionLabel && (
          <span className={styles.positionLabel}>
            {translations.positionLabel}
          </span>
        )}
        <h2 className={styles.sectionTitle}>{sectionTranslations?.title}</h2>
        {sectionTranslations?.text && (
          <p className={styles.sectionBody}>{sectionTranslations.text}</p>
        )}
        <div className={styles.sectionChildrenWrapper}>{children}</div>
      </div>
    </ContentSection>
  );
};

export { FormSection };
