import { faCheck } from "@fortawesome/pro-regular-svg-icons";
import { Button, Pill, PillThemes } from "@kolibrisoftware/customerportal-ui";
import { useTranslation } from "@kolibrisoftware/hooks-util";
import { createErrorToast, createSuccessToast } from "helpers/create-new-toast";
import { FC, useCallback, useMemo, useRef, useState } from "react";
import { checklistItemApi } from "store/api";
import { useDispatch, useSelector } from "store/helpers";
import { actions as toastActions } from "store/toasts";
import ConfirmPrompt from "../confirm-prompt";
import { useStyles } from "./styles";

type Props = {
  dossierId: string;
  checklistItemId: string;
  disabled?: boolean;
};

const ReviewChecklistItemButton: FC<Props> = ({
  dossierId,
  checklistItemId,
  disabled,
}) => {
  const dispatch = useDispatch();
  const t = useTranslation();
  const styles = useStyles();
  const [isPromptOpen, setIsPromptOpen] = useState(false);
  const realEstateAgencyId = useSelector(
    state => state.main.companyData?.realEstateAgencyId
  );
  const isSubmitting = useRef(false);

  const translations = useMemo(
    () => ({
      success: t("sendToReview.success"),
      error: t("sendToReview.error"),
      promptTitle: t("sendToReview.prompt.title"),
      promptContent: t("sendToReview.prompt.content"),
      promptConfirm: t("sendToReview.prompt.confirm"),
    }),
    [t]
  );

  const pillTheme = useMemo(
    () => (disabled ? undefined : PillThemes.Primary),
    [disabled]
  );

  const handleOpenPrompt = () => {
    setIsPromptOpen(true);
  };

  const handleSubmitCallback = useCallback(async () => {
    if (isSubmitting.current) return;
    isSubmitting.current = true;

    try {
      const response = await dispatch(
        checklistItemApi.sendForReview({
          realEstateAgencyId: realEstateAgencyId || "",
          assignmentId: dossierId,
          checklistItemId: checklistItemId,
        })
      ).then(data => data.payload);

      if (!response) {
        throw new Error();
      }
      setIsPromptOpen(false);
      const toastBody = createSuccessToast(translations.success);
      dispatch(toastActions.addToast(toastBody));
    } catch (error) {
      const toastBody = createErrorToast(translations.error);
      dispatch(toastActions.addToast(toastBody));
    }

    isSubmitting.current = false;
  }, [dispatch, dossierId, checklistItemId, translations, realEstateAgencyId]);

  return (
    <div className={styles.reviewActionWrapper}>
      <Pill pillTheme={pillTheme}>
        <Button
          className={styles.action}
          label={t("questionnaire.reviewLabel")}
          onClick={handleOpenPrompt}
          icon={faCheck}
          disabled={disabled}
        />
      </Pill>

      <ConfirmPrompt
        title={translations.promptTitle}
        content={translations.promptContent}
        confirmLabel={translations.promptConfirm}
        visible={isPromptOpen}
        confirmCallback={handleSubmitCallback}
        closeCallback={() => setIsPromptOpen(false)}
      />
    </div>
  );
};

export default ReviewChecklistItemButton;
