import { PageLoadingStatus } from "enums/page-loading-status";
import React, { FC, useCallback, useLayoutEffect, useRef } from "react";
import { RedirectToNotFound } from "shared/components/error-page/error-page";
import { LoadingScreen } from "shared/ui/loading-screen/loading-screen";
import { useDispatch, useSelector } from "store/helpers";
import { thunks as objectPreviewThunks } from "store/object-preview";

type Props = {
  children: React.ReactNode;
};
const InitialDataLayer: FC<Props> = ({ children }) => {
  const assignmentId = useSelector(state => state.dossier.assignmentDetails.id);
  const dispatch = useDispatch();
  const initialized = useRef(false);
  const loadingStatus = useSelector(state => state.objectPreview.loadingStatus);
  const showPreview = useSelector(
    state => state.dossier.assignmentDetails.showPreview
  );
  const assignmentPreview = useSelector(
    state => state.objectPreview.assignmentPreview
  );
  const initial = useCallback(async () => {
    if (!!initialized.current) return;
    if (
      !!Object.entries(assignmentPreview).length &&
      assignmentPreview.assignmentId === assignmentId
    ) {
      return; // this is in case we already have the loaded data
    }
    await dispatch(objectPreviewThunks.loadAssignmentPreview());
  }, [dispatch, assignmentId, assignmentPreview]);

  useLayoutEffect(() => {
    if (!initialized.current && !!assignmentId) {
      initial();
      initialized.current = true;
    }
  }, [initial, assignmentId]);

  if (
    !showPreview &&
    loadingStatus !== PageLoadingStatus.Loading &&
    loadingStatus !== PageLoadingStatus.Pending
  ) {
    return <RedirectToNotFound />;
  }
  return (
    <>
      {/* LOADING */}
      {(loadingStatus === PageLoadingStatus.Loading ||
        loadingStatus === PageLoadingStatus.Pending) && <LoadingScreen />}

      {/* FAILED */}
      {loadingStatus === PageLoadingStatus.Failed && <RedirectToNotFound />}

      {/* SUCCESS */}
      {loadingStatus === PageLoadingStatus.Success && children}
    </>
  );
};

export default InitialDataLayer;
