import { faCheckCircle } from "@fortawesome/pro-regular-svg-icons";
import { faCloudArrowUp } from "@fortawesome/pro-solid-svg-icons";
import { UploadStatus } from "enums/upload-status";
import { ExtendedChecklistItem } from "helpers/extend-checklist-items";
import { useTheme } from "injectors/theme";
import { FC, useCallback, useMemo, useState } from "react";
import {
  FileInput,
  Button,
  Hint,
  HintProps,
  ButtonThemes,
  HintThemes,
} from "@kolibrisoftware/customerportal-ui";
import { documentApi } from "store/api";
import { useDispatch } from "store/helpers";
import { useStyles } from "./styles";
import checklistItemHelpers from "helpers/checklist-item";
import { useTranslation } from "@kolibrisoftware/hooks-util";

const INPUT_ACCEPTED_FILES = [
  ".doc",
  ".docx",
  ".xls",
  ".xlsx",
  ".pdf",
  ".png",
  ".jpg",
  ".zip",
];

type Props = {
  checklistItem: ExtendedChecklistItem;
  onConfirm: ((value?: any) => void) | undefined;
  onCancel: ((reason?: any) => void) | undefined;
  "data-cy"?: string;
};

const UploadDocumentModal: FC<Props> = ({
  checklistItem,
  onConfirm,
  onCancel,
  "data-cy": CypressAccesor,
}) => {
  const theme = useTheme();
  const t = useTranslation();
  const styles = useStyles({ theme });
  const [selectedFile, setSelectedFile] = useState<File | null>(null);
  const [uploadStatus, setUploadStatus] = useState<{
    status: UploadStatus;
    fileRejected?: boolean;
  }>({
    status: UploadStatus.Pending,
    fileRejected: false,
  });
  const dispatch = useDispatch();

  const hintComponentProps: HintProps | null = useMemo(() => {
    switch (uploadStatus.status) {
      case UploadStatus.Pending: {
        return {
          content: t("uploadDocument.hint.info"),
          hintTheme: HintThemes.Info,
        };
      }
      case UploadStatus.FileSelected: {
        return {
          content: t("uploadDocument.hint.fileSelected"),
          hintTheme: HintThemes.Success,
        };
      }
      case UploadStatus.Loading: {
        return {
          content: t("uploadDocument.hint.uploading"),
          hintTheme: HintThemes.Info,
          icon: faCloudArrowUp,
        };
      }
      case UploadStatus.Success: {
        return {
          content: t("uploadDocument.hint.success"),
          hintTheme: HintThemes.Success,
          icon: faCheckCircle,
        };
      }
      case UploadStatus.Error: {
        let content = t("uploadDocument.hint.error");

        if (uploadStatus.fileRejected) {
          content = t("uploadDocument.hint.fileError");
        }

        return {
          content,
          hintTheme: HintThemes.Error,
        };
      }
      default: {
        return null;
      }
    }
  }, [uploadStatus, t]);

  const isButtonDisabled = useMemo(() => {
    switch (uploadStatus.status) {
      case UploadStatus.Pending:
      case UploadStatus.Success:
      case UploadStatus.Loading:
      case UploadStatus.Error:
        return true;
      default:
        return false;
    }
  }, [uploadStatus]);

  const handleFileSelect = useCallback((file: File) => {
    setSelectedFile(file);
    setUploadStatus({ status: UploadStatus.FileSelected });
  }, []);

  const handleSuccessMessage = useCallback(() => {
    setUploadStatus({ status: UploadStatus.Success });
    setTimeout(() => {
      onConfirm?.(selectedFile);
    }, 2000);
  }, [onConfirm, selectedFile]);

  const handleRejectFile = useCallback(() => {
    setUploadStatus({ status: UploadStatus.Error, fileRejected: true });
  }, []);

  const handleConfirm = useCallback(async () => {
    if (!selectedFile) return;
    setUploadStatus({ status: UploadStatus.Loading });

    try {
      let uploadOnBehalfOfRelationId = undefined;
      if (checklistItem.targetRelation) {
        uploadOnBehalfOfRelationId = checklistItem.targetRelation.id;
      }
      const response = await dispatch(
        documentApi.uploadDocument({
          file: selectedFile,
          checklistItemId: checklistItem.id,
          uploadOnBehalfOfRelationId,
        })
      );
      const requestStatus = response.meta.requestStatus;
      if (requestStatus === "fulfilled") {
        handleSuccessMessage();
      } else {
        throw new Error("There was an error uploading the document");
      }
    } catch (error) {
      setUploadStatus({ status: UploadStatus.Error, fileRejected: false });
    }
  }, [selectedFile, checklistItem, handleSuccessMessage, dispatch]);

  const handleRemoveFile = () => {
    setUploadStatus({ status: UploadStatus.Pending });
    setSelectedFile(null);
  };
  const checklistItemDescription = useMemo(
    () => checklistItemHelpers.resolveDescription(checklistItem),
    [checklistItem]
  );

  return (
    <div className={styles.uploadFileModalContainer} data-cy={CypressAccesor}>
      <hr className={styles.horizontalSpacer} />
      {checklistItemDescription && (
        <div className={styles.descriptionWrapper}>
          {checklistItemDescription}
        </div>
      )}

      <FileInput
        acceptedFiles={INPUT_ACCEPTED_FILES}
        filesizeText={t("uploadDocument.fileSize")}
        placeholderTitle={t("uploadDocument.title")}
        placeholderSubtitle={t("uploadDocument.subtitle")}
        status={uploadStatus.status}
        singleFileCallback={handleFileSelect}
        removeFileCallback={handleRemoveFile}
        rejectFileCallback={handleRejectFile}
        data-cy="UploadDocumentFileInput"
      />

      {hintComponentProps && <Hint {...hintComponentProps} />}
      <hr className={styles.horizontalSpacer} />
      <div className={styles.actionButtonsWrapper}>
        <Button
          onClick={() => onCancel?.(false)}
          label={t("uploadDocument.declineAction")}
          buttonTheme={ButtonThemes.Blank}
          size="lg"
        />
        <Button
          onClick={handleConfirm}
          label={t("uploadDocument.confirmAction")}
          buttonTheme={ButtonThemes.Primary}
          size="lg"
          disabled={isButtonDisabled}
          data-cy="UploadDocumentFileConfirmAction"
        />
      </div>
    </div>
  );
};

export { UploadDocumentModal };
