import { faVideoSlash } from "@fortawesome/pro-regular-svg-icons";
import resolveYoutubeLink from "helpers/resolve-youtube-link";
import { useTheme } from "injectors/theme";
import { FC, useCallback, useMemo, useState } from "react";
import { NoFilesAvailable } from "shared/ui/no-files-available/no-files-available";
import { useSelector } from "store/helpers";
import { ObjectCarouselControllers } from "../object-carousel-controllers/object-carousel-controllers";
import { useStyles } from "./styles";
import { useTranslation } from "@kolibrisoftware/hooks-util";
import { PillThemes } from "@kolibrisoftware/customerportal-ui";

type Props = {};

const ObjectView360: FC<Props> = () => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const [selectedIndex, setSelectedIndex] = useState(0);
  const { videoUrls } = useSelector(
    state => state.objectPreview.assignmentPreview
  );
  const t = useTranslation();

  const showControls = useMemo(
    () => videoUrls && videoUrls.length > 1,
    [videoUrls]
  );

  const handleNextView = useCallback(() => {
    setSelectedIndex(prevState => {
      if (videoUrls && videoUrls.length) {
        return (prevState + 1) % videoUrls?.length;
      }
      return prevState;
    });
  }, [videoUrls]);

  const handlePrevView = useCallback(() => {
    setSelectedIndex(prevState => {
      if (videoUrls && videoUrls.length) {
        return (prevState - 1 + videoUrls.length) % videoUrls?.length;
      }
      return prevState;
    });
  }, [videoUrls]);

  const controllersLabel = useMemo(
    () =>
      t("objectPreview.view360.carrouselLabel", {
        values: {
          current: selectedIndex + 1,
          total: videoUrls?.length,
        },
      }),
    [t, selectedIndex, videoUrls]
  );

  return (
    <>
      {(!videoUrls || videoUrls.length === 0) && (
        <NoFilesAvailable
          translationKey="objectPreview.view360.notAvailable"
          icon={faVideoSlash}
        />
      )}
      {videoUrls && (
        <div className={styles.objectView360Wrapper}>
          <iframe
            className={styles.objectView360Iframe}
            src={resolveYoutubeLink(videoUrls?.[selectedIndex] || "")}
            title="360ViewIframe"
          />
          {showControls && (
            <div className={styles.objectView360Controls}>
              <ObjectCarouselControllers
                label={controllersLabel}
                prevAction={handlePrevView}
                nextAction={handleNextView}
                pillTheme={PillThemes.Blank}
              />
            </div>
          )}
        </div>
      )}
    </>
  );
};

export { ObjectView360 };
