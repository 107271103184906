import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  objectBrochureItemWrapper: responsiveStyles({
    position: "relative",
    backgroundColor: theme.colors.offWhite.toString(),
    height: "100%",
    display: "flex",
    alignItems: "flex-end",
    justifyContent: "center",
    borderRadius: 3,
    border: `1px solid ${theme.colors.lightGray.toString()}`,
  })
    .xs({ minHeight: 375 }, { propagate: true })
    .getStyle(),
  objectBrochureItemPreview: {
    position: "absolute",
    objectFit: "cover",
    height: "100%",
    width: "100%",
    top: 0,
    left: 0,
    zIndex: 1,
  },
  objectBrochureItemDetails: {
    padding: {
      top: 5,
      left: 2,
      right: 2,
      bottom: 2,
    },
    maxWidth: "100%",
    width: "100%",
    backgroundColor: theme.colors.offWhite.toString(),

    textAlign: "center",
    zIndex: 2,
  },
  objectBrochureItemTitle: {
    fontSize: 13,
    fontWeight: 500,
    margin: 0,
    marginBottom: 5,
    padding: "0 13px",
    whiteSpace: "nowrap",
    textOverflow: "ellipsis",
    overflow: "hidden",
  },
}));

export { useStyles };
