import { FC, useMemo } from "react";
import { EnergyClass } from "@kolibrisoftware/customerportal-api";
import { useTheme } from "injectors/theme";
import { useStyles } from "./styles";
import classNames from "classnames";
import { useTranslation } from "@kolibrisoftware/hooks-util";

type Props = {
  energyClass: EnergyClass;
};

const EnergyLabel: FC<Props> = ({ energyClass }) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const t = useTranslation();

  const labelTheme = useMemo(() => {
    switch (energyClass) {
      case EnergyClass.Aplus:
      case EnergyClass.Aplusplus:
      case EnergyClass.Aplusplusplus:
      case EnergyClass.Aplusplusplusplus:
      case EnergyClass.Aplusplusplusplusplus:
        return styles.theme_A;
      default:
        return styles[`theme_${energyClass}`];
    }
  }, [styles, energyClass]);
  return (
    <div className={classNames(styles.energyLabelWrapper, labelTheme)}>
      {t("energyClassOptions." + energyClass)}
    </div>
  );
};

export { EnergyLabel };
