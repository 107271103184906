import { useDefaultMediaQueries } from "@kolibrisoftware/hooks-util";
import { PageLoadingStatus } from "enums/page-loading-status";
import MobileNavigation from "layouts/mobile-navigation-layout/mobile-navigation";
import { useCallback, useLayoutEffect, useRef, useEffect } from "react";
import { Outlet, useParams } from "react-router-dom";
import {
  actions as dossierActions,
  thunks as dossierThunks,
} from "store/dossier";
import { useDispatch, useSelector } from "store/helpers";
import { thunks as mainThunks } from "store/main";

export const DossierModuleWrapper = () => {
  const { isScreenXS, isScreenS } = useDefaultMediaQueries();
  const { dossierId } = useParams();
  const initialized = useRef(false);
  const dispatch = useDispatch();
  const { assignmentDetails, loadingStatus } = useSelector(
    state => state.dossier
  );
  const companyData = useSelector(state => state.main.companyData);
  const culture = useSelector(state => state.main.culture);
  const initial = useCallback(async () => {
    try {
      if (!assignmentDetails || assignmentDetails.id !== dossierId) {
        dispatch(dossierActions.clearState());
        if (!companyData) {
          await dispatch(mainThunks.getCompanyData());
        }

        await dispatch(
          dossierThunks.loadAssignment({ assignmentId: dossierId || "" })
        );
      }
    } catch (error) {
      throw error;
    }
  }, [dossierId, assignmentDetails, dispatch, companyData]);

  useLayoutEffect(() => {
    if (!initialized.current) {
      initial();
      initialized.current = true;
    }
  }, [initial]);

  useEffect(() => {
    if (initialized.current && loadingStatus === PageLoadingStatus.Success) {
      dispatch(dossierThunks.loadChecklistItems({ culture: culture }));
    }
  }, [culture, dispatch, loadingStatus]);

  return (
    <>
      {loadingStatus === PageLoadingStatus.Success && <Outlet />}
      {(isScreenXS || isScreenS) && <MobileNavigation />}
    </>
  );
};
