import {
  RawTemplate,
  ValidRow,
  ValidatedTemplate,
  ValidatorType,
} from "./types";

/**
 *
 * @param template
 * Checks all the categories in the template:
 *    - Iterates through rows
 *        It will find the first option that has all values set
 *          if Found -> Pushes a row with just that option to the category
 *          if NotFound -> Will do nothing
 *    If the category has no rows, it will be excluded from the validatedTemplate
 *    If category is disabled, it will ignore it
 */
const rawTemplateValidator = (template: RawTemplate): ValidatedTemplate => {
  const response: ValidatedTemplate = {}; // Validate the template
  // Iterating through categories
  template.forEach(category => {
    const categoryKey = category.label;
    if (category.disabled) {
      return;
    }
    const validatedCategory: ValidRow[] = [];
    // Iterating through rows
    category.rows.forEach(row => {
      // Find first available option
      const rowOption = row.options.find(option => {
        switch (option.validatorType) {
          /**
           * Checking the categories
           *
           * 1. Simple
           *    a. hasNoValues and has translationKey
           *    b. hasTranslationKey and all values are present
           * 2. Complex
           *    a. isArray and has at least one value
           *    b. value is not undefined
           */
          case ValidatorType.Simple:
            // 1.a
            if (option.simple?.hasNoValues && !!option.simple.translationKey) {
              return option;
              // 1.b
            } else if (option.simple?.translationValues) {
              const translationValueKeys = Object.keys(
                option.simple.translationValues
              );
              let isDefined = translationValueKeys.length > 0;

              for (let i = 0; i < translationValueKeys.length; i++) {
                const currentKey = translationValueKeys[i];
                if (!option.simple?.translationValues[currentKey]) {
                  isDefined = false;
                  break;
                }
              }

              if (isDefined) return true;
            }
            break;

          case ValidatorType.Complex:
            // 2.a
            if (option.complex?.isArray && !!option.complex.value.length) {
              return true;
              // 2.b
            } else if (!option.complex?.isArray && !!option.complex?.value) {
              return true;
            }
            break;
          default:
            break;
        }
        return false;
      });
      if (!!rowOption) {
        validatedCategory.push({ label: row.label, option: rowOption });
      }
    });
    if (!!validatedCategory.length) {
      response[categoryKey] = validatedCategory;
    }
  });
  return response;
};

export default rawTemplateValidator;
