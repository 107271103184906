import React, { FC, useCallback, useEffect } from "react";
import { useStyles } from "./styles";
import { Toast as ToastInterface } from "store/toasts";
import classnames from "classnames";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTimes } from "@fortawesome/pro-regular-svg-icons";
import { useTheme } from "injectors/theme";

type Props = {
  onRemove: (id: string) => void;
  size?: "small" | "medium" | "large";
} & ToastInterface &
  React.DetailedHTMLProps<React.HTMLAttributes<HTMLDivElement>, HTMLDivElement>;

const Toast: FC<Props> = ({
  id,
  message,
  icon,
  canManuallyRemove,
  removeAfter,
  theme: toastTheme,
  errorId,
  onRemove,
  className,
  size,
  ...nativeProps
}) => {
  const theme = useTheme();
  const styles = useStyles({ size, theme: toastTheme })({ theme });

  const onRemoveCallback = useCallback(() => {
    onRemove(id);
  }, [onRemove, id]);

  useEffect(() => {
    if (!!removeAfter) {
      setTimeout(onRemoveCallback, removeAfter);
    }
  }, [removeAfter, onRemoveCallback]);

  return (
    <div
      {...nativeProps}
      className={classnames(styles.toastContainer, className)}
    >
      {!!icon && (
        <div className={styles.icon}>
          <FontAwesomeIcon icon={icon} fontSize={20} />
        </div>
      )}
      <div
        className={styles.message}
        dangerouslySetInnerHTML={{ __html: message }}
      />
      {!!canManuallyRemove && (
        <div className={styles.close} onClick={onRemoveCallback}>
          <FontAwesomeIcon icon={faTimes} />
        </div>
      )}
    </div>
  );
};

export { Toast };
