import {
  faCheck,
  faChevronLeft,
  faCircleExclamation,
  faPenToSquare,
  faSpinnerThird,
} from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  useDefaultMediaQueries,
  useTranslation,
} from "@kolibrisoftware/hooks-util";
import classNames from "classnames";
import { ROUTER_PATHS } from "constants/routes";
import { AnswerSavingStatus } from "enums/answer-saving-status";
import { useTheme } from "injectors/theme";
import { FC, useMemo } from "react";
import { Outlet, useNavigate, useParams } from "react-router-dom";
import SecondaryNavbar from "layouts/secondary-navbar-wrapper";
import { useStyles } from "./styles";

type Props = {
  centerTitle: string;
  backActionLabel: string;
  savingStatus?: AnswerSavingStatus;
  reviewButton?: React.ReactNode;
  children?: React.ReactNode;
  presence?: React.ReactNode;
};

const BackActionLayout: FC<Props> = ({
  centerTitle,
  backActionLabel,
  savingStatus,
  reviewButton,
  presence,
  children,
}) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const t = useTranslation();
  const { isScreenXS, isScreenS } = useDefaultMediaQueries();
  const navigate = useNavigate();
  const { dossierId } = useParams();

  const checklistPath = useMemo(
    () => `/dossier/${dossierId}/${ROUTER_PATHS.CHECKLIST}`,
    [dossierId]
  );

  const translations = useMemo(
    () => ({
      validatorSaved: t("validatorLabel.saved"),
      validatorInProgress: t("validatorLabel.inProgress"),
      validatorEditing: t("validatorLabel.editing"),
      validatorError: t("validatorLabel.error"),
    }),
    [t]
  );

  const savingStatusIcon = useMemo(() => {
    switch (savingStatus) {
      case AnswerSavingStatus.Editing:
        return {
          icon: faPenToSquare,
          label: translations.validatorEditing,
          color: "#ff7600",
        };
      case AnswerSavingStatus.Saved:
        return {
          icon: faCheck,
          label: translations.validatorSaved,
          color: "green",
        };
      case AnswerSavingStatus.Error:
        return {
          icon: faCircleExclamation,
          label: translations.validatorError,
          color: "red",
        };
      default:
        return {
          icon: faSpinnerThird,
          label: translations.validatorInProgress,
        };
    }
  }, [savingStatus, translations]);

  return (
    <>
      <SecondaryNavbar>
        <>
          <div className={styles.navActionWrapper}>
            <button
              className={styles.navAction}
              tabIndex={-1}
              onClick={() => navigate(checklistPath)}
            >
              <FontAwesomeIcon icon={faChevronLeft} />
              {!isScreenXS && !isScreenS && <p>{backActionLabel}</p>}
            </button>
          </div>
          <div className={styles.navTitle}>
            <span>{centerTitle}</span>
            {savingStatus && (
              <div className={styles.savingStatus}>
                •
                <span
                  className={classNames(styles.savingStatusIcon, {
                    [styles.loadingStatus]:
                      savingStatus === AnswerSavingStatus.InProgress,
                  })}
                >
                  <FontAwesomeIcon
                    style={{ color: savingStatusIcon.color }}
                    icon={savingStatusIcon.icon}
                  />
                </span>
                <span className={styles.savingStatusLabel}>
                  {savingStatusIcon?.label}
                </span>
              </div>
            )}
          </div>
          <div className={styles.fauxDix}>
            {presence}
            {reviewButton}
          </div>
        </>
      </SecondaryNavbar>
      <div className={styles.layoutWrapper}>
        {children ? children : <Outlet />}
      </div>
    </>
  );
};

export { BackActionLayout };
