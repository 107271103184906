import { ThemeInterface } from "injectors/theme";
import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  questionDetailsWrapper: responsiveStyles({
    position: "relative",
    display: "flex",
    gap: 10,
    padding: "10px 0",
    alignItems: "center",
  })
    .s(
      {
        flexDirection: "column",
        alignItems: "unset",
        gap: 5,
      },
      { propagate: true }
    )
    .getStyle(),
  questionDetailsColumn: {
    flexDirection: "column",
    alignItems: "unset",
    gap: 5,
  },
  booleanWrapper: responsiveStyles({
    flexDirection: "row-reverse",
    alignItems: "center",
  })
    .s(
      {
        "& $inputWrapper": {
          flex: "unset",
          minWidth: "unset",
        },
      },
      { propagate: true }
    )
    .getStyle(),

  bottomBorder: {
    "&:not(:last-child)": {
      borderBottom: "1px solid #efefef",
    },
  },
  childrenPadding: responsiveStyles({
    paddingLeft: 33,
  })
    .s(
      {
        paddingLeft: 15,
      },
      { propagate: true }
    )
    .xs({ paddingLeft: 0 })
    .getStyle(),
  translationWrapper: {
    flex: 3,
  },
  boolTranslationWrapper: {
    flex: 1,
  },
  inputWrapper: {
    flex: 1,
  },
  boolInputWrapper: {
    flex: "none",
    maxWidth: "70%",
  },
}));

export { useStyles };
