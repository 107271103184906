import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  foreignSectionContainer: {},
  divider: {
    outline: "none",
    border: "none",
    height: 1,
    background: "#80808033",
  },
}));

export { useStyles };
