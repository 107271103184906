import { createEntityAdapter, createSlice } from "@reduxjs/toolkit";
import { Toast } from ".";

export const adapter = createEntityAdapter<Toast>();
export const slice = createSlice({
  name: "toasts",
  initialState: adapter.getInitialState(),
  reducers: {
    addToast: adapter.upsertOne,
    removeToast: adapter.removeOne,
  },
});
