import {
  faEnvelope,
  faGlobe,
  faPhone,
} from "@fortawesome/pro-regular-svg-icons";
import { PhoneNumberType } from "@kolibrisoftware/customerportal-public-api";
import { useTheme } from "injectors/theme";
import { isUndefined } from "lodash-es";
import { FC, useMemo } from "react";
import { useSelector } from "store/helpers";
import CompanyDetailsCardRow from "./company-details-card-row";
import { useStyles } from "./styles";
import classnames from "classnames";

type Props = { extraStyles?: React.CSSProperties };

const CompanyDetailsCard: FC<Props> = ({ extraStyles }) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const companyData = useSelector(state => state.main.companyData);

  const phoneNumber = useMemo(() => {
    const phoneNumberIndex = companyData?.phoneNumbers?.findIndex(
      item => item.type === PhoneNumberType.Work
    );

    if (phoneNumberIndex && phoneNumberIndex >= 0) {
      return companyData?.phoneNumbers?.[phoneNumberIndex].number;
    }
    return false;
  }, [companyData]);

  return (
    <div
      className={classnames(styles.companyDetailsCardContainer, {
        [styles.noLogoDetails]: !companyData?.logo,
      })}
      style={extraStyles}
    >
      {companyData?.logo && (
        <div className={styles.companyLogoWrapper}>
          <img
            className={styles.companyLogo}
            src={`data:image/jpeg;base64,${companyData?.logo}`}
            alt="Company logo"
          />
        </div>
      )}
      <div className={styles.companyDetailsWrapper}>
        <h3 className={styles.companyName}>{companyData?.name}</h3>

        <CompanyDetailsCardRow
          label={"companyDetails.phone"}
          value={phoneNumber || ""}
          linkTo={`tel:${phoneNumber}`}
          icon={faPhone}
        />

        <CompanyDetailsCardRow
          label={"companyDetails.email"}
          value={companyData?.email || ""}
          linkTo={`mailto:${companyData?.email}`}
          icon={faEnvelope}
        />

        <CompanyDetailsCardRow
          label={"companyDetails.website"}
          value={companyData?.website || ""}
          linkTo={companyData?.website || ""}
          icon={faGlobe}
        />
      </div>
    </div>
  );
};

export { CompanyDetailsCard };
