import React, { FC } from "react";
import { useStyles } from "./styles";
import { useTheme } from "injectors/theme";
import {
  actions as toastsActions,
  selectors as toastsSelectors,
} from "store/toasts";
import { useDispatch, useSelector } from "store/helpers";
import Portal from "../portal";
import Toast from "../toast";

type Props = {};

const Snackbar: FC<Props> = props => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const toasts = useSelector(toastsSelectors.selectAll);
  const dispatch = useDispatch();

  const onRemoveCallback = (id: string) => {
    dispatch(toastsActions.removeToast(id));
  };

  return (
    <>
      {!!toasts.length && (
        <Portal>
          <div className={styles.snackbarContainer}>
            {toasts.map(toast => {
              return (
                <Toast
                  key={toast.id}
                  onRemove={() => onRemoveCallback(toast.id)}
                  size="medium"
                  {...toast}
                />
              );
            })}
          </div>
        </Portal>
      )}
    </>
  );
};

export { Snackbar };
