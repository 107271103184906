import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  carouselControllersWrapper: {},
  carouselControllerLabel: {
    padding: 5,
  },
}));

export { useStyles };
