import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  snackbarContainer: {
    position: "fixed",
    bottom: 0,
    right: 30,
  },
}));

export { useStyles };
