export type Range = {
  start: number;
  stop: number;
  height: number;
  ref: HTMLDivElement;
};

const mapIntersectionObserverEntriesToRange = (
  entries: IntersectionObserverEntry[],
  observer: IntersectionObserver
) => {
  const calculatedRanges = entries.map(entry => {
    const rect = entry.target.getBoundingClientRect();
    const parentRect = (
      observer?.root as HTMLDivElement
    )?.getBoundingClientRect();

    return {
      start: rect.top - parentRect.top,
      stop: rect.top - parentRect.top + rect.height,
      height: rect.height,
      ref: entry.target,
    } as Range;
  });
  return calculatedRanges;
};

export { mapIntersectionObserverEntriesToRange };
