import { faFolderXmark } from "@fortawesome/pro-regular-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useTranslation } from "@kolibrisoftware/hooks-util";
import { useTheme } from "injectors/theme";
import { FC } from "react";
import { useStyles } from "./styles";

type Props = {};

const EmptyScreenComponent: FC<Props> = props => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const t = useTranslation();

  return (
    <div className={styles.emptyScreenWrapper}>
      <FontAwesomeIcon icon={faFolderXmark} className={styles.icon} />
      <span>{t("dossiers.noDossiers")}</span>
    </div>
  );
};

export { EmptyScreenComponent };
