import { SingleAssignment } from "@kolibrisoftware/customerportal-api";
import { useTranslation } from "@kolibrisoftware/hooks-util";
import { useTheme } from "injectors/theme";
import PlaceholderPhoto from "shared/ui/placeholder-thumbnail";
import { FC, useMemo } from "react";
import useStyles from "./styles";
import classnames from "classnames";
import { intl } from "injectors/translations/helpers/intl-context";

type Props = {
  assignment: SingleAssignment;
};

export const AssignmentCard: FC<Props> = ({ assignment }) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const t = useTranslation();

  const translations = useMemo(
    () => ({
      price: assignment.forSale
        ? t("assignmentCard.price.forSale", {
            values: {
              price: assignment.price || 0,
            },
          })
        : t(`assignmentCard.price.forRent.${assignment.rentCondition}`, {
            values: { price: assignment.price || 0 },
          }),
    }),
    [t, assignment]
  );

  return (
    <div className={styles.previewContainer}>
      <div className={styles.thumbWrapper}>
        <div className={styles.thumbContainer}>
          {assignment.previewThumbnailUrl && (
            <div
              className={classnames(styles.thumb)}
              style={{
                backgroundImage: `url(${assignment.previewThumbnailUrl})`,
              }}
            />
          )}
          {!assignment.previewThumbnailUrl && (
            <PlaceholderPhoto isResponsive={true} />
          )}
        </div>
      </div>
      <div className={styles.body}>
        <div className={styles.previewTitle}>{assignment.displayName}</div>
        <div className={styles.previewBody}>
          {`${assignment.postalCode} ${assignment.locality}`}
        </div>
        <div className={styles.previewBody}>{translations.price}</div>
      </div>
    </div>
  );
};
