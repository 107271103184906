import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  objectTabsContainer: responsiveStyles({
    padding: 14,
    display: "flex",
    justifyContent: "space-between",
    flexWrap: "wrap",
    gap: 14,
  })
    .s(
      {
        gap: 5,
        justifyContent: "flex-start",
      },
      { propagate: true }
    )
    .getStyle(),
}));

export { useStyles };
