import { FormAnswerEditor } from "@kolibrisoftware/customerportal-api";
import { useDefaultMediaQueries } from "@kolibrisoftware/hooks-util";
import { extractFormTranslationsFromStructure } from "helpers/form-elements";
import { useTheme } from "injectors/theme";
import FormSectionGroup from "modules/list-of-items/components/form-section-group";
import { FC, useEffect, useMemo, useState } from "react";
import { AnswerNav } from "shared/components/answer-nav";
import DocumentBottomHint from "shared/components/document-bottom-hint";
import HeaderSection from "shared/ui/header-section";
import { useSelector } from "store/helpers";
import { selectListOfItemsAnswers } from "store/list-of-items/selectors";
import { useStyles } from "./styles";

type Props = {
  renewHint?: boolean;
};

const RootScreen: FC<Props> = ({ renewHint }) => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const formStructure = useSelector(state => state.listOfItems.formStructure);
  const formEditableStatus = useSelector(
    state => state.listOfItems.formEditableStatus
  );
  const questionAnswers = useSelector(selectListOfItemsAnswers);
  const { isScreenXS, isScreenS } = useDefaultMediaQueries();
  const [isHintVisible, setIsHintVisible] = useState<boolean>(
    !isScreenXS && !isScreenS
  );

  const formSectionGroups = useMemo(() => {
    return formStructure?.parts || [];
  }, [formStructure]);
  const formTranslations = extractFormTranslationsFromStructure(formStructure);

  const modifiedAnswerIds = useMemo(() => {
    return (formStructure?.latestModifiedAnswerIds || []).reduce(
      (state, latestModifiedAnswerId) => {
        const answer = questionAnswers.find(
          questionAnswer => questionAnswer.id === latestModifiedAnswerId
        );

        if (answer && answer.editor === FormAnswerEditor.Owner) {
          state.push(latestModifiedAnswerId);
        }

        return state;
      },
      [] as string[]
    );
  }, [formStructure?.latestModifiedAnswerIds, questionAnswers]);

  const showAnswerNav = useMemo(
    () => modifiedAnswerIds.length > 0,
    [modifiedAnswerIds]
  );

  useEffect(() => {
    if (renewHint) {
      setIsHintVisible(renewHint);
    }
  }, [renewHint]);

  return (
    <div className={styles.contentWrapper}>
      <div className={styles.listOfItemsDocumentWrapper}>
        {formTranslations && (
          <HeaderSection
            title={formTranslations.title}
            description={formTranslations.description}
          />
        )}

        {formSectionGroups.map(sectionGroupStructure => {
          return (
            <FormSectionGroup
              sectionGroupStructure={sectionGroupStructure}
              key={sectionGroupStructure.id}
            />
          );
        })}
        <div style={{ height: 100 }}></div>
      </div>

      <div className={styles.hints}>
        {isHintVisible && (
          <DocumentBottomHint
            status={formEditableStatus}
            removeCallback={() => setIsHintVisible(false)}
          />
        )}

        {showAnswerNav && <AnswerNav modifiedAnswerIds={modifiedAnswerIds} />}
      </div>
    </div>
  );
};

export { RootScreen };
