import { responsiveStyles } from "@kolibrisoftware/helpers-util";
import { ThemeInterface } from "injectors/theme";
import { createUseStyles } from "react-jss";

const useStyles = createUseStyles((theme: ThemeInterface) => ({
  addQuestionWrapper: responsiveStyles({
    display: "flex",
    gap: 20,
    paddingBottom: 10,
  })
    .s(
      {
        marginTop: 20,
        paddingBottom: 20,
        display: "block",
        borderBottom: `1px solid ${theme.colors.offBlack
          .alpha(0.2)
          .toString()}`,
      },
      { propagate: true }
    )
    .getStyle(),
  addQuestionInputContainer: {
    position: "relative",
    flex: 4,
  },
  addQuestionLabel: {
    fontSize: 13,
    fontWeight: 500,
  },
  addQuestionAction: {
    position: "absolute",
    top: 0,
    height: "100%",
    display: "flex",
    alignItems: "center",
    right: "5px",
    fontSize: 15,
    color:
      theme.companyTheme?.mainColor?.toString() ||
      theme.colors.primary.toString(),
    transition: "all 0.2s ease",
    width: 18,
    "&:hover": {
      color: theme.colors.success.toString(),
      opacity: 0.8,
    },
  },
  fauxSpacer: {
    flex: 2,
  },
}));

export { useStyles };
