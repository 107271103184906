import { createSelector } from "@reduxjs/toolkit";
import { RootState } from "store";
import { selectChecklistItems } from "store/dossier/selectors";

export const selectQuestionnaireSlice = (state: RootState) =>
  state.questionnaire;

export const selectQuestionnaireForm = createSelector(
  selectQuestionnaireSlice,
  questionnaire => questionnaire.formStructure
);

export const selectQuestionnaireFormId = createSelector(
  selectQuestionnaireSlice,
  questionnaire => questionnaire.formStructure.id
);

export const selectQuestionnaireLatestModifiedAnswerIds = createSelector(
  selectQuestionnaireForm,
  questionnaire => questionnaire.latestModifiedAnswerIds || []
);

export const selectQuestionnaireAnswers = createSelector(
  selectQuestionnaireSlice,
  questionnaire => questionnaire.answers
);

export const selectQuestionnaireChecklistItem = createSelector(
  selectChecklistItems,
  selectQuestionnaireForm,
  (checklistItems, questionnaire) =>
    checklistItems.find(
      checklistItem => checklistItem.formId === questionnaire.id
    )
);

export const selectQuestionnaireHasModifiedAnswers = createSelector(
  selectQuestionnaireForm,
  questionnaire => !!questionnaire.latestModifiedAnswerIds?.length
);
