import {
  ErrorCode,
  Client as PublicCustomerportalClient,
} from "@kolibrisoftware/customerportal-public-api";
import { AppStatus } from "enums/app-status";
import { createErrorToast } from "helpers/create-new-toast";
import { createThunk, httpWebUtil } from "store/helpers";
import { actions as toastActions } from "store/toasts";
import { actions as mainActions } from "./index";
import { Culture } from "@kolibrisoftware/customerportal-api";

const initial = createThunk("main/initial", async ({ dispatch, getState }) => {
  try {
    dispatch(mainActions.setAppStatus(AppStatus.Loading));

    await dispatch(getCompanyData());
    await dispatch(getUserId());

    dispatch(mainActions.setAppStatus(AppStatus.Success));
  } catch (error: any) {
    dispatch(mainActions.setAppStatus(AppStatus.Failed));
    throw error;
  }
});

const loadTranslation = createThunk(
  "main/loadTranslation",
  async ({ dispatch }, culture?: Culture) => {
    try {
      const usedCulture = (culture || Culture.Nl).toLowerCase();
      const translations = await fetch(
        `${process.env.PUBLIC_URL}/translations/${usedCulture}.json`,
        {
          cache: "no-cache",
        }
      ).then(response => response.json());

      const enumTranslations = await fetch(
        `${process.env.PUBLIC_URL}/translations/enum-${usedCulture}.json`,
        {
          cache: "no-cache",
        }
      ).then(response => response.json());

      dispatch(
        mainActions.setTranslations({ ...translations, ...enumTranslations })
      );
    } catch (error) {
      throw error;
    }
  }
);

const getCompanyData = createThunk(
  "main/getCompanyData",
  async ({ settings, dispatch, handleError }) => {
    try {
      const { host } = window.location;
      if (!host) {
        throw new Error("No domain provided");
      }

      let [subdomain, domain, extension] = host.split(".");

      if (
        process.env.NODE_ENV === "development" ||
        (window as any).__CYPRESS__ ||
        (window as any).Cypress
      ) {
        domain = "mijnmakelaaronline";
        extension = "nl";
        subdomain = "kolibrihelpdeskalpha"; // VBO
        // subdomain = "vgpkantoor"; // vastgoedpro
        // subdomain = "nevermindmakelaardij"; // NVM
        // subdomain = "testhaha"; // OTHERS

        if ((window as any).__CYPRESS__ || (window as any).Cypress) {
          subdomain = "kolibrihelpdeskalpha";
        }
      }

      if (!settings?.apiUrlCustomerPortalPublic) {
        return;
      }
      const client = new PublicCustomerportalClient(
        settings?.apiUrlCustomerPortalPublic
      );

      const companyData = await client
        .getCompanyData(subdomain, [domain, extension].join("."))
        .then(response => response.companyData)
        .catch(error => {
          if (error?.result?.errorCode === ErrorCode.NotFound) {
            return null;
          } else {
            throw error;
          }
        });

      if (companyData === undefined) {
        throw new Error("Error retreiving company settings");
      }
      if (!companyData) {
        dispatch(mainActions.setAppStatus(AppStatus.NoCompanyData));
        return;
      }
      if (
        window.location.pathname !== "/" ||
        process.env.NODE_ENV !== "development"
      ) {
        dispatch(mainActions.setCompanyData(companyData));
      }
      return companyData;
    } catch (error: any) {
      const errorMessage = error.message
        ? error.message
        : `Something went wrong while loading the data.`;
      const toast = createErrorToast(errorMessage);
      dispatch(toastActions.addToast(toast));
      handleError(error);
      throw error;
    }
  }
);

const getUserId = createThunk("getUserId", async ({ dispatch, userId }) => {
  try {
    const currentUser = await userId();
    dispatch(mainActions.setUserId(currentUser));
  } catch (error) {
    throw new Error("Could not retrieve the current user's id");
  }
});

const logout = createThunk("logout", async ({ settings, dispatch }) => {
  try {
    if (!settings?.authority) {
      return;
    }

    httpWebUtil.signout().then(() => {
      window.location.href = `${settings.authority}logout`;
    });
  } catch (error: any) {
    const errorMessage = error.message
      ? error.message
      : `Something went wrong while trying to logout`;
    const toast = createErrorToast(errorMessage);
    dispatch(toastActions.addToast(toast));
  }
});

const navigateToProfile = createThunk(
  "navigateToProfile",
  async ({ settings, dispatch }) => {
    try {
      if (!settings?.authority) {
        return;
      }
      window.open(`${settings.authority}`, "_blank");
    } catch (error: any) {
      const errorMessage = error.message
        ? error.message
        : `Path for profile is missing`;
      const toast = createErrorToast(errorMessage);
      dispatch(toastActions.addToast(toast));
    }
  }
);

const thunks = {
  initial,
  getCompanyData,
  logout,
  navigateToProfile,
  getUserId,
  loadTranslation,
};

export default thunks;
