import {
  ElementEnumerationType,
  UserForm,
  FormCustomQuestionExtended,
  FormElement,
  FormElementExtended,
  FormElementType,
} from "@kolibrisoftware/customerportal-api";

const extractFormTranslationsFromStructure = (document: UserForm) => {
  const translations = document.translations?.[0];

  if (!translations) {
    return null;
  }
  return translations;
};

/**
 *
 * Extracts and flattens all sections from a Form object
 *
 * @param document
 * @returns
 */
const extractAndFlatSectionsFromStructure = (
  document: UserForm
): FormElement[] | null => {
  if (!document.parts) {
    return null;
  }

  let formSections = document.parts.reduce((result, part) => {
    const sections = part.elements?.filter(
      element => element.type === FormElementType.Section
    );
    if (sections) {
      result = [...result, ...sections];
    }
    return result;
  }, [] as FormElement[]);

  return formSections;
};

const extractAndFlatQuestionIdsFromElementsArray = (
  elements: FormElementExtended[]
): string[] => {
  if (!elements) {
    return [];
  }
  const result = elements
    .filter(element => element.type === FormElementType.Question && element.id)
    .map(element => element.id || "");
  return result;
};

const extractAndFlatCustomQuestionIds = (
  elements: FormCustomQuestionExtended[]
): string[] => {
  if (!elements) {
    return [];
  }
  return elements.map(element => element.id || "");
};
const indexToLetter = (i: number) => String.fromCharCode(97 + (i % 26));

const indexToEnumPrefix = (
  index: number,
  enumType: ElementEnumerationType | undefined
) => {
  let enumValue = "";
  switch (enumType) {
    case ElementEnumerationType.Dot:
      enumValue = "•";
      break;
    case ElementEnumerationType.Letter:
      enumValue = `${indexToLetter(index)}.`;
      break;
    case ElementEnumerationType.Number:
      enumValue = `${index}.`;
      break;
    default:
      break;
  }
  return enumValue;
};

export {
  extractFormTranslationsFromStructure,
  extractAndFlatCustomQuestionIds,
  extractAndFlatSectionsFromStructure,
  extractAndFlatQuestionIdsFromElementsArray,
  indexToLetter,
  indexToEnumPrefix,
};
