import {
  MenuItem,
  MenuItemsEvents,
  MenuItemsUseCase,
} from "constants/menu-items";
import useMenuItems from "hooks/useMenuItems";
import { useTheme } from "injectors/theme";
import { FC, useCallback } from "react";
import { PrimaryNavigationNavItem } from "../primary-navigation-nav-item/primary-navigation-nav-item";
import { useStyles } from "./styles";
import { thunks as mainThunks, actions as mainActions } from "store/main";
import { ROUTER_PATHS } from "constants/routes";
import { useDispatch } from "store/helpers";
import { useNavigate } from "react-router-dom";
import { CompanyDetailsCard } from "shared/components/company-details-card/company-details-card";
import { LanguageSelect } from "@kolibrisoftware/customerportal-ui";
import { useSelector } from "store/helpers";
import { Culture } from "@kolibrisoftware/customerportal-api";

type Props = {};

const PrimaryNavigation: FC<Props> = props => {
  const theme = useTheme();
  const styles = useStyles({ theme });
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const currentCulture = useSelector(state => state.main.culture);
  const menuItems = useMenuItems(MenuItemsUseCase.PRIMARY);

  const setCulture = useCallback(
    (culture: string) => {
      dispatch(mainActions.setCulture(culture as Culture));
    },
    [dispatch]
  );

  const handleExternalNavigation = useCallback(
    (item: MenuItem) => {
      switch (item.path) {
        case ROUTER_PATHS.LOGOUT:
          dispatch(mainThunks.logout());
          break;
        case ROUTER_PATHS.PROFILE:
          dispatch(mainThunks.navigateToProfile());
          break;
        default:
          window.open(item.path);
      }
    },
    [dispatch]
  );

  /**
   * Returns the navigation item
   * with resolved onClickHandler
   */
  const navItemsMapper = useCallback(
    (item: MenuItem, index: number) => {
      if (item.eventType === MenuItemsEvents.LanguageSwitch) {
        return (
          <LanguageSelect
            currentCulture={currentCulture.toString()}
            setCulture={setCulture}
            key={`mobile-nav-item-${index}`}
          />
        );
      }

      let onClickHandler = () => navigate(item.path);
      let contextMenuContent = undefined;

      if (item.eventType === MenuItemsEvents.ExternalNavigation) {
        onClickHandler = () => handleExternalNavigation(item);
      }

      if (item.eventType === MenuItemsEvents.OpenModal) {
        switch (item.path) {
          case ROUTER_PATHS.REALTOR:
            contextMenuContent = <CompanyDetailsCard />;
            break;
          default:
            break;
        }
      }

      return (
        <PrimaryNavigationNavItem
          item={item}
          key={`mobile-nav-item-${index}`}
          onClick={onClickHandler}
          contextMenuContent={contextMenuContent}
        />
      );
    },
    [handleExternalNavigation, navigate, currentCulture, setCulture]
  );

  return (
    <div className={styles.primaryNavigationWrapper}>
      {menuItems.map(navItemsMapper)}
    </div>
  );
};

export { PrimaryNavigation };
